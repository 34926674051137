


























































































































import Component from "vue-class-component";
import { Emit, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import RulesMixin from "../mixins/rulesMixin";
import AppSelect from "./AppSelect.vue";
import NumberInput from "./NumberInput.vue";
import AppSubTitle from "./AppSubTitle.vue";
import DatePickerYearFormatMixin from "../mixins/datePickerYearFormatMixin";

@Component({
  components: {
    AppSelect,
    NumberInput,
    AppSubTitle
  }
})
export default class DateInput extends Mixins(
  RulesMixin,
  DatePickerYearFormatMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: "" }) value!: string | null;
  @Prop({ default: "600px" }) width!: string;
  @Prop({ default: "" }) require_message!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) isNeed!: boolean;
  @Prop({ default: false }) isBirthday!: boolean;
  /** 今日ボタン表示フラグ */
  @Prop({ default: true }) show_today!: boolean;
  /** 8月1日ボタン表示フラグ */
  @Prop({ default: false }) private august_start!: boolean;
  /** 7月末ボタン表示フラグ */
  @Prop({ default: false }) private july_end!: boolean;
  /** 1年ボタン表示フラグ */
  @Prop({ default: false }) private one_year!: boolean;
  @Prop() private targetDate!: string;
  /** ルール */
  @Prop({ type: Array, default: () => [] }) rules!: (string | boolean)[];

  /** 無効化フラグ */
  @Prop({ default: false }) private disabled!: boolean;

  private readonly nowYear = new Date().getFullYear();
  private eraValue = "";
  private year = 0;
  private month = 0;
  private day = 0;
  private openCalendar = false;

  private get Label() {
    if (this.isNeed && this.label) {
      return "*" + this.label;
    }
    return this.label;
  }

  private get Value() {
    // 初期値に今日をセット
    const todayString = this.getTodayStr();
    let targetDate = todayString;

    // 正しい日付が入力されていればカレンダーに反映
    if (this.setCalendarDateCheck()) {
      const targetYear = this.eraValue.split("-")[0];
      const nowYear = Number(targetYear) + this.Year - 1;
      targetDate =
        nowYear.toString() +
        "-" +
        ("0" + this.Month).slice(-2) +
        "-" +
        ("0" + this.Day).slice(-2);
    }

    return targetDate;
  }
  private set Value(targetDate: string) {
    this.setDateForm(targetDate);
    this.openCalendar = false;
  }

  private get EraValue() {
    return this.eraValue;
  }
  private set EraValue(newValue) {
    //年号未選択の場合、年月日をリセット
    if (newValue == "") {
      this.year = 0;
      this.month = 0;
      this.day = 0;
      this.input("");
    }
    this.eraValue = newValue;
    this.setDate(newValue, this.year, this.month, this.day);
  }

  private get Year() {
    return this.year;
  }
  private set Year(newValue) {
    this.year = newValue;
    this.setDate(this.eraValue, newValue, this.month, this.day);
  }

  private get Month() {
    return this.month;
  }
  private set Month(newValue) {
    this.month = newValue;
    this.setDate(this.eraValue, this.year, newValue, this.day);
  }

  private get Day() {
    return this.day;
  }
  private set Day(newValue) {
    this.day = newValue;
    this.setDate(this.eraValue, this.year, this.month, newValue);
  }

  // 選択されている元号のリスト表示順を取得
  private get SelectedEraIndex() {
    return this.eraList.findIndex(era => {
      return era.value === this.EraValue;
    });
  }

  // 現在の元号の元年を取得
  private get CurrentEraStart() {
    return Number(this.eraList[0].value.slice(0, 4));
  }

  // その元号の最大年数を返す
  private get MaxYear() {
    const currentEraIndex = this.SelectedEraIndex;
    if (currentEraIndex === -1) {
      return 0; // 元号にマッチしない未定義動作を抑制
    }
    // 設定した元号と次の元号の年差が年数
    const currentEraYear = Number(
      this.eraList[currentEraIndex].value.slice(0, 4)
    );
    if (currentEraIndex === 0) {
      if (this.isBirthday) {
        // 誕生日フォームで今の元号であれば今年以下
        return this.nowYear - currentEraYear + 1;
      } else {
        return 99;
      }
    }
    const nextEraYear = Number(
      this.eraList[currentEraIndex - 1].value.slice(0, 4)
    );
    return nextEraYear - currentEraYear + 1;
  }

  // 最大月を返す
  private get MaxMonth() {
    if (
      this.isBirthday &&
      this.SelectedEraIndex === 0 &&
      this.CurrentEraStart + this.Year - 1 === this.nowYear
    ) {
      return new Date().getMonth() + 1;
    }
    return 12;
  }

  // 月末日またはNaN(数値比較で常にfalse)を返す
  private get MaxDay() {
    const eraYear = +this.EraValue.slice(0, 4);
    const monthLastDate = new Date(eraYear + this.Year - 1, this.Month, 0);
    // 誕生日フォームで今年指定であれば、今日を超えないように
    if (
      this.isBirthday &&
      this.SelectedEraIndex === 0 &&
      this.CurrentEraStart + this.Year - 1 === this.nowYear &&
      this.Month - 1 >= new Date().getMonth()
    ) {
      return new Date().getDate();
    }
    return monthLastDate.getDate();
  }

  private setDate(era: string, year: number, month: number, day: number) {
    if (!era || !year || !month || !day) {
      return;
    }
    if (year <= -1 || month <= -1 || day <= -1) {
      return;
    }

    let eraYear = Number(era.substring(0, 4));
    eraYear = eraYear + year - 1;

    this.input(
      ("000" + eraYear).slice(-4) +
        "-" +
        ("0" + month).slice(-2) +
        "-" +
        ("0" + day).slice(-2)
    );
  }

  private get EraRule(): boolean | string {
    if (this.isNeed && this.eraValue == "") {
      return "必須です";
    }
    if (this.eraValue == "" && this.year && this.month && this.day) {
      return "選択してください";
    }
    return true;
  }

  private get YearRule(): boolean | string {
    return this.checkInput(
      String(this.Year),
      1,
      this.MaxYear,
      this.require_message
    );
  }

  private get MonthRule(): boolean | string {
    return this.checkInput(
      String(this.Month),
      1,
      this.MaxMonth,
      this.require_message
    );
  }

  private get DayRule(): boolean | string {
    return this.checkInput(
      String(this.Day),
      1,
      this.MaxDay,
      this.require_message
    );
  }

  /** 年月日のチェック */
  private checkInput(
    valueStr: string,
    min: number,
    max: number,
    msg: string
  ): boolean | string {
    // 年号が未設定なら、スキップ、
    // 年月日の値には負の数を許容しないのでチェックする
    // isNeedがtrueなら
    // 最大も最小もチェックする
    // isNeedがfalseなら
    // 最大も最小もチェックする、ただし値が空なら最小はチェックしない

    const value = +valueStr;
    if (Number.isNaN(value) || valueStr.match("-")) {
      return "数値を入力してください";
    }
    if (!this.isNeed && this.eraValue != "" && value == 0) {
      return "年号選択時は必須です";
    }
    if (!this.isNeed && !this.EraValue) {
      return true;
    }
    const checkedMax = this.maxNumber(
      value,
      max,
      `${max}以下を入力してください`
    );
    const checkedMin = this.minNumber(
      value,
      min,
      `${min}以上を入力してください`
    );
    const checkedRequire = valueStr !== "";

    // 最大値チェック
    // 年月の入力不正によりmaxがNaNだった場合は、エラーメッセージを出さない（他の部分でエラーになっているから十分）
    if (typeof checkedMax === "string" && !isNaN(max)) {
      return checkedMax;
    }
    if (this.isNeed) {
      if (!checkedRequire) {
        return msg || "必須です";
      }

      // 最小値チェック
      // 年月の入力不正によりminがNaNだった場合は、エラーメッセージを出さない（他の部分でエラーになっているから十分）
      if (typeof checkedMin === "string" && !isNaN(min)) {
        return checkedMin;
      }
    } else {
      // 最小値チェック
      // 年月の入力不正によりminがNaNだった場合は、エラーメッセージを出さない（他の部分でエラーになっているから十分）
      if (!checkedRequire && typeof checkedMin === "string" && !isNaN(min)) {
        return checkedMin;
      }
    }
    return true;
  }

  @Emit("input")
  private input(newValue: string) {
    return newValue;
  }

  @Watch("value", { immediate: true })
  private watchValue() {
    if (!this.value) {
      this.eraValue = "";
      this.year = 0;
      this.month = 0;
      this.day = 0;
      return;
    }

    this.setDateForm(this.value);
  }

  private get age(): string {
    if (!this.value) return "";
    const now = new Date();
    const birth = new Date(this.value);
    let age = now.getFullYear() - birth.getFullYear();
    if (Number.isNaN(age)) return "";
    const toBirthday = new Date(
      now.getFullYear(),
      birth.getMonth(),
      birth.getDate()
    );
    if (now < toBirthday) age--;

    if (age < 0) return "";
    return `（${age}歳）`;
  }

  private eraList = [
    {
      value: "2019-05-01",
      text: "令和"
    },
    {
      value: "1989-01-08",
      text: "平成"
    },
    {
      value: "1926-12-25",
      text: "昭和"
    },
    {
      value: "1912-07-30",
      text: "大正"
    },
    {
      value: "1868-01-25",
      text: "明治"
    },
    {
      value: "",
      text: "未選択"
    }
  ];

  /** 今日ボタンクリック */
  private today() {
    const todayString = this.getTodayStr();
    this.setDateForm(todayString);
    return;
  }

  /** 今日の日付をYYYY-mm-ddで返す */
  private getTodayStr() {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const todayString = date.toISOString().substr(0, 10);
    return todayString;
  }

  /** 選択された日付を入力フォーム用に分ける */
  private setDateForm(dateString: string) {
    const targetDate = new Date(dateString.replace(/-/g, "/"));
    for (const era of this.eraList) {
      const eraFirstDate = new Date(era.value.replace(/-/g, "/"));
      if (eraFirstDate <= targetDate) {
        this.eraValue = era.value;
        this.year = targetDate.getFullYear() - eraFirstDate.getFullYear() + 1;
        this.month = targetDate.getMonth() + 1;
        this.day = targetDate.getDate();
        this.setDate(this.eraValue, this.year, this.month, this.day);
        return;
      }
    }
    return;
  }

  /** 正しい日付か判定 */
  private setCalendarDateCheck() {
    const yearCheck = this.checkInput(
      this.Year.toString(),
      1,
      this.MaxYear,
      this.require_message
    );
    const MonthCheck = this.checkInput(
      this.Month.toString(),
      1,
      this.MaxMonth,
      this.require_message
    );
    const DayCheck = this.checkInput(
      this.Day.toString(),
      1,
      this.MaxDay,
      this.require_message
    );

    /** 日付が入っていてかつ正しい値か */
    if (
      this.EraValue &&
      this.Year &&
      this.Month &&
      this.Day &&
      yearCheck == true &&
      MonthCheck == true &&
      DayCheck == true
    ) {
      return true;
    }
    return false;
  }

  private toJapanDate(date: Date, num = 0): Date {
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate() + num);
    return date;
  }

  private augustStart() {
    const date = this.toJapanDate(new Date());
    const augustDate = this.toJapanDate(new Date(date.getFullYear(), 7, 1));
    const setDate = augustDate.toISOString().substr(0, 10);
    this.setDateForm(setDate);
  }

  private julyEnd() {
    let date = this.toJapanDate(new Date());
    if (this.targetDate) {
      date = new Date(this.targetDate.replace(/-/g, "/"));
    }
    let augustDate = new Date(date.getFullYear() + 1, 7, 1);
    if (date.getMonth() + 1 < 7) {
      augustDate = new Date(date.getFullYear(), 7, 1);
    }
    augustDate = this.toJapanDate(augustDate, -1);
    const setDate = augustDate.toISOString().substr(0, 10);
    this.setDateForm(setDate);
  }

  private oneYear() {
    let date = this.toJapanDate(new Date());
    if (this.targetDate) {
      date = new Date(this.targetDate.replace(/-/g, "/"));
    }
    const oneYearDate = this.toJapanDate(
      new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()),
      -1
    );
    const setDate = oneYearDate.toISOString().substr(0, 10);
    this.setDateForm(setDate);
  }
}
