









































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  Dataset,
  PromptTemplate,
  DefaultPromptTemplate,
} from "#/model/admin/index";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, Choice } from "@/types";
import OpenAISetting from "@/components/ai/OpenAISetting.vue";
import GeminiSetting from "@/components/ai/GeminiSetting.vue";
import { LLM_TYPE } from "@/views/aivnplan/const";

@Component({ components: { OpenAISetting, GeminiSetting } })
/**
 * プロンプトテンプレート一覧
 */
export default class PromptTemplateForm extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** Ref */

  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** プロンプトテンプレート */
  private promptTemplate: PromptTemplate = DefaultPromptTemplate();

  /** データセット選択肢 */
  private datasetChoice: Choice[] = [];

  /** 登録済みデータセット */
  private datasets: Dataset[] = [];

  /** 編集モードフラグ */
  private isEditMode = false;

  /** LLMサービス選択肢 */
  private llmServiceChoice: Choice[] = [
    {
      text: "OpenAI",
      value: LLM_TYPE.OPEN_AI,
    },
    {
      text: "Gemini",
      value: LLM_TYPE.GEMINI,
    },
  ];

  private LLM_TYPE = LLM_TYPE;

  /** 関数 */

  created() {
    this.fetch();
  }

  /** 取得 */
  private fetch() {
    const promptTemplateId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/prompt/get",
      { id: promptTemplateId },
      async (res) => {
        this.promptTemplate = res.data.prompt_template;
        this.datasetChoice = res.data.dataset_choice;
        this.datasets = res.data.datasets;
      }
    );
  }

  /** 保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/prompt/save",
      { prompt_template: this.promptTemplate },
      async () => {
        this.$router.push({ path: "/ai-vnplan/prompt/list" });
      }
    );
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** 編集モード切り替え */
  private toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  /** データセット変更時、テンプレートに反映 */
  private onChangeDataset() {
    // 未選択の場合、データセットブロックの内容をリセット
    if (!this.promptTemplate.dataset_id) {
      this.promptTemplate.blocks = this.promptTemplate.blocks.map((block) => {
        if (block.is_dataset_block) {
          block.text = "";
        }

        return block;
      });
      return;
    }

    // 選択したデータセットが存在しない場合は何もしない
    const dataset = this.datasets.find(
      (dataset) => dataset.id === this.promptTemplate.dataset_id
    );
    if (!dataset) {
      return;
    }

    // データセット選択項目をテンプレートに追加
    this.promptTemplate.blocks = this.promptTemplate.blocks.map((block) => {
      if (block.is_dataset_block) {
        const itemNames: string[] = dataset.selections.map(
          (selection) => `・${selection.item_name}`
        );

        itemNames.unshift("# 利用者データ========");
        itemNames.push("========");

        block.text = itemNames.join("\n");
      }

      return block;
    });
  }

  /** 削除 */
  private async deletePromptTemplate() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/prompt/delete",
      { prompt_template: this.promptTemplate },
      async () => {
        this.$router.push({ path: "/ai-vnplan/prompt/list" });
      }
    );
  }
}
