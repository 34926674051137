





































import { Component, Emit, Mixins } from "vue-property-decorator";
import Cookies from "js-cookie";
import { AxiosError, AxiosResponse } from "axios";
import UserMixin from "@/mixins/userMixin";
import * as user from "@/store/modules/user/types";
import { namespace } from "vuex-class";

const User = namespace(user.name);

@Component
export default class DoubleLoginArea extends Mixins(UserMixin) {
  private keyCode = ""; //セキュリティコード
  private errorMessages = ""; //エラーメッセージ
  private loading = false;

  @User.Mutation(user.SET_USER) setUser!: Function;

  //セキュリティコード確認
  private doubleLogin() {
    this.loading = true;
    window.axios
      .post(window.base_url + "/auth/double", {
        token: this.$route.query.security_code,
        key_code: this.keyCode,
      })
      .then((res: AxiosResponse) => {
        this.loading = false;
        window.token = res.data.token;
        Cookies.set("admin-token", res.data.token);
        window.reftoken = res.data.ref_token;
        Cookies.set("admin-reftoken", res.data.ref_token);
        Cookies.set("admin-userid", res.data.user.user_id);
        this.setUser(res.data);
        this.login();
      })
      .catch(async (error: AxiosError) => {
        this.loading = false;
        if (
          error.response &&
          (error.response.status == 401 || error.response.data.code == 101)
        ) {
          this.errorMessages = error.response.data.message;
        } else {
          await this.$openAlert(
            "サーバでエラーが発生しました。\n(" + error + ")"
          );
          console.log(error);
          console.log(error.response);
        }
      });
  }

  @Emit("login:double")
  private login() {
    return;
  }
}
