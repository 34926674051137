






























































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Office } from "@/model/office";
import { Agreement } from "@/model/agreement";
import RulesMixin from "#/mixins/rulesMixin";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";
import Step1 from "./approvalstepper/Step1.vue";
import Step2 from "./approvalstepper/Step2.vue";
import Step3 from "./approvalstepper/Step3.vue";

@Component({
  components: {
    Step1,
    Step2,
    Step3,
  },
})
/**
 * 事業所契約承認作業をステップ表示するコンポーネント
 */
export default class ContractApprovalStepper extends Mixins(
  UtilMixin,
  RulesMixin
) {
  /** 契約情報 */
  @Prop({ default: () => ({}) }) agreement!: Agreement;
  /** 事業所情報 */
  @Prop({ default: () => ({}) }) office!: Office;
  /** iBowKintai連携情報 */
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;
  /** 初期ステップ番号 */
  @Prop({ default: 1 }) initStepNo!: number;

  @Emit() private save(savingConfflag: number) {
    return savingConfflag;
  }

  @Emit() private update() {
    return;
  }

  /** 変更履歴フラグ */
  private saveHistoryLog = false;

  /** 現在のステップ番号は初期ステップ番号をベースに作成 */
  private get currentStep() {
    return this.initStepNo;
  }

  private set currentStep(newValue) {
    // return this.initStepNo;
  }

  /** v-stepper用のステップ情報 */
  private get steps() {
    return [
      {
        step_no: 1,
        step_name: "見積もり番号入力",
        confflag: -2,
        complete: () => this.office.billing_info.confflag > -2,
        error: () =>
          !this.hasCommentFlags.filter((data) => data.stepNo == 1)[0]
            .hasComment,
      },
      {
        step_no: 2,
        step_name: "契約内容入力",
        confflag: -1,
        complete: () => this.office.billing_info.confflag > -1,
        error: () =>
          !this.hasCommentFlags.filter((data) => data.stepNo == 2)[0]
            .hasComment,
      },
      {
        step_no: 3,
        step_name: "承認",
        confflag: 0,
        complete: () => this.office.billing_info.confflag > 0,
        error: () =>
          !this.hasCommentFlags.filter((data) => data.stepNo == 3)[0]
            .hasComment,
      },
    ];
  }

  /** 各confflagに対するコメントが存在するかどうかフラグ */
  private get hasCommentFlags() {
    return [
      {
        stepNo: 1,
        hasComment: this.hasComments(-2),
      },
      {
        stepNo: 2,
        hasComment: this.hasComments(-1),
      },
      {
        stepNo: 3,
        hasComment: this.hasComments(0),
      },
    ];
  }

  /** 変更履歴の初期値を空に設定 */
  created(): void {
    this.office.billing_info.change_message = "";
  }

  /** 指定したconfflagに対するコメント存在チェック */
  private hasComments(confflag: number) {
    if (!this.office.billing_info.billing_comments) {
      return true;
    }

    return (
      this.office.billing_info.billing_comments.filter(
        (data) => data.confflag == confflag
      ).length > 0
    );
  }

  /** ステップ変更時は変更履歴フォームリセット */
  private onChangeStep() {
    this.office.billing_info.change_message = "";
    this.saveHistoryLog = false;
  }
}
