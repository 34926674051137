var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"mt-4 elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.inquirys,"no-data-text":"問い合わせがありません。","disable-pagination":_vm.disablePagination,"hide-default-footer":_vm.disablePagination,"multi-sort":"","sort-by":_vm.SortBy,"sort-desc":_vm.SortDesc},on:{"update:sortBy":function($event){_vm.SortBy=$event},"update:sort-by":function($event){_vm.SortBy=$event},"update:sortDesc":function($event){_vm.SortDesc=$event},"update:sort-desc":function($event){_vm.SortDesc=$event}},scopedSlots:_vm._u([{key:"item.questionnaire_text",fn:function(ref){
var item = ref.item;
return [(item.questionnaire_text)?_c('v-chip',{staticClass:"mt-1 mb-1",attrs:{"small":"","label":"","color":item.questionnaire_color}},[_c('span',{staticClass:"p-chip__tag",staticStyle:{"font-size":"0.6rem","font-weight":"600","color":"white","text-align":"start"}},[_vm._v(_vm._s(item.questionnaire_text))])]):_vm._e()]}},{key:"item.inquiry_tag_name",fn:function(ref){
var item = ref.item;
return [(item.inquiry_tag_id > 0)?_c('v-chip',{staticClass:"mt-1 mb-1",attrs:{"dark":"","small":"","label":"","color":"#00ACC1"}},[_c('span',{staticClass:"p-chip__tag"},[_vm._v(_vm._s(item.inquiry_tag_name))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticStyle:{"font-weight":"bold"},attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.show(item)}}},[_vm._v("詳細")])],1)]}}],null,true)},[_c('span',[_vm._v("[問い合わせ者]"),_c('br'),_vm._v(_vm._s(item.inquiry_staff_furigana)),_c('br'),_vm._v(_vm._s(item.inquiry_staff_name)),_c('br')]),_c('span',[_vm._v("[問い合わせ内容]"),_c('br')]),_c('span',{domProps:{"innerHTML":_vm._s(item.contact)}})])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-chip',{staticClass:"mt-1 mb-1",attrs:{"dark":"","x-small":"","label":"","color":item.is_published
                  ? _vm.idToText(item.status, _vm.INQUIRY_STATUS_COLOR)
                  : 'grey'}},[_c('span',{staticStyle:{"display":"inline-block","width":"100px","text-align":"center","font-weight":"bold"}},[_vm._v(_vm._s(_vm.idToText(item.status, _vm.INQUIRY_STATUS)))])])],1),(item.inquiry_answers && item.inquiry_answers.length)?_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},_vm._l((item.inquiry_answers),function(answer){return _c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(
                answer.answer_status < _vm.ANSWER_COMPLETED ||
                answer.answer_status > _vm.ANSWER_COMPLETED_UNSOLVED
              ),expression:"\n                answer.answer_status < ANSWER_COMPLETED ||\n                answer.answer_status > ANSWER_COMPLETED_UNSOLVED\n              "}],key:("answer" + (answer.id)),staticClass:"mr-1 mb-1",attrs:{"dark":"","x-small":"","label":"","color":_vm.idToText(answer.answer_status, _vm.ANSWER_STATUS_COLOR)}},[_c('span',[_vm._v(_vm._s(_vm.idToText(answer.answer_status, _vm.LISTED_ANSWER_STATUS)))])])}),1):_vm._e()]}},{key:"item.version",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",attrs:{"dark":"","x-small":"","label":"","color":_vm.idToText(item.version, _vm.INQUIRY_VERSION_COLOR)}},[_c('span',{staticStyle:{"display":"inline-block","width":"50px","text-align":"center","font-weight":"bold"}},[_vm._v(_vm._s(_vm.idToText(item.version, _vm.INQUIRY_VERSION)))])])]}},{key:"item.hope",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.idToText(item.hope_contact_div, _vm.HOPE_CONTACT_DIV))+" "),_c('br'),_vm._v(" ("+_vm._s(item.ticket_no)+") ")]}},{key:"item.office_name",fn:function(ref){
              var item = ref.item;
return [(item.kst_no)?_c('div',[_vm._v("["+_vm._s(item.kst_no)+"]")]):_vm._e(),_vm._v(" "+_vm._s(item.office_name)+" ")]}},{key:"item.admin_name",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAdminNames(item.inquiry_answers))+" ")]}},{key:"item.hope_contact_datetime",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{ u_red: item.need_mark_hope_contact_date_time }},[_vm._v(_vm._s(item.hope_contact_datetime))])]}},{key:"item.last",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_answered_datetime)+" ")]}},{key:"item.co_name",fn:function(ref){
              var item = ref.item;
return [(item.co_code)?_c('div',[_vm._v("["+_vm._s(item.co_code)+"]")]):_vm._e(),_vm._v(" "+_vm._s(item.co_name)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }