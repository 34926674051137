































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AppSubHeader from "#/components/AppSubHeader.vue";
import { Choice } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { AdminAuth, AdminAuthFunction } from "@/model/adminUser";
import { VForm } from "@/types";

interface EditStatus {
  text: string;
  label: string;
  value: number;
}

interface DispAdminAuth extends AdminAuth {
  admin_auth_functions: DispAdminAuthFunction[];
}

interface DispAdminAuthFunction extends AdminAuthFunction {
  editing_auths: DispAuth[];
  is_editing_str: string[];
}

interface DispAuth {
  name: string;
  disabled: boolean;
  allowed: number;
  type: number;
  check_digit: number;
  key: string;
}

const DEFAULT_DISP_ADMIN_AUTH: DispAdminAuth = {
  id: 0,
  name: "",
  admin_auth_functions: [],
};

@Component({
  components: {
    AppSubHeader,
  },
})
export default class AuthEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** フォーム操作用 */
  @Ref("auth_form") private readonly form!: VForm;

  /** 権限情報 */
  private adminAuth: DispAdminAuth = { ...DEFAULT_DISP_ADMIN_AUTH };

  private displays: Choice[] = [
    { value: 0, text: "表示しない" },
    { value: 1, text: "表示する" },
  ];

  private execs: Choice[] = [
    { value: 0, text: "なし" },
    { value: 1, text: "あり" },
  ];

  created() {
    const authId = Number(this.$route.params.id);
    this.fetchAuth(authId);
  }

  /** 権限情報を取得 */
  private fetchAuth(authId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/auth/get",
      { id: authId },
      (res) => {
        this.adminAuth = res.data.auth;
      }
    );
  }

  /** 保存処理 */
  async save(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/auth/save",
      { admin_auth: this.adminAuth },
      () => {
        this.$router.push({ path: "/auth/list" });
      }
    );
  }

  /** キャンセルボタンクリック時 */
  private async cancel() {
    if (
      await this.$openConfirm(
        "編集中の場合は内容が破棄されます\nよろしいですか？"
      )
    ) {
      this.$router.push({ path: "/auth/list" });
    }
  }

  /** 編集権限の変更時 */
  private onChangeEditAuth(path: string) {
    this.adminAuth.admin_auth_functions =
      this.adminAuth.admin_auth_functions.map((data) => {
        if (data.function.path != path) {
          return data;
        }

        data.editing_auths = data.editing_auths.map((editAuth) => {
          if (editAuth.type == 2) {
            editAuth.disabled = !data.editing_auths[0].allowed;
            editAuth.allowed = editAuth.disabled ? 0 : editAuth.allowed;
          }
          return editAuth;
        });
        return data;
      });
  }

  /** 削除処理 */
  async deleteAuth(): Promise<void> {
    if (!(await this.$openConfirm("権限を削除しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/auth/delete",
      { admin_auth: this.adminAuth },
      () => {
        this.$router.push({ path: "/auth/list" });
      }
    );
  }
}
