






























































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
// import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { EwellUser } from "@/model/ewellUser";

@Component({
  // components: {
  //   AppAuthButton,
  // },
})
export default class IbowAccountList extends Mixins(AxiosMixin) {
  /** ewellユーザー情報 */
  private ewellUsers: EwellUser[] = [];

  /** 絞込み用条件 */
  private filterObj = {
    name: "",
    keyword: "",
  };

  private headers = [
    {
      text: "詳細",
      align: "center",
      sortable: false,
      value: "actions",
    },
    {
      text: "iBowID",
      sorttable: true,
      value: "user_id",
    },
    {
      text: "氏名",
      sorttable: true,
      value: "name",
    },
    {
      text: "ふりがな",
      sorttable: true,
      value: "furigana",
    },
    {
      text: "メールアドレス",
      sorttable: false,
      value: "mail_address",
    },
    {
      text: "携帯電話番号",
      sorttable: false,
      value: "tel_no",
    },
    {
      text: "システムID",
      sorttable: false,
      value: "system_id",
    },
  ];

  // created(): void {
  //   this.fetchIbowAccounts();
  // }

  /** eWeLLユーザー取得 */
  private fetchIbowAccounts() {
    if (this.filterObj.name != "" || this.filterObj.keyword) {
    this.postJsonCheck(
      window.base_url + "/api/ibowaccounts/get",
      {
        name: this.filterObj.name,
        keyword: this.filterObj.keyword,
      },
      (res) => {
        this.ewellUsers = res.data.ewell_users;
      }
    );
    } else {
      alert("全件検索は出来ません 検索ワードを入力して下さい。");
    }
  }

  /** 詳細ボタンクリック時 */
  private goDetail(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/ibowaccount/detail/${id}` });
  }
}
