var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("STEP1: 見積もり番号入力")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-form',{ref:"estimateEditor"},[_c('v-simple-table',{staticClass:"elevation-1",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("見積もり番号")]),_c('td',{attrs:{"align":"left"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"見積もり番号","hide-details":"","rules":[
                        _vm.checkEstimateNo(
                          _vm.office.billing_info.estimate_no,
                          '空白のみの見積もり番号は正しくありません。'
                        ),
                        _vm.minLength(
                          _vm.office.billing_info.estimate_no,
                          200,
                          '200文字以内で入力してください。'
                        ) ]},model:{value:(_vm.office.billing_info.estimate_no),callback:function ($$v) {_vm.$set(_vm.office.billing_info, "estimate_no", $$v)},expression:"office.billing_info.estimate_no"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("eWeLL営業担当者名")]),_c('td',{attrs:{"align":"left"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"eWeLL営業担当者名","hide-details":"","rules":[
                        _vm.minLength(
                          _vm.office.billing_info.ewell_sales_staff_fullname,
                          200,
                          '200文字以内で入力してください。'
                        ) ]},model:{value:(_vm.office.billing_info.ewell_sales_staff_fullname),callback:function ($$v) {_vm.$set(_vm.office.billing_info, "ewell_sales_staff_fullname", $$v)},expression:"office.billing_info.ewell_sales_staff_fullname"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v(" eWeLL営業担当者メールアドレス ")]),_c('td',{attrs:{"align":"left"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"eWeLL営業担当者メールアドレス","hide-details":"","rules":[
                        _vm.minLength(
                          _vm.office.billing_info.ewell_sales_staff_mail,
                          200,
                          '200文字以内で入力してください。'
                        ),
                        _vm.emailFormat(
                          _vm.office.billing_info.ewell_sales_staff_mail,
                          'メールアドレスの形式が正しくありません。'
                        ) ]},model:{value:(_vm.office.billing_info.ewell_sales_staff_mail),callback:function ($$v) {_vm.$set(_vm.office.billing_info, "ewell_sales_staff_mail", $$v)},expression:"office.billing_info.ewell_sales_staff_mail"}})],1)])])]},proxy:true}])}),_c('ChangeHistory',{staticClass:"mt-4",attrs:{"saveHistoryLog":_vm.SaveHistoryLog},on:{"update:saveHistoryLog":function($event){_vm.SaveHistoryLog=$event},"update:save-history-log":function($event){_vm.SaveHistoryLog=$event}},model:{value:(_vm.office.billing_info.change_message),callback:function ($$v) {_vm.$set(_vm.office.billing_info, "change_message", $$v)},expression:"office.billing_info.change_message"}}),_c('AppAuthButton',{attrs:{"className":"mt-2","label":"保存","subAuthName":"is_estimate"},on:{"click":_vm.saveEstimateNo}})],1)],1),_c('v-col',[_c('BillingCommentViewer',{attrs:{"billing":_vm.office.billing_info,"agreement_id":_vm.agreement.id,"confflag":_vm.confflag}})],1)],1)],1),_c('AppLoadingDialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }