






















































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import AppTimePicker from "#/components/AppTimePicker.vue";
import AppSubHeader from "#/components/AppSubHeader.vue";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import TargetOfficeSelector from "@/components/admin_common/TargetOfficeSelector.vue";
import {
  MaintenanceInfo,
  DEFAULT_MEINTENANCE_INFO,
} from "@/model/agreement/maintenanceInfo";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm, TargetOfficeChoice } from "@/types";

interface DispMaintenanceInfo extends MaintenanceInfo {
  target_offices: TargetOfficeChoice[];
}

@Component({
  components: {
    TextDayChangePicker,
    AppTimePicker,
    AppSubHeader,
    TargetOfficeSelector,
    TrumbowygEditor,
    AppAuthButton,
  },
})
export default class MaintenanceNotification extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** フォームバリデーション用 */
  @Ref("form") private readonly form!: VForm;

  /** 表示対象選択エリアを表示するかどうか */
  private maintenanceTarget = 0;

  /** 作成・編集対象のメンテナンス情報 */
  private info: DispMaintenanceInfo = {
    ...DEFAULT_MEINTENANCE_INFO,
    target_offices: [],
  };

  /** 送信先事業所情報 */
  private targetOffices: TargetOfficeChoice[] = [];

  /** 選択可能事業所情報 */
  private offices: TargetOfficeChoice[] = [];

  //保存ボタンクリック
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか?"))) {
      return;
    }

    if (this.maintenanceTarget == 1 && this.targetOffices.length == 0) {
      if (
        !(await this.$openConfirm(
          "事業所が選択されていないため、全体へのお知らせになります。よろしいですか？"
        ))
      ) {
        return;
      }
    }

    // リクエストサイズ削減のため、不要な事業所情報を空で渡す
    // 結局、this.targetOfficesが表示対象事業所情報を持っているので問題なし
    this.info.target_offices = [];
    this.info.maintenance_info_offices = [];

    this.postJsonCheck(
      window.base_url + "/api/admin/maintenance/save",
      {
        maintenance_info: this.info,
        target_offices: this.targetOffices,
      },
      () => {
        this.$router.push({ path: "/maintenance/notification" });
      }
    );
  }

  //閉じるボタンクリック
  private clickClose() {
    this.$router.go(-1);
  }

  //削除ボタンクリック
  private async clickDelete() {
    if (await this.$openConfirm("データを削除します\nよろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/admin/maintenance/delete",
        {
          maintenance_info: this.info,
        },
        () => {
          this.$router.push({ path: "/maintenance/notification" });
        }
      );
    }
  }

  created(): void {
    this.fetchMaintenanceInfo();
  }

  /** メンテナンス情報取得 */
  private fetchMaintenanceInfo(): void {
    // id=0(新規作成)の時も事業所取得したいのでリクエスト投げる
    const maintenanceInfoId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/maintenance/get",
      {
        ids: [maintenanceInfoId],
      },
      (res) => {
        if (res.data.maintenance_infos) {
          this.offices = res.data.offices;

          if (res.data.maintenance_infos.length > 0) {
            this.info = res.data.maintenance_infos[0];
            this.targetOffices = this.info.target_offices.slice();
          }

          // 送信先が既に存在している場合
          this.maintenanceTarget = this.targetOffices.length > 0 ? 1 : 0;
        }
      }
    );
  }

  /** 全体 <-> 選択の切り替え時 */
  private onChangeTarget() {
    // 送信先事業所情報を空にする
    this.targetOffices = [];
  }

  /** 掲載期間とメンテナンス期間に関するrule */
  private betweenRule() {
    if (
      this.dateAfter(
        this.info.start_datetime,
        this.info.mainte_start_datetime,
        true,
        ""
      ) !== true &&
      this.dateAfter(
        this.info.end_datetime,
        this.info.mainte_end_datetime,
        true,
        ""
      ) !== true
    ) {
      return "メンテナンス期間は掲載期間内に設定してください。";
    }
    return true;
  }
}
