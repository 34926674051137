
















// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VueTimepicker from "vue2-timepicker";
import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import "vue2-timepicker/dist/VueTimepicker.css";
import RulesSaveMixin from "../mixins/rulesSaveMixin";
import AppTimePickerString from "./AppTimePickerString.vue";

@Component({
  components: {
    VueTimepicker,
    AppTimePickerString
  }
})
export default class AppTimePicker extends Mixins(RulesSaveMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** value: 時 */
  @Prop({ default: null }) private hh!: number | null;

  /** value: 分 */
  @Prop({ default: null }) private mm!: number | null;

  /** 高さ */
  @Prop({ default: "48px" }) private readonly height!: string;

  /** 横幅 */
  @Prop({ default: "65px" }) private readonly width!: string;

  /** 最小横幅 */
  @Prop({ default: "65px" }) private readonly minWidth!: string;

  /** 無効フラグ */
  @Prop({ default: false }) private readonly disabled!: boolean;

  /** 分間隔 */
  @Prop({ default: 1 }) private readonly minuteInterval!: number;

  /** inputの表示位置 */
  @Prop({ default: "down" }) private dropDirection!: string;

  /** 必須 */
  @Prop({ default: false }) private isNeed!: boolean;

  @Emit("update:hh")
  private updateHH(hh: string) {
    const num = Number(hh);
    if (hh == "" || isNaN(num)) {
      return hh;
    }
    return num;
  }
  @Emit("update:mm")
  private updateMM(mm: string) {
    const num = Number(mm);
    if (mm == "" || isNaN(num)) {
      return mm;
    }
    return num;
  }

  private get HH() {
    return this.hh + "";
  }

  private set HH(hh: string) {
    this.updateHH(hh);
  }

  private get MM() {
    return this.mm + "";
  }
  private set MM(mm: string) {
    this.updateMM(mm);
  }

  @Emit()
  private blur() {
    return;
  }
}
