


































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import { Office } from "@/model/office";
import { Agreement } from "@/model/agreement";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";
import AxiosMixin from "@/mixins/axiosMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import BillingCommentViewer from "@/components/contractreception/common/BillingCommentViewer.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

/**
 * 事業所契約承認
 */
@Component({
  components: { OfficeInfo, BillingCommentViewer, AppAuthButton },
})
export default class extends Mixins(AxiosMixin) {
  @Prop({ default: () => ({}) }) agreement!: Agreement;
  @Prop({ default: () => ({}) }) office!: Office;

  /** iBowKintai連携情報 */
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;

  @Emit() private save() {
    return;
  }

  /** 契約状態 */
  private confflag = 0;

  /** 承認ステップで承認ボタンクリック時 */
  private async approveContractInfo() {
    if (!(await this.$openConfirm("この内容で契約を承認しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/billing_info/save",
      {
        billing: this.office.billing_info,
        agreement_id: this.agreement.id,
        saving_confflag: this.confflag,
      },
      () => {
        this.save();
      }
    );
  }
}
