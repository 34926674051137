




























































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import { DispTemplate, DEFAULT_DISP_TEMPLATE } from "@/views/inquiry/types";
import { TEMPLATE_STATUS, TEMPLATE_STATUS_COLOR } from "@/consts/inquiry";

@Component({
  components: {
    AttachmentViewer,
    AnswerContent,
  },
})
export default class FAQTemplateNavigationDrawer extends Mixins(UtilMixin) {
  /** ナビゲーションドロワー制御用 */
  @Prop({ default: false }) value!: boolean;

  /** 表示対象のFAQテンプレート情報 */
  @Prop({ default: () => DEFAULT_DISP_TEMPLATE }) template!: DispTemplate;

  /** 編集可能かどうか */
  @Prop({ default: false }) editable!: boolean;

  /** テンプレート状態定数 */
  private TEMPLATE_STATUS = TEMPLATE_STATUS;

  /** テンプレート状態色わけ定数 */
  private TEMPLATE_STATUS_COLOR = TEMPLATE_STATUS_COLOR;

  private get Drawer(): boolean {
    return this.value;
  }

  private set Drawer(newValue: boolean) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  @Emit("insert:template")
  private insertTemplateContent(template: DispTemplate) {
    return template;
  }
}
