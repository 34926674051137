












import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component
export default class AppTabs extends Vue {
  @Prop({ default: true }) private readonly centerActive!: boolean;

  @Prop({ default: true }) private readonly showArrows!: boolean;

  @Prop() private readonly value!: string;

  @Emit() private input(newValue: string) {
    return newValue;
  }

  private get Value(): string {
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }
}
