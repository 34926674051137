










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppExpansionPanelContent extends Vue {
  @Prop({ default: false }) noPadding!: boolean;
}
