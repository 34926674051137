





























import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";

@Component({
  components: {
    AppTextField,
  },
})
export default class SearchBase extends Vue {
  private co_code = "";
  private kst_no = "";
  private office_name = "";

  @Emit()
  private search() {
    return {
      co_code: this.co_code,
      kst_no: this.kst_no,
      office_name: this.office_name,
    };
  }
}
