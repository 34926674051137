





















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { State, Mutation } from "vuex-class";
import { SET_IS_BTN_CLICK } from "../store/types";

@Component
export default class AppButton extends Vue {
  /** ラベル */
  @Prop() private readonly label!: string;

  /** 幅 */
  @Prop() private readonly width!: string;

  /** 高さ */
  @Prop({ default: "48px" }) private readonly height!: string;

  /** 丸ボタン状態 */
  @Prop({ default: false }) private readonly rounded!: boolean;

  /** 無効状態 */
  @Prop({ default: false }) private readonly disabled!: boolean;

  /** 意図しない2回押しとみなす間隔[ms] */
  @Prop({ default: 500 }) private readonly chatteringThreshold!: number;

  /** 処理中かどうか */
  @State private readonly is_processing!: boolean;

  @Mutation(SET_IS_BTN_CLICK) setIsBtnClick!: Function;

  /** クリックしたかどうか */
  private is_click = false;

  /** クリックイベント */
  @Emit("click") click(event: Event) {
    return event;
  }

  /** 無効状態 */
  private get Disabled() {
    return this.disabled;
  }

  /** リスナー設定 */
  private get Listeners() {
    return Object.assign({}, this.$listeners, {
      click: this.Click
    });
  }

  /** ボタンクリック */
  private Click(event: Event) {
    if (this.is_processing || this.is_click) {
      event.preventDefault();
      return;
    }
    this.is_click = true;
    setTimeout(() => {
      this.is_click = false;
    }, this.chatteringThreshold);
    if (this.setIsBtnClick) {
      this.setIsBtnClick(true);
      setTimeout(() => {
        this.setIsBtnClick(false);
      }, 2000);
    }
    this.click(event);
  }
}
