
























































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { AdminUser } from "@/model/adminUser";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import AnswerForm from "@/components/inquiry/AnswerForm.vue";
import InquiryInfo from "@/components/inquiry/InquiryInfo.vue";
import AnswerInfo from "@/components/inquiry/AnswerInfo.vue";
import RelatedInquiryViewer from "@/components/inquiry/RelatedInquiryViewer.vue";
import InquiryContactInfo from "@/components/inquiry/InquiryContactInfo.vue";
import ContactHistory from "@/components/inquiry/contactHistory.vue";
import {
  DispInquiry,
  DispInquiryAnswer,
  InquiryDiv,
  InquiryGroup,
  InquiryServiceDiv,
  InquiryAnswerFile,
  DEFAULT_DISP_INQUIRY,
  DEFAULT_DISP_INQUIRY_ANSWER,
  OfficestaffContact,
  DEFAULT_OFFICESTAFF_CONTACT,
  InquiryAnswer,
  Template,
} from "@/model/inquiry";
import {
  ANSWER_ASSIGN,
  ANSWER_COMPLETED,
  ANSWER_COMPLETED_UNSOLVED,
  INQUIRY_COMPLETED,
  RECENT_INQUIRY_LIMIT,
} from "@/consts/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import UserMixin from "@/mixins/userMixin";
import { VForm } from "@/types";
import { AdminAuthFunction } from "@/store/modules/user/types";

@Component({
  components: {
    AnswerContent,
    ContactHistory,
    AnswerForm,
    InquiryInfo,
    InquiryContactInfo,
    AnswerInfo,
    RelatedInquiryViewer,
  },
})
export default class InquiryAnswerEdit extends Mixins(AxiosMixin, UserMixin) {
  /** 回答フォーム操作用 */
  @Ref("answer_form") private readonly answerForm!: AnswerForm;

  /** 回答フォームバリデーション用 */
  @Ref("answer_vform") private readonly answerVForm!: VForm;

  private RECENT_INQUIRY_LIMIT = RECENT_INQUIRY_LIMIT;
  /** 問い合わせステータス定数(完了) */
  private INQUIRY_COMPLETED = INQUIRY_COMPLETED;

  /** 回答対象の問い合わせ情報 */
  private inquiry: DispInquiry = { ...DEFAULT_DISP_INQUIRY };

  /** 作成・編集対象の回答情報 */
  private inquiryAnswer: DispInquiryAnswer = { ...DEFAULT_DISP_INQUIRY_ANSWER };

  /** 表示する問い合わせ情報 */
  private inquiries: DispInquiry[] = [];

  /** 直近の問い合わせ情報 */
  private latestInquiries: DispInquiry[] = [];

  private answerTime = 0;

  /** 管理者情報 */
  private adminUsers: AdminUser[] = [];

  /** マスタデータ */
  private inquiryDivs: InquiryDiv[] = [];
  private inquiryGroups: InquiryGroup[] = [];
  private inquiryServiceDivs: InquiryServiceDiv[] = [];

  private isSaving = false;

  /** 事業所職員コンタクト履歴情報 */
  private officestaffContact: OfficestaffContact = {
    ...DEFAULT_OFFICESTAFF_CONTACT,
  };

  /** ログイン中のユーザー名 */
  private userName = "";

  /** 回答対象の問い合わせ情報 */
  private get AnswerTargetInquiry(): DispInquiry {
    const inquiry = this.inquiries.find(
      (inquiry: DispInquiry) => inquiry.id == this.inquiryAnswer.inquiry_id
    );
    return inquiry ? inquiry : { ...DEFAULT_DISP_INQUIRY };
  }

  created(): void {
    this.userName = `${this.User.last_name} ${this.User.first_name}`;

    this.fetchInquiryAnswer();
  }

  private fetchInquiryAnswer(): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/answer/get",
      {
        parent_inquiry_id: Number(this.$route.params.parentInqId),
        inquiry_id: Number(this.$route.params.inqId),
        inquiry_answer_id: Number(this.$route.params.ansId),
      },
      (res) => {
        this.inquiry = res.data.inquiry;
        this.inquiryAnswer = res.data.inquiry_answer;
        this.adminUsers = res.data.admin_users;
        this.inquiryDivs = res.data.inquiry_divs;
        this.inquiryGroups = res.data.inquiry_groups;
        this.inquiryServiceDivs = res.data.service_divs;
        this.latestInquiries = res.data.latest_inquiries;
        this.officestaffContact = res.data.inquiry.officestaff_contact;
        this.inquiries = res.data.inquiries;
      }
    );
  }

  /** アサインステータスを指定できるかどうか */
  private get assignable(): boolean {
    // サポート管理のサブ権限情報を取得
    const authInfo = this.User.admin_auth.admin_auth_functions.filter(
      (data: AdminAuthFunction) => data.function.path == "/inquiry"
    )[0];

    const subAuthInfo = authInfo.editing_auths.filter(
      (data) => data.key == "is_assign"
    )[0];

    // アサイン可能状態かつ権限がある場合にアサインステータスを指定可能
    return this.AnswerTargetInquiry.assignable && subAuthInfo.allowed == 1;
  }

  private onChangeAnswerTime(value: number) {
    this.answerTime = value;
  }

  private get isEditMode() {
    return Number(this.$route.params.ansId) != 0;
  }

  private get editable() {
    return (
      this.AnswerTargetInquiry.status != INQUIRY_COMPLETED ||
      this.inquiryAnswer.id == 0
    );
  }

  /** 保存ボタンクリック */
  private async clickSave() {
    this.isSaving = true;

    // 回答ステータスがアサイン以外の場合のみバリデーションチェックを行う
    if (this.inquiryAnswer.answer_status != ANSWER_ASSIGN) {
      if (!this.answerVForm.validate()) {
        await this.$openAlert("入力内容に不備があります");
        return;
      }
    }

    if (
      !(await this.$openConfirm(
        `回答を保存します。よろしいですか？\n\n${this.createQuestionnaireMessage()}`
      ))
    ) {
      return;
    }

    // file.path -> file.file_pathへコピー
    this.inquiryAnswer.inquiry_answer_files.forEach(
      (data: InquiryAnswerFile) => {
        data.file_path = data.path;
      }
    );

    this.postJsonCheck(
      window.base_url + "/api/admin/inquirys/answer/save",
      {
        inquiry: this.AnswerTargetInquiry,
        inquiry_answer: this.inquiryAnswer,
        answer_time: this.answerTime,
      },
      () => {
        this.$router.push({ path: "/inquiry/show/" + this.inquiry.id });
      }
    );
  }

  /** アンケート設定アラート文言作成 */
  private createQuestionnaireMessage(): string {
    // 判定用回答一覧
    const answers = [
      ...this.AnswerTargetInquiry.inquiry_answers,
      this.inquiryAnswer,
    ].map((answer) => {
      return answer.id == this.inquiryAnswer.id ? this.inquiryAnswer : answer;
    });

    // 全回答完了フラグ
    const completed = !answers.some(
      (answer) =>
        answer.answer_status < ANSWER_COMPLETED ||
        answer.answer_status > ANSWER_COMPLETED_UNSOLVED
    );
    // 回答公開フラグ
    const published = this.inquiryAnswer.is_published == 1;
    // アンケート設定
    const showQuestionnaire = this.inquiryAnswer.show_questionnaire == 1;

    return (
      "" +
      (showQuestionnaire
        ? "現在、アンケート「表示」に設定されています。"
        : "現在、アンケート「非表示」に設定されています。") +
      (showQuestionnaire && !completed
        ? "\n※問い合わせが完了していません。"
        : "") +
      (showQuestionnaire && !published ? "\n※回答が非公開になっています。" : "")
    );
  }

  /** 差戻しボタンクリック時 */
  private async remand() {
    if (!(await this.$openConfirm("回答を差戻します\nよろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/inquirys/answer/remand",
      { inquiry: this.AnswerTargetInquiry, inquiry_answer: this.inquiryAnswer },
      () => {
        this.$router.push({ path: "/inquiry/show/" + this.inquiry.id });
      }
    );
  }

  /** 編集中キャンセルボタンクリック */
  private async clickCancel() {
    if (
      await this.$openConfirm(
        "編集中の場合は内容が破棄されます\nよろしいですか？"
      )
    ) {
      this.$router.push({ path: "/inquiry/show/" + this.inquiry.id });
    }
  }

  // 削除ボタンクリック
  private async clickDelete() {
    if (!(await this.$openConfirm("回答を削除します\nよろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/answer/delete",
      {
        inquiry: this.AnswerTargetInquiry,
        inquiry_answer_id: this.inquiryAnswer.id,
      },
      () => {
        this.$router.push({ path: "/inquiry/show/" + this.inquiry.id });
      }
    );
  }

  /** テンプレート作成ボタンクリック */
  private goTemplateEdit() {
    // 別タブでテンプレート編集画面を開く
    const routeData = this.$router.resolve({
      path: "/template/edit/0",
    });
    window.open(routeData.href, "_blank");
  }

  /** 事業所職員コンタクト履歴保存 */
  private saveOfficestaffContact(data: OfficestaffContact) {
    this.postJsonCheck(
      window.base_url + "/api/admin/officestaff/contact/save",
      { officestaff_contact: data },
      (res) => {
        this.officestaffContact = res.data.officestaff_contact;
      }
    );
  }

  private onResetForm() {
    this.isSaving = false;
    this.answerVForm.resetValidation();
  }

  /** 過去の問い合わせ情報から回答情報を反映させる */
  private insertAnswerContent(answer: InquiryAnswer) {
    this.inquiryAnswer = { ...this.inquiryAnswer };
    this.inquiryAnswer.answer_content = answer.answer_content;

    this.inquiryAnswer.inquiry_answer_files = answer.inquiry_answer_files.map(
      (file) => {
        return {
          id: 0,
          inquiry_answer_id: this.inquiryAnswer.id,
          sort_no: 1,
          file_path: file.file_path,
          created_at: "",
          updated_at: "",
          deleted_at: "",
          path: "",
          comment: "",
        };
      }
    );

    // this.inquiryAnswer.inquiry_answer_files = [...answer.inquiry_answer_files];
  }

  /** FAQテンプレート情報から回答情報を反映させる */
  private insertTemplateContent(template: Template) {
    this.inquiryAnswer = { ...this.inquiryAnswer };
    this.inquiryAnswer.answer_content = template.answer_content;
    this.inquiryAnswer.inquiry_answer_files = template.template_files.map(
      (file) => {
        return {
          id: 0,
          inquiry_answer_id: this.inquiryAnswer.id,
          sort_no: 1,
          file_path: file.file_path,
          created_at: "",
          updated_at: "",
          deleted_at: "",
          path: "",
          comment: "",
        };
      }
    );
  }
}
