



















































































































import { Component, Mixins, Watch, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Category } from "@/model/inquiry";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import InfiniteLoading from "vue-infinite-loading";
import { Choice } from "@/types";

interface CategoryChoice extends Choice, Category {
  /** カテゴリ階層の深さ */
  depth: number;
}

const DEFAULT_CATEGORY_CHOICE: CategoryChoice = {
  id: 0,
  text: "未選択",
  value: 0,
  category_id: 0,
  parent_id: 0,
  category_type: 0,
  category_name: "",
  depth: 0,
  child_categorys: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

@Component({
  components: {
    AnswerContent,
    InfiniteLoading,
  },
})
export default class CategorySelector extends Mixins(UtilMixin, AxiosMixin) {
  /** カテゴリID */
  @Prop({ default: 0 }) value!: number;

  /** メニューリスト開閉状態 */
  private menu = false;

  /** カテゴリ階層 -> カテゴリ選択肢情報のマップ */
  private mCategory = new Map<number, CategoryChoice[]>([
    [0, [{ ...DEFAULT_CATEGORY_CHOICE }]],
    [1, [{ ...DEFAULT_CATEGORY_CHOICE }]],
    [2, [{ ...DEFAULT_CATEGORY_CHOICE }]],
  ]);

  /** フィルター後の選択肢 */
  private choices1: CategoryChoice[] = [];
  private choices2: CategoryChoice[] = [];
  private choices3: CategoryChoice[] = [];

  /** 選択状態 */
  private selectedCateogryId1 = 0;
  private selectedCateogryId2 = 0;
  private selectedCateogryId3 = 0;

  /** 最終的なカテゴリ情報 */
  private selectedCategory: CategoryChoice[] = [{ ...DEFAULT_CATEGORY_CHOICE }];

  public created(): void {
    this.fetchCategory();
  }

  /** カテゴリクリア時 */
  private clearCategory() {
    this.selectedCategory = [{ ...DEFAULT_CATEGORY_CHOICE }];
    // @inputを発火させる
    this.onChangeCategory();
  }

  /** 選択状態が変わった時に選択肢を新しく作り直す */
  @Watch("mCategory")
  onChangeCateogrys() {
    this.choices1 = [
      DEFAULT_CATEGORY_CHOICE,
      ...(this.mCategory.get(0) as CategoryChoice[]),
    ];
  }

  @Watch("selectedCateogryId1")
  onChangeCategory2() {
    this.choices2 = [...(this.mCategory.get(1) as CategoryChoice[])].filter(
      (data: CategoryChoice) => {
        return data.parent_id == this.selectedCateogryId1 || data.id == 0;
      }
    );

    if (this.choices2.length == 1) {
      this.choices2 = [];
    }

    this.selectedCateogryId2 = 0;
  }

  @Watch("selectedCateogryId2")
  onChangeCategory3() {
    this.choices3 = (this.mCategory.get(2) as CategoryChoice[]).filter(
      (data: CategoryChoice) => {
        return data.parent_id == this.selectedCateogryId2 || data.id == 0;
      }
    );

    if (this.choices3.length == 1) {
      this.choices3 = [];
    }

    this.selectedCateogryId3 = 0;
  }

  /** カテゴリマスタ情報取得 */
  private fetchCategory() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/categorys/get",
      {},
      (res) => {
        this.searchCategorysRecursively(res.data.categorys);
        this.mCategory = new Map(this.mCategory);
      }
    );
  }

  /**
   * カテゴリマスタデータを再帰的に探索してデータ生成
   *
   * 1. categoryId -> categoryのマップ
   * 2. カテゴリの深さ -> categoryChoiceのマップ情報
   */
  private searchCategorysRecursively(src: Category[], depth = 0) {
    src.forEach((category: Category) => {
      // マップに格納
      const categorys = this.mCategory.get(depth) as CategoryChoice[];
      categorys.push({
        ...category,
        text: category.category_name,
        value: category.id,
        depth: depth,
      });
      this.mCategory.set(depth, categorys);

      // 子カテゴリが存在しない(=最下位カテゴリ)の場合は探索打ち切り
      if (!category.child_categorys || category.child_categorys.length == 0) {
        return;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.searchCategorysRecursively(category.child_categorys, depth + 1);
    });
  }

  private onSelectCategory() {
    // 現在選択中のカテゴリーデータを使う
    const distText: string[] = [];
    let tempCategory = { ...DEFAULT_CATEGORY_CHOICE };
    tempCategory = (this.mCategory.get(0) as CategoryChoice[]).filter(
      (data: CategoryChoice) => {
        return data.id == this.selectedCateogryId1;
      }
    )[0];

    distText.push(tempCategory.text);

    if (this.selectedCateogryId2 != 0) {
      tempCategory = (this.mCategory.get(1) as CategoryChoice[]).filter(
        (data: CategoryChoice) => {
          return data.id == this.selectedCateogryId2;
        }
      )[0];

      distText.push(tempCategory.text);
    }
    if (this.selectedCateogryId3 != 0) {
      tempCategory = (this.mCategory.get(2) as CategoryChoice[]).filter(
        (data: CategoryChoice) => {
          return data.id == this.selectedCateogryId3;
        }
      )[0];

      distText.push(tempCategory.text);
    }

    tempCategory.text = distText.join(" / ");

    this.selectedCategory = [{ ...tempCategory }];
    this.reset();
    this.menu = false;

    // @inputを発火させる
    this.onChangeCategory();
  }

  private reset() {
    this.selectedCateogryId1 = 0;
    this.selectedCateogryId2 = 0;
    this.selectedCateogryId3 = 0;
  }

  @Emit("input")
  onChangeCategory() {
    return this.selectedCategory[0].id;
  }
}
