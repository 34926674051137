


















































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import {
  Inquiry,
  InquiryFile,
  DEFAULT_INQUIRY,
  DEFAULT_INQUIRY_FILE,
} from "@/model/inquiry";

@Component({
  components: {
    FileUpload,
  },
})
export default class InquiryFileUploader extends Mixins(UtilMixin) {
  @Prop({ default: () => DEFAULT_INQUIRY }) inquiry!: Inquiry;
  /** アップロード可能なファイルタイプ */
  private accepts = [];
  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";
  /** ファイルダウンロードAPIパス */
  private previewApiUrl = window.base_url + "/api/preview";
  /** 添付可能数 */
  private FILE_LIMIT = 5;
  /** 保存先 */
  private get imagePath(): string {
    return `admin/inquiry/${this.inquiry.id}/`;
  }

  @Watch("inquiry", { immediate: true })
  onChangeInquiry() {
    this.inquiry.inquiry_files = this.inquiry.inquiry_files.map(
      (inquiryFile: InquiryFile) => {
        const file = { ...inquiryFile };
        file.path = file.file_path;
        return file;
      }
    );
  }

  /** 添付枠を追加 */
  private add(): void {
    const inquiryFile = { ...DEFAULT_INQUIRY_FILE };
    inquiryFile.inquiry_id = this.inquiry.id;
    this.inquiry.inquiry_files.push(inquiryFile);
  }

  /** 添付ファイル枠 */
  private async remove(index: number): Promise<void> {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }

    this.inquiry.inquiry_files.splice(index, 1);
  }
}
