




































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import ChangeHistory from "@/components/common/ChangeHistory.vue";
import { Choice } from "@/types";
import { ReceiptActingMail } from "./types";
import { VForm } from "@/types";

import {
  Agreement,
  AgreementHistory,
  ReceiptLink,
  ReceiptLinkSetting,
} from "@/model/agreement";

import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

import {
  PREFECTURES,
  SETTING_ID,
  LINK_SYSTEM_ID,
  LinkSystemId,
} from "@/consts/const";

@Component({
  components: { TextDayChangePicker, AppAuthButton, ChangeHistory },
})
export default class ContractEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("contract-editor") private readonly form!: VForm;

  private PREFECTURES = PREFECTURES;
  private SETTING_ID = SETTING_ID;
  private LINK_SYSTEM_ID = LINK_SYSTEM_ID;
  private LinkSystemId = LinkSystemId;

  private receipt_acting_mails: ReceiptActingMail[] = [];

  private receipt_links: ReceiptLink[] = [];

  private receipt_settings: ReceiptLinkSetting[] = [];

  private receipt_exclusion_divs: Choice[] = [
    { value: 1, text: "事業所単位" },
    { value: 2, text: "契約単位" },
  ];

  private onAndOffs: Choice[] = [
    { value: 0, text: "未使用" },
    { value: 1, text: "使用" },
  ];

  private saveHistoryLog = false;

  //メールアドレス追加
  private addMail() {
    if (!this.receipt_acting_mails) this.receipt_acting_mails = [];

    const len = this.receipt_acting_mails.length;

    const newMail: ReceiptActingMail = {
      id: 0,
      agreement_history_id: this.agreementHistory.id,
      mail_address: "",
      sort_no: len,
    } as ReceiptActingMail;

    this.receipt_acting_mails.push(newMail);
  }

  private async deleteMail(index: number) {
    if (await this.$openConfirm("削除しますか？")) {
      this.receipt_acting_mails.splice(index, 1);
    }
  }

  /** ほのぼのライセンスキーハッシュ値を空にする必要があるかどうか判定 */
  private checkNeedHashEmpty(): number {
    return this.agreement.honobono_license == "" &&
      this.agreement.honobono_key == ""
      ? 1
      : 0;
  }

  // 保存ボタンクリック
  private async clickSave(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    if (!this.saveHistoryLog) {
      this.agreementHistory.change_message = "";
    }

    this.agreementHistory.receipt_link_settings = this.receipt_settings;

    this.postJsonCheck(
      window.base_url + "/api/admin/agreement/update",
      {
        agreement_history: this.agreementHistory,
        honobono_license: this.agreement.honobono_license,
        need_hash_empty: this.checkNeedHashEmpty(),
      },
      () => {
        this.$router.go(-1);
      }
    );
  }

  /** 確定ボタンクリック時 */
  private async confirm(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    if (!(await this.$openConfirm("確定処理を行いますか？"))) {
      return;
    }

    this.agreementHistory.receipt_link_settings = this.receipt_settings;
    this.agreementHistory.confflag = 1;

    this.postJsonCheck(
      window.base_url + "/api/admin/agreement/update",
      {
        agreement_history: this.agreementHistory,
        honobono_license: this.agreement.honobono_license,
        need_hash_empty: this.checkNeedHashEmpty(),
      },
      () => {
        this.$router.go(-1);
      }
    );
  }

  //キャンセルボタンクリック
  private clickCancel() {
    this.$router.go(-1);
  }

  //iBowレセプトボタン制御変更
  private async changeiBowRecBtn() {
    if (this.agreementHistory.ibow_rec_btn_ctrl == 0) {
      this.agreementHistory.ibow_rec_btn_ctrl_start = "";
      this.agreementHistory.ibow_rec_btn_ctrl_end = "";
    }
  }

  //チェックボックスチェック
  private async checkBoxes(link: ReceiptLink) {
    if (link.link_system_id == 1) {
      //ほのぼのNextの場合

      // 解除の場合は何もしない
      if (link.use_div == 0) {
        if (!this.hasCheckedReceiptLink()) {
          // 全解除時は全部表示
          this.createReceiptLinkSettingTemplate();
        }
        return;
      }

      // レセプト連携設定を作成
      this.createReceiptLinkSettingTemplateForHonobono();

      //連携ありがあるかどうか調べる
      let needCheck = false;
      for (const ele of this.receipt_links) {
        if (link !== ele && ele.use_div == 1) {
          needCheck = true;
        }
      }

      //他に連携していない場合は何もしない
      if (!needCheck) {
        return;
      }

      if (
        await this.$openConfirm(
          "ほのぼのNEXTは他連携と併用できません。他の連携設定が解除されますがよろしいですか？"
        )
      ) {
        for (const ele of this.receipt_links) {
          ele.use_div = 0;
        }
        link.use_div = 1;
      } else {
        this.$nextTick(() => {
          link.use_div = 0;
          this.createReceiptLinkSettingTemplate();
        });
      }
    } else {
      //ほのぼの連携以外の場合

      //解除の場合は何もしない
      if (link.use_div == 0) {
        if (!this.hasCheckedReceiptLink()) {
          // 全解除時は全部表示
          this.createReceiptLinkSettingTemplate();
        }
        return;
      }

      this.createReceiptLinkSettingTemplate();

      //ほのぼのNextが連携されているかどうか調べる
      let needCheck = false;
      let honobono: ReceiptLink | null = null;
      for (const ele of this.receipt_links) {
        if (ele.link_system_id == 1 && ele.use_div == 1) {
          needCheck = true;
          honobono = ele;
        }
      }

      //ほのぼのNextが連携されていない場合は何もしない
      if (!needCheck) {
        return;
      }

      if (
        await this.$openConfirm(
          "ほのぼのNEXTは他連携と併用できないため連携設定が解除されますがよろしいですか？"
        )
      ) {
        if (honobono) {
          honobono.use_div = 0;
        }
      } else {
        this.$nextTick(() => {
          // チェックをキャンセルした場合はリンク設定を再代入
          link.use_div = 0;
          this.createReceiptLinkSettingTemplateForHonobono();
        });
      }
    }
  }

  private agreement: Agreement = {} as Agreement;
  private agreementHistory: AgreementHistory = {} as AgreementHistory;

  // インスタンス作成時にAgreement情報を取得
  public created(): void {
    if (this.$route.params.id) this.getAgreement(Number(this.$route.params.id));
  }

  // レセプト連携のうち1つでもチェックが入っているかチェック
  private hasCheckedReceiptLink() {
    for (const link of this.receipt_links) {
      if (link.use_div == 1) {
        return true;
      }
    }
    return false;
  }

  private createReceiptLinkSettingTemplate() {
    const data: ReceiptLinkSetting[] = [];
    for (let i = 1; i <= 6; i++) {
      data.push({
        id: 0,
        setting_id: i,
        insert_div: 1,
        delete_div: 1,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      });
    }
    this.agreementHistory.receipt_link_settings = data;
    this.receipt_settings = data;
  }

  private createReceiptLinkSettingTemplateForHonobono() {
    const data: ReceiptLinkSetting[] = [
      {
        id: 0,
        setting_id: 1,
        insert_div: 0,
        delete_div: 0,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      },
      {
        id: 0,
        setting_id: 2,
        insert_div: 0,
        delete_div: 0,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      },
      {
        id: 0,
        setting_id: 3,
        insert_div: 0,
        delete_div: 0,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      },
      {
        id: 0,
        setting_id: 4,
        insert_div: 0,
        delete_div: 0,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      },
      {
        id: 0,
        setting_id: 5,
        insert_div: 1,
        delete_div: 1,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      },
      {
        id: 0,
        setting_id: 6,
        insert_div: 1,
        delete_div: 1,
        updated_at: "",
        agreement_history_id: this.agreementHistory.id,
      },
    ];

    this.agreementHistory.receipt_link_settings = data;
    this.receipt_settings = data;
  }

  private checkReceiptMailAdress(): boolean | string {
    const useAgency = this.agreementHistory.receipt_agency_div;
    const hasEmails =
      this.agreementHistory.receipt_acting_mails &&
      this.agreementHistory.receipt_acting_mails.filter(
        (mail) => mail.mail_address != ""
      ).length > 0;

    if (useAgency && !hasEmails) {
      return "メールアドレスを設定してください。";
    }

    return true;
  }

  private getAgreement(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      { is_display_confirm: 1, ids: [id] },
      (res) => {
        if (res.data.agreements) {
          this.agreement = res.data.agreements[0];
          this.agreementHistory =
            this.agreement.agreement_historys[
              this.agreement.agreement_historys.length - 1
            ];

          this.agreementHistory.change_message = "";

          // レセプト代行メールアドレス
          if (!this.agreementHistory.receipt_acting_mails) {
            this.agreementHistory.receipt_acting_mails = [];
          }
          this.receipt_acting_mails =
            this.agreementHistory.receipt_acting_mails;

          // レセプト連携
          if (
            !this.agreementHistory.receipt_links ||
            this.agreementHistory.receipt_links.length == 0
          ) {
            const data: ReceiptLink[] = [];
            for (let i = 1; i <= 6; i++) {
              data.push({
                id: 0,
                link_system_id: i,
                use_div: 0,
                updated_at: "",
                agreement_history_id: this.agreementHistory.id,
              });
            }
            this.agreementHistory.receipt_links = data;
          }
          this.receipt_links = this.agreementHistory.receipt_links.slice(0, 4);

          // レセプト連携機能設定
          if (
            !this.agreementHistory.receipt_link_settings ||
            !this.agreementHistory.receipt_link_settings.length
          ) {
            // デフォルトは全部表示
            this.createReceiptLinkSettingTemplate();
          }

          this.receipt_settings = this.agreementHistory.receipt_link_settings;
        }
      }
    );
  }

  /** ほのぼのライセンスハッシュ値リセット時 */
  private onDeleteHonobonoKey() {
    this.agreement.honobono_license = "";
    this.agreement.honobono_key = "";
  }
}
