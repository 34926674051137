





































































































import { Component, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";
import DatePickerYearFormatMixin from "../mixins/datePickerYearFormatMixin";
import AppTextField from "./AppTextField.vue";
import AppButton from "./AppButton.vue";
import * as appDate from "../utility/appDate";

/** allowed-dates属性の型 */
export type FuncAllowedDate = (dateStr: string) => boolean;

@Component({
  components: {
    AppTextField,
    AppButton
  }
})
export default class TextDayChangePicker extends Mixins(
  RulesSaveMixin,
  DatePickerYearFormatMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: "" }) private value!: string;
  @Prop({ default: "対象年月日" }) private label!: string;
  /** 今日ボタン表示フラグ */
  @Prop({ default: true }) private show_today!: boolean;
  /** 翌月ボタン表示フラグ */
  @Prop({ default: false }) private show_next_month!: boolean;
  @Prop({ default: "266px" }) private width!: string;
  @Prop({ default: "225px" }) private minWidth!: string;
  @Prop({ default: "top" }) private warekiPosition!: string;
  @Prop({ default: false }) private isNeed!: boolean;
  @Prop({ default: false }) private showAge!: boolean;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: true }) private clearable!: boolean;
  @Prop({ default: false }) private isReadOnly!: boolean;
  @Prop() private targetDate!: string;
  @Prop() private notAster!: boolean;

  private pickerFlag = false;
  private formattingDate = "";

  @Watch("value") private watchValue() {
    this.formattingDate = this.value;
  }

  @Emit() private input(val: string) {
    return val;
  }

  @Emit() private change(dateStr: string) {
    // チェンジイベント
    return dateStr;
  }

  @Emit("click:clear") private clearDate() {
    this.date = "";
  }

  @Emit("click:today") private clickToday() {
    //
  }

  @Emit() private blur() {
    return;
  }

  created() {
    this.date = this.value;
  }

  /** 今日ボタンクリック */
  private today() {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    this.date = date.toISOString().substr(0, 10);
    const now = appDate.dateToStr(date, "yyyy-MM-dd");
    this.change(now);
    this.clickToday();
  }

  /** 翌月ボタンクリック */
  private nextMonth() {
    const date = new Date();
    const originMonth = date.getMonth();
    date.setMonth(originMonth + 1);
    if (date.getMonth() - originMonth === 2) {
      // 例えば実行時が31日で、翌月に31日が無いために翌々日1日になってしまう場合、ここで月末に補正する
      date.setDate(0);
    }
    this.date = appDate.dateToStr(date, "yyyy-MM-dd");
  }

  /** 選択をリセットできるか */
  private get Clearable() {
    // clearable属性を優先
    if (this.$attrs.clearable != null) {
      return this.$attrs.clearable;
    }
    // 必須の場合リセットできない、必須でない場合リセットできるように
    return !this.isNeed;
  }

  private get date(): string {
    return this.value;
  }
  private set date(val: string) {
    this.formattingDate = val;
    this.input(val);
  }

  private get age(): string {
    if (!this.date) return "";
    const now = new Date();
    const birth = new Date(this.date);
    let age = now.getFullYear() - birth.getFullYear();
    const toBirthday = new Date(
      now.getFullYear(),
      birth.getMonth(),
      birth.getDate()
    );
    if (now < toBirthday) {
      age--;
    }
    return `（${age}歳）`;
  }

  private get warekiDate(): string {
    return appDate.toWarekiDatetime(this.date, true, false);
  }

  private get dateFormatted(): string {
    if (!this.date) return "";
    const [year, month, day] = this.date.split("-");
    return `${year}年${month}月${day}日`;
  }
  private set dateFormatted(val: string) {
    if (val === "") {
      this.date = "";
    }

    let regex = /^([0-9]{8})$/g;
    const result = regex.exec(val);
    if (result) {
      const date = result[1];
      this.date =
        date.substring(0, 4) +
        "-" +
        date.substring(4, 6) +
        "-" +
        date.substring(6, 8);
      return;
    }

    regex = /^([0-9]{4})\/([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})\/([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})$/g;
    this.regixMatch(regex, val);

    regex = /^(0-9]{4})-([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})-([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})$/g;
    this.regixMatch(regex, val);

    regex = /^([0-9]{4})年([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})月([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})日$/g;
    this.regixMatch(regex, val);
  }

  private get localLabel(): string {
    if (this.isNeed && !this.notAster) return "*" + this.label;
    return this.label;
  }

  /** 日付選択エリアルール */
  private get TextDayChangeRules(): Array<boolean | string> {
    return [this.AddrRequired, ...this.rules];
  }

  /** 必須条件取得 */
  private get AddrRequired(): boolean | string {
    if (!this.isNeed) {
      return true;
    }
    return !!this.dateFormatted || "必須です";
  }

  private checkMonth() {
    this.input(this.formattingDate);
  }

  private toJapanDate(date: Date, num = 0): Date {
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate() + num);
    return date;
  }

  private addDay(num: number) {
    // 入力されている日を(num)日後にする
    // 入力が空なら今日にする
    let dateValueObj = new Date();
    if (this.date) {
      dateValueObj = new Date(this.date.replace(/-/g, "/"));
    } else {
      // 年月日以外は0にする
      dateValueObj = new Date(
        dateValueObj.getFullYear(),
        dateValueObj.getMonth(),
        dateValueObj.getDate() - num
      );
    }
    // allowed-dates属性に翌日や前日が当てはまらない場合、180日分見る
    for (let challenge = 1; challenge <= 180; challenge++) {
      /** 前日-n日／翌日+n日 */
      let addDay = challenge - 1;
      // numが1なら翌日にする、numが-1なら前日にする
      if (num < 0) addDay *= -1;
      const date = this.toJapanDate(dateValueObj, num + addDay);
      const dateStr = date.toISOString().substr(0, 10);
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const isAllowedDate = (this.$attrs[
        "allowed-dates"
      ] as any) as FuncAllowedDate;
      /* eslint-enable @typescript-eslint/no-explicit-any */
      if (isAllowedDate == null || isAllowedDate(dateStr)) {
        // 前日または翌日が有効ならそれを返す
        this.date = dateStr;
        this.change(this.date);
        break;
      }
    }
  }

  private regixMatch(regex: RegExp, value: string) {
    const result = regex.exec(value);
    if (result) {
      const year = result[1];
      const month = result[2];
      const day = result[3];
      this.date =
        ("000" + year).slice(-4) +
        "-" +
        ("0" + month).slice(-2) +
        "-" +
        ("0" + day).slice(-2);
    }
  }
}
