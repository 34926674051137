/**
 * 文字列操作共通モジュール
 */

//全角カナを半角カナに変換
export const zenkana2Hankana = (str: string): string => {
  const zen = [
    "ア",
    "イ",
    "ウ",
    "エ",
    "オ",
    "カ",
    "キ",
    "ク",
    "ケ",
    "コ",
    "サ",
    "シ",
    "ス",
    "セ",
    "ソ",
    "タ",
    "チ",
    "ツ",
    "テ",
    "ト",
    "ナ",
    "ニ",
    "ヌ",
    "ネ",
    "ノ",
    "ハ",
    "ヒ",
    "フ",
    "ヘ",
    "ホ",
    "マ",
    "ミ",
    "ム",
    "メ",
    "モ",
    "ヤ",
    "ヰ",
    "ユ",
    "ヱ",
    "ヨ",
    "ラ",
    "リ",
    "ル",
    "レ",
    "ロ",
    "ワ",
    "ヲ",
    "ン",
    "ガ",
    "ギ",
    "グ",
    "ゲ",
    "ゴ",
    "ザ",
    "ジ",
    "ズ",
    "ゼ",
    "ゾ",
    "ダ",
    "ヂ",
    "ヅ",
    "デ",
    "ド",
    "バ",
    "ビ",
    "ブ",
    "ベ",
    "ボ",
    "パ",
    "ピ",
    "プ",
    "ペ",
    "ポ",
    "ァ",
    "ィ",
    "ゥ",
    "ェ",
    "ォ",
    "ャ",
    "ュ",
    "ョ",
    "ッ",
    "゛",
    "°",
    "、",
    "。",
    "「",
    "」",
    "ー",
    "・"
  ];

  const han = [
    "ｱ",
    "ｲ",
    "ｳ",
    "ｴ",
    "ｵ",
    "ｶ",
    "ｷ",
    "ｸ",
    "ｹ",
    "ｺ",
    "ｻ",
    "ｼ",
    "ｽ",
    "ｾ",
    "ｿ",
    "ﾀ",
    "ﾁ",
    "ﾂ",
    "ﾃ",
    "ﾄ",
    "ﾅ",
    "ﾆ",
    "ﾇ",
    "ﾈ",
    "ﾉ",
    "ﾊ",
    "ﾋ",
    "ﾌ",
    "ﾍ",
    "ﾎ",
    "ﾏ",
    "ﾐ",
    "ﾑ",
    "ﾒ",
    "ﾓ",
    "ﾔ",
    "ｲ",
    "ﾕ",
    "ｴ",
    "ﾖ",
    "ﾗ",
    "ﾘ",
    "ﾙ",
    "ﾚ",
    "ﾛ",
    "ﾜ",
    "ｦ",
    "ﾝ",
    "ｶﾞ",
    "ｷﾞ",
    "ｸﾞ",
    "ｹﾞ",
    "ｺﾞ",
    "ｻﾞ",
    "ｼﾞ",
    "ｽﾞ",
    "ｾﾞ",
    "ｿﾞ",
    "ﾀﾞ",
    "ﾁﾞ",
    "ﾂﾞ",
    "ﾃﾞ",
    "ﾄﾞ",
    "ﾊﾞ",
    "ﾋﾞ",
    "ﾌﾞ",
    "ﾍﾞ",
    "ﾎﾞ",
    "ﾊﾟ",
    "ﾋﾟ",
    "ﾌﾟ",
    "ﾍﾟ",
    "ﾎﾟ",
    "ｧ",
    "ｨ",
    "ｩ",
    "ｪ",
    "ｫ",
    "ｬ",
    "ｭ",
    "ｮ",
    "ｯ",
    "ﾞ",
    "ﾟ",
    "､",
    "｡",
    "｢",
    "｣",
    "ｰ",
    "･"
  ];

  let ato = "";

  for (let i = 0; i < str.length; i++) {
    let maechar = str.charAt(i);
    const zenindex = zen.indexOf(maechar);
    if (zenindex >= 0) {
      maechar = han[zenindex];
    }
    ato += maechar;
  }

  return ato;
};

//半角カナ小文字を大文字に変換
export const hankana2Upper = (str: string): string => {
  const low = ["ｧ", "ｨ", "ｩ", "ｪ", "ｫ", "ｬ", "ｭ", "ｮ", "ｯ"];

  const upper = ["ｱ", "ｲ", "ｳ", "ｴ", "ｵ", "ﾔ", "ﾕ", "ﾖ", "ﾂ"];

  let ato = "";

  for (let i = 0; i < str.length; i++) {
    let maechar = str.charAt(i);
    const lowindex = low.indexOf(maechar);
    if (lowindex >= 0) {
      maechar = upper[lowindex];
    }
    ato += maechar;
  }

  return ato;
};

//平仮名をカタカナに変換
export const hiraToKana = (str: string): string => {
  return str.replace(/[\u3041-\u3096]/g, match => {
    const chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
};

/** アルファベット/数値を半角に変換 */
export const alphabetToKana = (str: string): string => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, match => {
    return String.fromCharCode(match.charCodeAt(0) - 0xfee0);
  });
};

//ひらがな、かたかなを半角カナに変換
export const moji2Hankana = (str: string): string => {
  let zenkana = hiraToKana(str);
  zenkana = alphabetToKana(zenkana);
  return zenkana2Hankana(zenkana);
};
