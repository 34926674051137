









































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import {
  BillingComment,
  Billing,
  DEFAULT_BILLING,
  DEFAULT_BILLING_COMMENT,
} from "@/model/agreement";
import SendBillingCommentDialog from "@/components/contractreception/common/SendBillingCommentDialog.vue";

@Component({
  components: {
    SendBillingCommentDialog,
    AppAuthButton,
  },
})
export default class BillingCommentViewer extends Mixins(
  UtilMixin,
  AxiosMixin
) {
  @Prop({ default: DEFAULT_BILLING }) billing!: Billing;
  @Prop({ default: 0 }) agreement_id!: number;
  /** コメント対象のconfflag */
  @Prop({ default: -2 }) confflag!: number;

  @Ref("comment-dialog")
  private readonly sendBillingCommentDialog!: SendBillingCommentDialog;

  private get hasComments() {
    return this.filteredComments.length > 0;
  }

  /** confflagによる表示対象コメント */
  private get filteredComments() {
    if (!this.billing.billing_comments) {
      return [];
    }

    return this.billing.billing_comments.filter(
      (data: BillingComment) => data.confflag == this.confflag
    );
  }

  private open() {
    this.sendBillingCommentDialog.open();
  }

  // コメントの追加(ダイアログ側から情報受け取る)
  private add(comment: string) {
    const billingComment = { ...DEFAULT_BILLING_COMMENT };
    billingComment.billing_id = this.billing.id;
    billingComment.confflag = this.confflag;
    billingComment.comment = comment;

    this.postJsonCheck(
      window.base_url + "/api/admin/billing_comment/save",
      {
        billing_comment: billingComment,
        agreement_id: this.agreement_id,
      },
      (res) => {
        if (!this.billing.billing_comments) {
          this.billing.billing_comments = [] as BillingComment[];
        }
        this.billing.billing_comments.push(res.data.billing_comment);
        this.sendBillingCommentDialog.close();
      }
    );
  }

  // コメントの削除
  private async remove(comment: BillingComment) {
    if (!(await this.$openConfirm("本当に削除しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/billing_comment/delete",
      {
        billing_comment: comment,
        agreement_id: this.agreement_id,
      },
      () => {
        this.billing.billing_comments = this.billing.billing_comments.filter(
          (bc) => bc.id != comment.id
        );
      }
    );
  }
}
