var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("権限編集")]),_c('v-card-text',[_c('v-form',{ref:"auth_form"},[_c('AppTextField',{staticClass:"mb-2",attrs:{"label":"権限名","width":"100%","rules":[
                _vm.required(_vm.adminAuth.name, '必須項目です。'),
                _vm.minLength(
                  _vm.adminAuth.name,
                  200,
                  '200文字以内で入力してください。'
                ) ]},model:{value:(_vm.adminAuth.name),callback:function ($$v) {_vm.$set(_vm.adminAuth, "name", $$v)},expression:"adminAuth.name"}}),_c('v-divider',{staticClass:"mb-4"}),_c('v-simple-table',{staticClass:"elevation-1",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.adminAuth.admin_auth_functions),function(admin_auth_function,index){return _c('tr',{key:index},[_c('td',{staticClass:"head"},[_vm._v(" "+_vm._s(admin_auth_function.function.name)+" ")]),_c('td',[_c('AppSelect',{staticClass:"px-4 pt-5",staticStyle:{"max-width":"100%"},attrs:{"label":"閲覧権限","items":_vm.displays},model:{value:(admin_auth_function.is_reading),callback:function ($$v) {_vm.$set(admin_auth_function, "is_reading", $$v)},expression:"admin_auth_function.is_reading"}})],1),_c('td',{staticClass:"py-5"},[_vm._l((admin_auth_function.editing_auths),function(editAuth,idx){return [_c('AppSelect',{key:idx,staticClass:"px-4 pt-5",staticStyle:{"max-width":"100%"},attrs:{"label":editAuth.name,"items":_vm.execs,"disabled":editAuth.disabled},on:{"change":function($event){return _vm.onChangeEditAuth(
                              admin_auth_function.function.path
                            )}},model:{value:(editAuth.allowed),callback:function ($$v) {_vm.$set(editAuth, "allowed", $$v)},expression:"editAuth.allowed"}})]})],2)])}),0)]},proxy:true}])})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("保存")]),_c('v-btn',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("キャンセル")]),_c('v-spacer'),(_vm.adminAuth.id > 0)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"alert"},on:{"click":function($event){return _vm.deleteAuth()}}},[_vm._v(" 削除 ")]):_vm._e()],1)],1)],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }