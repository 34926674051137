import {
  Inquiry,
  DEFAULT_INQUIRY,
  Template,
  DEFAULT_TEMPLATE,
} from "@/model/inquiry";

/** 一覧表示用Inquiry */
export interface ListInquiry extends Inquiry {
  ticket_no: string;
  last_answered_datetime: string;
  co_code: string;
  kst_no: string;
  corresponding_admin_names: string;
  use_receipt_type: number;
  inquiry_date: string;
  inquiry_time: string;
  all_answer_contents: string;
  answer_statuses: number;
  need_mark_hope_contact_date_time: number;
  inquiry_staff_name: string;
  inquiry_staff_furigana: string;
  co_name: string;
  office_name: string;
  services: string;
  groups: string;
  divs: string;
}

export const DEFAULT_LIST_INQUIRY: ListInquiry = {
  ...DEFAULT_INQUIRY,
  ticket_no: "",
  last_answered_datetime: "",
  co_code: "",
  kst_no: "",
  corresponding_admin_names: "",
  use_receipt_type: 0,
  inquiry_date: "",
  inquiry_time: "",
  all_answer_contents: "",
  answer_statuses: 0,
  need_mark_hope_contact_date_time: 0,
  inquiry_staff_name: "",
  inquiry_staff_furigana: "",
  co_name: "",
  office_name: "",
  services: "",
  groups: "",
  divs: "",
};

export interface DispTemplate extends Template {
  /** カンマ区切りのカテゴリ文字列 */
  categorys: string;
  /** 最終更新者(管理者)名 */
  admin_name: string;
}

export const DEFAULT_DISP_TEMPLATE: DispTemplate = {
  ...DEFAULT_TEMPLATE,
  categorys: "",
  admin_name: "",
};

export interface InquirySearchObj {
  /** 担当者ID */
  admin_id: number;
  /** 企業名 */
  co_name: string;
  /** 企業コード */
  co_code: string;
  /** KST番号 */
  kst_no: string;
  /** 事業所名 */
  office_name: string;
  /** 問い合わせ内容 */
  inquiry_content: string;
  /** 回答内容 */
  answer_content: string;
  /** 電話番号 */
  tel_no: string;
  /** 問い合わせサービス種類 */
  service_divs: number[];
  /** 問い合わせ区分 */
  divs: number[];
  /** アンケート回答結果 */
  questionnaire_selection_ids: number[];
  /** 問い合わせ日:開始 */
  start_date: string;
  /** 問い合わせ日:終了 */
  end_date: string;
  /** 回答完了日:開始 */
  start_complete_date: string;
  /** 回答完了日:終了 */
  end_complete_date: string;
  /** チケット番号 */
  ticket_no: string;
  /** OR検索かどうか */
  is_or_search: number;
  /** 並び替え項目 */
  order_item: string;
  /** 並び替え順序 */
  order_div: string;
  /** 並び替え条件(項目と順序から構成される文字列で自動的に格納) */
  order: string;
  /** テーブルのソート項目 */
  sortBy: [];
  /** テーブルのソート順序 */
  sortDesc: [];
}

export const DEFAULT_INQUIRY_SEARCH_OBJ: InquirySearchObj = {
  admin_id: 0,
  co_name: "",
  co_code: "",
  kst_no: "",
  office_name: "",
  inquiry_content: "",
  answer_content: "",
  tel_no: "",
  service_divs: [0],
  divs: [0],
  questionnaire_selection_ids: [],
  start_date: "",
  end_date: "",
  start_complete_date: "",
  end_complete_date: "",
  ticket_no: "",
  is_or_search: 0,
  order_item: "inquiry_datetime",
  order_div: "DESC",
  order: "inquiry_datetime DESC",
  sortBy: [],
  sortDesc: [],
};
