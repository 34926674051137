






















import { Component, Mixins, Emit, Model, Prop } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppCheckbox extends Mixins(RulesSaveMixin) {
  /** ラベル */
  @Prop() label!: string;

  /** 横に並べるかどうか */
  @Prop({ default: false }) row!: boolean;

  /** 返却値反転 */
  @Prop({ default: false }) reverse!: boolean;

  /** 値 */
  @Model("change") readonly inputValue!: unknown;

  /** 値変更時 */
  @Emit() private change(newValue: unknown): unknown {
    return newValue;
  }

  /** チェックが付いた時の値 */
  private get TrueValue() {
    if (this.Value === 0 || this.Value === 1) {
      return this.reverse ? 0 : 1;
    } else {
      return this.reverse ? false : true;
    }
  }

  /** チェックが外れた時の値 */
  private get FalseValue() {
    if (this.Value === 0 || this.Value === 1) {
      return this.reverse ? 1 : 0;
    } else {
      return this.reverse ? true : false;
    }
  }

  /** 値 */
  private get Value(): unknown {
    return this.inputValue;
  }

  /** 値 */
  private set Value(newValue: unknown) {
    this.change(newValue);
  }
}
