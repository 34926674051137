




























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { HashtagChoice } from "@/model/inquiry";

@Component({})
export default class HashtagSelector extends Mixins(AxiosMixin, UtilMixin) {
  /** 初期値 */
  @Prop({ default: () => [] }) value!: string[];
  /** キーワード選択肢 */
  @Prop({ default: () => [] }) keywords!: HashtagChoice[];

  /** v-model使う用 */
  private get inputHashtags() {
    return this.value;
  }

  /** v-model使う用 */
  private set inputHashtags(inputData: (string | HashtagChoice)[]) {
    this.onInput(inputData);
  }

  @Emit("input")
  private onInput(inputData: (string | HashtagChoice)[]) {
    return inputData.map((data: string | HashtagChoice) => {
      return typeof data == "string" ? data : data.text;
    });
  }
}
