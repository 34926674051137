



















































































































import { Component, Mixins } from "vue-property-decorator";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import TextMonthChangePicker from "#/components/TextMonthChangePicker.vue";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {
    TextDayChangePicker,
    TextMonthChangePicker,
    AppAuthButton,
  },
})
export default class ConfigurationExport extends Mixins(
  RulesMixin,
  UtilMixin,
  AxiosMixin
) {
  private agreementStartDate = this.dateToStr(
    this.getOneYearAgoDate(),
    "yyyy-MM-dd"
  );
  private agreementEndDate = this.dateToStr(new Date(), "yyyy-MM-dd");
  private rec2TargetYearmonth = this.dateToStr(
    this.getPrevMonthDate(),
    "yyyy-MM"
  );
  private rec2Check = 0;
  private staffTargetYearmonth = this.dateToStr(
    this.getPrevMonthDate(),
    "yyyy-MM"
  );
  private inquiryStartYearmonth = this.dateToStr(new Date(), "yyyy-MM");
  private inquiryEndYearmonth = this.dateToStr(new Date(), "yyyy-MM");

  private INFO_DIV_AGREEMENT = 1;
  private INFO_DIV_REC2 = 2;
  private INFO_DIV_STAFF = 3;
  private INFO_DIV_INQUIRY = 4;

  /** CSVエクスポート */
  private csvExport(infoDiv: number) {
    switch (infoDiv) {
      case this.INFO_DIV_AGREEMENT:
        if (!this.checkAgreementForm()) {
          return;
        }
        break;
      case this.INFO_DIV_REC2:
        if (!this.checkRec2Form()) {
          return;
        }
        break;
      case this.INFO_DIV_STAFF:
        if (!this.checkStaffForm()) {
          return;
        }
        break;
      case this.INFO_DIV_INQUIRY:
        if (!this.checkInquiryForm()) {
          return;
        }
        break;
    }

    this.postJsonBlobResCheck(
      window.base_url + "/api/admin/configuration/export",
      { ...this.createSearchObj(infoDiv) },
      (res) => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = this.getCsvFileName(infoDiv);
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /** 検索条件作成 */
  private createSearchObj(infoDiv: number) {
    switch (infoDiv) {
      case this.INFO_DIV_AGREEMENT:
        return {
          info_div: this.INFO_DIV_AGREEMENT,
          start_date: this.agreementStartDate,
          end_date: this.agreementEndDate,
          start_yearmonth: "",
          end_yearmonth: "",
          target_yearmonth: "",
          check: 0,
        };
      case this.INFO_DIV_REC2:
        return {
          info_div: this.INFO_DIV_REC2,
          start_date: "",
          end_date: "",
          start_yearmonth: "",
          end_yearmonth: "",
          target_yearmonth: this.rec2TargetYearmonth,
          check: this.rec2Check ? 1 : 0,
        };
      case this.INFO_DIV_STAFF:
        return {
          info_div: this.INFO_DIV_STAFF,
          start_date: "",
          end_date: "",
          start_yearmonth: "",
          end_yearmonth: "",
          target_yearmonth: this.staffTargetYearmonth,
          check: 0,
        };
      case this.INFO_DIV_INQUIRY:
        return {
          info_div: this.INFO_DIV_INQUIRY,
          start_date: "",
          end_date: "",
          start_yearmonth: this.inquiryStartYearmonth,
          end_yearmonth: this.inquiryEndYearmonth,
          target_yearmonth: "",
          check: 0,
        };
    }
  }

  /** 全企業情報csvエクスポート用フォームチェック */
  private async checkAgreementForm() {
    if (this.agreementStartDate == "" || this.agreementEndDate == "") {
      await this.$openAlert("取得開始日と取得終了日は必須です。");
      return false;
    }

    if (
      this.dateAfter(
        this.agreementStartDate,
        this.agreementEndDate,
        true,
        ""
      ) !== true
    ) {
      await this.$openAlert("取得終了日は取得開始日以降を設定してください。");
      return false;
    }
    return true;
  }

  /** 記録書II情報csvエクスポート用フォームチェック */
  private async checkRec2Form() {
    if (this.rec2TargetYearmonth == "") {
      await this.$openAlert("取得対象年月は必須です。");
      return false;
    }

    // 前月データ作成
    const prevMonthDt = new Date();
    prevMonthDt.setMonth(prevMonthDt.getMonth() - 1);

    if (
      this.dateAfter(
        `${this.rec2TargetYearmonth}-01`,
        `${this.dateToStr(prevMonthDt, "yyyy-MM")}-01`,
        false,
        ""
      ) !== true
    ) {
      await this.$openAlert("取得対象年月は前月以前を設定してください。");
      return false;
    }
    return true;
  }

  /** 職員情報csvエクスポート用フォームチェック */
  private async checkStaffForm() {
    if (this.staffTargetYearmonth == "") {
      await this.$openAlert("取得対象年月は必須です。");
      return false;
    }

    // 前月データ作成
    const prevMonthDt = new Date();
    prevMonthDt.setMonth(prevMonthDt.getMonth() - 1);

    if (
      this.dateAfter(
        `${this.staffTargetYearmonth}-01`,
        `${this.dateToStr(prevMonthDt, "yyyy-MM")}-01`,
        false,
        ""
      ) !== true
    ) {
      await this.$openAlert("取得対象年月は前月以前を設定してください。");
      return false;
    }
    return true;
  }

  /** 問い合わせ情報csvエクスポート用フォームチェック */
  private async checkInquiryForm() {
    if (this.inquiryStartYearmonth == "" || this.inquiryEndYearmonth == "") {
      await this.$openAlert("取得開始年月と取得終了年月は必須です。");
      return false;
    }

    if (
      this.dateAfter(
        `${this.inquiryStartYearmonth}-01`,
        `${this.inquiryEndYearmonth}-01`,
        true,
        ""
      ) !== true
    ) {
      await this.$openAlert(
        "取得終了年月は取得開始年月以降を設定してください。"
      );
      return false;
    }

    if (
      this.checkYearmonthPeriod(
        `${this.inquiryStartYearmonth}-01`,
        `${this.inquiryEndYearmonth}-01`,
        11,
        ""
      ) !== true
    ) {
      return await this.$openConfirm(
        "取得範囲が1年分を超えています。エクスポートに時間が掛かる可能性がありますがよろしいですか？"
      );
    }

    return true;
  }

  /** 出力されるcsvファイル名 */
  private getCsvFileName(infoDiv: number): string {
    switch (infoDiv) {
      case this.INFO_DIV_AGREEMENT:
        return `agreement_${this.dateToStr(new Date(), "yyyyMMddHHmmss")}.csv`;
      case this.INFO_DIV_REC2:
        return `rec2_${this.dateToStr(
          this.newDate(this.rec2TargetYearmonth + "-01"),
          "yyyyMM"
        )}_${this.dateToStr(new Date(), "yyyyMMddHHmmss")}.csv`;
      case this.INFO_DIV_STAFF:
        return `staff_${this.dateToStr(
          this.newDate(this.staffTargetYearmonth + "-01"),
          "yyyyMM"
        )}_${this.dateToStr(new Date(), "yyyyMMddHHmmss")}.csv`;
      case this.INFO_DIV_INQUIRY:
        return `inq_info_${this.dateToStr(new Date(), "yyyyMMddHHmmss")}.csv`;
    }
    return "";
  }

  private getOneYearAgoDate() {
    const nowDt = new Date();
    nowDt.setMonth(nowDt.getMonth() - 11);
    return nowDt;
  }

  private getPrevMonthDate() {
    const nowDt = new Date();
    nowDt.setMonth(nowDt.getMonth() - 1);
    return nowDt;
  }
}
