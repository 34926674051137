






























import { Component, Mixins } from "vue-property-decorator";
import UserMixin from "@/mixins/userMixin";
import Cookies from "js-cookie";

@Component
export default class AppMenu extends Mixins(UserMixin) {
  //ログアウト
  private async logout() {
    if (!(await this.$openConfirm("ログアウトしますか？"))) {
      return;
    }
    window.token = "";
    Cookies.set("admin-token", "");
    window.reftoken = "";
    Cookies.set("admin-reftoken", "");
    location.href = "/";
  }
}
