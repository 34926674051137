










import { Component, Vue } from "vue-property-decorator";
import AppHeader from "@/components/main/AppHeader.vue";
import AppMenu from "@/components/main/AppMenu.vue";

@Component({
  components: {
    AppHeader,
    AppMenu,
  },
})
export default class Main extends Vue {}
