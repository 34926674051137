






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppSubHeader extends Vue {
  @Prop() label!: string;
  @Prop() alert!: string;
  @Prop({ default: false }) isNeed!: boolean;

  private get Label(): string {
    if (this.isNeed) return "*" + this.label;
    return this.label;
  }
}
