import Base from "./../base";

/** 事業所職員コンタクト履歴 */
export interface OfficestaffContact extends Base {
  /** 事業所職員コンタクト履歴ID */
  id: number;
  /** 事業所職員ID */
  staff_id: number;
  /** メッセージ */
  message: string;
}

export const DEFAULT_OFFICESTAFF_CONTACT: OfficestaffContact = {
  id: 0,
  staff_id: 0,
  message: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
};
