

























































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import {
  InquiryContact,
  DispInquiry,
  DispInquiryAnswer,
  DEFAULT_DISP_INQUIRY,
  OfficestaffContact,
  DEFAULT_OFFICESTAFF_CONTACT,
} from "@/model/inquiry";
import InquiryInfo from "@/components/inquiry/InquiryInfo.vue";
import AnswerInfo from "@/components/inquiry/AnswerInfo.vue";
import InquiryContactInfo from "@/components/inquiry/InquiryContactInfo.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UserMixin from "@/mixins/userMixin";
import { INQUIRY_COMPLETED, ANSWER_ASSIGN } from "@/consts/inquiry";
import { Choice } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OfficestaffContactInfo from "@/components/inquiry/OfficestaffContactInfo.vue";
import InquiryQuestionnaireInfo from "@/components/inquiry/InquiryQuestionnaireInfo.vue";

@Component({
  components: {
    InquiryInfo,
    InquiryContactInfo,
    AnswerInfo,
    AppAuthButton,
    OfficestaffContactInfo,
    InquiryQuestionnaireInfo,
  },
})
/** 問い合わせ詳細画面用コンポーネント */
export default class InquiryDetail extends Mixins(AxiosMixin, UserMixin) {
  /** 表示する問い合わせ情報 */
  @Prop({ default: () => DEFAULT_DISP_INQUIRY }) inquiry!: DispInquiry;

  /** 事業所職員コンタクト履歴情報 */
  @Prop({ default: () => DEFAULT_OFFICESTAFF_CONTACT })
  officeStaffContact!: OfficestaffContact;

  /** 問い合わせタグマスタ情報 */
  @Prop({ default: () => [] }) inquiryTagChoices!: Choice[];

  /** 問い合わせステータス定数(完了) */
  private INQUIRY_COMPLETED = INQUIRY_COMPLETED;

  /** ログイン中ユーザー名 */
  private get LoginUserName(): string {
    return `${this.User.last_name} ${this.User.first_name}`;
  }

  /** 回答情報s */
  private get answers(): DispInquiryAnswer[] {
    return this.inquiry.inquiry_answers;
  }

  /** 回答起票無効化フラグ */
  private get answerDisabled() {
    // 回答が1つ & アサイン状態の場合は回答起票ができない
    return (
      this.inquiry.inquiry_answers.length == 1 &&
      this.inquiry.inquiry_answers[0].answer_status == ANSWER_ASSIGN
    );
  }

  /** 問い合わせ編集ボタンクリック時 */
  @Emit("click:edit")
  private toggleEditMode() {
    return this.inquiry.id;
  }

  /** 問い合わせステータス更新 */
  @Emit("save:status")
  private saveStatus(status: number) {
    return { inquiry: this.inquiry, inquiry_status: status };
  }

  /** 問い合わせタグ更新 */
  @Emit("save:tag")
  private saveTag(inquiryTagId: number) {
    return { inquiry: this.inquiry, inquiry_tag_id: inquiryTagId };
  }

  /** 回答起票ボタンクリック時 */
  @Emit("click:answer")
  private async clickNewAnswer() {
    return this.inquiry.id;
  }

  /** 回答編集ボタンクリック時 */
  @Emit("click:editanswer")
  private clickAnswer(answerId: number) {
    return { inquiry_id: this.inquiry.id, answer_id: answerId };
  }

  /** コンタクト履歴保存時 */
  @Emit("save:contact")
  private saveInquiryContact(inquiryContact: InquiryContact) {
    inquiryContact.inquiry_id = this.inquiry.id;
    return inquiryContact;
  }

  /** 問い合わせの公開設定を変更した場合 */
  @Emit("update:inquiry")
  private async onChangeInquirySwitch() {
    return this.inquiry;
  }

  /** 回答の公開設定を変更した場合 */
  @Emit("update:answer")
  private async onChangeInquiryAnswerSwitch(answer: DispInquiryAnswer) {
    return { inquiry: this.inquiry, inquiry_answer: answer };
  }

  /** アンケート設定を変更した場合 */
  @Emit("update:questionnaire")
  private async onChangeQuestionnaireSwitch(answer: DispInquiryAnswer) {
    return { inquiry: this.inquiry, inquiry_answer: answer };
  }

  /** 問い合わせ保存 */
  @Emit("save:inquiry")
  private saveInquiry() {
    return this.inquiry;
  }

  /** 回答保存 */
  @Emit("save:answer")
  private saveInquiryAnswer(answer: DispInquiryAnswer) {
    return { inquiry: this.inquiry, inquiry_answer: answer };
  }

  /** 事業所職員コンタクト履歴保存 */
  @Emit("save:personal")
  private saveOfficeStaffContact(contact: OfficestaffContactInfo) {
    return contact;
  }

  /** 管理者ログイン */
  @Emit("login:system")
  private systemLogin(agreementId: number) {
    return agreementId;
  }
  /** 代理ログイン */
  @Emit("login:agent")
  private agentLogin(staffId: number) {
    return staffId;
  }
}
