var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"mt-2 elevation-1",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("看護計画書AI自動作成機能")]),_c('td',{attrs:{"align":"left"}},[_c('v-switch',{staticClass:"mt-2 mb-2",staticStyle:{"width":"60px"},attrs:{"hide-details":"","inset":"","true-value":Number(1),"false-value":Number(0)},model:{value:(_vm.billing.is_ai_enabled),callback:function ($$v) {_vm.$set(_vm.billing, "is_ai_enabled", $$v)},expression:"billing.is_ai_enabled"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("月次報告書AI自動作成機能")]),_c('td',{attrs:{"align":"left"}},[_c('v-switch',{staticClass:"mt-2 mb-2",staticStyle:{"width":"60px"},attrs:{"hide-details":"","inset":"","true-value":Number(1),"false-value":Number(0)},model:{value:(_vm.billing.is_ai_month_report_enabled),callback:function ($$v) {_vm.$set(_vm.billing, "is_ai_month_report_enabled", $$v)},expression:"billing.is_ai_month_report_enabled"}})],1)]),(_vm.isAiReportReleased)?[_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("開始日")]),_c('td',{attrs:{"align":"left"}},[_c('AppDayPicker',{staticClass:"mt-2 mb-2",attrs:{"label":"開始日","rules":[_vm.checkStartDate(_vm.billing.ai_report_start_date)]},model:{value:(_vm.billing.ai_report_start_date),callback:function ($$v) {_vm.$set(_vm.billing, "ai_report_start_date", $$v)},expression:"billing.ai_report_start_date"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("終了日")]),_c('td',{attrs:{"align":"left"}},[_c('AppDayPicker',{staticClass:"mt-2 mb-2",attrs:{"label":"終了日","rules":[
                  _vm.dateAfter(
                    _vm.billing.ai_report_start_date,
                    _vm.billing.ai_report_end_date,
                    true,
                    '終了日は開始日以降を設定して下さい。'
                  ) ]},model:{value:(_vm.billing.ai_report_end_date),callback:function ($$v) {_vm.$set(_vm.billing, "ai_report_end_date", $$v)},expression:"billing.ai_report_end_date"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("契約開始日")]),_c('td',{attrs:{"align":"left"}},[_c('AppDayPicker',{staticClass:"mt-2 mb-2",attrs:{"label":"契約開始日","rules":[
                  _vm.checkStartDate(_vm.billing.ai_report_agreement_start_date) ]},model:{value:(_vm.billing.ai_report_agreement_start_date),callback:function ($$v) {_vm.$set(_vm.billing, "ai_report_agreement_start_date", $$v)},expression:"billing.ai_report_agreement_start_date"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("契約終了日")]),_c('td',{attrs:{"align":"left"}},[_c('AppDayPicker',{staticClass:"mt-2 mb-2",attrs:{"label":"契約終了日","rules":[
                  _vm.dateAfter(
                    _vm.billing.ai_report_agreement_start_date,
                    _vm.billing.ai_report_agreement_end_date,
                    true,
                    '契約終了日は契約開始日以降を設定して下さい。'
                  ) ]},model:{value:(_vm.billing.ai_report_agreement_end_date),callback:function ($$v) {_vm.$set(_vm.billing, "ai_report_agreement_end_date", $$v)},expression:"billing.ai_report_agreement_end_date"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("請求管理システムへの送信")]),_c('td',{attrs:{"align":"left"}},[_c('v-switch',{staticClass:"mt-2 mb-2",staticStyle:{"width":"60px"},attrs:{"hide-details":"","inset":"","true-value":Number(0),"false-value":Number(1)},model:{value:(_vm.billing.ai_report_is_send_receipt_system),callback:function ($$v) {_vm.$set(_vm.billing, "ai_report_is_send_receipt_system", $$v)},expression:"billing.ai_report_is_send_receipt_system"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" 通知メールアドレス "),_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,731743192)},[_c('span',[_vm._v("書類AI自動作成機能の利用開始時にメールでお知らせ致します。")])])],1),_c('td',{attrs:{"align":"left"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"通知メールアドレス","hide-details":"","maxlength":"400","rules":[
                  _vm.emailFormat(
                    _vm.billing.ai_report_notice_mailaddress,
                    'メールアドレスの形式が正しくありません。'
                  ),
                  _vm.minLength(
                    _vm.billing.ai_report_notice_mailaddress,
                    400,
                    '400文字以内で入力して下さい。'
                  ) ]},model:{value:(_vm.billing.ai_report_notice_mailaddress),callback:function ($$v) {_vm.$set(_vm.billing, "ai_report_notice_mailaddress", $$v)},expression:"billing.ai_report_notice_mailaddress"}})],1)])]:_vm._e()],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }