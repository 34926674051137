











































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import RulesMixin from "../mixins/rulesMixin";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppTextField extends Mixins(RulesMixin, RulesSaveMixin) {
  /** ラベル */
  @Prop() label!: string;
  /** 値 */
  @Prop() value!: string;
  /** 備考 */
  @Prop() remarks!: string;
  /** 高さ */
  @Prop({ default: "48px" }) height!: string;
  /** 幅 */
  @Prop({ default: "100%" }) width!: string;
  /** 最低幅 */
  @Prop({ default: "225px" }) minWidth!: string;
  /** 最大文字数（計測あり） */
  @Prop({ default: 0 }) counterNum!: number;
  /** 最大文字数 */
  @Prop({ default: "" }) maxlength!: string | number;
  /** 必須かどうか */
  @Prop({ default: false }) isNeed!: boolean;

  @Emit() private input(newValue: string): string {
    return newValue;
  }

  /** フィールドルール */
  private get TextFieldRules(): (string | boolean)[] {
    return [this.MaxlengthRule, this.CounterRule, ...this.SaveCheckRules];
  }

  /** 最大文字数ルール */
  private get MaxlengthRule(): string | boolean {
    if (Number(this.maxlength) === 0) {
      return true;
    }
    return this.minLength(
      this.value,
      Number(this.maxlength),
      this.maxlength + "文字以内で入力してください。"
    );
  }

  /** 最大文字数ルール */
  private get CounterRule(): string | boolean {
    if (this.counterNum == 0) {
      return true;
    }
    if (this.Counter > this.counterNum) {
      return this.counterNum + "文字以内で入力して下さい";
    }
    return true;
  }

  private get Counter(): number {
    if (this.counterNum > 0) {
      return this.simpleCountChar(this.value)[0];
    }
    return 0;
  }

  private get Value(): string {
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }

  private get Label(): string {
    if (this.isNeed) return "*" + this.label;
    return this.label;
  }

  /** 文字を計測する */
  private simpleCountChar(newValue: string): [number, string] {
    if (!newValue) {
      return [0, newValue];
    }
    const length = [...newValue.toString()].length;
    return [length, newValue];
  }
}
