





















import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Choice } from "@/types";
import { Inquiry, DEFAULT_INQUIRY } from "@/model/inquiry";
@Component({})
export default class InquiryTagSelectDialog extends Mixins(UtilMixin) {
  /** 問い合わせ情報 */
  @Prop({ default: () => DEFAULT_INQUIRY }) inquiry!: Inquiry;

  /** 問い合わせタグマスタ情報 */
  @Prop() inquiryTagChoices!: Choice[];

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** 問い合わせタグID */
  private inquiryTagId = 0;

  /** ダイアログ開く */
  public open() {
    this.inquiryTagId = this.inquiry.inquiry_tag_id;
    this.isOpen = true;
  }

  /** ダイアログ閉じる */
  public close() {
    this.isOpen = false;
  }

  /** 保存時イベント */
  @Emit("save:tag")
  private save(): number {
    this.isOpen = false;
    return this.inquiryTagId;
  }
}
