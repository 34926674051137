import * as SELECT_FILTER from "../../const/selectFilter";
import { moji2Hankana } from "../../utility/appString";

/** 選択肢一覧情報（呼び出し元から受け取る必要がある情報） */
export interface ColumnKey {
  type: number; //フィルタタイプ 0:文字列 1:文字列（リスト選択） 2:数字
  key: string;
  text: string;
  selectable: string[] | null;
}

/** フィルタ用条件（呼び出し元へ渡す必要がある検索条件） */
export interface FilterClause {
  id: number;
  word: string | null;
  menu: boolean;
  matchType: number;
  numIdx: number;
  dateFilterCondition: number;
  colKey: ColumnKey;
}

export interface HeaderColumn {
  text: string;
  value: string;
  sortable: boolean;
  align?: string;
  width?: string;
  filterCondition?: number;
  filterType?: number;
  filter?: (val: object) => boolean;
  columnKey?: ColumnKey;
}

//------------------------------------------
// 検索条件にvalが該当するかチェック（文字列用）
//------------------------------------------
const isMatchString = (val: string | number | Date, clause: FilterClause) => {
  if (clause.word == null) {
    return true;
  }
  //データなしの場合は、完全一致で判定
  if (clause.word === SELECT_FILTER.NO_DATA) {
    return val === "" || val === SELECT_FILTER.NO_DATA;
  }
  if (clause.word === SELECT_FILTER.EXIST_DATA) {
    return val !== "";
  }
  const v = moji2Hankana(String(val));
  const w = moji2Hankana(clause.word);
  const idx = v.trim().toLowerCase().indexOf(w.trim().toLowerCase());

  // フィルタータイプが「部分一致のみ検索」の場合の処理
  if (clause.colKey.type === SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL) {
    return idx !== -1;
  }

  switch (clause.matchType) {
    case SELECT_FILTER.MATCH_TYPE_PREFIX: // 前方一致
      return idx === 0;
    case SELECT_FILTER.MATCH_TYPE_PARTIAL: // 部分一致
      return idx !== -1;
    default:
      if (clause.colKey.type != SELECT_FILTER.FILTER_TYPE.EQUAL_LIST) {
        return v === w;
      } else {
        switch (clause.numIdx) {
          case 0: //{ value: 0, text: "に等しい" }
            return v === w;
          case 1: //{ value: 1, text: "に等しくない" }
            return v !== w;
        }
      }

      return v === w;
  }
};

//------------------------------------------
// 検索条件にvalが該当するかチェック（数値用）
//------------------------------------------
const isMatchNumber = (val: string | number | Date, clause: FilterClause) => {
  if (clause.word == null) {
    return true;
  }
  const v = Number(val);
  const w = Number(clause.word);
  // 数字に変換できない場合
  if (isNaN(v) || isNaN(w)) {
    return true;
  }
  switch (clause.numIdx) {
    case 0: //{ value: 0, text: "に等しい" }
      return v == w;
    case 1: //{ value: 1, text: "に等しくない" }
      return v != w;
    case 2: //{ value: 2, text: "より大きい" }
      return v > w;
    case 3: //{ value: 3, text: "以上" }
      return v > w || v == w;
    case 4: //{ value: 4, text: "より小さい" }
      return v < w;
    case 5: //{ value: 5, text: "以下" }
      return v < w || v == w;
  }
  return true;
};

//------------------------------------------
// 検索条件にvalが該当するかチェック（数値用）
//------------------------------------------
const isMatchDate = (val: string | number | Date, clause: FilterClause) => {
  if (clause.word == null) {
    return true;
  }

  const value = new Date(val);
  const word = new Date(clause.word);
  // 日付に変換できない場合
  if (isNaN(value.getTime()) || isNaN(word.getTime())) {
    return false;
  }

  // yyyy-MM-dd HH:mm:ssでも日付部のみで比較
  value.setHours(0, 0, 0);
  word.setHours(0, 0, 0);

  const v = value.getTime();
  const w = word.getTime();

  switch (clause.numIdx) {
    case 0: //{ value: 0, text: "に等しい" }
      return v == w;
    case 1: //{ value: 1, text: "に等しくない" }
      return v != w;
    case 2: //{ value: 2, text: "より大きい" }
      return v > w;
    case 3: //{ value: 3, text: "以上" }
      return v > w || v == w;
    case 4: //{ value: 4, text: "より小さい" }
      return v < w;
    case 5: //{ value: 5, text: "以下" }
      return v < w || v == w;
  }
  return true;
};

//------------------------------------------
// 検索条件にvalが該当するかチェック
// clausesは同じカラムの条件のみであること
//------------------------------------------
export const IsFilterMatch = (
  filterClauses: FilterClause[],
  key: string,
  val: string | number | Date
) => {
  // 同じカラムの検索条件のみ抽出
  const clauses = filterClauses.filter((clause) => {
    return (
      clause.colKey.key == key &&
      (clause.word != "" || clause.dateFilterCondition != 0)
    );
  });
  if (clauses.length == 0) {
    return true;
  }
  // OR 条件

  let bret = true;
  for (let i = 0; i < clauses.length; i++) {
    const clause = clauses[i];
    switch (clause.colKey.type) {
      case SELECT_FILTER.FILTER_TYPE.EDIT:
      case SELECT_FILTER.FILTER_TYPE.LIST:
      case SELECT_FILTER.FILTER_TYPE.EDIT_LIST:
      case SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL:
      case SELECT_FILTER.FILTER_TYPE.EQUAL_LIST:
        bret = isMatchString(val, clause);
        break;
      case SELECT_FILTER.FILTER_TYPE.NUMBER:
        bret = isMatchNumber(val, clause);
        break;
      case SELECT_FILTER.FILTER_TYPE.DATE:
      case SELECT_FILTER.FILTER_TYPE.MONTH:
      case SELECT_FILTER.FILTER_TYPE.TIME:
        bret = isMatchDate(val, clause);
        break;
    }
    if (!bret) {
      break;
    }
  }
  return bret;
};
