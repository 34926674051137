









































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { ServiceLink, ServiceLinksByCategories } from "@/model/serviceLink";
import { linkHeaders } from "@/components/serviceLink/const";
import SortTable from "@/components/serviceLink/SortTable.vue";

@Component({
  components: {
    AppAuthButton,
    SortTable,
  },
})
export default class extends Mixins(AxiosMixin) {
  private homeServiceLinks: ServiceLink[] = [];
  private serviceLinks: ServiceLink[] = [];
  private serviceLinksByCategories: ServiceLinksByCategories[] = [];
  private homeHeaders = linkHeaders;
  private serviceLinkHeaders = linkHeaders.filter((h) => h.text != "カテゴリ");

  private tab = 0;

  searchText = "";

  created(): void {
    this.fetchServiceLinksByCategory();
    this.fetchHomeServiceLinks();
  }

  private fetchServiceLinksByCategory(): void {
    this.postJsonCheck(
      window.base_url + "/api/service_links/category/get",
      {
        show_service_links: true,
        sort_type: 1,
        is_not_end: true,
      },
      (res) => {
        this.serviceLinksByCategories = res.data.service_links_by_categories;
      }
    );
  }

  private fetchHomeServiceLinks(): void {
    this.postJsonCheck(
      window.base_url + "/api/service_links/get",
      {
        show_home: true,
        sort_type: 2,
        is_not_end: true,
      },
      (res) => {
        this.homeServiceLinks = res.data.service_links;
      }
    );
  }

  private save(serviceLinks: ServiceLink[]) {
    this.postJsonCheck(
      window.base_url + "/api/service_links/save",
      {
        service_links: serviceLinks,
      },
      () => {
        this.fetchServiceLinksByCategory();
        this.fetchHomeServiceLinks();
      }
    );
  }
}
