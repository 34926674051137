import { MutationTree } from "vuex";
import { RootState, SET_IS_REGISTERD_AUTH_NAVIGATION } from "./types";

export const mutations: MutationTree<RootState> = {
  [SET_IS_REGISTERD_AUTH_NAVIGATION](
    state,
    is_registerd_auth_navigation: boolean
  ) {
    state.is_registerd_auth_navigation = is_registerd_auth_navigation;
  },
};
