








































import { Component, Mixins, Emit, Prop, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { ServiceLinkCategory } from "@/model/serviceLink";
import { VForm } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class CategoryEditDalog extends Mixins(AxiosMixin, UtilMixin) {
  /** フォームバリデーション用 */
  @Ref("category_form") private readonly form!: VForm;

  // @Prop({}) serviceLinkCategory!: ServiceLinkCategory

  @Emit("clickDelete") private clickDelete() {
    return this.serviceLinkCategory;
  }

  @Emit("clickSave") private clickSave() {
    return this.serviceLinkCategory;
  }

  /** ダイアログ操作用 */
  private dialog = false;
  private serviceLinkCategory: ServiceLinkCategory = {
    id: 0,
    name: "",
    sort_no: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
  };

  public open(category?: ServiceLinkCategory) {
    if (category) {
      this.serviceLinkCategory = { ...category };
    }
    this.dialog = true;
  }
  public close() {
    this.reset();
    this.dialog = false;
  }

  /** データリセット */
  private reset() {
    this.serviceLinkCategory = {
      id: 0,
      name: "",
      sort_no: 0,
      created_at: "",
      updated_at: "",
      deleted_at: "",
    };
  }

  /** 保存ボタンクリック */
  private async onClickSave() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (await this.$openConfirm("保存しますか?")) {
      this.clickSave();
    }
  }

  /** 削除ボタンクリック */
  private async onClickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.clickDelete();
    }
  }
}
