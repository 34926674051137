
















import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import InputDemositeStart from "@/components/demosite/InputDemositeStart.vue";
import InputDemositeResult from "@/components/demosite/InputDemositeResult.vue";
import { FileIF, DEFAULT_FILEIF } from "@/views/demosite/types";

@Component({
  components: {
    FileUpload,
    InputDemositeStart,
    InputDemositeResult,
  },
})
/**
 * デモサイト一括作成
 */
export default class InputDemosite extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** 設定中のファイル情報 */
  private inputFile: FileIF = { ...DEFAULT_FILEIF };

  /** 状態(1:初期 2:結果) */
  private state = 1;

  /** 一括作成 */
  private input() {
    this.postJsonCheck(
      window.base_url + "/api/admin/demosites/input",
      {
        file_path: this.inputFile.path,
        file_name: this.inputFile.name,
      },
      () => {
        // 結果画面表示
        this.state = 2;
      }
    );
  }

  /** 画面リセット時 */
  private reset() {
    this.state = 1;
  }
}
