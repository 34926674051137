




































































































































































































































import {
  Component,
  Prop,
  Mixins,
  Emit,
  Watch,
  Ref,
} from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import FileUpload, { FileIF } from "#/components/FileUpload.vue";
import DateInput from "#/components/DateInput.vue";
import RulesMixin from "#/mixins/rulesMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { ServiceLink, ServiceLinkMail } from "@/model/serviceLink";
import { LINK_TYPE } from "@/components/serviceLink/type";
import AppIcon from "#/components/AppIcon.vue";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import { VForm } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

export interface FileItem {
  id: number;
  file_name: string;
  file_size: string;
  updated_at: string;
}

@Component({
  components: {
    AppPlaces,
    FileUpload,
    DateInput,
    AppIcon,
    TextDayChangePicker,
    TrumbowygEditor,
    AppAuthButton,
  },
})
export default class ServiceLinkEdit extends Mixins(RulesMixin, AxiosMixin) {
  @Prop({}) serviceLink!: ServiceLink;
  /** フォームバリデーション用 */
  @Ref("service_link_form") private readonly form!: VForm;

  @Emit("clickDelete") private clickDelete() {
    return;
  }

  @Emit("clickSave") private clickSave() {
    switch (this.serviceLink.type) {
      case LINK_TYPE.DIRECT:
        this.serviceLink.service_link_mails = [];
        this.serviceLink.intermediate_page = "";
        break;
      case LINK_TYPE.MAIL:
        this.serviceLink.url = "";
        this.serviceLink.intermediate_page = "";
        this.filterBlankMail();

        break;
      case LINK_TYPE.INTERMEDIATE:
        this.serviceLink.url = "";
        this.serviceLink.service_link_mails = [];
        break;

      default:
        break;
    }
    this.serviceLink.img = this.file.path;
    return;
  }

  @Emit("clickCancel") private clickCancel() {
    return;
  }

  @Watch("serviceLink", { immediate: true })
  onChangeServiceLink() {
    this.file = {
      id: 0,
      path: this.serviceLink.img,
      comment: "",
    };
  }

  private linkTypes = [
    { text: "直リンク", value: 1 },
    { text: "お問合せフォーム", value: 2 },
    { text: "中間ページ", value: 3 },
  ];

  private officeId = 0;
  private categories = [];

  private file: FileIF = {
    id: 0, //ID
    path: "", //'画像パス',
    comment: "", //'コメント',
  };
  private uploadApiUrl = window.base_url + "/api/upload"; // ファイルアップロードAPIパス
  private previewApiUrl = window.base_url + "/api/preview"; // ファイルダウンロードAPIパス
  private get imagePath() {
    return "admin/service_link/" + String(this.serviceLink.id);
  }

  mounted() {
    this.getCategories();
  }

  updated() {
    this.addBlankMail();
  }

  /** 画像削除ボタンクリック */
  private async clickDeleteFile() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.serviceLink.img = "";
      this.file = { id: 0, path: "", comment: "" };
    }
  }

  /** 保存ボタンクリック */
  private async onClickSave() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (await this.$openConfirm("保存しますか?")) {
      this.clickSave();
    }
  }

  /** キャンセルボタンクリック */
  private async onClickCancel() {
    if (
      await this.$openConfirm(
        "編集を終了します。\n編集中の場合は内容が破棄されます。"
      )
    ) {
      this.clickCancel();
    }
  }

  /** 削除ボタンクリック */
  private async onClickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.clickDelete();
    }
  }

  /**
   * メールアドレス空オブジェクト追加
   */
  private addBlankMail() {
    // 空のメールアドレスが１件でもあった場合処理しない
    if (this.serviceLink.service_link_mails?.some((t) => t.mail_address === ""))
      return;

    this.serviceLink.service_link_mails.push({
      id: 0,
      service_link_id: this.serviceLink.id,
      mail_address: "",
      created_at: "",
      updated_at: "",
      deleted_at: "",
    });
    const copy = this.serviceLink.service_link_mails;
    this.serviceLink.service_link_mails = [...copy];
  }

  /**
   * メールアドレス空オブジェクト排除
   */
  private filterBlankMail() {
    // 空ではないメールアドレスのみ
    this.serviceLink.service_link_mails =
      this.serviceLink.service_link_mails.filter((t) => t.mail_address != "");
  }

  /**
   * メールアドレス変更時処理
   */
  private changeMail(idx: number, mail: ServiceLinkMail) {
    if (
      this.serviceLink.service_link_mails.length > 1 &&
      mail.mail_address === ""
    ) {
      this.serviceLink.service_link_mails = [
        ...this.serviceLink.service_link_mails.filter(
          (mail) => mail.mail_address != ""
        ),
      ];
    }
    this.addBlankMail();
  }

  private createUrl() {
    return `${window.ibow_auth_url}/service-link/inquiry-form/${this.serviceLink.id}`;
  }

  private mailRule() {
    console.log("mails", this.serviceLink.service_link_mails);
    if (this.serviceLink.service_link_mails.length == 1) {
      return "メールアドレスは１件以上入力してください。";
    }
    return true;
  }

  private isValidUrl(url: string) {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return "正しいURLを入力してください。";
    }
  }

  /**
   * カテゴリ一覧取得
   */
  private getCategories(): void {
    this.postJsonCheck(
      window.base_url + "/api/service_link/categories/get",
      {},
      (res) => {
        this.categories = res.data.service_link_categories;
      }
    );
  }
}
