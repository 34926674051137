import Base from "../base";

/** 計画書自動作成設定 */
export interface AiVnplanSetting extends Base {
  /** ID */
  id: number;
  /** プロンプトテンプレートID */
  prompt_template_id: number;
  /** 最終更新者ID */
  editor_admin_id: number;
  /** インデックス情報 */
  index_settings: AiVnplanIndexSetting[];
}

export const DefaultAiVnplanSetting = (): AiVnplanSetting => ({
  id: 0,
  prompt_template_id: 0,
  editor_admin_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  index_settings: [],
});

/** 計画書自動作成インデックス設定 */
export interface AiVnplanIndexSetting extends Base {
  /** ID */
  id: number;
  /** 計画書自動作成設定 */
  ai_vnplan_setting_id: number;
  /** 年月指定種類 */
  yearmonth_type: number;
}

export const DefaultAiVnplanIndexSetting = (): AiVnplanIndexSetting => ({
  id: 0,
  ai_vnplan_setting_id: 0,
  yearmonth_type: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});
