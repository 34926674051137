export const SET_IS_SAVE = "SET_IS_SAVE";
export const SET_IS_PROCESSING = "SET_IS_PROCESSING";
export const SET_IS_BTN_CLICK = "SET_IS_BTN_CLICK";

// チャット
export const SET_FETCHING_ACOUNT_ID = "SET_FETCHING_ACOUNT_ID";
export const DELETE_FETCHING_ACCOUNT_ID = "DELETE_FETCHING_ACCOUNT_ID";
export const SET_ACCOUNT_IMAGE_PATH = "SET_ACCOUNT_IMAGE_PATH";
export const FETCH_ACCOUNT_IMAGE_PATH = "FETCH_ACCOUNT_IMAGE_PATH";
export const SET_AGREEMENTS = "SET_AGREEMENTS";

export interface ChatState {
  enabledAgreements: Agreement[];
  accountImages: AccountImage[];
  fetchingAccountIds: number[];
}

export interface AccountImage {
  accountId: number;
  path: string;
  expired: Date;
}

export interface Agreement {
  id: number;
  corp_name: string;
}
