






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppChip extends Vue {
  /** 色 */
  @Prop() private readonly color!: string;
}
