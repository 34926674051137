






































































import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DataTableHeader } from "vuetify/types/index";
import { Office } from "@/model/office";
import AmchartLineGraph from "@/components/configuration/statistics/AmchartLineGraph.vue";
import {
  LineGraphData,
  LineData,
  CountData,
  DEFAULT_LINE_GRAPH_DATA,
  SearchObj,
} from "@/components/configuration/statistics/types";

/** 加算項目による集計グラフ情報 */
interface AddDivCountGraph extends LineGraphData {
  /** 加算項目による集計情報 */
  lines: ServiceCodeCountItem[];
}

/** 加算項目による集計情報 */
interface ServiceCodeCountItem extends LineData {
  /** 凡例名 */
  title: string;
  /** 事業所ID */
  office_id: number;
  /** 事業所名 */
  office_name: string;
  /** サービスコード */
  service_code: number;
  /** サービスコード種別ID */
  kind_code: number;
  /** サービスコード名 */
  service_name: string;
  /** 集計情報 */
  count_data: AddDivCountData[];
}

/** 集計情報 */
interface AddDivCountData extends CountData {
  /** 枚数 */
  count: number;
  /** 年月情報(yyyy-MM) */
  date: string;
}

/** サービスコードマスタ */
interface ServiceCode {
  /** サービスコード */
  service_code: number;
  /** サービスコード種別ID */
  kind_code: number;
  /** サービスコード名 */
  service_name: string;
  /** 選択肢名 */
  choice_name: string;
}

@Component({
  components: {
    AmchartLineGraph,
  },
})
export default class AddDivCountViewer extends Mixins(AxiosMixin, UtilMixin) {
  /** 検索条件 */
  @Prop() searchObj!: SearchObj;

  /** amchart操作用 */
  @Ref("amchart") private readonly amchart!: AmchartLineGraph;

  /** グラフデータ */
  private graphData: AddDivCountGraph = { ...DEFAULT_LINE_GRAPH_DATA };

  /** テーブルヘッダー情報 */
  private headers: DataTableHeader[] = [];

  /** 事業所情報 */
  private offices: Office[] = [];

  /** 医療サービスコード */
  private medicalServiceCodes: ServiceCode[] = [];

  /** 介護サービスコード */
  private careServiceCodes: ServiceCode[] = [];

  /** 選択中 */
  private selectedOffices: Office[] = [];

  /** 選択中事業所IDs */
  private selectedOfficeIds: number[] = [];

  /** 選択中 */
  private selectedMedicalServiceCodes: ServiceCode[] = [];

  /** 選択中 */
  private selectedCareServiceCodes: ServiceCode[] = [];

  /** データテーブル絞込み用 */
  private searchKey = "";

  /** 初回時、検索ボタンクリック時に再描画させる */
  @Watch("searchObj")
  onChangeSearchObj() {
    this.fetchAddDivCount();
  }
  /** 初回時、検索ボタンクリック時に再描画させる */
  created() {
    this.fetchAddDivCount();
  }

  /** 与えられた検索条件を元に確定済みレセプト訪問記録の医療・介護区分の枚数を取得 */
  private async fetchAddDivCount() {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/configuration/get_add_div_count",
        { ...this.searchObj },
        (res) => {
          this.graphData = {
            title: "記録書II枚数",
            unit: "枚",
            lines: [],
          };

          this.graphData.lines = res.data.service_code_count_items.map(
            (data: ServiceCodeCountItem) => {
              return {
                title: `${data.office_name} ${data.service_name}`,
                office_id: data.office_id,
                office_name: data.office_name,
                service_code: data.service_code,
                kind_code: data.kind_code,
                service_name: data.service_name,
                count_data: data.count_data,
              };
            }
          );

          // 集計対象の項目情報
          this.offices = res.data.offices;
          this.medicalServiceCodes = res.data.medical_service_codes;
          this.careServiceCodes = res.data.care_service_codes;

          this.selectedOffices = [];
          this.selectedOfficeIds = [];
          this.selectedMedicalServiceCodes = [];
          this.selectedCareServiceCodes = [];

          this.createHeaders();

          resolve(0);
        }
      );
    });
  }

  // Excel形式でエクスポート
  private exportData() {
    this.amchart.exportData();
  }

  /** data-table用ヘッダー生成 */
  private createHeaders() {
    // startとendからヘッダー情報を生成
    const startDt = this.newDate(
      this.convYearmonth2DateStr(this.searchObj.start_yearmonth) + "-01"
    );
    const endDt = this.newDate(
      this.convYearmonth2DateStr(this.searchObj.end_yearmonth) + "-01"
    );

    const resHeaders: DataTableHeader[] = [];
    resHeaders.push({
      text: "事業所",
      value: "office",
      align: "center",
      width: "100px",
      divider: true,
    });

    resHeaders.push({
      text: "サービスコード種類",
      value: "item",
      align: "center",
      width: "160px",
      divider: true,
    });

    const current = startDt;
    let index = 0;
    while (current <= endDt) {
      resHeaders.push({
        text: this.dateToStr(current, "yyyy年MM月"),
        value: `yearmonth${index}`,
        align: "center",
        width: "120px",
        divider: true,
      });
      current.setMonth(current.getMonth() + 1);
      index++;
    }
    this.headers = resHeaders;
  }

  /** v-data-table用データ作成 */
  private get items() {
    return this.filteredGraphData.lines.map((data: ServiceCodeCountItem) => {
      const item: { [index: string]: unknown } = {
        office: data.office_name,
        item: `${data.service_name}`,
      };
      data.count_data.forEach((count, index) => {
        item[`yearmonth${index}`] = count.count;
      });
      return item;
    });
  }

  /** Excel出力用データ作成 */
  private get excelData() {
    return this.filteredGraphData.lines.map((data: ServiceCodeCountItem) => {
      const item: { [index: string]: unknown } = {
        事業所名: data.office_name,
        サービスコード種類: `${data.service_name}`,
      };
      data.count_data.forEach((count) => {
        item[this.dateToStr(this.newDate(count.date + "-01"), "yyyy年MM月")] =
          count.count;
      });
      return item;
    });
  }

  /** チェックに合わせて表示するデータを変更 */
  private get filteredGraphData() {
    const data = { ...this.graphData };
    data.lines = this.graphData.lines.filter((data: ServiceCodeCountItem) => {
      // 折線データの事業所IDが選択中のものかどうか
      const officeMatch = this.selectedOfficeIds.includes(data.office_id);

      const medicalMatch =
        this.selectedMedicalServiceCodes.filter(
          (serviceCode: ServiceCode) =>
            data.service_code == serviceCode.service_code &&
            data.kind_code == serviceCode.kind_code
        ).length > 0;
      const careMatch =
        this.selectedCareServiceCodes.filter(
          (serviceCode: ServiceCode) =>
            data.service_code == serviceCode.service_code &&
            data.kind_code == serviceCode.kind_code
        ).length > 0;

      return officeMatch && (medicalMatch || careMatch);
    });

    return data;
  }

  /** 事業所選択肢 */
  private get officeChoices() {
    return this.offices.map((office: Office) => {
      return { text: office.office_name, value: office.id };
    });
  }

  /** yearmonth:number -> dateStr:string */
  private convYearmonth2DateStr(yearmonth: number): string {
    const yearmonthStr = String(yearmonth);
    return `${yearmonthStr.slice(0, 4)}/${yearmonthStr.slice(4, 7)}`;
  }
}
