// 都道府県一覧
export const PREFECTURES = [
  { text: "", value: 0 },
  { text: "北海道", value: 1 },
  { text: "青森県", value: 2 },
  { text: "岩手県", value: 3 },
  { text: "宮城県", value: 4 },
  { text: "秋田県", value: 5 },
  { text: "山形県", value: 6 },
  { text: "福島県", value: 7 },
  { text: "茨城県", value: 8 },
  { text: "栃木県", value: 9 },
  { text: "群馬県", value: 10 },
  { text: "埼玉県", value: 11 },
  { text: "千葉県", value: 12 },
  { text: "東京都", value: 13 },
  { text: "神奈川県", value: 14 },
  { text: "新潟県", value: 15 },
  { text: "富山県", value: 16 },
  { text: "石川県", value: 17 },
  { text: "福井県", value: 18 },
  { text: "山梨県", value: 19 },
  { text: "長野県", value: 20 },
  { text: "岐阜県", value: 21 },
  { text: "静岡県", value: 22 },
  { text: "愛知県", value: 23 },
  { text: "三重県", value: 24 },
  { text: "滋賀県", value: 25 },
  { text: "京都府", value: 26 },
  { text: "大阪府", value: 27 },
  { text: "兵庫県", value: 28 },
  { text: "奈良県", value: 29 },
  { text: "和歌山県", value: 30 },
  { text: "鳥取県", value: 31 },
  { text: "島根県", value: 32 },
  { text: "岡山県", value: 33 },
  { text: "広島県", value: 34 },
  { text: "山口県", value: 35 },
  { text: "徳島県", value: 36 },
  { text: "香川県", value: 37 },
  { text: "愛媛県", value: 38 },
  { text: "高知県", value: 39 },
  { text: "福岡県", value: 40 },
  { text: "佐賀県", value: 41 },
  { text: "長崎県", value: 42 },
  { text: "熊本県", value: 43 },
  { text: "大分県", value: 44 },
  { text: "宮崎県", value: 45 },
  { text: "鹿児島県", value: 46 },
  { text: "沖縄県", value: 47 },
];
// レセプト使用タイプ
export const USE_RECEIPT_TYPE = [
  { text: "未選択", value: 0 },
  { text: "訪看鳥", value: 1 },
  { text: "給管帳クラウド", value: 2 },
  { text: "ほのぼのNEXT", value: 3 },
  { text: "ワイズマン", value: 4 },
  { text: "すこやかサン", value: 5 },
  { text: "ちょうじゅ", value: 6 },
  { text: "SilverLand", value: 7 },
  { text: "FlowersNEXT", value: 8 },
  { text: "その他", value: 9 },
];

// システム停止理由
export const STOP_REASON = [
  { value: 0, text: "未設定" },
  { value: 1, text: "終了（更新なし）" },
  { value: 2, text: "中途解約" },
  { value: 3, text: "休止" },
];

// 契約プラン
export const AGREEMENT_PLAN_TYPE = [
  { value: 0, text: "未選択" },
  { value: 1, text: "2年通常(A)" },
  { value: 2, text: "東京CP(A)" },
  { value: 3, text: "2年サマーCP(A)" },
  { value: 4, text: "5年サマーCP(A)" },
  { value: 5, text: "3年CP(B)" },
  { value: 6, text: "3年相対(C)" },
  { value: 7, text: "5年相対(C)" },
  { value: 8, text: "2年基本" },
  { value: 9, text: "2年基本・パーフェクトコース" },
  { value: 10, text: "2年相対" },
  { value: 11, text: "3年相対" },
  { value: 12, text: "4年相対" },
  { value: 13, text: "5年相対" },
  { value: 14, text: "7年相対" },
  { value: 15, text: "相対" },
  { value: 16, text: "IT導入補助金プラン" },
  { value: 17, text: "IT導入補助金プラン・パーフェクトコース" },
  { value: 99, text: "その他" },
];

// 請求管理システムへの送信
export const NEED_RECEIPT_SYSTEM_MAIL = [
  { value: 0, text: "送る" },
  { value: 1, text: "送らない" },
];

// 銀行の種類
export const BANK_TYPE = [
  { value: 0, text: "未選択" },
  { value: 1, text: "銀行" },
  { value: 2, text: "信用金庫" },
  { value: 3, text: "信用組合" },
  { value: 4, text: "農業協同組合" },
];

// 銀行選択
export const BANK_SELECTION = [
  { value: 0, text: "未選択" },
  { value: 1, text: "ゆうちょ銀行" },
  { value: 2, text: "それ以外の金融機関" },
];

// 支店種類
export const BRANCH_TYPE = [
  { value: 0, text: "未選択" },
  { value: 1, text: "本店" },
  { value: 2, text: "支店" },
  { value: 3, text: "出張所" },
  { value: 4, text: "御中" },
];

// 口座種類
export const DEPOSIT_TYPE = [
  { value: 0, text: "未選択" },
  { value: 1, text: "普通" },
  { value: 2, text: "当座" },
];

// レセプト代行使用の有無
export const IBOW_RECEIPT_DIV = [
  { value: 0, text: "未使用" },
  { value: 1, text: "使用" },
];

// レセプト請求管理システムへの送信
export const RECEIPT_IS_SEND_RECEIPT_SYSTEM = [
  { value: 0, text: "送る" },
  { value: 1, text: "送らない" },
];

// レセプト代行使用の有無
export const IS_OCR_ENABLED = [
  { value: 0, text: "未使用" },
  { value: 1, text: "使用" },
];

// OJT研修機能使用の有無
export const USE_OJT = [
  { value: 0, text: "未使用" },
  { value: 1, text: "使用" },
];

// 介護保険区分
export const CARE_DIV = [
  { value: 1, text: "介護" },
  { value: 2, text: "予防" },
];

export const IS_ATTENDANCE_USE = [
  { value: 0, text: "未使用" },
  { value: 1, text: "使用" },
];

// レセプト事前チェック機能使用の有無
export const USE_RECEIPT_PRE_CHECK = [
  { value: 0, text: "未使用" },
  { value: 1, text: "使用" },
];

/** 使用/未使用区分 */
export const USE_DIV = [
  { value: 0, text: "未使用" },
  { value: 1, text: "使用" },
];

/** 請求管理システム送る/送らない区分 */
export const SEND_RECEIPT_SYSTEM_DIV = [
  { value: 0, text: "送る" },
  { value: 1, text: "送らない" },
];

export const LinkSystemId = {
  HONOBONO_NEXT: 1, // ほのぼの
  WIZEMAN: 2, // ワイズマン
  SUKOYAKASAN: 3, // すこやかサン
  TYOUJU: 4, // ちょうじゅ
  SILVER_LAND: 5, // SilverLand
  FLOWERS_NEXT: 6, // Flowers Next
} as const;
export type LinkSystemId = typeof LinkSystemId[keyof typeof LinkSystemId]; // 'r' | 'w' | 'x'
export const LINK_SYSTEM_ID = [
  { value: LinkSystemId.HONOBONO_NEXT, text: "ほのぼのNEXT" },
  { value: LinkSystemId.WIZEMAN, text: "ワイズマン" },
  { value: LinkSystemId.SUKOYAKASAN, text: "すこやかサン" },
  { value: LinkSystemId.TYOUJU, text: "ちょうじゅ" },
  { value: LinkSystemId.SILVER_LAND, text: "SilverLand" },
  { value: LinkSystemId.FLOWERS_NEXT, text: "Flowers Next" },
];

export const SETTING_ID = [
  { value: 1, text: "利用者情報" },
  { value: 2, text: "職員情報" },
  { value: 3, text: "医療機関情報" },
  { value: 4, text: "医師情報" },
  { value: 5, text: "関連機関情報" },
  { value: 6, text: "関連機関担当者情報" },
];

/** 職員権限定数 */
export const STAFF_AUTH = [
  { value: 1, text: "一般職員" },
  { value: 10, text: "事務所事務員" },
  { value: 11, text: "事務所所長" },
  { value: 20, text: "本社職員" },
  { value: 99, text: "システム管理者" },
];
