




















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { ACCESS_CONFIRMS } from "@/consts/inquiry";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import { DispInquiry, DEFAULT_DISP_INQUIRY } from "@/model/inquiry";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    AttachmentViewer,
  },
})
export default class InquirySimpleViewer extends Mixins(UtilMixin) {
  /** 問い合わせ */
  @Prop({ default: () => DEFAULT_DISP_INQUIRY })
  inquiry!: DispInquiry;

  /** 定数_登録状況の確認 */
  private ACCESS_CONFIRMS = ACCESS_CONFIRMS;

  /** HTML用問い合わせ内容 */
  private getHtmlContent(text: string) {
    return text.replace(/\r?\n/g, "<br>");
  }
}
