














































































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { InquiryDiv, InquiryGroup, InquiryServiceDiv } from "@/model/inquiry";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { Choice } from "@/types";
import PastInquiryNavigationDrawer from "@/components/inquiry/PastInquiryNavigationDrawer.vue";
import { ListInquiry } from "@/views/inquiry/types";
import { InquiryAnswer } from "@/model/inquiry";

@Component({
  components: {
    InfiniteLoading,
    PastInquiryNavigationDrawer,
  },
})
export default class PastInquiryViewer extends Mixins(UtilMixin, AxiosMixin) {
  private inquirys: ListInquiry[] = [];

  /** 検索: 事業所名 */
  private officeName = "";
  /** 検索: チケット番号 */
  private ticketNo = "";

  /** 検索: 問い合わせ内容 */
  private inquiryContent = "";

  /** 検索: 回答内容 */
  private answerContent = "";

  /** 検索: 問い合わせ区分 */
  private selectedDivs = [];

  /** 検索: 問い合わせグループ */
  private selectedGroups = [];

  /** 検索: 問い合わせ区分 */
  private selectedServiceDivs = [];

  /** スクロールカウント数 */
  private scrollCount = 0;

  /** 1回の取得件数 */
  private limit = 10;

  /** 最大スクロールカウント数 */
  private maxScrollCount = -1;

  /** 契約情報詳細を表示するためのナビゲーションドロワー制御用フラグ */
  private drawer = false;

  /** 詳細表示対象の問い合わせ */
  private targetInquiry: ListInquiry = {} as ListInquiry;

  /** 問い合わせ区分マスタ情報 */
  @Prop({ default: () => [] }) divs!: InquiryDiv[];

  /** 問い合わせグループマスタ情報 */
  @Prop({ default: () => [] }) groups!: InquiryGroup[];

  /** 問い合わせサービス種類マスタ情報 */
  @Prop({ default: () => [] }) serviceDivs!: InquiryServiceDiv[];

  /** 編集可能かどうか */
  @Prop({ default: false }) editable!: boolean;

  /** 検索エリア表示フラグ */
  private showSearchArea = true;

  private get inquiryDivChoices(): Choice[] {
    return this.divs.map((div: InquiryDiv) => {
      return { text: div.name, value: div.id };
    });
  }

  private get inquiryGroupChoices(): Choice[] {
    return this.groups.map((group: InquiryGroup) => {
      return { text: group.name, value: group.id };
    });
  }

  private get serviceDivChoices(): Choice[] {
    return this.serviceDivs.map((serviceDiv: InquiryServiceDiv) => {
      return { text: serviceDiv.name, value: serviceDiv.id };
    });
  }

  /** 過去の問い合わせリスト表示エリアの高さ */
  private get panelStyle() {
    return this.showSearchArea ? "max-height: 60vh" : "max-height: 80vh";
  }

  /** 各問い合わせがクリックされた場合は問い合わせ情報をナビゲーションドロワーで表示 */
  private displayInquiryInfo(inquiry: ListInquiry) {
    this.drawer = !this.drawer;
    this.targetInquiry = inquiry;
  }

  // 問い合わせ情報を取得
  private async fetchInquirys() {
    return new Promise((resolve) => {
      if (this.scrollCount != 0) {
        // 検索ボタン押下時以外での取得はローディングダイアログを表示させない
        this.loading = -100;
      }

      this.postJsonCheck(
        window.base_url + "/api/admin/inquiries/get",
        {
          office_name: this.officeName,
          ticket_no: this.ticketNo,
          inquiry_content: this.inquiryContent,
          answer_content: this.answerContent,
          limit: this.limit,
          offset: this.limit * this.scrollCount,
          divs: this.selectedDivs,
          groups: this.selectedGroups,
          service_divs: this.selectedServiceDivs,
        },
        (res) => {
          this.inquirys.push(...res.data.inquirys);

          // inquiryCountから最大スクロールカウント数を計算
          this.maxScrollCount = Math.floor(res.data.inquiry_count / this.limit);
          resolve(0);
        }
      );
    });
  }

  /** 検索ボタン押下時 */
  private search(): void {
    this.inquirys = [];
    this.scrollCount = 0;
    this.maxScrollCount = -1;

    // 検索ボタン押下時の取得の場合はローディングダイアログを表示させる
    this.loading = 0;

    this.fetchInquirys();
  }

  /**
   * スクロールが最下部まで到達した時の無限ローディング処理
   *
   * スクロール回数が最大回数に到達していない場合は+1してさらに取得
   * 取得した結果はinquirysにpush
   */
  private async infiniteHandler($state: StateChanger) {
    // 読み込み完了していない場合は何もせず続行
    if (this.maxScrollCount == -1) {
      return;
    }

    // 最大スクロール回数に到達している場合は完了
    if (this.scrollCount == this.maxScrollCount) {
      $state.complete();
      return;
    }

    // 到達していない場合は+1して再度fetch
    this.scrollCount++;
    await this.fetchInquirys();
    $state.loaded();
  }

  @Emit("insert:answer")
  private insertAnswerContent(answer: InquiryAnswer) {
    this.drawer = false;
    return answer;
  }
}
