




















































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import NumberInputString from "./NumberInputString.vue";
import AppSubTitle from "./AppSubTitle.vue";
import RulesMixin from "../mixins/rulesMixin";

@Component({
  components: {
    NumberInputString,
    AppSubTitle
  }
})
export default class AppPhoneArea extends Mixins(RulesMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) area!: string;
  @Prop({ default: "" }) city!: string;
  @Prop({ default: "" }) num!: string;
  @Prop({ default: "" }) contact!: string;
  @Prop({ default: false }) telRequired!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "100%" }) width!: string;
  @Prop({ default: "225px" }) minWidth!: string;
  @Prop({ default: false }) isDispContact!: boolean;
  @Prop({ default: "" }) contactLabel!: string;
  @Prop({ default: "24%" }) itemWidth!: string;

  @Emit("update:area")
  private updateArea(newValue: string): string {
    return newValue;
  }

  @Emit("update:city")
  private updateCity(newValue: string): string {
    return newValue;
  }

  @Emit("update:num")
  private updateNum(newValue: string): string {
    return newValue;
  }
  @Emit("update:contact")
  private updateContact(newValue: string): string {
    return newValue;
  }

  private telRules(str: string): boolean | string {
    if (!this.telRequired) {
      return true;
    }
    return !!str || "必須です";
  }

  private get Area() {
    return this.area;
  }

  private set Area(newValue) {
    this.updateArea(newValue);
  }

  private get City() {
    return this.city;
  }

  private set City(newValue) {
    this.updateCity(newValue);
  }

  private get Num() {
    return this.num;
  }

  private set Num(newValue) {
    this.updateNum(newValue);
  }
  private get Contact() {
    return this.contact;
  }

  private set Contact(newValue) {
    this.updateContact(newValue);
  }
}
