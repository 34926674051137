




























































































import { Component, Prop, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import UtilMixin from "@/mixins/utilMixin";
import SearchBase from "@/components/admin_common/SearchBase.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_IS_PUBLIC_COLOR,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_APPROVAL_STATUS_COLOR,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR,
} from "@/consts/portal";
import { PortalOffice } from "@/model/portal";

@Component({
  components: {
    AppTextField,
    SearchBase,
    AppAuthButton,
  },
})
export default class PortalOfficeTable extends Mixins(AxiosMixin, UtilMixin) {
  private searchKey = ""; //絞り込み文字列

  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_IS_PUBLIC_COLOR = PORTAL_OFFICE_IS_PUBLIC_COLOR;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_APPROVAL_STATUS_COLOR =
    PORTAL_OFFICE_APPROVAL_STATUS_COLOR;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR;

  /** 契約情報 */
  @Prop({ default: () => [] }) private items: PortalOffice[] = [];

  private headers = [
    { text: "事業所名", value: "name" },
    { text: "承認状態", value: "approval_status" },
    { text: "初回掲載情報承認状態", value: "listing_review_status" },
    { text: "掲載情報公開状態", value: "is_public" },
    { text: "iBow利用", value: "use_ibow" },
    { text: "編集", value: "action" },
  ];

  /** 詳細ボタンクリック時 */
  private goDetailIbow(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/portal-office-ibow/detail/${id}` });
  }
  private goDetailNoIbow(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({
      path: `/portal/portal-office-no-ibow/detail/${id}`,
    });
  }
}
