






























import { Component, Mixins, Ref } from "vue-property-decorator";
import {
  Term,
  DefaultTerm,
  DefaultTermLong,
  DefaultTermShort,
  DefaultTermProblem,
  DefaultTermSolution,
} from "#/model/master";
import AiRecommend from "@/components/recommend/AiRecommend.vue";
import { PromptLine } from "@/views/recommend/types";
import Form from "@/components/recommend/Form.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";

@Component({
  components: { AiRecommend, Form },
})
/**
 * AIお勧め検索 編集
 */
export default class TermEdit extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** Ref */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** 病名に対する看護計画内容 */
  private term: Term = DefaultTerm();

  /** 保存前の公開設定 */
  private beforeVisibility = 0;

  private promptLines: PromptLine[] = [];

  /** 関数 */

  created() {
    this.fetch();
    this.fetchPrompt();
  }

  /** デフォルトプロンプト取得 */
  private fetchPrompt() {
    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/prompt/get",
      {},
      async (res) => {
        this.promptLines = res.data.prompt_lines;
      }
    );
  }

  /** 取得 */
  private fetch() {
    const termId = Number(this.$route.params.id);

    if (termId === 0) {
      this.setInitialData();
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/term/get",
      { ids: [termId] },
      async (res) => {
        this.term = res.data.terms[0];
        this.beforeVisibility = this.term.visibility;
      }
    );
  }

  /** 保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/term/save",
      { term: this.term, before_visibility: this.beforeVisibility },
      () => {
        this.$router.push({ path: `/recommend/list` });
      }
    );
  }

  /** 削除 */
  private async deleteTerm() {
    if (!(await this.$openConfirm("本当に削除してもよろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/term/delete",
      { term: this.term },
      () => {
        this.$router.push({ path: `/recommend/list` });
      }
    );
  }

  /** 編集キャンセル */
  private async cancel() {
    if (
      !(await this.$openConfirm(
        "編集を終了します。\n編集中の場合は内容が破棄されます。"
      ))
    ) {
      return;
    }

    this.$router.push({ path: `/recommend/list` });
  }

  /** 病名重複チェック */
  private checkDupe() {
    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/term/check/dupe",
      { terms: [this.term] },
      async (res) => {
        await this.$openAlert(res.data.check_message);
      }
    );
  }

  /** 新規作成用、初期値作成 */
  private setInitialData() {
    this.term = DefaultTerm();
    this.term.term_longs = [DefaultTermLong()];
    this.term.term_shorts = [DefaultTermShort()];
    this.term.term_problems = [DefaultTermProblem()];
    this.term.term_problems[0].term_solutions = [DefaultTermSolution()];
  }

  /** 送信ボタン押下時 */
  private async send() {
    const prompt = this.promptLines.map((promptLine: PromptLine) => {
      const tmp = { ...promptLine };
      if (tmp.text === "${病名}") {
        tmp.text = this.term.disease;
      }

      return tmp;
    });

    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/send",
      {
        disease: this.term.disease,
        prompt_lines: prompt,
      },
      async (res) => {
        this.term.term_ai_answers = [res.data.ai_answer];
      }
    );
  }

  /** AIが回答した看護計画を反映 */
  private apply() {
    if (!this.term.term_ai_answers || this.term.term_ai_answers.length == 0) {
      return;
    }

    const aiAnswer = this.term.term_ai_answers[0];
    if (aiAnswer.answer_type != 1) {
      return;
    }

    const aiTerm = aiAnswer.term;

    this.term.term_longs = JSON.parse(JSON.stringify(aiTerm.term_longs));
    this.term.term_shorts = JSON.parse(JSON.stringify(aiTerm.term_shorts));
    this.term.term_problems = JSON.parse(JSON.stringify(aiTerm.term_problems));
  }
}
