


























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import AxiosMixin from "@/mixins/axiosMixin";

@Component({
  components: { FileUpload },
})
/**
 * 職員一括連携 初期画面
 */
export default class extends Mixins(UtilMixin, AxiosMixin) {
  /** ダイアログ開閉状態 */
  @Prop({ default: false }) private readonly value!: boolean;

  @Emit() private input(value: boolean) {
    return value;
  }

  private get Value() {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  /** 選択中ファイル情報 */
  private inputFile = {
    id: 0,
    path: "",
    name: "",
  };

  /** ファイルアップロード用API */
  private uploadApiUrl = `${window.base_url}/api/upload`;

  /** アップロードファイル保存先パス */
  private get ImagePath(): string {
    return `admin/kintai-replication/`;
  }

  /** 取込ボタンクリック時 */
  private async onClickStart() {
    if (this.inputFile.path == "") {
      await this.$openAlert("Excelファイルを指定してください。");
      return;
    }

    this.postJsonCheck(
      window.base_url +
        "/api/kintai-replication/input-kintai-replication-excel",
      {
        agreement_id: Number(this.$route.params.id),
        file_path: this.inputFile.path,
        file_name: this.inputFile.name,
      },
      () => {
        this.$openAlert("取込が完了しました。");
        this.Value = false;
        return;
      }
    );
  }
}
