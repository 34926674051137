






































// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VueTimepicker from "vue2-timepicker";
import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import "vue2-timepicker/dist/VueTimepicker.css";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component({
  components: {
    VueTimepicker
  }
})
export default class AppTimePickerString extends Mixins(RulesSaveMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** value: 時 */
  @Prop({ default: "" }) private hh!: string;

  /** value: 分 */
  @Prop({ default: "" }) private mm!: string;

  /** 必須 */
  @Prop({ default: false }) private readonly isNeed!: boolean;

  /** 高さ */
  @Prop({ default: "48px" }) private readonly height!: string;

  /** 横幅 */
  @Prop({ default: "65px" }) private readonly width!: string;

  /** 最小横幅 */
  @Prop({ default: "65px" }) private readonly minWidth!: string;

  /** 無効フラグ */
  @Prop({ default: false }) private readonly disabled!: boolean;

  /** 分間隔 */
  @Prop({ default: 1 }) private readonly minuteInterval!: number;

  /** inputの表示位置 */
  @Prop({ default: "down" }) private dropDirection!: string;

  /** 時間チェックバリデーションを行わない */
  @Prop({ default: false }) private notCheckTime!: boolean;

  /** ルール */
  // @Prop({ default: [] }) private timeRules!: (string | boolean)[];

  @Emit("update:hh")
  private updateHH(hh: string) {
    return hh;
  }
  @Emit("update:mm")
  private updateMM(mm: string) {
    return mm;
  }

  @Emit()
  private blur() {
    return;
  }

  private get HH() {
    return this.hh;
  }
  private set HH(hh: string) {
    this.updateHH(hh);
  }

  private get MM() {
    return this.mm;
  }
  private set MM(mm: string) {
    this.updateMM(mm);
  }

  private get RuleTime(): string | boolean {
    if (this.notCheckTime) {
      return true;
    }
    if (this.isNeed && this.HH === "" && this.MM === "") {
      return "時間を入力してください。";
    }
    if (this.HH === "" && this.MM !== "") {
      return "「時」を入力してください。";
    }
    if (this.HH !== "" && this.MM === "") {
      return "「分」を入力してください。";
    }
    return true;
  }

  private get RuleHour() {
    if (Number(this.HH) < 0 || 23 <  Number(this.HH)) {
      return "「時」は0〜23の範囲で入力してください。";
    }
    return true;
  }

  private get RuleMinute() {
    if (Number(this.MM) < 0 || 59 <  Number(this.MM)) {
      return "「分」は0〜59の範囲で入力してください。";
    }
    return true;
  }
}
