var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('AppSubTitle',{staticClass:"mb-6"},[_vm._v("バッチ処理設定")]),_c('div',{staticClass:"mb-8"},[_c('v-form',{ref:"batch-form"},[_c('div',{staticClass:"mx-2 mb-6 font-weight-bold text-subtitle-2"},[_c('p',[_vm._v(" 有効にしておくことで、毎月1日に前月分のインデックスが自動作成されます。 ")])]),_c('AppSelect',{staticClass:"mb-4",attrs:{"label":"データセット","width":"360px","items":_vm.datasetChoice,"rules":[_vm.checkBatchSettingDataset()]},model:{value:(_vm.batchSetting.dataset_id),callback:function ($$v) {_vm.$set(_vm.batchSetting, "dataset_id", $$v)},expression:"batchSetting.dataset_id"}}),_c('NumberInput',{staticClass:"mb-4",attrs:{"label":"最大処理件数","width":"360px","hide-details":"","maxlength":7,"rules":[_vm.checkBatchSettingProcessLimit()]},model:{value:(_vm.batchSetting.process_limit),callback:function ($$v) {_vm.$set(_vm.batchSetting, "process_limit", $$v)},expression:"batchSetting.process_limit"}}),_c('v-switch',{staticStyle:{"width":"180px"},attrs:{"label":"バッチ処理有効","inset":""},model:{value:(_vm.batchSetting.enable),callback:function ($$v) {_vm.$set(_vm.batchSetting, "enable", $$v)},expression:"batchSetting.enable"}}),_c('div',{staticStyle:{"width":"180px"}},[_c('AppAuthButton',{attrs:{"label":"保存","color":"primary"},on:{"click":function($event){return _vm.saveBatchSetting()}}})],1)],1)],1),_c('v-divider'),_c('AppSubTitle',{staticClass:"my-6"},[_vm._v("手動作成")]),_c('div',{staticClass:"mb-8"},[_c('v-form',{ref:"form"},[_c('div',[_c('AppSelect',{staticClass:"mb-4",attrs:{"label":"データセット","width":"360px","rules":[
            _vm.minNumber(_vm.datasetId, 1, 'データセットを選択してください。') ],"items":_vm.datasetChoice},model:{value:(_vm.datasetId),callback:function ($$v) {_vm.datasetId=$$v},expression:"datasetId"}}),_c('AppMonthPicker',{staticClass:"mb-4",attrs:{"label":"年月指定","width":"360px","rules":[_vm.required(_vm.yearmonth, '年月を選択してください。')],"clearable":false},model:{value:(_vm.yearmonth),callback:function ($$v) {_vm.yearmonth=$$v},expression:"yearmonth"}}),_c('NumberInput',{staticClass:"mb-4",attrs:{"label":"最大処理件数","width":"360px","hide-details":"","maxlength":7,"rules":[
            _vm.minNumber(
              _vm.processLimit,
              1,
              '最大処理件数は1件以上を指定してください。'
            ) ]},model:{value:(_vm.processLimit),callback:function ($$v) {_vm.processLimit=$$v},expression:"processLimit"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('AppAuthButton',{attrs:{"label":"インデックスを作成","color":"primary"},on:{"click":function($event){return _vm.createIndex()}}})],1)],1)])],1),_c('AppDataTable',{staticClass:"elevation-0 adl-table",attrs:{"loadingText":"検索中...お待ちください","no-data-text":"データがありませんでした。","headers":_vm.headers,"items":_vm.indices,"pageNo":_vm.pageNo,"search":_vm.searchKeyword,"frontOnlyPageCount":_vm.itemPerPage,"isNoFilterButton":true,"loading":_vm.loading > 0},on:{"update:pageNo":_vm.changePage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"align-center pt-2 mx-4",attrs:{"no-gutters":""}},[_c('AppTextField',{attrs:{"hide-details":"","clearable":"","width":"300px"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('AppIcon',{attrs:{"icon":"search"}})]},proxy:true}]),model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('AppAuthButton',{attrs:{"label":item.is_valid ? '無効化' : '有効化',"color":item.is_valid ? 'append' : 'primary',"className":item.is_valid ? 'mr-2 white--text' : 'mr-2'},on:{"click":function($event){return _vm.switchIndexValid(item)}}}),_c('AppAuthButton',{attrs:{"label":"削除","color":"alert","className":"white--text"},on:{"click":function($event){return _vm.deleteIndex(item.id)}}})],1)]}}],null,true)}),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }