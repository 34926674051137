











































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Setting, DEFAULT_SETTING } from "@/model/setting";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import AppToolTip from "#/components/AppToolTip.vue";
import { ListSupportableDateSetting } from "@/views/configuration/types";
import SupportableDateSetting from "@/components/configuration/setting/SupportableDateSetting.vue";

@Component({
  components: {
    AppAuthButton,
    AppToolTip,
    SupportableDateSetting,
  },
})
export default class ConfigurationShow extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Ref("supportableDateSetting")
  private readonly supportableDateSettingComponent!: SupportableDateSetting;

  /** 編集中かどうか */
  private isEditMode = false;

  /** 基本設定情報 */
  private setting: Setting = { ...DEFAULT_SETTING };

  /** タブ切り替え用 */
  private settingType = 0;

  /** 基本設定 */
  private SETTING_BASIC = 0;

  /** サポート対応可否設定 */
  private SETTING_SUPPORTABLE = 1;

  /** サポート対応可否設定情報 */
  private supportableDateSettings: ListSupportableDateSetting[] = [];

  /** 掲示板掲載通知先メールアドレス表記 */
  private get InformationNoticeMail(): string {
    return this.setting.information_notice_mail
      ? this.setting.information_notice_mail
      : "未設定";
  }

  created(): void {
    this.fetchSettings();
  }

  /** 基本設定情報を取得 */
  private fetchSettings() {
    this.postJsonCheck(
      window.base_url + "/api/admin/settings/get",
      {},
      (res) => {
        this.setting = res.data.setting;
        this.supportableDateSettings = res.data.supportable_date_settings;
      }
    );
  }

  /** 編集中かどうかの切り替え */
  private toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  /** 保存処理 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    // 保存
    this.postJsonCheck(
      window.base_url + "/api/admin/settings/save",
      { setting: this.setting },
      (res) => {
        this.isEditMode = false;
        this.setting = res.data.setting;
      }
    );
  }

  /** サポート対応可否設定情報の削除 */
  private async deleteSupportableDateSetting(
    target: ListSupportableDateSetting
  ) {
    if (!(await this.$openConfirm("本当に削除しますか？"))) {
      return;
    }
    // 削除処理
    this.postJsonCheck(
      window.base_url + "/api/admin/setting/support/delete",
      { supportable_date_setting: target },
      () => {
        this.fetchSettings();
      }
    );
  }

  /** サポート対応可否設定情報の追加・更新 */
  private saveSupportableDateSetting(target: ListSupportableDateSetting) {
    // 保存処理
    this.postJsonCheck(
      window.base_url + "/api/admin/setting/support/save",
      { supportable_date_setting: target },
      () => {
        this.supportableDateSettingComponent.reset();
        this.fetchSettings();
      }
    );
  }
}
