





























import { Component, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { AdminAuth } from "@/model/adminUser";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class AuthList extends Mixins(AxiosMixin, UtilMixin) {
  private adminAuths: AdminAuth[] = [];

  created(): void {
    // 権限一覧取得
    this.postJsonCheck(window.base_url + "/api/admin/auths/get", {}, (res) => {
      if (res.data.auths) {
        this.adminAuths = res.data.auths;
      }
    });
  }

  // 新規作成時は0、それ以外は権限IDを渡す
  private edit(id: number) {
    this.$router.push({ path: "/auth/edit/" + id });
  }
}
