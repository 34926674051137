


















import { Component, Vue, Prop } from "vue-property-decorator";
import AppIcon from "./AppIcon.vue";

@Component({
  components: { AppIcon }
})
export default class AppToolTip extends Vue {
  @Prop({ default: "" }) private label!: string;
  @Prop({ default: "mdi-help-circle" }) private icon!: string;
  @Prop({ default: true }) private top!: boolean;
  @Prop({ default: false }) private bottom!: boolean;
  @Prop({ default: false }) private right!: boolean;
  @Prop({ default: false }) private left!: boolean;
  @Prop({ default: "" }) private addContentClass!: string;
  @Prop({ default: "" }) private maxWidth!: string;
}
