
















































import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import { BillingFile, DEFAULT_BILLING_FILE } from "@/model/agreement/billing";

@Component({
  components: {
    FileUpload,
  },
})
export default class BillingFileUploader extends Mixins(UtilMixin) {
  @Prop({ default: () => [] as BillingFile[] }) billing_files!: BillingFile[];
  @Prop({ default: "" }) co_code!: string;
  @Prop({ default: 0 }) office_id!: number;
  @Prop({ default: 0 }) billing_id!: number;

  // アップロード可能なファイルタイプ
  private accepts = ["application/pdf", "image/*"];
  // ファイルアップロードAPIパス
  private uploadApiUrl = window.base_url + "/api/upload";
  // ファイルダウンロードAPIパス
  private previewApiUrl = window.base_url + "/api/preview";
  // 保存先
  private get imagePath(): string {
    return `admin/${this.co_code}/${this.office_id}/`;
  }

  /** 枠追加上限 */
  private LIMIT = 4;

  private get localBillingFiles(): BillingFile[] {
    return this.billing_files;
  }

  // 新しく枠を追加
  private add(): void {
    const newBf = { ...DEFAULT_BILLING_FILE };
    newBf.billing_id = this.billing_id;
    newBf.name = "関連ファイル";
    this.localBillingFiles.push(newBf);
  }

  /** 枠ごと削除 */
  private async onClickDeleteRow(index: number) {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.onDelete(index);
  }

  /** 枠、ファイル削除時 */
  private onDelete(index: number): void {
    this.localBillingFiles.splice(index, 1);
  }

  /** 追加不可能かどうか */
  private get disableAddButton() {
    return this.billing_files.length == this.LIMIT;
  }
}
