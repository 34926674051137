













































































































































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Choice } from "@/types";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import {
  ANSWER_STATUS,
  ANSWER_METHOD,
  ANSWER_ASSIGN,
  INQUIRY_PUBLISHED,
  ACCESS_CONFIRMS,
  ANSWER_COMPLETED_UNSOLVED,
  ANSWER_COMPLETED,
} from "@/consts/inquiry";
import { AdminUser } from "@/model/adminUser";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import InquiryAnswerFileUploader from "@/components/inquiry/InquiryAnswerFileUploader.vue";
import AnswerInfo from "@/components/inquiry/AnswerInfo.vue";
import InquirySimpleViewer from "@/components/inquiry/InquirySimpleViewer.vue";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import ZeroDispNumberInput from "#/components/ZeroDispNumberInput.vue";

import {
  InquiryDiv,
  InquiryGroup,
  InquiryServiceDiv,
  DispInquiry,
  DispInquiryAnswer,
  DEFAULT_DISP_INQUIRY,
  DEFAULT_DISP_INQUIRY_ANSWER,
  DEFAULT_OFFICESTAFF_CONTACT,
  OfficestaffContact,
} from "@/model/inquiry";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import OfficestaffContactInfo from "@/components/inquiry/OfficestaffContactInfo.vue";

interface SelectableChoice extends Choice {
  /** 選択肢として使用できるかフラグ */
  selectable: boolean;
}

@Component({
  components: {
    AnswerContent,
    TrumbowygEditor,
    InquiryAnswerFileUploader,
    OfficestaffContactInfo,
    AttachmentViewer,
    ZeroDispNumberInput,
    AnswerInfo,
    InquirySimpleViewer,
  },
})
export default class AnswerForm extends Mixins(UtilMixin, RulesMixin) {
  /** 回答情報 */
  @Prop({ default: () => DEFAULT_DISP_INQUIRY_ANSWER })
  inquiryAnswer!: DispInquiryAnswer;

  /** 初回問い合わせ */
  @Prop({ default: () => DEFAULT_DISP_INQUIRY })
  inquiry!: DispInquiry;

  /** 回答対象の問い合わせ+一連の問い合わせ */
  @Prop({ default: () => [] })
  inquiries!: DispInquiry[];

  /** アサインを選択できるかどうかフラグ */
  @Prop({ default: false }) assignable!: boolean;
  /** 合計回答対応時間 */
  @Prop({ default: 0 }) answerTime!: number;
  /** 管理者データ */
  @Prop({ default: () => [] }) adminUsers!: AdminUser[];
  /** 問い合わせ区分マスタデータ */
  @Prop({ default: () => [] }) inquiryDivs!: InquiryDiv[];
  /** 問い合わせグループマスタデータ */
  @Prop({ default: () => [] }) inquiryGroups!: InquiryGroup[];
  /** 問い合わせサービス種類マスタデータ */
  @Prop({ default: () => [] }) inquiryServiceDivs!: InquiryServiceDiv[];

  /** 事業所職員コンタクト履歴 */
  @Prop({ default: () => DEFAULT_OFFICESTAFF_CONTACT })
  officestaffContact!: OfficestaffContact;

  /** 保存ボタン押した時用 */
  @Prop() isSaving!: boolean;

  /** ログイン中ユーザー名 */
  @Prop({ default: "" }) userName!: string;

  private isChangedServiceDivs = false;
  private isChangedInquiryDivs = false;
  private isChangedInquiryGroups = false;

  /** 回答ステータス */
  private ANSWER_STATUS = ANSWER_STATUS;
  /** 回答手段 */
  private ANSWER_METHOD = ANSWER_METHOD;
  private ANSWER_ASSIGN = ANSWER_ASSIGN;
  private ANSWER_COMPLETED = ANSWER_COMPLETED;
  private ANSWER_COMPLETED_UNSOLVED = ANSWER_COMPLETED_UNSOLVED;

  /** 公開設定選択肢 */
  private INQUIRY_PUBLISHED = INQUIRY_PUBLISHED;

  private ACCESS_CONFIRMS = ACCESS_CONFIRMS;

  private get innerAnswerTime() {
    return this.answerTime;
  }

  private set innerAnswerTime(value: number) {
    this.onChangeAnswerTime(value);
  }

  @Emit("answertime")
  private onChangeAnswerTime(value: number) {
    return value;
  }

  /** 回答ステータスの選択肢を生成 */
  private get FILTERED_ANSWER_STATUS() {
    // アサインを選択できるのは質問が1件もない状態で回答を起票する場合のみ
    if (this.assignable) {
      return this.ANSWER_STATUS.filter(
        (data: SelectableChoice) => data.selectable
      );
    }

    return this.ANSWER_STATUS.filter(
      (data: SelectableChoice) => data.value != ANSWER_ASSIGN && data.selectable
    );
  }

  /** 管理者選択肢 */
  private get adminUserChoices() {
    const choices: Choice[] = [];
    this.adminUsers.forEach((adminUser: AdminUser) => {
      choices.push({
        text: adminUser.last_name + adminUser.first_name,
        value: adminUser.id,
      });
    });

    return choices;
  }

  private formRequired = true;

  private get isValidServiceDivs() {
    if (
      this.inquiryAnswer.answer_status < ANSWER_COMPLETED ||
      this.inquiryAnswer.answer_status > ANSWER_COMPLETED_UNSOLVED
    ) {
      return true;
    }

    return (
      (!this.isChangedServiceDivs && !this.isSaving) ||
      this.inquiryAnswer.selected_service_divs.length
    );
  }

  private get isValidInquiryDivs() {
    if (
      this.inquiryAnswer.answer_status < ANSWER_COMPLETED ||
      this.inquiryAnswer.answer_status > ANSWER_COMPLETED_UNSOLVED
    ) {
      return true;
    }
    return (
      (!this.isChangedInquiryDivs && !this.isSaving) ||
      this.inquiryAnswer.selected_inquiry_divs.length
    );
  }

  private get isValidInquiryGroups() {
    if (
      this.inquiryAnswer.answer_status < ANSWER_COMPLETED ||
      this.inquiryAnswer.answer_status > ANSWER_COMPLETED_UNSOLVED
    ) {
      return true;
    }
    return (
      (!this.isChangedInquiryGroups && !this.isSaving) ||
      this.inquiryAnswer.selected_inquiry_groups.length
    );
  }

  private serviceDivRule(): boolean | string {
    // 回答ステータスが対応完了系の場合はチェック必須
    if (
      this.inquiryAnswer.selected_service_divs.length == 0 &&
      this.inquiryAnswer.answer_status >= ANSWER_COMPLETED &&
      this.inquiryAnswer.answer_status <= ANSWER_COMPLETED_UNSOLVED
    ) {
      return "";
    }

    return true;
  }

  private inquiryDivRule(): boolean | string {
    // 回答ステータスが対応完了系の場合はチェック必須
    if (
      this.inquiryAnswer.selected_inquiry_divs.length == 0 &&
      this.inquiryAnswer.answer_status >= ANSWER_COMPLETED &&
      this.inquiryAnswer.answer_status <= ANSWER_COMPLETED_UNSOLVED
    ) {
      return "";
    }

    return true;
  }

  private inquiryGroupRule(): boolean | string {
    // 回答ステータスが対応完了系の場合はチェック必須
    if (
      this.inquiryAnswer.selected_inquiry_groups.length == 0 &&
      this.inquiryAnswer.answer_status >= ANSWER_COMPLETED &&
      this.inquiryAnswer.answer_status <= ANSWER_COMPLETED_UNSOLVED
    ) {
      return "";
    }

    return true;
  }

  private onChangeAnswerStatus() {
    this.formRequired = this.inquiryAnswer.answer_status != ANSWER_ASSIGN;
    this.reset();
    this.isChangedServiceDivs = false;
    this.isChangedInquiryDivs = false;
    this.isChangedInquiryGroups = false;
  }

  private get require(): boolean {
    return this.inquiryAnswer.answer_status != ANSWER_ASSIGN;
  }

  /** 公開設定に関するバリデーションチェック */
  private checkPublishStatus(): string | boolean {
    if (
      this.inquiryAnswer.answer_status == ANSWER_ASSIGN &&
      this.inquiryAnswer.is_published == 1
    ) {
      return "回答ステータスがアサインの場合は回答を公開することができません。";
    }

    return true;
  }

  @Emit("reset")
  private reset() {
    return;
  }

  /** 事業所職員コンタクト履歴保存 */
  @Emit("officestaffcontact")
  private saveOfficestaffContact(data: OfficestaffContact) {
    return data;
  }
}
