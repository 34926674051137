










import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import {
  CategoryGraphData,
  ExcelAdditionalData,
} from "@/components/configuration/statistics/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {},
})
export default class AmchartCategoryGraph extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** データ */
  @Prop() graphData!: CategoryGraphData;

  /** Excel出力用情報 */
  @Prop() additionalData!: ExcelAdditionalData[];

  /** エクスポートcsvファイル名 */
  @Prop({ default: "file" }) excelFileName!: string;

  /** エクスポートcsvシート名 */
  @Prop({ default: "シート1" }) sheetName!: string;

  /** チャート変数 */
  private chart!: am4charts.XYChart;

  @Watch("graphData")
  onChangeGraphData() {
    this.updateChart();
  }

  public mounted() {
    this.updateChart();
  }

  /** チャートデータ更新(初回含む) */
  private updateChart() {
    // チャート破棄
    this.disposeChart();

    // チャート初期設定
    this.chart = this.initChart();

    // チャートデータセット
    this.setChartData(this.chart);
  }

  public beforeDestroy(): void {
    this.disposeChart();
  }

  /** chart変数破棄 */
  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  public initChart(): am4charts.XYChart {
    let chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    // ロケール設定
    chart = this.setChartLocale(chart);

    chart.paddingRight = 20;

    // グラフカーソル
    chart.cursor = new am4charts.XYCursor();

    return chart;
  }

  private setChartLocale(chart: am4charts.XYChart) {
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_ja_JP;
    chart.language.locale["_date_day"] = "MMMdd日";
    chart.language.locale["_date_year"] = "yyyy年";
    return chart;
  }

  // チャートデータセット
  public setChartData(chart: am4charts.XYChart) {
    // x軸に関する設定
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = this.graphData.xAxis_var_name;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.minGridDistance = 20;
    xAxis.title.text = this.graphData.title_xAxis;

    // y軸に関する設定
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 5;
    valueAxis.title.text = this.graphData.title_yAxis;
    valueAxis.fontSize = 12;
    valueAxis.min = 0;

    // series作成
    const series = new am4charts.ColumnSeries();
    series.dataFields.valueY = this.graphData.yAxis_var_name;
    series.dataFields.categoryX = this.graphData.xAxis_var_name;
    series.stacked = true;
    series.tooltipText = "{valueY}" + this.graphData.unit; // ツールチップ

    // 棒の内側に表示するラベル
    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.locationX = 0.5;
    labelBullet.locationY = 0.5;
    labelBullet.label.text = "{valueY}" + this.graphData.unit;
    labelBullet.label.fill = am4core.color("#fff");
    chart.series.push(series);

    // 棒の枠線の色を順番に設定
    series.columns.template.adapter.add("stroke", function (_, target) {
      if (target.dataItem) {
        return chart.colors.getIndex(target.dataItem.index);
      }
      return chart.colors.next();
    });

    // 棒の色を順番に設定
    series.columns.template.adapter.add("fill", function (_, target) {
      if (target.dataItem) {
        return chart.colors.getIndex(target.dataItem.index);
      }
      return chart.colors.next();
    });

    chart.data = this.graphData.data;

    // Excel出力用追加データ格納
    chart.exporting.adapter.add("xlsxWorkbook", (wb) => {
      this.additionalData.forEach((data: ExcelAdditionalData) => {
        wb.workbook.SheetNames.push(data.sheet_name);
        wb.workbook.Sheets[data.sheet_name] = wb.xlsx.utils.aoa_to_sheet(
          data.data
        );
      });
      return wb;
    });
  }

  /** Excel形式でデータエクスポート */
  public async exportData() {
    this.chart.exporting.title = this.sheetName;
    const img = await this.chart.exporting.getExcel("xlsx");
    const link = document.createElement("a");
    link.href = img;
    link.download = `${this.excelFileName}.xlsx`;
    link.click();
  }
}
