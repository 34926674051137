






















import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { ServiceLink } from "@/model/serviceLink";
import { linkSortableHeaders } from "@/components/serviceLink/const";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class ServiceLinkList extends Mixins(AxiosMixin) {
  @Prop({ default: false }) showEdit!: boolean;
  @Prop({}) serviceLinks!: ServiceLink[];
  @Emit("clickEdit")
  private clickEdit(id: number) {
    return id;
  }
  private searchText = "";
  private headers = linkSortableHeaders;

  mounted() {
    if (this.showEdit) {
      this.headers = [
        ...linkSortableHeaders,
        {
          text: "",
          value: "edit",
          sortable: false,
          align: "start",
        },
      ];
    }
  }
}
