























































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { OfficeStaff } from "@/model/staff";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { OfficeStaffSearchObj } from "@/types";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class OfficeStaffChangeDialog extends Mixins(AxiosMixin) {
  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** 絞り込み文字列 */
  private searchKey = "";

  /** 検索条件 */
  private searchObj: OfficeStaffSearchObj = {
    co_code: "",
    kst_no: "",
    keyword: "",
    staff_keyword: "",
    co_name: "",
    office_name: "",
  };

  /** ヘッダー */
  private headers = [
    { text: "選択", value: "action", width: "100px" },
    { text: "iBowID", value: "ewell_user.user_id", width: "120px" },
    {
      text: "メールアドレス",
      value: "ewell_user.mail_address",
      width: "120px",
    },
    { text: "氏名", value: "info.ibow_nickname", width: "160px" },
    { text: "権限", value: "info.auth_name", width: "160px" },
  ];

  /** 職員一覧 */
  private staffs: OfficeStaff[] = [];

  /** テキストダイアログ */
  private isTextDialogOpen = false;

  /** 登録iBowアカウント */
  private ibowId = "";

  /** 選択した職員 */
  private staff?: OfficeStaff;

  //データ取得
  getEwellUsers() {
    this.postJsonCheck(
      window.base_url + "/api/admin/staffs/get",
      { ...this.searchObj, is_all: 1 },
      (res) => {
        this.staffs = res.data.staffs;
      }
    );
  }

  /** ダイアログを開く */
  public open(co_code: string) {
    this.searchObj.co_code = co_code;
    this.isOpen = true;
    this.getEwellUsers();
  }

  //アカウント切り替え
  private async changeOfficeStaff() {
    if (this.ibowId) {
      if (
        !(await this.$openConfirm(
          this.staff?.info.ibow_nickname + "のiBowIDを変更しますか。"
        ))
      ) {
        return;
      }
    } else {
      if (
        !(await this.$openConfirm(
          this.staff?.info.ibow_nickname + "のiBowIDを削除しますか。"
        ))
      ) {
        return;
      }
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/agreement/changeStaff",
      {
        staff_id: this.staff?.id,
        ibow_id: this.ibowId,
      },
      () => {
        this.isTextDialogOpen = false;
        this.isOpen = false;
      }
    );
  }
}
