































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import PortalMailLogSearchBox from "@/components/portal/PortalMailLogSearchBox.vue";
import PortalMailLogDetailDialog from "@/components/portal/PortalMailLogDetailDialog.vue";
import UtilMixin from "@/mixins/utilMixin";
import { PortalMailLog } from "@/model/portal";

@Component({
  components: {
    AppAuthButton,
    PortalMailLogSearchBox,
    PortalMailLogDetailDialog,
  },
})
export default class extends Mixins(AxiosMixin, UtilMixin) {
  /** 詳細表示用ダイアログ */
  @Ref("portalMailLogDetailDialog")
  private readonly portalMailLogDetailDialog!: PortalMailLogDetailDialog;

  private searchText = "";
  private items = [];
  private item: PortalMailLog = {
    id: null,
    mail_subject: "",
    mail_body: "",
    mail_address: "",
    template_id: null,
    created_at: "",
    updated_at: "",
    deleted_at: "",
  };

  get filteredItems() {
    if (!this.searchText) return this.items;

    return this.items.filter((item: PortalMailLog) => {
      if (!item.mail_subject) {
        return;
      }
      return item.mail_subject.includes(this.searchText);
    });
  }

  get getItem() {
    return this.item;
  }

  private fetchList(searchObj: unknown): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_mail_log/search",
      searchObj,
      (res) => {
        this.items = res.data.mail_logs ?? [];
      }
    );
  }

  /** 詳細ボタンクリック時 */
  private showDialog(item: PortalMailLog) {
    this.item = item;
    this.portalMailLogDetailDialog.open();
  }
}
