





































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import {
  Agreement,
  DEFAULT_AGREEMENT,
  DEFAULT_AGREEMENT_HISTORY,
} from "@/model/agreement";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class AgreementInfo extends Mixins(UtilMixin) {
  /** 表示する契約情報 */
  @Prop({ default: DEFAULT_AGREEMENT }) agreement!: Agreement;

  /** 契約履歴情報取得 */
  private get agreementHistory() {
    return this.agreement.agreement_historys
      ? this.agreement.agreement_historys[0]
      : DEFAULT_AGREEMENT_HISTORY;
  }
}
