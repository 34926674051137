















































































































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { EwellUser, DEFAULT_EWELLUSER } from "@/model/ewellUser";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class IbowAccountDetail extends Mixins(AxiosMixin) {
  /** ewellユーザー情報 */
  private ewellUser: EwellUser = { ...DEFAULT_EWELLUSER };

  /** 絞込み用条件 */
  private filterObj = {
    name: "",
    keyword: "",
  };

  private headers = [
    {
      text: "詳細",
      align: "center",
      sortable: false,
      value: "actions",
    },
    {
      text: "iBowID",
      sorttable: true,
      value: "user_id",
    },
    {
      text: "メールアドレス",
      sorttable: false,
      value: "mail_address",
    },
    {
      text: "電話番号",
      sorttable: false,
      value: "tel_no",
    },
    {
      text: "システムID",
      sorttable: false,
      value: "system_id",
    },
  ];

  created() {
    this.fetchIbowAccount(Number(this.$route.params.id));
  }

  /** eWeLLユーザー取得 */
  private fetchIbowAccount(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/ibowaccount/get",
      {
        id: id,
      },
      (res) => {
        this.ewellUser = res.data.ewell_user;
      }
    );
  }

  /** eWeLLユーザー編集 */
  private editMode(id: number) {
    this.$router.push({ path: "/ibowaccount/edit/" + id });
  }

  /** eWeLLユーザー停止 */
  private stopMode() {
    if (!confirm("アカウントを停止しますか？")) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/ibowaccount/stop",
      { ewell_user: this.ewellUser },
      () => {
        this.$router.push({ path: "/ibowaccount/list" });
      }
    );
  }

  /** eWeLLユーザー削除 */
  private deleteMode() {
    if (!confirm("アカウントを削除しますか？")) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/ibowaccount/delete",
      { ewell_user: this.ewellUser },
      () => {
        this.$router.push({ path: "/ibowaccount/list" });
      }
    );
  }
}
