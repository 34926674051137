import { Vue, Component } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import * as user from "@/store/modules/user/types";

@Component
export default class UserMixin extends Vue {
  @State(user.name) userState!: user.UserState;

  @Getter("UserName", { namespace: user.name }) UserName!: string;

  public get User(): user.User {
    return this.userState.user;
  }
}
