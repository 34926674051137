export interface ChikenLinkInfo {
  office_id: number; // iBow事業所ID
  is_working: boolean; //'アカウント稼働状態',
  officecd: number; //'事業所コード（勤怠管理）',
  is_linked_ibow: boolean; //'iBow連携有無',
  id_total_usage: number; //'ID利用数',
  inactive_id_total: number; //非アクティブID数
  staff_total_usage: number; //職員ID利用上限数
  is_position_to_adress: boolean; //住所変換使用フラグ
  is_view_unlimited_log: boolean; //記録閲覧期間無制限フラグ : 0:制限あり 1:無制限
  paid_contract_start: string; //有料契約開始日
  paid_contract_end: string; //有料契約終了日
}

export const DEFAULT_CHIKEN_LINK_INFO: ChikenLinkInfo = {
  office_id: 0,
  is_working: false,
  officecd: 0,
  is_linked_ibow: false,
  id_total_usage: 0,
  inactive_id_total: 0,
  staff_total_usage: 0,
  is_position_to_adress: false,
  is_view_unlimited_log: false,
  paid_contract_start: "",
  paid_contract_end: "",
};
