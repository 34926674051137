





































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { InquiryContact, DEFAULT_INQUIRY_CONTACT } from "@/model/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import UserMixin from "@/mixins/userMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class InquiryContactInfo extends Mixins(
  UtilMixin,
  AxiosMixin,
  UserMixin
) {
  private isEditContactHistory = false;

  /** ログイン中の管理者名 */
  private userName = "";

  /** コンタクト履歴バー表示・非表示 */
  private panel = 0;

  /** 編集可能フラグ */
  @Prop({ default: false }) editable!: boolean;

  /** 編集対象コンタクト履歴情報 */
  @Prop({ default: () => DEFAULT_INQUIRY_CONTACT })
  inquiryContact!: InquiryContact;

  /** 初期設定: 管理者名を取得 */
  created(): void {
    this.userName = `${this.User.last_name} ${this.User.first_name}`;
  }

  private get innerValue() {
    return { ...this.inquiryContact };
  }

  /** HTML用コンタクト履歴 */
  private get htmlContactMessage() {
    return this.innerValue.contact_info.replace(/\r?\n/g, "<br>");
  }

  /** コンタクト履歴-編集ボタンクリック */
  private toggleEditContactHistory() {
    const date = new Date();
    const prefix =
      "(" +
      (date.getMonth() + 1) +
      "/" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      " " +
      this.userName +
      ") =============================================\n\n";
    this.innerValue.contact_info = prefix + this.innerValue.contact_info;
    this.isEditContactHistory = !this.isEditContactHistory;
  }

  /** コンタクト履歴-保存ボタンクリック */
  @Emit()
  private save() {
    this.isEditContactHistory = !this.isEditContactHistory;
    return this.innerValue;
  }

  /** 編集キャンセル時 */
  private cancel() {
    // 編集前のテキストに戻す
    this.innerValue.contact_info = this.inquiryContact.contact_info;
    this.isEditContactHistory = !this.isEditContactHistory;
  }
}
