



















































































import { Component, Mixins, Ref, Emit } from "vue-property-decorator";
import { Term, DefaultTerm } from "#/model/master";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import { GENERATE_LIMIT } from "@/components/recommend/const";

@Component
/**
 * AIお勧め検索 AIによる一括作成ダイアログ
 */
export default class BatchGenerateDialog extends Mixins(
  RulesMixin,
  AxiosMixin
) {
  /** Ref */
  @Ref("form") private readonly form!: VForm;

  /** 入力エリア:枠追加後の自動スクロール用 */
  @Ref("inputArea") private readonly inputArea!: HTMLDivElement;

  /** 変数 */

  /** 一括作成の対象となる病名 */
  private terms: Term[] = [DefaultTerm()];

  /** ダイアログ開閉状態 */
  private isOpenDialog = false;

  /** 一括作成の最大数 */
  private GENERATE_LIMIT = GENERATE_LIMIT;

  /** getter */

  /** 枠追加ボタン有効 */
  private get IsAddButtonValid(): boolean {
    return this.terms.length < GENERATE_LIMIT;
  }

  /** 枠削除ボタン無効化 */
  private get RemoveButtonDisabled(): boolean {
    return this.terms.length == 1;
  }

  /** 関数 */

  /** ダイアログを開く処理 */
  public openDialog() {
    this.isOpenDialog = true;
    this.terms = [DefaultTerm()];
    this.form.resetValidation();
  }

  /** ダイアログを閉じる処理 */
  private closeDialog() {
    this.isOpenDialog = false;
  }

  /** 枠を追加 */
  private add() {
    this.terms.push(DefaultTerm());

    this.$nextTick(() => {
      this.inputArea?.scrollTo(0, this.inputArea.scrollHeight);
    });
  }

  /** 枠を削除 */
  private async remove(index: number) {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    this.terms.splice(index, 1);
    this.form.resetValidation();
  }

  /** 病名重複チェックボタン押下時 */
  private checkDupe() {
    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/term/check/dupe",
      { terms: this.terms },
      (res) => {
        this.$openAlert(res.data.check_message);
      }
    );
  }

  /** 作成ボタン押下時 */
  private async generate() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("作成しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/terms/generate",
      { terms: this.terms },
      async (res) => {
        await this.$openAlert(res.data.generate_message);
        this.closeDialog();
        this.done();
      }
    );
  }

  /** バリデーションルール:同名の病名不可 */
  private checkDisease(
    disease: string,
    index: number,
    message: string
  ): string | boolean {
    if (disease == "") {
      return true;
    }

    const findIndex = this.terms.findIndex(
      (term: Term) => term.disease == disease
    );

    if (findIndex !== -1 && findIndex != index) {
      return message;
    }

    return true;
  }

  /** Emit */

  /** 作成完了 */
  @Emit("done:generate")
  private done() {
    return;
  }
}
