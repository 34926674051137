






import { Component, Vue, Emit } from "vue-property-decorator";

@Component
/**
 * AIお勧め検索 コピーボタン
 */
export default class CopyButton extends Vue {
  /** Emit */

  /** クリップボードにコピー */
  @Emit("copy")
  private copy() {
    return;
  }
}
