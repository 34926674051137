import Base from "../base";

export interface MaintenanceInfo extends Base {
  id: number;
  start_datetime: string; // '掲載開始日時',
  end_datetime: string; // '掲載終了日時',
  title: string; // 'タイトル',
  content: string; //'本文';
  mainte_start_datetime: string; // 'メンテ開始日時',
  mainte_end_datetime: string; // 'メンテ終了日時',
  start_datetime_wareki: string;
  end_datetime_wareki: string;
  mainte_start_datetime_wareki: string;
  mainte_end_datetime_wareki: string;
}
export const DefaultMaintenanceInfo = (): MaintenanceInfo => ({
  id: 0,
  start_datetime: "", // '掲載開始日時',
  end_datetime: "", // '掲載終了日時',
  title: "", // 'タイトル',
  content: "", //'本文',
  mainte_start_datetime: "", // 'メンテ開始日時',
  mainte_end_datetime: "", // 'メンテ終了日時',
  start_datetime_wareki: "",
  end_datetime_wareki: "",
  mainte_start_datetime_wareki: "",
  mainte_end_datetime_wareki: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
