var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(Number(this.$route.params.id) > 0 ? "アンケート編集" : "アンケート新規作成")+" ")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("アンケート表示対象")]),_c('v-radio-group',{attrs:{"row":"","mandatory":""},on:{"change":_vm.onChangeTarget},model:{value:(_vm.researchTarget),callback:function ($$v) {_vm.researchTarget=$$v},expression:"researchTarget"}},[_c('v-radio',{attrs:{"label":"全て","value":0}}),_c('v-radio',{attrs:{"label":"選択","value":1}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.researchTarget == 1),expression:"researchTarget == 1"}],staticClass:"pb-3"},[_c('TargetOfficeSelector',{attrs:{"offices":_vm.offices},model:{value:(_vm.targetOffices),callback:function ($$v) {_vm.targetOffices=$$v},expression:"targetOffices"}})],1)]),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("掲載期間")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('TextDayChangePicker',{attrs:{"label":"開始日","rules":[_vm.required(_vm.question.start_date, '必須項目です。')]},model:{value:(_vm.question.start_date),callback:function ($$v) {_vm.$set(_vm.question, "start_date", $$v)},expression:"question.start_date"}}),_c('div',{staticClass:"ml-1 mt-3 mr-1"},[_vm._v("〜")]),_c('TextDayChangePicker',{attrs:{"label":"終了日","rules":[
              _vm.required(_vm.question.end_date, '必須項目です。'),
              _vm.dateAfter(
                _vm.question.start_date,
                _vm.question.end_date,
                true,
                '掲載終了日は開始日以降を選択してください。'
              ) ]},model:{value:(_vm.question.end_date),callback:function ($$v) {_vm.$set(_vm.question, "end_date", $$v)},expression:"question.end_date"}})],1),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("件名")]),_c('AppTextField',{staticClass:"my-2",attrs:{"label":"件名","rules":[_vm.required(_vm.question.title, '必須項目です。')],"maxlength":"200"},model:{value:(_vm.question.title),callback:function ($$v) {_vm.$set(_vm.question, "title", $$v)},expression:"question.title"}}),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("本文")]),_c('TrumbowygEditor',{attrs:{"placeholder":"本文","required":true},model:{value:(_vm.question.content),callback:function ($$v) {_vm.$set(_vm.question, "content", $$v)},expression:"question.content"}})],1)],1),_c('v-card-actions',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),(_vm.question.id)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"alert","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove.apply(null, arguments)}}},[_vm._v("削除")]):_vm._e(),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("キャンセル")])],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }