






































import { Component, Mixins } from "vue-property-decorator";
import BillingInfoDetailNavigation from "@/components/contractreception/BillingInfoDetailNavigation.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import { Billing } from "@/model/agreement/billing";

@Component({
  components: {
    BillingInfoDetailNavigation,
  },
})
export default class OfficeContractHistory extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private drawer = false;
  private currentItem = {};

  private headers = [
    {
      text: "履歴保存日時",
      value: "updated_at",
      align: "start",
      width: "",
    },
    {
      text: "変更メッセージ",
      value: "change_message",
      align: "start",
      width: "",
    },
  ];

  private office: Office = { ...DEFAULT_OFFICE };

  public created(): void {
    if (this.$route.params.id) this.getAgreement(Number(this.$route.params.id));
  }

  private getAgreement(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      {
        agreement_id: id,
        ids: [id],
        need_change_history: 1,
      },
      (res) => {
        if (this.$route.query.office_id) {
          this.office = res.data.agreements[0].offices.find(
            (o: Office) => o.id == Number(this.$route.query.office_id)
          );

          this.office.billings = this.office.billings
            .filter((history: Billing) => {
              return history.change_message && history.change_message != "";
            })
            .sort((a, b) => {
              if (this.newDate(a.updated_at) > this.newDate(b.updated_at))
                return -1;
              if (this.newDate(a.updated_at) < this.newDate(b.updated_at))
                return 1;
              return 0;
            });
        }
      }
    );
  }

  private rowClicked(event: Billing): void {
    this.drawer = !this.drawer;
    this.currentItem = event;
  }
}
