
















































































import { Component, Prop, Mixins, Emit, Ref } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import FileUpload, { FileIF } from "#/components/FileUpload.vue";
import DateInput from "#/components/DateInput.vue";
import RulesMixin from "#/mixins/rulesMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { ServiceLinkCategory } from "@/model/serviceLink";
import AppIcon from "#/components/AppIcon.vue";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import CategoryEditDialog from "@/components/serviceLink/CategoryEditDialog.vue";
import Sortable from "sortablejs";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

export interface FileItem {
  id: number;
  file_name: string;
  file_size: string;
  updated_at: string;
}

@Component({
  components: {
    AppPlaces,
    FileUpload,
    DateInput,
    AppIcon,
    TextDayChangePicker,
    TrumbowygEditor,
    CategoryEditDialog,
    AppAuthButton,
  },
})
export default class CategoryList extends Mixins(RulesMixin, AxiosMixin) {
  @Prop({}) value!: ServiceLinkCategory[];

  /** カテゴリ編集ダイアログ */
  @Ref("category_edit_dialog") categoryEditDialog!: CategoryEditDialog;

  @Emit("closeDialog")
  private closeDialog() {
    return;
  }

  @Emit("clickSaveSort")
  private clickSaveSort() {
    // 並び替えしてない場合処理を行わない
    if (this.sortNos.length == 0) return;

    // ソート番号を振り直す
    this.value.forEach((serviceLinkCategory, idx) => {
      serviceLinkCategory.sort_no = this.sortNos[idx];
    });
    return;
  }

  @Emit("cancelSort")
  private cancelSort() {
    return;
  }

  @Emit() private input(val: ServiceLinkCategory[]) {
    return val;
  }

  private sortable?: Sortable = undefined; //SortableObject
  private isSort = false; // ソート中
  private sortNos: number[] = [];

  private get Categories() {
    return this.value;
  }
  private set Categories(val: ServiceLinkCategory[]) {
    this.input(val);
  }

  mounted() {
    const elmSortList = document.querySelector(`.category-list`) as HTMLElement;

    this.sortable = new Sortable(elmSortList, {
      animation: 100,
      ghostClass: "ghost",
      onEnd: (e) => {
        if (this.sortNos.length == 0) {
          this.sortNos = this.value.map((serviceLinkCategory) => {
            return serviceLinkCategory.sort_no;
          });
        }

        if (e.oldIndex !== undefined && e.newIndex !== undefined) {
          const rowSelected = this.value.splice(e.oldIndex, 1)[0];
          this.value.splice(e.newIndex, 0, rowSelected);
        }
      },
    });
    this.sortable?.option("disabled", true);
  }

  private onClickSaveSort() {
    this.clickSaveSort();
    this.isSort = false;
  }

  private onClickCancel() {
    this.isSort = false;
    this.cancelSort();
  }

  private saveCategory(category: ServiceLinkCategory) {
    this.postJsonCheck(
      window.base_url + "/api/service_link/category/save",
      {
        service_link_category: category,
      },
      () => {
        this.categoryEditDialog.close();
        this.closeDialog();
      }
    );
  }

  private deleteCategory(category: ServiceLinkCategory) {
    this.postJsonCheck(
      window.base_url + "/api/service_link/category/delete",
      {
        service_link_category: category,
      },
      (res) => {
        console.log(res);
        if (res.data.code === 999) {
          alert(res.data.message);
          return;
        }
        this.categoryEditDialog.close();
        this.closeDialog();
      }
    );
  }

  private startChangeSort() {
    this.isSort = true;
    this.sortable?.option("disabled", false);
  }
}
