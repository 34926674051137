export interface BulletinBoardAdmin {
  id?: number; // '管理者掲示板',
  editor_admin_id: number; // '編集管理者ID',
  board_div: number; // '掲示板区分\n1:iBowに関するお知らせ\n2:eWeLLからのお知らせ',
  start_date: string; // '掲載開始期間',
  end_date: string; // '掲載終了期間',
  title: string; //'件名',
  message: string; //'掲示内容',
  status: number; // '状態\n1:通常\n2:早期終了',
  updated_at?: string; //'更新日時',
  start_date_wareki: string;
  end_date_wareki: string;
  is_reading: number;
  is_add_dialog: number;
  bulletin_board_admin_offices: BulletinBoardAdminOffice[];
}

export interface BulletinBoardAdminOffice {
  id?: number;
  bulletin_board_admin_id: number;
  agreement_id?: number;
  office_id: number;
}

export const DEFAULT_BULLETIN_BOARD_ADMIN: BulletinBoardAdmin = {
  id: 0,
  editor_admin_id: 0,
  board_div: 1,
  start_date: "",
  end_date: "",
  title: "",
  message: "",
  status: 1,
  start_date_wareki: "",
  end_date_wareki: "",
  is_reading: 0,
  is_add_dialog: 0,
  bulletin_board_admin_offices: [],
};
