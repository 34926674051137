


















































import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { Choice } from "../types";
import AppIcon from "./AppIcon.vue";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component({
  components: { AppIcon }
})
export default class AppSelect extends Mixins(RulesSaveMixin) {
  /** ラベル */
  @Prop() label!: string;
  /** 値 */
  @Prop() value!: string;
  /** 選択肢リスト */
  @Prop() items!: Choice[];
  /** 高さ */
  @Prop({ default: "48px" }) height!: string;
  /** 横幅 */
  @Prop({ default: "100%" }) width!: string;
  /** 最小横幅 */
  @Prop({ default: "225px" }) minWidth!: string;
  /** 必須フラグ */
  @Prop({ default: false }) isNeed!: boolean;
  /** 複数選択 */
  @Prop({ default: false }) multiple!: boolean;
  /** 選択肢の後ろに付ける文字列(例: ~件など) */
  @Prop({ default: "" }) suffix!: string;
  /** メニュープロップ */
  @Prop({ default: () => ({}) }) menuProps!: {};

  /** 値変更時 */
  @Emit() private input(newValue: string): string {
    return newValue;
  }

  /** ラベル */
  private get Label(): string {
    if (this.isNeed) return "*" + this.label;
    return this.label;
  }

  /** 値 */
  private get Value(): string {
    return this.value;
  }

  /** 値 */
  private set Value(newValue: string) {
    this.input(newValue);
  }
}
