export const BOARD_DIV = [
  { text: "iBowに関するお知らせ", value: 1 },
  { text: "eWeLLからのお知らせ", value: 2 },
  { text: "サポートからのお知らせ", value: 3 },
];

export const BOARD_DIV_COLOR = [
  { text: "teal", value: 1 },
  { text: "indigo darken-1", value: 2 },
  { text: "amber darken-3", value: 3 },
];

export const STATUS = [
  { text: "通常", value: 1 },
  { text: "早期終了", value: 2 },
];

export const IS_READING = [
  { text: "未読", value: 0 },
  { text: "既読", value: 1 },
];
