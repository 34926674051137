










import { Component, Mixins, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
/**
 * デモサイト一括作成 結果
 */
export default class InputDemositeResult extends Mixins(UtilMixin) {
  /** 終了ボタンクリック時 */
  @Emit("click:reset")
  private reset() {
    return;
  }
}
