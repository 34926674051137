



















































import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  DispInquiry,
  OfficestaffContact,
  DEFAULT_DISP_INQUIRY,
  DEFAULT_OFFICESTAFF_CONTACT,
  InquiryFile,
} from "@/model/inquiry";
import { Agreement, DEFAULT_AGREEMENT } from "@/model/agreement";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import { Staff, DefaultStaff } from "#/model/staff";
import InquiryForm from "@/components/inquiry/InquiryForm.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import UserMixin from "@/mixins/userMixin";
import { ANSWER_ASSIGN } from "@/consts/inquiry";
import * as appDate from "#/utility/appDate";

@Component({
  components: {
    InquiryForm,
  },
})
export default class InquiryEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  UserMixin
) {
  /** 編集対象の問い合わせ情報 */
  private inquiry: DispInquiry = { ...DEFAULT_DISP_INQUIRY };

  /** 初期化処理 */
  @Watch("$route", { deep: true })
  onChangeRoute() {
    this.fetchInquiry();
  }

  /** 回答起票無効化フラグ */
  private get answerDisabled() {
    // 回答が1つ & アサイン状態の場合は回答起票ができない
    return (
      this.inquiry.inquiry_answers.length == 1 &&
      this.inquiry.inquiry_answers[0].answer_status == ANSWER_ASSIGN
    );
  }

  /** 削除ボタン無効化フラグ */
  private get deleteButtonDisabled() {
    // 問い合わせIDが0の場合（新規作成時）は無効化
    return this.inquiry.id == 0;
  }

  /** 問い合わせに紐づく契約情報 */
  private inquiryAgreement: Agreement = { ...DEFAULT_AGREEMENT };

  /** 問い合わせに紐づく事業所情報 */
  private inquiryOffice: Office = { ...DEFAULT_OFFICE };

  /** 問い合わせに紐づく職員情報 */
  private inquiryStaff: Staff = { ...DefaultStaff() };

  /** 事業所職員コンタクト履歴情報 */
  private officestaffContact: OfficestaffContact = {
    ...DEFAULT_OFFICESTAFF_CONTACT,
  };

  /** ログイン中のユーザー名 */
  private userName = "";

  /** 追加問い合わせされた状態の問い合わせを編集しているか */
  private hasSubInquiry = false;

  created(): void {
    this.userName = `${this.User.last_name} ${this.User.first_name}`;

    this.fetchInquiry();
  }

  /** ID指定で問い合わせ情報を取得 */
  private fetchInquiry(): void {
    const parentInquiryId = Number(this.$route.params.parentId);
    const inquiryId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/form/get",
      { parent_inquiry_id: parentInquiryId, inquiry_id: inquiryId },
      (res) => {
        this.inquiry = res.data.inquiry;
        this.inquiryAgreement = res.data.inquiry.inquiry_agreement;
        this.inquiryOffice = res.data.inquiry.inquiry_office;
        this.inquiryStaff = res.data.inquiry.inquiry_staff;
        this.officestaffContact = res.data.inquiry.officestaff_contact;
        this.hasSubInquiry = res.data.has_sub_inquiry;

        // DB、バックエンドのProtoでは時分秒まで含んだ形で持っている
        // 一方、日時選択コンポーネントは時分まで前提なため、ここで形を合わせる
        this.inquiry.inquiry_datetime = this.inquiry.inquiry_datetime_short;
        this.inquiry.hope_contact_datetime =
          this.inquiry.hope_contact_datetime_short;
      }
    );
  }

  /** 保存ボタンクリック時 */
  private async clickSave() {
    if (!this.validate()) {
      return;
    }

    if (
      !(await this.$openConfirm(
        "問い合わせデータを保存します\nよろしいですか？"
      ))
    ) {
      return;
    }

    // file.path -> file.file_pathへコピー
    this.inquiry.inquiry_files.forEach((data: InquiryFile) => {
      data.file_path = data.path;
    });

    if (this.inquiry.version == 0) {
      this.inquiry.staff_name = this.inquiryStaff.info.ibow_nickname;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/save",
      {
        inquiry: this.inquiry,
        inquiry_office_id: this.inquiryOffice.id,
        inquiry_staff_id: this.inquiryStaff.id,
        inquiry_agreement_id: this.inquiryAgreement.id,
        inquiry_staff_auth_id: this.inquiryStaff.info.auth_id,
      },
      (res) => {
        const nextInquiryId = res.data.inquiry.parent_inquiry_id
          ? res.data.inquiry.parent_inquiry_id
          : res.data.inquiry.id;

        this.$router.push({
          path: `/inquiry/show/${nextInquiryId}`,
        });
      }
    );
  }

  /** 編集中キャンセルボタンクリック時 */
  private async clickCancel() {
    if (
      await this.$openConfirm(
        "編集中の場合は内容が破棄されます\nよろしいですか？"
      )
    ) {
      this.$router.go(-1);
    }
  }

  /** 削除ボタンクリック時 */
  private async clickDelete() {
    if (
      !(await this.$openConfirm(
        "問い合わせデータを削除します\nよろしいですか？"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/delete",
      { inquiry: this.inquiry },
      () => {
        const nextPath = this.inquiry.parent_inquiry_id
          ? "/inquiry/show/" + this.inquiry.parent_inquiry_id
          : "/inquiry/inprogress";

        this.$router.push({ path: nextPath });
      }
    );
  }

  /** 回答起票ボタンクリック時 */
  private async clickNewAnswer() {
    if (
      await this.$openConfirm(
        "問い合わせ内容を保存後、起票します\nよろしいですか？"
      )
    ) {
      if (!this.validate()) {
        return;
      }

      // file.path -> file.file_pathへコピー
      this.inquiry.inquiry_files.forEach((data: InquiryFile) => {
        data.file_path = data.path;
      });

      if (this.inquiry.version == 0) {
        this.inquiry.staff_name = this.inquiryStaff.info.ibow_nickname;
      }

      this.postJsonCheck(
        window.base_url + "/api/admin/inquiry/save",
        {
          inquiry: this.inquiry,
          inquiry_office_id: this.inquiryOffice.id,
          inquiry_staff_id: this.inquiryStaff.id,
          inquiry_agreement_id: this.inquiryAgreement.id,
          inquiry_staff_auth_id: this.inquiryStaff.info.auth_id,
        },
        (res) => {
          const parentInquiryId = res.data.inquiry.parent_inquiry_id
            ? res.data.inquiry.parent_inquiry_id
            : res.data.inquiry.id;
          const inquiryId = res.data.inquiry.id;
          this.$router.push({
            path: `/inquiry/${parentInquiryId}/answer/edit/${inquiryId}/0`,
          });
        }
      );
    }
  }

  /** バリデーションチェック */
  private async validate(): Promise<boolean> {
    // 非公開設定時はチェックしない
    if (this.inquiry.is_published == 0) {
      return true;
    }

    if (!this.inquiry.contact) {
      await this.$openAlert("問い合わせ内容を入力して下さい。");
      return false;
    }

    if (!this.inquiry.inquiry_datetime) {
      await this.$openAlert("問い合わせ日時を入力して下さい。");
      return false;
    }

    if (!this.inquiryStaff.id) {
      await this.$openAlert("問い合わせ者を選択して下さい。");
      return false;
    }

    if (!this.inquiryOffice.id) {
      await this.$openAlert("事業所を選択して下さい。");
      return false;
    }
    return true;
  }

  /** 職員選択時 */
  private onSelectStaff(staff: Staff) {
    this.inquiryStaff = { ...staff };

    this.postJsonCheck(
      window.base_url + "/api/admin/agreement/get",
      { agreement_id: staff.agreement_id },
      (res) => {
        this.inquiryAgreement = res.data.agreement;
        this.inquiryOffice = staff.office_id
          ? this.inquiryAgreement.offices.filter(
              (office: Office) =>
                office.id == staff.office_id ||
                office.id == staff.office.office_id_for_master
            )[0]
          : { ...DEFAULT_OFFICE };
      }
    );

    this.postJsonCheck(
      window.base_url + "/api/admin/officestaff/contact/get",
      { staff_id: staff.id },
      (res) => {
        this.officestaffContact = { ...res.data.officestaff_contact };
        this.officestaffContact.staff_id = this.inquiryStaff.id;
      }
    );
  }

  /** 事業所選択時 */
  private onSelectOffice(office: Office) {
    this.inquiryOffice = { ...office };
  }

  /** v1:事業所選択時 */
  private onSelectOfficeDialog(obj: { agreement: Agreement; office: Office }) {
    this.inquiryAgreement = obj.agreement;
    this.inquiryOffice = obj.office;
    this.inquiryStaff = { ...DefaultStaff() };
  }

  /** バージョン情報変更時 */
  private onChangeVersion() {
    // リセット
    this.inquiryAgreement = { ...DEFAULT_AGREEMENT };
    this.inquiryOffice = { ...DEFAULT_OFFICE };
    this.inquiryStaff = { ...DefaultStaff() };
  }

  /** 選択職員が所属する契約への管理者ログイン */
  private loginIbow(agreementId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/ibow/admin-login",
      {
        agreement_id: agreementId,
      },
      (res) => {
        window.open(
          window.ibow_url + "?token=" + res.data.onetime_token,
          "_blank"
        );
      }
    );
  }

  /** 選択職員で代理ログイン */
  private loginAgentIbow(staffId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/ibow/agent-login",
      {
        staff_id: staffId,
      },
      (res) => {
        window.open(
          window.ibow_url + "?token=" + res.data.onetime_token,
          "_blank"
        );
      }
    );
  }

  /** 事業所職員コンタクト履歴保存 */
  private saveOfficestaffContact(data: OfficestaffContact) {
    this.postJsonCheck(
      window.base_url + "/api/admin/officestaff/contact/save",
      { officestaff_contact: data },
      (res) => {
        this.officestaffContact = res.data.officestaff_contact;
      }
    );
  }
}
