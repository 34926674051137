





















import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { OfficeSearchObj } from "@/types";

@Component({})
export default class OfficeSelectDialog extends Mixins(UtilMixin) {
  /** 検索条件 */
  @Prop() searchObj!: OfficeSearchObj;

  /** 検索クリック時 */
  @Emit("search")
  search() {
    return;
  }
}
