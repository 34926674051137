/** ファイルインタフェース */
export interface FileIF {
  /** ID */
  id: number;
  /** 画像パス */
  path: string;
  /** コメント */
  comment: string;
  /** ファイル名 */
  name: string;
}

/** ファイルインタフェースデフォルト値 */
export const DEFAULT_FILEIF: FileIF = {
  id: 0,
  path: "",
  comment: "",
  name: "",
};
