























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { ServiceLinkMailLog } from "@/model/serviceLink";
import ServiceLinkList from "@/components/serviceLink/ServiceLinkList.vue";
import { linkMailLogHeaders } from "@/components/serviceLink/const";

@Component({
  components: {
    AppAuthButton,
    ServiceLinkList,
  },
})
export default class extends Mixins(AxiosMixin) {
  private serviceLinkMailLogs: ServiceLinkMailLog[] = [];
  private searchText = "";
  private headers = linkMailLogHeaders;

  get ServiceLinks() {
    return this.serviceLinkMailLogs;
  }

  created(): void {
    this.fetch();
  }

  private fetch(): void {
    this.postJsonCheck(
      window.base_url + "/api/service_links/mail_logs/get",
      {},
      (res) => {
        this.serviceLinkMailLogs = res.data.service_link_mail_logs;
      }
    );
  }
}
