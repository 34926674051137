

































































import { Component, Vue, Prop } from "vue-property-decorator";
import { InquiryAnswer, DEFAULT_INQUIRY_ANSWER } from "@/model/inquiry";

@Component({})
/** CSアンケート回答結果 */
export default class InquiryQuestionnaireInfo extends Vue {
  /** 回答 */
  @Prop({ default: DEFAULT_INQUIRY_ANSWER }) inquiryAnswer!: InquiryAnswer;
}
