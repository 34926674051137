






















































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Billing, DEFAULT_BILLING } from "@/model/agreement/billing";
import RulesMixin from "#/mixins/rulesMixin";

@Component({})
export default class OfficeInfoAiReportEditor extends Mixins(RulesMixin) {
  /** Prop */

  /** 事業所請求情報 */
  @Prop({ default: () => DEFAULT_BILLING }) billing!: Billing;

  /** 書類AI自動作成 正式稼働フラグ */
  @Prop({ default: () => false }) isAiReportReleased!: boolean;

  /** 関数 */

  /** バリデーションチェック:(契約)開始日 */
  private checkStartDate(date: string): boolean | string {
    // 正式稼働前（=非表示）はチェックしない
    if (!this.isAiReportReleased) {
      return true;
    }

    // 機能ONの場合に必須
    const isAiOn =
      this.billing.is_ai_enabled || this.billing.is_ai_month_report_enabled;
    if (isAiOn && !date) {
      return "必須項目です。";
    }
    return true;
  }
}
