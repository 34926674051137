import { render, staticRenderFns } from "./InquirySimpleViewer.vue?vue&type=template&id=8cbac33e&scoped=true&"
import script from "./InquirySimpleViewer.vue?vue&type=script&lang=ts&"
export * from "./InquirySimpleViewer.vue?vue&type=script&lang=ts&"
import style0 from "./InquirySimpleViewer.vue?vue&type=style&index=0&id=8cbac33e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cbac33e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VSubheader})
