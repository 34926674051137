










import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppExpansionPanels extends Vue {
  @Prop() private readonly value!: unknown;

  @Emit() private input(newValue: unknown) {
    return newValue;
  }

  private get Value(): unknown {
    return this.value;
  }

  private set Value(newValue: unknown) {
    this.input(newValue);
  }
}
