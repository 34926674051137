

































































































































import { Component, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import AppSelect from "#/components/AppSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { BulletinBoardAdmin } from "@/model/bulletinBoard";
import { BOARD_DIV, BOARD_DIV_COLOR, STATUS } from "./consts";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

interface DispBulletinBoardAdmin extends BulletinBoardAdmin {
  /** 編集者名 */
  editor_name: string;
}

@Component({
  components: {
    AppTextField,
    AppSelect,
    TextDayChangePicker,
    AppAuthButton,
  },
})
export default class EndOfPub extends Mixins(AxiosMixin, UtilMixin) {
  private search_keyword = "";
  private search_start_date = "";
  private search_end_date = "";

  private items: DispBulletinBoardAdmin[] = [];
  private BOARD_DIV = BOARD_DIV;
  private BOARD_DIV_COLOR = BOARD_DIV_COLOR;
  private STATUS = STATUS;

  public isPreviewShown = false; //プレビューダイアログ制御

  private selected = {} as DispBulletinBoardAdmin; //選択アイテム

  get filteredItems() {
    if (
      !this.search_keyword &&
      !this.search_start_date &&
      !this.search_end_date
    ) {
      return this.items;
    }

    return this.items.filter((item) => {
      return (
        (item.title.includes(this.search_keyword) ||
          item.message.includes(this.search_keyword)) &&
        (this.search_end_date
          ? item.start_date <= this.search_end_date
          : true) &&
        (this.search_start_date
          ? item.end_date >= this.search_start_date
          : true)
      );
    });
  }

  public created(): void {
    this.fetchBulletinboards();
  }

  private preview(item: DispBulletinBoardAdmin) {
    this.isPreviewShown = true;
    this.selected = item;
  }

  //モーダル内再公開ボタンクリック
  private redirectToEdit(id: number) {
    this.$router.push({ path: "/information/form/" + id });
  }

  private fetchBulletinboards(): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/bulletinboards/get",
      {
        date_div: 1,
      },
      (res) => {
        if (res.data.bulletin_board_admins) {
          this.items = res.data.bulletin_board_admins;
        }
      }
    );
  }
}
