


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppToolTip from "./AppToolTip.vue";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppToolTip,
    AppIcon
  }
})
export default class AppTitle extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) hint!: string;
}
