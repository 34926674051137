











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AnswerContent extends Vue {
  @Prop() private readonly answer_content!: string;
  /** 開閉パネルのヘッダータイトル名 */
  @Prop({ default: "回答内容" }) private readonly title!: string;

  private panel = 0;

  //HTML用回答内容
  private get htmlAnswerContent() {
    return this.answer_content.replace(/\r?\n/g, "<br>");
  }
}
