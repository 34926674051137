
























































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { TEMPLATE_STATUS, TEMPLATE_PUBLIC } from "@/consts/inquiry";
import {
  Template,
  TemplateCategory,
  TemplateHashtag,
  TemplateFile,
  Category,
  DEFAULT_TEMPLATE,
  DEFAULT_TEMPLATE_CATEGORY,
  HashtagChoice,
  HashtagCount,
} from "@/model/inquiry";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import { Choice } from "@/types";
import UserMixin from "@/mixins/userMixin";
import HashtagSelector from "@/components/inquiry/HashtagSelector.vue";
import TemplateFileUploader from "@/components/inquiry/TemplateFileUploader.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

@Component({
  components: {
    TextDayChangePicker,
    TrumbowygEditor,
    HashtagSelector,
    TemplateFileUploader,
  },
})
export default class TemplateEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  UserMixin,
  RulesMixin
) {
  /** フォームバリデーション用 */
  @Ref("form") private readonly form!: VForm;

  /** 編集対象のテンプレート情報 */
  private template: Template = {
    ...DEFAULT_TEMPLATE,
  };

  private unselected: Choice = { text: "未選択", value: 0 };

  /** 最終的に登録されるカテゴリ */
  private registeringCategoryIds: number[] = [];

  /** テンプレート状態定数 */
  private TEMPLATE_STATUS = TEMPLATE_STATUS;
  private TEMPLATE_PUBLIC = TEMPLATE_PUBLIC;

  /** カテゴリID -> カテゴリマスタデータのマップ */
  private cId2CategoryMap = new Map<number, Category>();

  /** カテゴリの深さごとの選択肢用Choiceデータを枠ごとに */
  private categoryChoices = Array<Array<Array<Choice>>>([]);

  /** カテゴリの選択状態を枠ごとに(絶対1個は存在) */
  private selectedCategoryIds = [[0, 0, 0]];

  private categorys: Category[] = [];

  /** キーワードの集計状況 */
  private keywordChoices: HashtagChoice[] = [];

  /** ハッシュタグ */
  private hashtags: string[] = [];

  /** 添付ファイル上限数 */
  private TEMPLATE_FILE_LIMIT = 5;

  /** 公開日時表示フラグ */
  private showPublishDatetime = true;

  /** 初回時の処理 */
  public async created() {
    this.fetchKeywordCounts();

    // カテゴリマスタを先に取得完了させる
    await this.fetchCategory();
    this.fetchTemplate();
  }

  /** 編集対象テンプレート情報取得 */
  private fetchTemplate() {
    const templateId = Number(this.$route.params.id);
    this.selectedCategoryIds = [[0, 0, 0]];
    // 新規作成(id=0)の場合はデフォルト値のまま
    if (templateId == 0) {
      this.setShowPublishDatetime(this.template.status);
      return;
    }
    // 編集の場合は対象テンプレート情報を取得
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/templates/get",
      {
        ids: [templateId],
      },
      (res) => {
        const template: Template = res.data.templates[0];

        // DB、バックエンドのProtoでは時分秒まで含んだ形で持っている
        // 一方、日時選択コンポーネントは時分まで前提なため、ここで形を合わせる
        template.publish_datetime = template.publish_datetime_short;

        this.setShowPublishDatetime(template.status);

        if (!template.template_files) {
          template.template_files = [];
        }

        this.template = template;
      }
    );
  }

  /** カテゴリマスタ情報取得 */
  private async fetchCategory() {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/inquiry/categorys/get",
        {},
        (res) => {
          this.searchCategorysRecursively(res.data.categorys);
          resolve(0);
        }
      );
    });
  }

  /** キーワード集計取得 */
  private fetchKeywordCounts() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/hashtag/count",
      { use_case_type: 0 },
      (res) => {
        const counts: HashtagCount[] = res.data.hashtag_counts ?? [];
        const choices: HashtagChoice[] = counts.map((data) => ({
          text: data.hashtag,
          value: data.hashtag,
          count: data.count,
        }));
        this.keywordChoices = choices;
      }
    );
  }

  // キャンセルボタンクリック
  private async cancel() {
    if (
      await this.$openConfirm(
        "編集を終了します\n編集中の場合は内容が破棄されます"
      )
    ) {
      this.$router.push({ path: "/template" });
    }
  }

  private async deleteTemplate() {
    if (!(await this.$openConfirm("本当に削除しますか?"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/delete",
      { template: this.template },
      () => {
        this.$router.push({ path: "/template" });
      }
    );
  }

  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか?"))) {
      return;
    }

    const templateCategorys = this.registeringCategoryIds.flatMap(
      (id: number) => {
        const templateCategory = { ...DEFAULT_TEMPLATE_CATEGORY };
        templateCategory.template_id = this.template.id;
        templateCategory.category_id = id;
        return id ? templateCategory : [];
      }
    );

    this.template.template_categorys = templateCategorys;

    // ハッシュタグ情報を格納
    this.template.template_hashtags = this.hashtags.map((data: string) => {
      return {
        id: 0,
        template_id: this.template.id,
        hashtag: data,
        created_at: "",
        updated_at: "",
        deleted_at: "",
      };
    });

    // file.path -> file.file_pathへコピー
    this.template.template_files.forEach((data: TemplateFile) => {
      data.file_path = data.path;
    });

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/save",
      { template: this.template },
      () => {
        this.$router.push({ path: "/template" });
      }
    );
  }

  private categoryRule() {
    if (!this.registeringCategoryIds[0]) {
      return "カテゴリを選択して下さい。";
    }
    return true;
  }

  private publishDatetimeRule() {
    if (
      this.template.status == TEMPLATE_PUBLIC &&
      (this.template.publish_datetime == "" ||
        this.template.publish_datetime == " :") // 日時を入力して空欄にした場合、日時の間の半角スペースと時刻のコロンが残る
    ) {
      // FAQ公開かつ公開日時が未入力の場合にエラー
      return "公開日時を選択して下さい。";
    }
    return true;
  }

  private get registeringCategorys(): string {
    const categorys = this.registeringCategoryIds.flatMap((id: number) => {
      return id == 0 ? [] : this.cId2CategoryMap.get(id)?.category_name;
    });

    return categorys.join(" / ");
  }

  /**
   * カテゴリマスタデータを再帰的に探索してデータ生成
   *
   * 1. categoryId -> categoryのマップ
   * 2. カテゴリの深さ別ID(選択肢用)
   */
  private searchCategorysRecursively(src: Category[], depth = 0) {
    src.forEach((category: Category) => {
      // マップに格納
      this.cId2CategoryMap.set(category.id, category);

      this.categorys.push(category);

      // 子カテゴリが存在しない(=最下位カテゴリ)の場合は探索打ち切り
      if (!category.child_categorys || category.child_categorys.length == 0) {
        return;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.searchCategorysRecursively(category.child_categorys, depth + 1);
    });
  }

  @Watch("template")
  onChangeTemplate() {
    // カテゴリが設定済みの場合、選択状態を変える
    if (
      this.template.template_categorys &&
      this.template.template_categorys.length > 0
    ) {
      this.selectedCategoryIds = [];
      this.recursiveCheck(this.template.template_categorys);
    }

    // ハッシュタグ文字配列情報を作成
    this.hashtags = this.template.template_hashtags
      ? this.template.template_hashtags.map(
          (data: TemplateHashtag) => data.hashtag
        )
      : [];
  }

  @Watch("selectedCategoryIds")
  onChangeSelectCategoryIds() {
    // categoryChoicesを再計算
    this.categoryChoices = this.selectedCategoryIds.map(
      (selected: number[], index: number) => {
        // とりあえずループ使わずに愚直に
        // 1個目はparent_id=0のやつをそのまま
        // 選択状態は今のまま
        const choices1: Choice[] = this.categorys.flatMap(
          (category: Category) => {
            return category.parent_id == 0
              ? { text: category.category_name, value: category.id }
              : [];
          }
        );

        choices1.unshift(this.unselected);

        if (selected[0] == 0) {
          this.selectedCategoryIds[index][0] = 0;
          this.selectedCategoryIds[index][1] = 0;
          this.selectedCategoryIds[index][2] = 0;
          return [choices1, [], []];
        }

        // もし1個目が0じゃないなら、2個目は1個目をparent_idとして持つcategoryを入れる
        let hasFlag = false;
        const choices2: Choice[] = this.categorys.flatMap(
          (category: Category) => {
            if (category.parent_id == selected[0]) {
              if (category.id == selected[1]) {
                hasFlag = true;
              }
              return { text: category.category_name, value: category.id };
            } else {
              return [];
            }
          }
        );

        // 絞込み後、現在の選択IDが含まれていなかったら自動的にみ選択にする
        if (!hasFlag) {
          this.selectedCategoryIds[index][1] = 0;
          this.selectedCategoryIds[index][2] = 0;
        }

        choices2.unshift(this.unselected);

        if (selected[1] == 0) {
          return [choices1, choices2, []];
        }

        // もし2個目が0じゃないなら、3個目は2個目をparent_idとして持つcategoryを入れる
        hasFlag = false;
        const choices3: Choice[] = this.categorys.flatMap(
          (category: Category) => {
            if (category.parent_id == selected[1]) {
              if (category.id == selected[2]) {
                hasFlag = true;
              }
              return { text: category.category_name, value: category.id };
            } else {
              return [];
            }
          }
        );

        if (!hasFlag) {
          this.selectedCategoryIds[index][2] = 0;
        }

        choices3.unshift(this.unselected);

        return [choices1, choices2, choices3];
      }
    );

    // 最終的に登録されるカテゴリID一覧を更新
    this.calcRegisteringCategorys();
  }

  /** 現在の選択状態から重複を排除し、最終的に登録されるカテゴリを文字列で設定する */
  private calcRegisteringCategorys() {
    const ids: number[] = [];
    this.selectedCategoryIds.forEach((selected: number[]) => {
      selected.forEach((id: number) => {
        if (!ids.includes(id)) {
          ids.push(id);
        }
      });
    });

    this.registeringCategoryIds = ids;
  }

  private recursiveCheck(src: TemplateCategory[], current: number[] = []) {
    src.forEach((category: TemplateCategory) => {
      // currentに対象のcategoryIdをpush
      current.push(category.category_id);

      // 子カテゴリが存在しない(=最下位カテゴリ)の場合は探索打ち切り
      if (!category.child_categorys || category.child_categorys.length == 0) {
        // 3に足りない分は0で埋める
        const dist = current.slice();
        for (let i = dist.length; i < 3; i++) {
          dist.push(0);
        }
        // selected情報にpush
        this.selectedCategoryIds.push(dist);
        current.pop();
        return;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.recursiveCheck(category.child_categorys, current);

      current = [];
    });
  }

  // 公開設定の切り替え
  private changeStatus(status: number) {
    this.setShowPublishDatetime(status);
    this.template.publish_datetime = "";
  }

  // 公開日時の表示フラグのセット
  private setShowPublishDatetime(status: number) {
    this.showPublishDatetime = status == TEMPLATE_PUBLIC;
  }
}
