


















































import { Component, Mixins, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { InquiryOfficeStaff } from "@/model/inquiry";
import OfficeStaffSearchBox from "@/components/admin_common/OfficeStaffSearchBox.vue";
import { OfficeStaffSearchObj } from "@/types";

@Component({
  components: {
    OfficeStaffSearchBox,
  },
})
export default class OfficeStaffSelectDialog extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 検索条件 */
  private searchObj: OfficeStaffSearchObj = {
    co_code: "",
    kst_no: "",
    keyword: "",
    staff_keyword: "",
    co_name: "",
    office_name: "",
  };

  private headers = [
    {
      text: "",
      align: "center",
      sortable: false,
      value: "actions",
      width: "60px",
    },
    {
      text: "職員名",
      align: "start",
      sortable: false,
      value: "info.ibow_nickname",
      width: "120px",
    },
    {
      text: "iBowID",
      align: "start",
      sortable: false,
      value: "ewell_user.user_id",
      width: "120px",
    },
    {
      text: "事業所名",
      align: "start",
      sortable: false,
      value: "office_name",
      width: "320px",
    },
    {
      text: "企業名",
      align: "start",
      sortable: false,
      value: "company_name",
      width: "240px",
    },
  ];

  /** 職員情報 */
  private staffs: InquiryOfficeStaff[] = [];

  /** ダイアログ操作用 */
  private dialog = false;

  private fetchOfficeStaffs() {
    this.postJsonCheck(
      window.base_url + "/api/admin/staffs/get",
      { ...this.searchObj },
      (res) => {
        this.staffs = res.data.staffs;
      }
    );
  }

  public open() {
    this.dialog = true;
  }
  public close() {
    this.reset();
    this.dialog = false;
  }

  /** データリセット */
  private reset() {
    this.searchObj = {
      co_code: "",
      kst_no: "",
      keyword: "",
      staff_keyword: "",
      co_name: "",
      office_name: "",
    };

    this.staffs = [];
  }

  /** 検索クリック時 */
  private async search() {
    if (
      this.searchObj.kst_no == "" &&
      this.searchObj.co_code == "" &&
      this.searchObj.keyword == "" &&
      this.searchObj.staff_keyword == "" &&
      this.searchObj.co_name == "" &&
      this.searchObj.office_name == ""
    ) {
      await this.$openAlert("検索条件は必ず入力してください。");
      return;
    }

    this.fetchOfficeStaffs();
  }

  @Emit()
  select(staff: InquiryOfficeStaff) {
    this.close();
    return staff;
  }
}
