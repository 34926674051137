import { ApplyOffice, DefaultApplyOffice } from "./apply";

//職員
export interface Staff {
  id: number; //ID
  agreement_id: number; //'契約ID',
  office_id: number; //'事業所ID\nNull:全事業所',
  ewell_user_id: number; //'eWeLL利用者ID',
  ewell_user: ApplyEwellUser; //eWeLL利用者
  is_exists_key: number; //'認証情報存在フラグ\n他システム連携・データ移行時',
  send_tel_no: string; //招待電話番号
  send_mail_address: string; //招待メールアドレス
  send_user_id: string; //招待iBowID
  info: StaffHistory; //職員履歴
  key: StaffKey; //職員認証情報
  office: ApplyOffice; // 事業所
  staff_certifications: StaffCertification[];
  staff_practitioner_parent_divs: StaffPractitionerParentDiv[];
  staff_area_package_trainings: StaffAreaPackageTraining[];
  staff_private: StaffPrivate;
  staff_ewell_user_info: StaffEwellUserInfo;
  ewell_user_info: EwellUserInfo[];
}

export interface ApplyEwellUser {
  id: number; // ID
  mail_address: string; // メールアドレス
  name: string; // 名前(フルネーム)
  tel_no: string; // 電話番号
  user_id: string; // iBowID
  birthday: string; // 誕生日
  icon_path: string; //アイコンのパス
  system_id: string; //システムID
  certifications: ApplyEwellUserCertification[]; // 資格区分情報
  practitioners: ApplyEwellUserPractitioner[]; // 特定行為情報
  is_nurse_practitioner: boolean; // 専門看護師(特定行為)かどうか
}

export interface ApplyEwellUserCertification {
  certification_id: number; // 資格区分ID
  is_check: number; // 有効フラグ
  certification_name: string; // 資格区分名
  sort_no: number; // 表示順
}

export interface ApplyEwellUserPractitioner {
  practitioner_id: number; // 特定行為ID
  is_check: number; // 有効フラグ
  practitioner_name: string; // 特定行為名称
  code: string; // 特定行為コード
  sort_no: number; // 表示順
}

export function DefaultApplyEwellUser(): ApplyEwellUser {
  return {
    id: 0,
    mail_address: "",
    name: "",
    tel_no: "",
    user_id: "",
    birthday: "",
    icon_path: "",
    system_id: "",
    certifications: [],
    practitioners: [],
    is_nurse_practitioner: false
  };
}

/** 職員 公開設定 */
export interface StaffPrivate {
  /** ID */
  id: number;
  /** 職員ID */
  staff_id: number;
  /** iBowID */
  user_id: number;
  /** システムID */
  system_id: number;
  /** メールアドレス */
  mail_address: number;
  /** 電話番号 */
  tel_no: number;
  /** 氏名 */
  name: number;
  /** 誕生日 */
  birthday: number;
  /** 性別 */
  sex: number;
  /** 住所 */
  address: number;
  /** 特定行為研修の修了の有無 */
  practitioner_training_completion: number;
  /** 緊急連絡先氏名 */
  emergency_contact_name: number;
  /** 緊急連絡先続柄 */
  emergency_contact_family_relationship: number;
  /** 緊急連絡先電話番号 */
  emergency_contact_tel_no: number;
  /** 緊急連絡先メールアドレス */
  emergency_contact_mail_address: number;
  /** 特定行為 */
  practitioner: number;
  /** 資格区分 */
  certification: number;
  /** 領域別パッケージ研修 */
  area_package_training: number;
}

// 職員 特定行為区分情報
export const DefaultStaffPrivate = (): StaffPrivate => ({
  id: 0,
  address: 0,
  area_package_training: 0,
  birthday: 0,
  certification: 0,
  emergency_contact_family_relationship: 0,
  emergency_contact_mail_address: 0,
  emergency_contact_name: 0,
  emergency_contact_tel_no: 0,
  mail_address: 0,
  name: 0,
  practitioner: 0,
  practitioner_training_completion: 0,
  sex: 0,
  staff_id: 0,
  system_id: 0,
  tel_no: 0,
  user_id: 0
});

export const DefaultStaffEwellUserInfo = (): StaffEwellUserInfo => ({
  id: 0,
  staff_id: 0,
  system_id: "",
  user_id: "",
  mail_address: "",
  tel_no: "",
  family_name: "",
  first_name: "",
  family_name_furigana: "",
  first_name_furigana: "",
  sex: 0,
  birthday: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  practitioner_training_completion: 0
});

export function DefaultStaff(): Staff {
  return {
    id: 0,
    agreement_id: 0,
    office_id: 0,
    ewell_user_id: 0,
    ewell_user: DefaultApplyEwellUser(),
    is_exists_key: 0,
    send_tel_no: "",
    send_mail_address: "",
    send_user_id: "",
    info: {
      id: 0,
      staff_id: 0,
      start_date: "",
      end_date: "",
      office_id: 0,
      ewell_user_id: 0,
      is_link_ewell_user_id: 0,
      ibow_nickname: "",
      ibow_nickname_furigana: "",
      office_staff_id: "",
      office_mobile_area: "",
      office_mobile_city: "",
      office_mobile_num: "",
      mobile_area: "",
      mobile_city: "",
      mobile_num: "",
      status: 1,
      auth_id: 0,
      is_check_agreement: 0,
      staff_div_id: 0,
      staff_div_name: "",
      visitable: 0,
      visitable_mental: 0,
      accompanable: 0,
      accompanable_mental: 0,
      receipt_flg: 0,
      vnplan_flg: 0,
      start_date_wareki: "",
      end_date_wareki: "",
      auth_name: "",
      is_show_opelog: 0,
      main_business: 0,
      employment_status: 0,
      fulltime_equivalent: 0,
      fulltime_value: 0,
      engagement_period: 0,
      engagement_period_value: 0
    },
    key: {
      id: 0,
      staff_id: 0,
      ibow_agreement_id: "",
      coop_id: "",
      ibow_user_id: "",
      ibow_password: "",
      ibow_url: "",
      ibow_fullname: ""
    },
    office: DefaultApplyOffice(),
    staff_area_package_trainings: [],
    staff_certifications: [],
    staff_practitioner_parent_divs: [],
    staff_private: DefaultStaffPrivate(),
    staff_ewell_user_info: DefaultStaffEwellUserInfo(),
    ewell_user_info: []
  };
}

/* 職員履歴 */
export interface StaffHistory {
  id: number; //ID
  staff_id: number; //'職員ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  office_id: number; //'事業所ID\nNull:全事業所',
  ewell_user_id: number; //'eWeLL利用者ID',
  is_link_ewell_user_id: number; //'eWeLLユーザーIDとの連携フラグ\n0:未連携\n1:連携済み',
  ibow_nickname: string; //職員名
  ibow_nickname_furigana: string; //職員名ふりがな
  office_staff_id: string; //'職員ID',
  office_mobile_area: string;
  office_mobile_city: string;
  office_mobile_num: string;
  mobile_area: string;
  mobile_city: string;
  mobile_num: string;
  status: number; //'ステータス\n1:在職\n2:休職\n3:退職',
  auth_id: number; //'権限ID',
  is_check_agreement: number; //'契約情報閲覧フラグ（本社職員のみ）',
  staff_div_id: number; //'職員区分ID',
  staff_div_name: string; //'職員区分名称',
  visitable: number; //'訪問可能フラグ',
  visitable_mental: number; //'訪問可能フラグ（精神）',
  accompanable: number; //'同行可能フラグ',
  accompanable_mental: number; //'同行可能フラグ（精神）',
  receipt_flg: number; //'レセプト対象フラグ',
  vnplan_flg: number; //'計画書の訪問予定職種',
  start_date_wareki: string; //開始日（和暦）
  end_date_wareki: string; //終了日（和暦）
  auth_name: string; //権限名称
  is_show_opelog: number; //'オペレーションログ表示権限があるか（事務所事務員のみ）',
  /** 主たる業務 1:保健師業務 2:助産師業務 3:看護師業務 4:准看護師業務 5:その他 */
  main_business: number;
  /** 雇用形態 1:正規雇用 2:非正規雇用 3:派遣 */
  employment_status: number;
  /** 常勤換算 1:フルタイム労働者 2:短時間労働者 */
  fulltime_equivalent: number;
  /** 常勤換算内容 */
  fulltime_value: number;
  /** 従事期間 1:1年未満 2:1年以上2年未満 3:2年以上 */
  engagement_period: number;
  /** 従事期間内容 1:新規 2:再就業 3:転職 4:その他 */
  engagement_period_value: number;
}

/* 職員認証情報 */
export interface StaffKey {
  id: number; //ID
  staff_id: number; //'職員ID',
  ibow_agreement_id: string; //'契約ID',
  coop_id: string; //他システム連携ID
  ibow_user_id: string; //'iBowユーザーID\n他システム連携・データ移行時',
  ibow_password: string; //'iBowパスワード\n他システム連携・データ移行時',
  ibow_url: string; //'旧iBowURL',
  ibow_fullname: string; //'iBow氏名\n他システム連携・データ移行時',
}

// 職員選択肢（訪問者・同行者）
export interface OfficeStaffChoice {
  value: number;
  text: string;
  job_type: number; // 職種
  visitable: number; // 訪問可能フラグ
  visitable_mental: number; // 訪問可能フラグ（精神）
  accompanable: number; // 同行可能フラグ
  accompanable_mental: number; // 同行可能フラグ（精神）
  nick_name: string;
}

// 職員選択肢デフォルト
export const DefaultOfficeStaffChoice = (): OfficeStaffChoice => ({
  value: 0,
  text: "未選択",
  job_type: 0,
  visitable: 0,
  visitable_mental: 0,
  accompanable: 0,
  accompanable_mental: 0,
  nick_name: ""
});

export interface StaffCertification {
  id: number; //ID
  staff_id: number;
  certification_id: number;
  certification_name: string;
  is_check: number;
  img_path: string;
  type: number;
  regist_to: string;
  regist_number: number;
  regist_date: string;
}

/** 職員 特定行為区分情報 */
export interface StaffPractitionerParentDiv {
  /** ID */
  id: number;
  /** 特定行為区分ID */
  practitioner_parent_div_id: number;
  /** 特定行為区分名称 */
  name: string;
  /** 有効フラグ */
  is_check: number;
  /**  指定機関番号 */
  specified_institute_num: number;

  /** 職員 特定行為情報 */
  staff_practitioners: StaffPractitioner[];
}

/** 職員 特定行為情報 */
export interface StaffPractitioner {
  /** ID */
  id: number;
  /** 職員 資格ID */
  staff_certification_id: number;
  /** 特定行為ID */
  practitioner_id: number;
  /** 特定行為名称 */
  practitioner_name: string;
  /** 特定行為コード */
  code: string;
  /** 有効フラグ */
  is_check: number;
}

/** 職員 領域別パッケージ研修 */
export interface StaffAreaPackageTraining {
  /** ID */
  id: number;
  /** 領域別パッケージ研修ID */
  area_package_training_id: number;
  /** 領域別パッケージ研修 */
  name: string;
  /** 有効フラグ */
  is_check: number;
}

export interface EwellUserInfo {
  value: unknown;
  text: string;
}

/** 職員 eWellユーザー情報 */
export interface StaffEwellUserInfo {
  /** ID */
  id: number;
  /** 職員ID  */
  staff_id: number;
  /** iBowシステムID  */
  system_id: string;
  /** iBowID  */
  user_id: string;
  /** メールアドレス  */
  mail_address: string;
  /** 電話番号  */
  tel_no: string;
  /** 姓  */
  family_name: string;
  /** 名  */
  first_name: string;
  /** 姓_ふりがな  */
  family_name_furigana: string;
  /** 名_ふりがな  */
  first_name_furigana: string;
  /** 性別 1:男 2:女  */
  sex: number;
  /** 誕生日  */
  birthday: string;
  /** 郵便番号  */
  post_code: string;
  /** 県  */
  pref: number;
  /** 市区町村  */
  city: string;
  /** 番地  */
  area: string;
  /** 建物  */
  building: string;
  /** 特定行為研修の修了の有無 */
  practitioner_training_completion: number;
}
