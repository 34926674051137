import { render, staticRenderFns } from "./PortalOfficialAnnouncementEdit.vue?vue&type=template&id=e770e804&scoped=true&"
import script from "./PortalOfficialAnnouncementEdit.vue?vue&type=script&lang=ts&"
export * from "./PortalOfficialAnnouncementEdit.vue?vue&type=script&lang=ts&"
import style0 from "./PortalOfficialAnnouncementEdit.vue?vue&type=style&index=0&id=e770e804&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e770e804",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VExpandTransition,VForm,VRadio,VRadioGroup,VSnackbar,VSpacer,VSubheader})
