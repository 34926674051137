
























































































































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import {
  Term,
  DefaultTerm,
  DefaultTermProblem,
  DefaultTermSolution,
} from "#/model/master";
import RulesMixin from "#/mixins/rulesMixin";
import AiRecommendMixin from "@/components/recommend/mixin";

@Component
/**
 * AIお勧め検索 問題点・解決策編集コンポーネント
 */
export default class FormProblemSolution extends Mixins(
  RulesMixin,
  AiRecommendMixin
) {
  /** Prop */

  /** 編集対象の看護計画内容 */
  @Prop({ default: () => DefaultTerm() }) value!: Term;

  /** getter */

  /** 編集対象の看護計画内容 */
  private get Term(): Term {
    return this.value;
  }

  private set Term(newValue: Term) {
    this.input(newValue);
  }

  /** 関数 */

  /** 問題点追加 */
  private addProblem() {
    this.Term.term_problems.push(DefaultTermProblem());
    this.Term.term_problems[this.Term.term_problems.length - 1].term_solutions =
      [DefaultTermSolution()];
  }

  /** 問題点削除 */
  private async removeProblem(index: number) {
    if (
      !(await this.$openConfirm(
        "問題点を削除します。よろしいですか？\n※保存ボタンを押すまで反映はされません。"
      ))
    ) {
      return;
    }

    this.Term.term_problems.splice(index, 1);
  }

  /** 解決策追加 */
  private addSolution(index: number) {
    this.Term.term_problems[index].term_solutions.push(DefaultTermSolution());
  }

  /** 解決策削除 */
  private async removeSolution(pIndex: number, sIndex: number) {
    if (
      !(await this.$openConfirm(
        "解決策を削除します。よろしいですか？\n※保存ボタンを押すまで反映はされません。"
      ))
    ) {
      return;
    }

    this.Term.term_problems[pIndex].term_solutions.splice(sIndex, 1);
  }

  /** 問題点を1つ上に移動 */
  private upProblem(index: number) {
    this.Term.term_problems = [
      ...this.swapWithPrevious(this.Term.term_problems, index),
    ];
  }

  /** 問題点を1つ下に移動 */
  private downProblem(index: number) {
    this.Term.term_problems = [
      ...this.swapWithNext(this.Term.term_problems, index),
    ];
  }

  /** 解決策を1つ上に移動 */
  private upSolution(pIndex: number, sIndex: number) {
    this.Term.term_problems[pIndex].term_solutions = [
      ...this.swapWithPrevious(
        this.Term.term_problems[pIndex].term_solutions,
        sIndex
      ),
    ];
  }

  /** 解決策を1つ下に移動 */
  private downSolution(pIndex: number, sIndex: number) {
    this.Term.term_problems[pIndex].term_solutions = [
      ...this.swapWithNext(
        this.Term.term_problems[pIndex].term_solutions,
        sIndex
      ),
    ];
  }

  /** Emit */

  /** 値変更 */
  @Emit("input")
  private input(term: Term) {
    return term;
  }
}
