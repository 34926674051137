






















































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { Question, DEFAULT_QUESTION } from "@/model/research";
import { QUESTION_STATUS, QUESTION_STATUS_COLOR } from "@/consts/research";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

interface DispQuestion extends Question {
  /** アンケートステータス 1~3 */
  status: number;
}

@Component({
  components: {
    AppAuthButton,
  },
})
export default class QuestionInfoViewer extends Mixins(AxiosMixin, UtilMixin) {
  /** 詳細表示対象のアンケート情報 */
  @Prop({ default: () => DEFAULT_QUESTION }) question!: DispQuestion;

  /** アンケート状態定数 */
  private QUESTION_STATUS = QUESTION_STATUS;

  /** アンケート状態色わけ定数 */
  private QUESTION_STATUS_COLOR = QUESTION_STATUS_COLOR;

  /** ダイアログ表示・非表示用フラグ */
  private dialog = false;

  public open() {
    this.dialog = true;
  }

  public close() {
    this.dialog = false;
  }

  @Emit()
  private edit() {
    return this.question.id;
  }

  @Emit()
  private remove() {
    return this.question;
  }

  @Emit()
  private csv() {
    return this.question.id;
  }
}
