import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

/* 共通コンポーネント */
// ボタン
import AppButton from "#/components/AppButton.vue";
Vue.component("AppButton", AppButton);
// 選択可能ボタン
import AppSelectableButton from "#/components/AppSelectableButton.vue";
Vue.component("AppSelectableButton", AppSelectableButton);
// タイトル
import AppSelect from "#/components/AppSelect.vue";
Vue.component("AppSelect", AppSelect);
// オートコンプリート
import AppAutocomplete from "#/components/AppAutocomplete.vue";
Vue.component("AppAutocomplete", AppAutocomplete);
// チェックボックス
import AppCheckbox from "#/components/AppCheckbox.vue";
Vue.component("AppCheckbox", AppCheckbox);
// スイッチ
import AppSwitch from "#/components/AppSwitch.vue";
Vue.component("AppSwitch", AppSwitch);
// テキストフィールド
import AppTextField from "#/components/AppTextField.vue";
Vue.component("AppTextField", AppTextField);
// 数値テキストフィールド(戻り値number)
import NumberInput from "#/components/NumberInput.vue";
Vue.component("NumberInput", NumberInput);
// 数値テキストフィールド(戻り値string)
import NumberInputString from "#/components/NumberInputString.vue";
Vue.component("NumberInputString", NumberInputString);
// テキストエリア
import AppTextArea from "#/components/AppTextArea.vue";
Vue.component("AppTextArea", AppTextArea);
// マンスピッカー（年月）
import AppMonthPicker from "#/components/TextMonthChangePicker.vue";
Vue.component("AppMonthPicker", AppMonthPicker);
// デイトピッカー（年月日）
import AppDayPicker from "#/components/TextDayChangePicker.vue";
Vue.component("AppDayPicker", AppDayPicker);
// タイムピッカー
import AppTimePicker from "#/components/AppTimePicker.vue";
Vue.component("AppTimePicker", AppTimePicker);
// デイトタイムピッカー（年月日時分）
import AppDatetimePicker from "#/components/TextDatetimeChangePicker.vue";
Vue.component("AppDatetimePicker", AppDatetimePicker);
// リンクボタン
import AppLinkButton from "#/components/AppLinkButton.vue";
Vue.component("AppLinkButton", AppLinkButton);
// タイトル
import AppTitle from "#/components/AppTitle.vue";
Vue.component("AppTitle", AppTitle);
// サブヘッダー
import AppSubHeader from "#/components/AppSubHeader.vue";
Vue.component("AppSubHeader", AppSubHeader);
// 電話番号エリア
import AppPhoneArea from "#/components/AppPhoneArea.vue";
Vue.component("AppPhoneArea", AppPhoneArea);
// ダイアログ
import AppDialog from "#/components/AppDialog.vue";
Vue.component("AppDialog", AppDialog);
// ファイル
import AppFileInput from "#/components/AppFileInput.vue";
Vue.component("AppFileInput", AppFileInput);
// ローディングダイアログ
import AppLoadingDialog from "#/components/AppLoadingDialog.vue";
Vue.component("AppLoadingDialog", AppLoadingDialog);
// フレックスエリア
import AppFlex from "#/components/AppFlex.vue";
Vue.component("AppFlex", AppFlex);
// フレックスエリア
import AppToolTip from "#/components/AppToolTip.vue";
Vue.component("AppToolTip", AppToolTip);

// データテーブル
import AppDataTable from "#/components/AppDataTable.vue";
Vue.component("AppDataTable", AppDataTable);
// アイコン
import AppIcon from "#/components/AppIcon.vue";
Vue.component("AppIcon", AppIcon);
// 絞り込みボタン
import AppFilterButton from "#/components/AppFilterButton.vue";
Vue.component("AppFilterButton", AppFilterButton);
// タブ
import AppTabs from "#/components/AppTabs.vue";
Vue.component("AppTabs", AppTabs);
// タブアイテム
import AppTab from "#/components/AppTab.vue";
Vue.component("AppTab", AppTab);

// エキスパンション群
import AppExpansionPanelGroups from "#/components/AppExpansionPanelGroups.vue";
Vue.component("AppExpansionPanelGroups", AppExpansionPanelGroups);
import AppExpansionPanels from "#/components/expantion/AppExpansionPanels.vue";
Vue.component("AppExpansionPanels", AppExpansionPanels);
import AppExpansionPanel from "#/components/expantion/AppExpansionPanel.vue";
Vue.component("AppExpansionPanel", AppExpansionPanel);
import AppExpansionPanelHeader from "#/components/expantion/AppExpansionPanelHeader.vue";
Vue.component("AppExpansionPanelHeader", AppExpansionPanelHeader);
import AppExpansionPanelContent from "#/components/expantion/AppExpansionPanelContent.vue";
Vue.component("AppExpansionPanelContent", AppExpansionPanelContent);

// 小ダイアログ
import AppMiniDialog from "#/components/AppMiniDialog.vue";
Vue.component("AppMiniDialog", AppMiniDialog);
import AppConfirm from "#/components/AppAlertConfirm.vue";
Vue.component("AppConfirm", AppConfirm);

// サブタイトル
import AppSubTitle from "#/components/AppSubTitle.vue";
Vue.component("AppSubTitle", AppSubTitle);

declare module "vue/types/vue" {
  interface Vue {
    $openAlert: (text: string) => Promise<boolean>;
    $openConfirm: (text: string) => Promise<boolean>;
  }
}
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
