























import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AmchartCategoryGraph from "@/components/configuration/statistics/AmchartCategoryGraph.vue";
import {
  CategoryGraphData,
  DEFAULT_CATEGORY_GRAPH_DATA,
  SearchObj,
  ExcelAdditionalData,
} from "@/components/configuration/statistics/types";

@Component({
  components: {
    AmchartCategoryGraph,
  },
})
export default class QuestionCountViewer extends Mixins(AxiosMixin, UtilMixin) {
  /** 検索条件 */
  @Prop() searchObj!: SearchObj;

  /** amchart操作用 */
  @Ref("amchart") private readonly amchart!: AmchartCategoryGraph;

  /** グラフデータ */
  private graphData: CategoryGraphData = DEFAULT_CATEGORY_GRAPH_DATA;

  /** Excel出力用追加情報 */
  private additionalData: ExcelAdditionalData[] = [];

  /** 初回時、検索ボタンクリック時に再描画させる */
  @Watch("searchObj")
  onChangeSearchObj() {
    this.fetchQuestionCountData();
  }
  /** 初回時、検索ボタンクリック時に再描画させる */
  created() {
    this.fetchQuestionCountData();
  }

  /** 与えられた検索条件を元に確定済みレセプト訪問記録の提出枚数を取得 */
  private fetchQuestionCountData() {
    this.postJsonCheck(
      window.base_url + "/api/admin/configuration/statistics/question/count",
      { ...this.searchObj },
      (res) => {
        if (!res.data.question_count_data) {
          return;
        }

        // 全体の集計結果
        const data = res.data.question_count_data.map(
          (countData: { point: unknown; result: unknown }) => {
            return { 評価: countData.point, 人数: countData.result };
          }
        );

        this.graphData = {
          title_xAxis: "評価",
          title_yAxis: "回答人数",
          xAxis_var_name: "評価",
          yAxis_var_name: "人数",
          unit: "人",
          data: data,
        };

        // 個別の回答情報
        if (!res.data.question_result) {
          return;
        }

        const detail = res.data.question_result.map(
          (result: {
            office_name: unknown;
            staff_name: unknown;
            result_point: unknown;
            free_text: unknown;
          }) => {
            return [
              result.office_name,
              result.staff_name,
              result.result_point,
              result.free_text,
            ];
          }
        );

        this.additionalData = [
          {
            sheet_name: "個別回答情報",
            data: [["事業所名", "職員名", "回答", "自由記入欄"], ...detail],
          },
        ];
      }
    );
  }

  // Excel形式でエクスポート
  private exportData() {
    this.amchart.exportData();
  }
}
