var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(Number(this.$route.params.id) > 0 ? "メンテ表示 編集" : "メンテ表示 新規作成")+" ")]),_c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form"},[_c('AppSubHeader',{staticClass:"pl-0",attrs:{"label":"メンテナンス表示対象"}}),_c('v-radio-group',{attrs:{"row":"","mandatory":""},on:{"change":_vm.onChangeTarget},model:{value:(_vm.maintenanceTarget),callback:function ($$v) {_vm.maintenanceTarget=$$v},expression:"maintenanceTarget"}},[_c('v-radio',{attrs:{"label":"全て","value":0}}),_c('v-radio',{attrs:{"label":"選択","value":1}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maintenanceTarget == 1),expression:"maintenanceTarget == 1"}],staticClass:"pb-3"},[_c('TargetOfficeSelector',{attrs:{"offices":_vm.offices},model:{value:(_vm.targetOffices),callback:function ($$v) {_vm.targetOffices=$$v},expression:"targetOffices"}})],1)]),_c('AppSubHeader',{staticClass:"pl-0",attrs:{"label":"掲載期間"}}),_c('div',[_c('AppDatetimePicker',{attrs:{"label":"開始","rules":[_vm.required(_vm.info.start_datetime, '必須項目です。')],"width":"calc(100% - 108px)"},model:{value:(_vm.info.start_datetime),callback:function ($$v) {_vm.$set(_vm.info, "start_datetime", $$v)},expression:"info.start_datetime"}}),_c('AppDatetimePicker',{attrs:{"label":"終了","rules":[
              _vm.required(_vm.info.start_datetime, '必須項目です。'),
              _vm.dateAfter(
                _vm.info.start_datetime,
                _vm.info.end_datetime,
                true,
                '掲載終了日時は開始日時以降を選択してください。'
              ) ],"width":"calc(100% - 108px)"},model:{value:(_vm.info.end_datetime),callback:function ($$v) {_vm.$set(_vm.info, "end_datetime", $$v)},expression:"info.end_datetime"}})],1),_c('AppSubHeader',{staticClass:"pl-0 mt-5",attrs:{"label":"メンテナンス期間"}}),_c('div',[_c('AppDatetimePicker',{attrs:{"label":"開始","rules":[_vm.required(_vm.info.mainte_start_datetime, '必須項目です。')],"width":"calc(100% - 108px)"},model:{value:(_vm.info.mainte_start_datetime),callback:function ($$v) {_vm.$set(_vm.info, "mainte_start_datetime", $$v)},expression:"info.mainte_start_datetime"}}),_c('AppDatetimePicker',{attrs:{"label":"終了","rules":[
              _vm.required(_vm.info.mainte_end_datetime, '必須項目です。'),
              _vm.dateAfter(
                _vm.info.mainte_start_datetime,
                _vm.info.mainte_end_datetime,
                true,
                'メンテナンス期間終了日時は開始日時以降を選択してください。'
              ),
              _vm.betweenRule() ],"width":"calc(100% - 108px)"},model:{value:(_vm.info.mainte_end_datetime),callback:function ($$v) {_vm.$set(_vm.info, "mainte_end_datetime", $$v)},expression:"info.mainte_end_datetime"}})],1),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("件名")]),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"件名","width":"100%","rules":[_vm.required(_vm.info.title, '必須項目です。')],"maxlength":"200"},model:{value:(_vm.info.title),callback:function ($$v) {_vm.$set(_vm.info, "title", $$v)},expression:"info.title"}}),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("本文")]),_c('TrumbowygEditor',{attrs:{"placeholder":"本文","required":true},model:{value:(_vm.info.content),callback:function ($$v) {_vm.$set(_vm.info, "content", $$v)},expression:"info.content"}})],1)],1),_c('v-card-actions',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),(_vm.info.id)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"alert","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickDelete.apply(null, arguments)}}},[_vm._v("削除")]):_vm._e(),_c('v-btn',{on:{"click":_vm.clickClose}},[_vm._v("キャンセル")])],1)],1),_c('AppLoadingDialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }