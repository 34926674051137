









































































































































































































































































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import * as SELECT_FILTER from "../../const/selectFilter";
import { ColumnKey, FilterClause } from "./filter";
import DateMixin from "../../mixins/dateMixin";

export const ChoiceCompareNumber = () => [
  { value: 0, text: "に等しい" },
  { value: 1, text: "に等しくない" },
  { value: 2, text: "より大きい" },
  { value: 3, text: "以上" },
  { value: 4, text: "より小さい" },
  { value: 5, text: "以下" }
];

export const ChoiceCompareDate = () => [
  { value: 0, text: "に等しい" },
  { value: 1, text: "に等しくない" },
  { value: 2, text: "より後" },
  { value: 3, text: "以後" },
  { value: 4, text: "より前" },
  { value: 5, text: "以前" }
];

export const ChoiceCompareTargetDate = () => [
  { value: 0, text: "対象年月日" },
  { value: 1, text: "今日" },
  { value: 2, text: "前月1日" },
  { value: 3, text: "前月末日" },
  { value: 8, text: "今月1日" },
  { value: 9, text: "今月末日" },
  { value: 4, text: "翌月1日" },
  { value: 5, text: "翌月末日" },
  { value: 6, text: "〜日後" },
  { value: 7, text: "〜日前" }
];

export const ChoiceCompareList = () => [
  { value: 0, text: "に等しい" },
  { value: 1, text: "に等しくない" }
];

@Component
export default class SelectFilter extends Mixins(DateMixin) {
  //------------------------------------------
  // プロパティ
  //------------------------------------------
  // フィルタ条件
  @Prop({ default: () => [] }) filterClauses!: FilterClause[];
  // フィルタ選択肢
  @Prop({ default: () => [] }) columnKeys!: ColumnKey[];

  private menu = false;

  private numsets = ChoiceCompareNumber();
  private dateNumsets = ChoiceCompareDate();
  private targetDateTypes = ChoiceCompareTargetDate();
  private listEqualSets = ChoiceCompareList();

  @Prop({ default: SELECT_FILTER.FILTER_TYPE.EDIT }) pFilterTypeEdit!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.LIST }) pFilterTypeList!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.NUMBER })
  pFilterTypeNumber!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.EDIT_LIST })
  pFilterTypeEditList!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.DATE })
  pFilterTypeDate!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.MONTH })
  pFilterTypeMonth!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.TIME })
  pFilterTypeTime!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL })
  pFilterTypeEditPartial!: number;
  @Prop({ default: SELECT_FILTER.FILTER_TYPE.EQUAL_LIST })
  pFilterTypeEqualList!: number;

  @Prop({ default: SELECT_FILTER.MATCH_TYPE_PREFIX }) pMatchTypePrefix!: number;
  @Prop({ default: SELECT_FILTER.MATCH_TYPE_PARTIAL })
  pMatchTypePartial!: number;
  @Prop({ default: SELECT_FILTER.MATCH_TYPE_PERFECT })
  pMatchTypePerfect!: number;

  /** 現在日 */
  private currentDate = "";
  /** 前月1日 */
  private beforeMonthFirst = "";
  /** 前月末日 */
  private beforeMonthLast = "";
  /** 翌月1日 */
  private nextMonthFirst = "";
  /** 翌月末日 */
  private nextMonthLast = "";
  /** 今月1日 */
  private thisMonthFirst = "";
  /** 今月末日 */
  private thisMonthLast = "";

  /** 前回の保存条件 */
  private prevTargetDate: string | null = null;

  /**
   * getMonth()の挙動
   * date.getMonth()に「+1」すると変数dateの月を+1し、日はdateの日になります。dateの日が月末日を超えている場合は、翌月に調整されます。「+2」の場合も、変数dateの月に+2します。日の調整は同様です。
   * date.getMonth()に「-1」すると変数dateの月を-1し、日はdateの日になります。dateの日が月末日を超えていた場合は、翌月に調整されます。「-2」の場合も、変数dateの月に-2します。日の調整は同様です。
   */

  created() {
    const now = this.dayjsDate();
    this.currentDate = now.format("YYYY-MM-DD");

    //今月
    const monthFirst = now.startOf("month");
    this.thisMonthFirst = monthFirst.format("YYYY-MM-DD");
    this.thisMonthLast = monthFirst.endOf("month").format("YYYY-MM-DD");

    //前月
    const beforeMonthFirst = monthFirst.subtract(1, "month").startOf("month");
    this.beforeMonthFirst = beforeMonthFirst.format("YYYY-MM-DD");
    this.beforeMonthLast = beforeMonthFirst.endOf("month").format("YYYY-MM-DD");

    //翌月
    const nextMonthFirst = monthFirst.add(1, "month").startOf("month");
    this.nextMonthFirst = nextMonthFirst.format("YYYY-MM-DD");
    this.nextMonthLast = nextMonthFirst.endOf("month").format("YYYY-MM-DD");
  }

  //------------------------------------------
  // チップの表示
  //------------------------------------------
  private chipLabel(clause: FilterClause) {
    let label = clause.word == null ? "" : clause.word;
    let note = "";
    if (
      clause.colKey.type === SELECT_FILTER.FILTER_TYPE.NUMBER ||
      clause.colKey.type === SELECT_FILTER.FILTER_TYPE.EQUAL_LIST
    ) {
      for (let i = 0; i < this.numsets.length; i++) {
        const set = this.numsets[i];
        if (set.value === clause.numIdx) {
          note = set.text;
          break;
        }
      }
    }

    if (
      clause.colKey.type === SELECT_FILTER.FILTER_TYPE.DATE ||
      clause.colKey.type === SELECT_FILTER.FILTER_TYPE.MONTH
    ) {
      if (clause.dateFilterCondition != 0) {
        const idx = this.targetDateTypes.findIndex(
          tdt => tdt.value == clause.dateFilterCondition
        );
        if (
          clause.dateFilterCondition == 6 ||
          clause.dateFilterCondition == 7
        ) {
          label =
            String(clause.matchType) + this.targetDateTypes[idx].text.substr(1);
        } else {
          label = this.targetDateTypes[idx].text;
        }
      }

      if (clause.numIdx != null) {
        const numsetIdx = this.dateNumsets.findIndex(
          numset => numset.value == clause.numIdx
        );
        label += this.dateNumsets[numsetIdx].text;
      }
    }
    return `${clause.colKey.text}：${label}${note}`;
  }

  //------------------------------------------
  // 検索対象カラム一覧選択イベント
  //------------------------------------------
  private onListClick(idx: number) {
    // 検索条件作成
    const ck = this.columnKeys[idx];
    let max = 0;
    if (this.filterClauses.length > 0) {
      const obj = this.filterClauses.reduce((a, b) => (a.id > b.id ? a : b));
      max = obj.id;
    }
    if (ck.type === SELECT_FILTER.FILTER_TYPE.LIST && ck.selectable == null) {
      ck.selectable = [];
    }
    let matchType: number;
    switch (ck.type) {
      case SELECT_FILTER.FILTER_TYPE.LIST:
      case SELECT_FILTER.FILTER_TYPE.EDIT_LIST:
      case SELECT_FILTER.FILTER_TYPE.EQUAL_LIST:
        matchType = SELECT_FILTER.MATCH_TYPE_PERFECT;
        break;
      default:
        matchType = SELECT_FILTER.MATCH_TYPE_PARTIAL;
        break;
    }

    const clause: FilterClause = {
      id: max + 1,
      colKey: ck,
      word: null,
      matchType: matchType,
      numIdx: 0,
      dateFilterCondition: 0,
      menu: false
    };
    this.filterClauses.push(clause);

    // 追加した情報を表示
    setTimeout(() => {
      clause.menu = true;
    }, 100);
  }

  //------------------------------------------
  // チップの削除ボタン押下イベント
  //------------------------------------------
  private closeChip(clause: FilterClause) {
    const idx = this.filterClauses.indexOf(clause);
    if (idx != -1) {
      this.filterClauses.splice(idx, 1);
    }
    this.onChangeCondition();
  }

  //------------------------------------------
  // 検索条件全体削除ボタン押下イベント
  //------------------------------------------
  public clearAll() {
    this.filterClauses.splice(0);
    this.onChangeCondition();
  }

  //------------------------------------------
  // 検索条件メニュー開閉イベント
  //------------------------------------------
  private onMenuToggle(opend: boolean) {
    // 開いたときは何もしない。
    if (opend) return;

    // 閉じたときに、検索条件が空の場合は、要素を削除する
    for (let idx = this.filterClauses.length - 1; 0 <= idx; idx--) {
      const clause = this.filterClauses[idx];
      if (this.hiddenMenu(clause)) {
        this.filterClauses.splice(idx, 1);
      }
    }
    this.onChangeCondition();
  }

  //------------------------------------------
  // 検索条件メニューの非表示判定
  //------------------------------------------
  private hiddenMenu(clause: FilterClause) {
    let hidden = false;
    switch (clause.colKey.type) {
      case SELECT_FILTER.FILTER_TYPE.EDIT:
      case SELECT_FILTER.FILTER_TYPE.LIST:
      case SELECT_FILTER.FILTER_TYPE.EDIT_LIST:
      case SELECT_FILTER.FILTER_TYPE.NUMBER:
        hidden = clause.word == null || clause.word == "";
        break;
      case SELECT_FILTER.FILTER_TYPE.DATE:
      case SELECT_FILTER.FILTER_TYPE.MONTH:
      case SELECT_FILTER.FILTER_TYPE.TIME:
        hidden =
          (clause.word == null || clause.word == "") &&
          clause.dateFilterCondition === 0;
        break;
    }
    return hidden;
  }

  //------------------------------------------
  // 検索カラム変更メソッド
  //------------------------------------------
  private onChangeCol(value: unknown, clause: FilterClause) {
    // 変更したカラム情報に
    const ck = this.columnKeys.find(obj => {
      return obj.key === value;
    });
    if (ck == null) {
      return;
    }

    //初期化
    clause.colKey = ck;
    clause.word = null;
    clause.matchType = SELECT_FILTER.MATCH_TYPE_PREFIX;
    clause.numIdx = 0;
    clause.dateFilterCondition = 0;

    if (clause.colKey.type == this.pFilterTypeEqualList) {
      clause.matchType = SELECT_FILTER.MATCH_TYPE_PERFECT;
    }
  }

  /**
   * 対象日付変更時処理
   */
  private changeDateFileterCondition(clause: FilterClause) {
    switch (clause.dateFilterCondition) {
      case 0: //指定日
        clause.word = this.prevTargetDate;
        break;
      case 1: //今日
        this.prevTargetDate = clause.word;
        clause.word = this.currentDate;
        break;
      case 2: //前月1日
        this.prevTargetDate = clause.word;
        clause.word = this.beforeMonthFirst;
        break;
      case 3: //前月末日
        this.prevTargetDate = clause.word;
        clause.word = this.beforeMonthLast;
        break;
      case 4: //翌月1日
        this.prevTargetDate = clause.word;
        clause.word = this.nextMonthFirst;
        break;
      case 5: //翌月末日
        this.prevTargetDate = clause.word;
        clause.word = this.nextMonthLast;
        break;
      case 6: {
        //〜日後
        this.prevTargetDate = clause.word;
        const afterDay = new Date();
        afterDay.setDate(afterDay.getDate() + clause.matchType);
        clause.word = afterDay ? afterDay.toISOString().substr(0, 10) : "";
        break;
      }
      case 7: {
        //〜日前
        this.prevTargetDate = clause.word;
        const beforeDay = new Date();
        beforeDay.setDate(beforeDay.getDate() - clause.matchType);
        clause.word = beforeDay ? beforeDay.toISOString().substr(0, 10) : "";
        break;
      }
      case 8: //今月1日
        this.prevTargetDate = clause.word;
        clause.word = this.thisMonthFirst;
        break;
      case 9: //今月末日
        this.prevTargetDate = clause.word;
        clause.word = this.thisMonthLast;
        break;
    }
  }

  public adjustClauses(clauses: FilterClause[]): FilterClause[] {
    const adjustedClauses = [];
    for (const clause of clauses) {
      if (clause.colKey.type != SELECT_FILTER.FILTER_TYPE.DATE) {
        adjustedClauses.push(clause);
        continue;
      }

      this.changeDateFileterCondition(clause);
      adjustedClauses.push(clause);
    }
    return adjustedClauses;
  }

  //全角カナを半角カナに変換
  public zenkana2Hankana(str: string): string {
    const zen = [
      "ア",
      "イ",
      "ウ",
      "エ",
      "オ",
      "カ",
      "キ",
      "ク",
      "ケ",
      "コ",
      "サ",
      "シ",
      "ス",
      "セ",
      "ソ",
      "タ",
      "チ",
      "ツ",
      "テ",
      "ト",
      "ナ",
      "ニ",
      "ヌ",
      "ネ",
      "ノ",
      "ハ",
      "ヒ",
      "フ",
      "ヘ",
      "ホ",
      "マ",
      "ミ",
      "ム",
      "メ",
      "モ",
      "ヤ",
      "ヰ",
      "ユ",
      "ヱ",
      "ヨ",
      "ラ",
      "リ",
      "ル",
      "レ",
      "ロ",
      "ワ",
      "ヲ",
      "ン",
      "ガ",
      "ギ",
      "グ",
      "ゲ",
      "ゴ",
      "ザ",
      "ジ",
      "ズ",
      "ゼ",
      "ゾ",
      "ダ",
      "ヂ",
      "ヅ",
      "デ",
      "ド",
      "バ",
      "ビ",
      "ブ",
      "ベ",
      "ボ",
      "パ",
      "ピ",
      "プ",
      "ペ",
      "ポ",
      "ァ",
      "ィ",
      "ゥ",
      "ェ",
      "ォ",
      "ャ",
      "ュ",
      "ョ",
      "ッ",
      "゛",
      "°",
      "、",
      "。",
      "「",
      "」",
      "ー",
      "・"
    ];

    const han = [
      "ｱ",
      "ｲ",
      "ｳ",
      "ｴ",
      "ｵ",
      "ｶ",
      "ｷ",
      "ｸ",
      "ｹ",
      "ｺ",
      "ｻ",
      "ｼ",
      "ｽ",
      "ｾ",
      "ｿ",
      "ﾀ",
      "ﾁ",
      "ﾂ",
      "ﾃ",
      "ﾄ",
      "ﾅ",
      "ﾆ",
      "ﾇ",
      "ﾈ",
      "ﾉ",
      "ﾊ",
      "ﾋ",
      "ﾌ",
      "ﾍ",
      "ﾎ",
      "ﾏ",
      "ﾐ",
      "ﾑ",
      "ﾒ",
      "ﾓ",
      "ﾔ",
      "ｲ",
      "ﾕ",
      "ｴ",
      "ﾖ",
      "ﾗ",
      "ﾘ",
      "ﾙ",
      "ﾚ",
      "ﾛ",
      "ﾜ",
      "ｦ",
      "ﾝ",
      "ｶﾞ",
      "ｷﾞ",
      "ｸﾞ",
      "ｹﾞ",
      "ｺﾞ",
      "ｻﾞ",
      "ｼﾞ",
      "ｽﾞ",
      "ｾﾞ",
      "ｿﾞ",
      "ﾀﾞ",
      "ﾁﾞ",
      "ﾂﾞ",
      "ﾃﾞ",
      "ﾄﾞ",
      "ﾊﾞ",
      "ﾋﾞ",
      "ﾌﾞ",
      "ﾍﾞ",
      "ﾎﾞ",
      "ﾊﾟ",
      "ﾋﾟ",
      "ﾌﾟ",
      "ﾍﾟ",
      "ﾎﾟ",
      "ｧ",
      "ｨ",
      "ｩ",
      "ｪ",
      "ｫ",
      "ｬ",
      "ｭ",
      "ｮ",
      "ｯ",
      "ﾞ",
      "ﾟ",
      "､",
      "｡",
      "｢",
      "｣",
      "ｰ",
      "･"
    ];

    let ato = "";

    for (let i = 0; i < str.length; i++) {
      let maechar = str.charAt(i);
      const zenindex = zen.indexOf(maechar);
      if (zenindex >= 0) {
        maechar = han[zenindex];
      }
      ato += maechar;
    }

    return ato;
  }

  //平仮名をカタカナに変換
  public hiraToKana(str: string): string {
    return str.replace(/[\u3041-\u3096]/g, match => {
      const chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }

  //ひらがな、かたかなを半角カナに変換
  public moji2Hankana(str: string): string {
    const zenkana = this.hiraToKana(str);
    return this.zenkana2Hankana(zenkana);
  }

  @Emit("change:condition")
  private onChangeCondition() {
    return;
  }
}
