

























































import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DataTableHeader } from "vuetify/types/index";
import { Choice } from "@/types";
import AmchartLineGraph from "@/components/configuration/statistics/AmchartLineGraph.vue";
import {
  LineGraphData,
  LineData,
  CountData,
  DEFAULT_LINE_GRAPH_DATA,
  SearchObj,
} from "@/components/configuration/statistics/types";
import { Office } from "@/model/office";

/** 保険種類による集計グラフ情報 */
interface InsuranceDivCountGraph extends LineGraphData {
  /** 保険種類による集計情報 */
  lines: InsuranceDivCount[];
}

/** 保険種類による集計情報 */
interface InsuranceDivCount extends LineData {
  /** 凡例名 */
  title: string;
  /** 事業所ID */
  office_id: number;
  /** 事業所名 */
  office_name: string;
  /** 保険区分 */
  insurance_div: number;
  /** 保険区分名 */
  insurance_name: string;
  /** 集計情報 */
  count_data: InsuranceDivCountData[];
}

/** 集計情報 */
interface InsuranceDivCountData extends CountData {
  /** 枚数 */
  count: number;
  /** 年月情報(yyyy-MM) */
  date: string;
}

@Component({
  components: {
    AmchartLineGraph,
  },
})
export default class InsuranceDivCountViewer extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 検索条件 */
  @Prop() searchObj!: SearchObj;

  /** amchart操作用 */
  @Ref("amchart") private readonly amchart!: AmchartLineGraph;

  /** グラフデータ */
  private graphData: InsuranceDivCountGraph = { ...DEFAULT_LINE_GRAPH_DATA };

  /** テーブルヘッダー情報 */
  private headers: DataTableHeader[] = [];

  /** 事業所情報 */
  private offices: Office[] = [];

  /** 保険区分情報 */
  private insuranceDivs: Choice[] = [];

  /** 選択中事業所IDs */
  private selectedOfficeIds: number[] = [];

  /** 選択中保険区分s */
  private selectedInsuranceDivs: number[] = [];

  /** データテーブル絞込み用 */
  private searchKey = "";

  /** 初回時、検索ボタンクリック時に再描画させる */
  @Watch("searchObj")
  onChangeSearchObj() {
    this.fetchInsuranceDivCount();
  }
  /** 初回時、検索ボタンクリック時に再描画させる */
  created() {
    this.fetchInsuranceDivCount();
  }

  /** 与えられた検索条件を元に確定済みレセプト訪問記録の医療・介護区分の枚数を取得 */
  private async fetchInsuranceDivCount() {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/configuration/get_insurance_div_count",
        { ...this.searchObj },
        (res) => {
          this.graphData = {
            title: "記録書II枚数",
            unit: "枚",
            lines: [],
          };

          this.graphData.lines = res.data.count_items.map(
            (data: InsuranceDivCount) => {
              // yearmonthで並び替えた後、変換
              return {
                title: `${data.office_name} ${data.insurance_name}`,
                office_id: data.office_id,
                office_name: data.office_name,
                insurance_div: data.insurance_div,
                insurance_name: data.insurance_name,
                count_data: data.count_data,
              };
            }
          );

          // 情報格納
          this.offices = res.data.offices;
          this.insuranceDivs = res.data.insurance_divs;

          this.selectedOfficeIds = [];
          this.selectedInsuranceDivs = [];

          this.createHeaders();

          resolve(0);
        }
      );
    });
  }

  // Excel形式でエクスポート
  private exportData() {
    this.amchart.exportData();
  }

  /** data-table用ヘッダー生成 */
  private createHeaders() {
    // startとendからヘッダー情報を生成
    const startDt = this.newDate(
      this.convYearmonth2DateStr(this.searchObj.start_yearmonth) + "-01"
    );
    const endDt = this.newDate(
      this.convYearmonth2DateStr(this.searchObj.end_yearmonth) + "-01"
    );

    const resHeaders: DataTableHeader[] = [];
    resHeaders.push({
      text: "事業所",
      value: "office",
      align: "center",
      width: "100px",
      divider: true,
    });

    resHeaders.push({
      text: "区分",
      value: "insuranceDiv",
      align: "center",
      width: "100px",
      divider: true,
    });

    const current = startDt;
    let index = 0;
    while (current <= endDt) {
      resHeaders.push({
        text: this.dateToStr(current, "yyyy年MM月"),
        value: `yearmonth${index}`,
        align: "center",
        width: "120px",
        divider: true,
      });
      current.setMonth(current.getMonth() + 1);
      index++;
    }
    this.headers = resHeaders;
  }

  /** v-data-table用データ作成 */
  private get items() {
    return this.filteredGraphData.lines.map((data: InsuranceDivCount) => {
      const item: { [index: string]: unknown } = {
        office: data.office_name,
        insuranceDiv: data.insurance_name,
      };
      data.count_data.forEach((count, index) => {
        item[`yearmonth${index}`] = count.count;
      });
      return item;
    });
  }

  /** Excel出力用データ作成 */
  private get excelData() {
    return this.filteredGraphData.lines.map((data: InsuranceDivCount) => {
      const item: { [index: string]: unknown } = {
        事業所名: data.office_name,
        区分: data.insurance_name,
      };
      data.count_data.forEach((count) => {
        item[this.dateToStr(this.newDate(count.date + "-01"), "yyyy年MM月")] =
          count.count;
      });
      return item;
    });
  }

  /** 事業所、区分の選択に合わせて表示するデータを変更 */
  private get filteredGraphData() {
    const data = { ...this.graphData };
    data.lines = this.graphData.lines.filter((data: InsuranceDivCount) => {
      // 折線データの事業所IDが選択中のものかどうか
      const officeMatch = this.selectedOfficeIds.includes(data.office_id);

      // 折線データの保険区分が選択中のものかどうか
      const insuranceMatch = this.selectedInsuranceDivs.includes(
        data.insurance_div
      );

      return officeMatch && insuranceMatch;
    });

    return data;
  }

  /** 事業所選択肢 */
  private get officeChoices() {
    return this.offices.map((office: Office) => {
      return { text: office.office_name, value: office.id };
    });
  }

  /** yearmonth:number -> dateStr:string */
  private convYearmonth2DateStr(yearmonth: number): string {
    const yearmonthStr = String(yearmonth);
    return `${yearmonthStr.slice(0, 4)}-${yearmonthStr.slice(4, 7)}`;
  }
}
