





















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppButton from "./AppButton.vue";

@Component({
  components: { AppButton }
})
export default class AppMiniDialog extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  @Prop() title!: string; //タイトル

  @Prop() value!: boolean; //ダイアログ表示フラグ

  @Prop() isDelete!: boolean; //削除ボタン表示フラグ

  @Prop({ default: true }) needSave!: boolean; //保存ボタン表示フラグ

  @Prop({ default: true }) needCancel!: boolean; //キャンセルボタン表示フラグ

  @Prop({ default: "保存" }) saveLabel!: string; //保存ボタンラベル

  @Prop({ default: "キャンセル" }) cancelLabel!: string; //キャンセルボタンラベル

  @Prop({ default: "削除する" }) deleteLabel!: string; //削除ボタンラベル

  @Prop({ default: "391px" }) width!: string; //横幅最大

  @Prop({ default: true }) persistent!: boolean; //画面外クリックで閉じるかどうか

  /** ダイアログstyle */
  @Prop({ default: "z-index: 205;" }) dialogStyle!: string;

  private get Value(): boolean {
    return this.value;
  }

  private set Value(newValue: boolean) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  @Emit()
  private cancel() {
    return;
  }

  @Emit()
  private save() {
    return;
  }

  @Emit()
  private remove() {
    return;
  }
}
