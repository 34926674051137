










































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppButton from "./AppButton.vue";
import AppIcon from "./AppIcon.vue";
import { Choice } from "../types";

@Component({
  components: {
    AppButton,
    AppIcon
  }
})
export default class AppSelectableButton extends Vue {
  /** 選択している項目のID */
  @Prop({ default: 0 }) value!: number;
  /** メニュー選択肢 */
  @Prop({ default: () => [] }) items!: Choice[];

  /** 変数 */

  /** メニュー表示制御 */
  private menu = false;

  /** 項目のどれかを選択しているか */
  private get hasSelectAny() {
    return this.items.some(item => item.value === this.value);
  }

  /** 選択できることを示すアイコンの色 */
  private get iconColor() {
    if (this.$attrs.disabled) {
      // ボタンが無効なら、その色に合わせる
      return this.$vuetify.theme.themes.light.greyLight;
    }
    return null;
  }

  @Emit()
  private select(value: number | string | null) {
    // 非活性になった項目をクリックした場合はメニューを閉じないようにしたかったため、
    // close-on-content-click="false"にしておき、活性項目をクリックした場合にこの関数内でメニューを閉じる
    this.menu = false;
    return value;
  }
}
