





































import { Component, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import PortalStaffSearchBox from "@/components/portal/PortalStaffSearchBox.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { PORTAL_STAFF_ROLE } from "@/consts/portal";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppTextField,
    PortalStaffSearchBox,
    AppAuthButton,
  },
})
export default class PortalStaffAgentLogin extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private searchKey = ""; //絞り込み文字列

  private items = [];

  private PORTAL_STAFF_ROLE = PORTAL_STAFF_ROLE;

  private headers = [
    { text: "選択", value: "action" },
    { text: "システムID", value: "system_id" },
    { text: "スタッフ名", value: "staff_name" },
    { text: "事業所名", value: "portal_office.name" },
    { text: "権限", value: "role" },
  ];

  //データ取得
  private fetchList(searchObj: unknown): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_staffs/get_with_office",
      searchObj,
      (res) => {
        this.items = res.data.portal_staffs ?? [];
      }
    );
  }

  //代理ログイン
  private login(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-agent-login",
      {
        portal_staff_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&token=" +
            res.data.onetime_token,
          "_blank"
        );
      }
    );
  }
}
