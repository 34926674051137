



















































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Term, DefaultTerm } from "#/model/master";
import FormLongTerm from "@/components/recommend/FormLongTerm.vue";
import FormShortTerm from "@/components/recommend/FormShortTerm.vue";
import FormProblemSolution from "@/components/recommend/FormProblemSolution.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { Choice } from "@/types";

@Component({ components: { FormLongTerm, FormShortTerm, FormProblemSolution } })
/**
 * AIお勧め検索 編集
 */
export default class TermEditForm extends Mixins(RulesMixin) {
  /** Prop */

  /** 編集対象の看護計画内容 */
  @Prop({ default: () => DefaultTerm() }) value!: Term;

  /** 変数 */

  /** 公開設定選択肢 */
  private visibilities: Choice[] = [
    {
      value: 0,
      text: "非公開",
    },
    {
      value: 1,
      text: "公開",
    },
  ];

  /** getter */

  /** 編集対象の看護計画内容 */
  private get Term(): Term {
    return this.value;
  }

  private set Term(newValue: Term) {
    this.input(newValue);
  }

  /** Emit */

  /** 値変更 */
  @Emit("input")
  private input(term: Term) {
    return term;
  }

  /** 保存 */
  @Emit("save")
  private save() {
    return;
  }

  /** 削除 */
  @Emit("delete")
  private deleteTerm() {
    return;
  }

  /** 編集キャンセル */
  @Emit("cancel")
  private cancel() {
    return;
  }

  /** 病名重複チェック */
  @Emit("check:dupe")
  private checkDupe() {
    return;
  }
}
