






























































































































































































































import { Component, Mixins } from "vue-property-decorator";
import {
  Template,
  TemplateCategory,
  TemplateHashtag,
  DEFAULT_TEMPLATE,
  HashtagCount,
  HashtagChoice,
} from "@/model/inquiry";
import { TEMPLATE_STATUS, TEMPLATE_STATUS_COLOR } from "@/consts/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import TemplateSearch from "@/components/inquiry/TemplateSearch.vue";
import HashtagSelector from "@/components/inquiry/HashtagSelector.vue";
import { TemplateSearchObj } from "@/types";

interface DispTemplate extends Template {
  /** カンマ区切りのカテゴリ文字列 */
  categorys: string;
  /** カンマ区切りのキーワード文字列 */
  keywords: string;
}

const DEFAULT_DISP_TEMPLATE: DispTemplate = {
  ...DEFAULT_TEMPLATE,
  categorys: "",
  keywords: "",
};

@Component({
  components: {
    AppAuthButton,
    AttachmentViewer,
    TemplateSearch,
    HashtagSelector,
  },
})
export default class TemplateList extends Mixins(AxiosMixin, UtilMixin) {
  /** 一覧表示対象のテンプレート情報 */
  private templates: Template[] = [];

  /** 選択しているテンプレート情報 */
  private selectedTemplates: DispTemplate[] = [];

  /** 集計したキーワード一覧 */
  private keywordChoices: HashtagChoice[] = [];

  /** 検索条件用キーワード一覧 */
  private keywordSearchChoices: HashtagChoice[] = [];

  /** 削除するキーワード候補一覧 */
  private keywordDeleteChoices: HashtagChoice[] = [];

  /** 詳細表示対象のテンプレート情報 */
  private template: DispTemplate = { ...DEFAULT_DISP_TEMPLATE };

  /** テンプレート状態定数 */
  private TEMPLATE_STATUS = TEMPLATE_STATUS;

  /** テンプレート状態色わけ定数 */
  private TEMPLATE_STATUS_COLOR = TEMPLATE_STATUS_COLOR;

  /** 検索条件 */
  private searchObj: TemplateSearchObj = {
    keyword: "",
    status: 0,
    category_id: 0,
    hashtag: "",
    only_recommend: 0,
  };

  /** 絞込み用 */
  private searchKey = "";

  /** キーワード一括編集ダイアログを開いているか */
  private isOpenKeywordDialog = false;

  /** 追加するキーワード名 */
  private addKeywords: string[] = [];

  /** 削除するキーワード名 */
  private deleteKeywords: string[] = [];

  /** ヘッダー情報 */
  private headers = [
    {
      text: "",
      sortable: false,
      value: "actions",
      width: "50px",
    },
    {
      text: "ID",
      sortable: true,
      value: "id",
      width: "70px",
    },
    {
      text: "問い合わせ内容",
      sortable: false,
      value: "inquiry_content",
      width: "250px",
    },
    {
      text: "カテゴリ",
      sortable: false,
      value: "categorys",
      width: "150px",
    },
    {
      text: "おすすめキーワード",
      sortable: false,
      value: "keywords",
      width: "200px",
    },
    {
      text: "公開日時",
      sortable: true,
      value: "publish_datetime",
      width: "150px",
    },
    {
      text: "ステータス",
      sortable: false,
      value: "status",
      width: "150px",
    },
    {
      text: "最終更新者",
      sortable: true,
      value: "admin_name",
      width: "150px",
    },
  ];

  private get DispTemplates() {
    return this.templates.map((src) => {
      const dist: DispTemplate = {
        ...src,
        categorys: this.getCategoryName(src.template_categorys, []),
        keywords: this.getKeywordName(src.template_hashtags ?? []),
      };
      return dist;
    });
  }

  created() {
    this.fetchKeywordCounts();
  }

  private clearList() {
    this.addKeywords = [];
    this.deleteKeywords = [];
    this.template = { ...DEFAULT_DISP_TEMPLATE };
    this.templates = [];
    this.selectedTemplates = [];
  }

  /** テンプレート情報取得 */
  private fetchTemplate(templateId = 0): void {
    this.clearList();
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/templates/get",
      {
        ...this.searchObj,
        statuses: this.searchObj.status ? [this.searchObj.status] : [],
      },
      (res) => {
        this.templates = res.data.templates ?? [];

        this.setTemplate(templateId);
      }
    );
  }

  /** キーワード集計取得 */
  private fetchKeywordCounts() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/hashtag/count",
      { use_case_type: 0 },
      (res) => {
        const counts: HashtagCount[] = res.data.hashtag_counts ?? [];
        const choices: HashtagChoice[] = counts.map((data) => ({
          text: data.hashtag,
          value: data.hashtag,
          count: data.count,
        }));
        this.keywordChoices = choices;
        this.keywordSearchChoices = [
          {
            text: "すべて",
            value: "",
            count: 0,
          },
          ...choices,
        ];
      }
    );
  }

  /** キーワード一括保存 */
  private bulkUpdateKeyword() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/keyword/save",
      {
        templates: this.selectedTemplates,
        add_keywords: this.addKeywords,
        delete_keywords: this.deleteKeywords,
      },
      () => {
        this.isOpenKeywordDialog = false;
        this.clearList();

        this.fetchTemplate();
        this.fetchKeywordCounts();
      }
    );
  }

  /** 詳細ボタンクリック時 */
  private show(target: DispTemplate) {
    this.template = target;
  }

  /** 詳細情報エリア内で削除ボタンクリック時 */
  private async remove() {
    if (!(await this.$openConfirm("本当に削除しますか?"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/delete",
      { template: this.template },
      () => {
        this.fetchTemplate();
      }
    );
  }

  /** 詳細情報エリア内で編集ボタンクリック時 */
  private edit(templateId: number) {
    this.$router.push({ path: `/template/edit/${templateId}` });
  }

  /** 詳細情報エリア内でおすすめFAQの設定スイッチが切り替わった時 */
  private changeIsRecommend(templateId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/save",
      { template: this.template },
      () => {
        this.fetchTemplate(templateId);
      }
    );
  }

  /** 登録済みカテゴリ名を文字列で取得 */
  private getCategoryName(categories: TemplateCategory[], result: string[]) {
    categories.forEach((category) => {
      if (!category.child_categorys || category.child_categorys.length === 0) {
        // 子カテゴリが存在しない(=最下位カテゴリ)の場合は自分自身のカテゴリ名をresultに追加
        result.push(category.category_name);
        return result;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.getCategoryName(category.child_categorys, result);
      result.push(category.category_name);
    });

    return result.reverse().join(", ");
  }

  /** 登録済みキーワード名を文字列で取得 */
  private getKeywordName(keywords: TemplateHashtag[]) {
    return keywords.map((keyword) => keyword.hashtag).join(", ");
  }

  /** キーワード一括編集ダイアログを開く */
  private openKeywordDialog() {
    // 選択した全てのテンプレートで設定しているキーワードを、削除候補に加える
    this.keywordDeleteChoices = [];
    const keywordCounts = new Map<string, number>();
    for (const template of this.selectedTemplates) {
      for (const keyword of template.template_hashtags ?? []) {
        keywordCounts.set(
          keyword.hashtag,
          (keywordCounts.get(keyword.hashtag) ?? 0) + 1
        );
      }
    }
    keywordCounts.forEach((count, keyword) => {
      if (count === this.selectedTemplates.length) {
        this.keywordDeleteChoices.push({
          text: keyword,
          value: keyword,
          count: count,
        });
      }
    });

    this.isOpenKeywordDialog = true;
  }

  /** 検索時 */
  private search() {
    if (!this.searchObj.category_id) {
      this.searchObj.category_id = 0;
    }
    if (!this.searchObj.hashtag) {
      this.searchObj.hashtag = "";
    }

    this.fetchTemplate();
  }

  /** 詳細表示対象のテンプレート情報をセットする */
  private setTemplate(templateId: number) {
    const template = this.DispTemplates.find(
      (template) => template.id === templateId
    );

    this.template = template ? template : { ...DEFAULT_DISP_TEMPLATE };
  }
}
