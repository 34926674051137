var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("回答編集")]),_c('v-card-text',[(_vm.editable)?_c('v-form',{ref:"answer_vform"},[_c('AnswerForm',{ref:"answer_form",attrs:{"userName":_vm.userName,"inquiryAnswer":_vm.inquiryAnswer,"adminUsers":_vm.adminUsers,"inquiryDivs":_vm.inquiryDivs,"inquiryGroups":_vm.inquiryGroups,"inquiryServiceDivs":_vm.inquiryServiceDivs,"assignable":_vm.assignable,"answerTime":_vm.answerTime,"isSaving":_vm.isSaving,"officestaffContact":_vm.officestaffContact,"inquiry":_vm.inquiry,"inquiries":_vm.inquiries},on:{"answertime":_vm.onChangeAnswerTime,"officestaffcontact":_vm.saveOfficestaffContact,"reset":_vm.onResetForm}})],1):_vm._e(),(
              _vm.AnswerTargetInquiry.status == _vm.INQUIRY_COMPLETED &&
              _vm.inquiryAnswer.id != 0
            )?_c('AnswerInfo',{attrs:{"inquiryAnswer":_vm.inquiryAnswer}}):_vm._e()],1),_c('v-card-actions',[(_vm.editable)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickSave()}}},[_vm._v("保存")]):_vm._e(),(
              _vm.AnswerTargetInquiry.status == _vm.INQUIRY_COMPLETED &&
              _vm.inquiryAnswer.id != 0
            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.remand()}}},[_vm._v("差戻し")]):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.clickCancel()}}},[_vm._v("キャンセル")]),_c('v-spacer'),(
              _vm.isEditMode && _vm.AnswerTargetInquiry.status != _vm.INQUIRY_COMPLETED
            )?_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.clickDelete()}}},[_vm._v("削除")]):_vm._e(),_c('v-btn',{attrs:{"color":"append","dark":""},on:{"click":function($event){return _vm.goTemplateEdit()}}},[_vm._v("テンプレート作成")])],1)],1),_vm._l((_vm.latestInquiries),function(inquiry,index){return _c('v-container',{key:inquiry.id,staticClass:"mt-4 pb-1",attrs:{"fluid":""}},[_c('v-card-title',[_vm._v("直近"+_vm._s(_vm.RECENT_INQUIRY_LIMIT)+"件の問い合わせ内容("+_vm._s(index + 1)+")")]),_c('v-card-text',[_c('InquiryInfo',{attrs:{"inquiry":inquiry,"editable":false}}),_c('InquiryContactInfo',{attrs:{"inquiryContact":inquiry.inquiry_contacts[0],"editable":false}})],1),_vm._l((inquiry.inquiry_answers),function(answer,index){return _c('v-card',{key:answer.id,staticClass:"ma-4"},[_c('v-card-title',[_vm._v(" 回答"+_vm._s(index + 1)+" ")]),_c('v-card-text',[_c('AnswerInfo',{attrs:{"inquiryAnswer":answer}})],1)],1)})],2)})],2),_c('v-col',{attrs:{"cols":"5"}},[_c('RelatedInquiryViewer',{attrs:{"divs":_vm.inquiryDivs,"groups":_vm.inquiryGroups,"serviceDivs":_vm.inquiryServiceDivs,"editable":_vm.editable},on:{"insert:answer":_vm.insertAnswerContent,"insert:template":_vm.insertTemplateContent}})],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }