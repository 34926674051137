

























import {
  Component,
  Mixins,
  Ref,
  Watch,
  Prop,
  Emit,
} from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Billing, DEFAULT_BILLING } from "@/model/agreement/billing";
import { Office, DEFAULT_OFFICE, OfficeHistory } from "@/model/office";
import OfficeInfoEditor from "@/components/contractreception/OfficeInfoEditor.vue";
import {
  DEFAULT_KINTAI_LINK_INFO,
  KintaiLinkInfo,
} from "@/model/agreement/kintaiLink";
import {
  DEFAULT_CHIKEN_LINK_INFO,
  ChikenLinkInfo,
} from "@/model/agreement/chikenLink";
import { VForm } from "@/types";
import ChangeHistory from "@/components/common/ChangeHistory.vue";

@Component({
  components: {
    OfficeInfoEditor,
    ChangeHistory,
  },
})
export default class extends Mixins(AxiosMixin, UtilMixin) {
  /** フォーム */
  @Ref("office-editor") private readonly form!: VForm;

  /** 事業所 */
  @Prop({ default: () => DEFAULT_OFFICE }) office!: Office;
  /** 請求情報 */
  @Prop({ default: () => DEFAULT_BILLING }) billing!: Billing;
  /** 企業コード */
  @Prop({ default: "" }) co_code!: string;
  /** 勤怠連携情報 */
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;
  /** 治験連携情報 */
  @Prop({ default: () => DEFAULT_CHIKEN_LINK_INFO })
  chikenLinkInfo!: ChikenLinkInfo;
  /** 営業に関わる項目をdisabledにするかどうかフラグ(契約時と編集時で分けたいため) */
  @Prop({ default: false }) disableSalesItem!: boolean;
  /** 契約状態 */
  @Prop({ default: 1 }) confflag!: number;
  /** 変更履歴フラグ */
  @Prop({ default: false }) private readonly saveHistoryLog!: boolean;

  /** データ更新 */
  @Emit() update(): void {
    return;
  }

  /** 変更履歴フラグ更新時 */
  @Emit("update:saveHistoryLog") private updateSaveHistoryLog(
    newValue: boolean
  ) {
    return newValue;
  }

  /** 事業所情報更新時の処理 */
  @Watch("office.office_info", { deep: true }) private watchOfficeInfo(
    newValue: OfficeHistory
  ) {
    if (
      this.oldOfficeInfo.id &&
      JSON.stringify(newValue) !== JSON.stringify(this.oldOfficeInfo)
    ) {
      this.is_office_info_update = true;
    }
    this.oldOfficeInfo = { ...newValue };
  }

  /** 事業所情報更新フラグ */
  private is_office_info_update = false;
  /** 事業所情報変更前の情報 */
  private oldOfficeInfo: OfficeHistory = { ...DEFAULT_OFFICE.office_info };

  /** 変更履歴フラグ */
  private get SaveHistoryLog() {
    return this.saveHistoryLog;
  }
  private set SaveHistoryLog(value: boolean) {
    this.updateSaveHistoryLog(value);
  }

  //保存ボタンクリック
  public async saveBillingInfo(afterSubmit: () => {}): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    if (!this.saveHistoryLog) {
      this.billing.change_message = "";
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/billing_info/save",
      {
        billing: this.billing,
        agreement_id: Number(this.$route.params.id),
        saving_confflag: this.confflag,
        is_office_info_update: this.is_office_info_update,
        office_info: this.office.office_info,
      },
      () => {
        afterSubmit();
      }
    );
  }
}
