













































































































































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import {
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_APPROVAL_STATUS_COLOR,
} from "@/consts/portal";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class PortalOrganizationDetail extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private organization = null;
  private adminRequestUsers = null;
  private showSaveCompleteBar = false;

  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_APPROVAL_STATUS_COLOR =
    PORTAL_OFFICE_APPROVAL_STATUS_COLOR;

  public async created() {
    await this.fetchItem(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** 契約情報取得 */
  private async fetchItem(id: number) {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_organization/get",
        { organization_id: id },
        (res) => {
          this.organization = res.data.organization;
          this.adminRequestUsers = res.data.admin_request_users;
          resolve(0);
        }
      );
    });
  }

  /** 詳細ボタンクリック時 */
  private goEdit(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/organization/edit/${id}` });
  }

  //代理ログイン
  private agentLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/requestuser-agent-login",
      {
        organization_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=2&token=" +
            res.data.onetime_token,
          "_blank"
        );
      }
    );
  }

  //管理者ログイン
  private adminLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/requestuser-admin-login",
      {
        organization_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=2&is_admin=true&token=" +
            res.data.onetime_token,
          "_blank"
        );
      }
    );
  }

  /** 承認するボタンクリック時 */
  private async approve(id: number) {
    if (!(await this.$openConfirm("承認しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_organization/update_approval_status",
      { organization_id: id },
      () => {
        this.fetchItem(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      }
    );
  }
}
