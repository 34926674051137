
















import Component from "vue-class-component";
import { Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component({
  components: {},
})
export default class QuestionSearch extends Mixins(AxiosMixin) {
  /** 検索:タイトル・本文の内容 */
  private keyword = "";

  @Emit()
  private search() {
    return {
      keyword: this.keyword,
    };
  }
}
