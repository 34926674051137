

















































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import {
  Detail,
  OfficeDiv,
} from "@/components/honobonosetting/honobonoOfficeCommon";

@Component({})
export default class HonobonoNavigation extends Mixins(UtilMixin) {
  @Prop({ default: false }) value!: boolean;

  @Prop({}) detail!: Detail;

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  private OfficeDiv = OfficeDiv;

  private get Drawer(): boolean {
    return this.value;
  }

  private set Drawer(newValue: boolean) {
    this.input(newValue);
  }
}
