
















































































































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import ContractDetailNavigation from "@/components/contractreception/ContractDetailNavigation.vue";
import { DispAgreement, DEFAULT_DISP_AGREEMENT } from "./types";
import {
  AGREEMENT_STATUS,
  AGREEMENT_STATUS_COLOR,
  OFFICE_STATUS,
  OFFICE_STATUS_COLOR,
} from "@/consts/agreement";
import { DataTableHeader } from "vuetify/types/index";
import SearchBase from "@/components/admin_common/SearchBase.vue";

@Component({
  components: {
    ContractDetailNavigation,
    SearchBase,
  },
})
export default class ContractReceptionList extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 未確定・確定ステータス */
  private AGREEMENT_STATUS = AGREEMENT_STATUS;
  private AGREEMENT_STATUS_COLOR = AGREEMENT_STATUS_COLOR;

  /** 事業所承認作業ステータス */
  private OFFICE_STATUS = OFFICE_STATUS;
  private OFFICE_STATUS_COLOR = OFFICE_STATUS_COLOR;

  /** 契約情報詳細を表示するためのナビゲーションドロワー制御用フラグ */
  private drawer = false;

  /** ナビゲーションドロワーで表示する契約情報 */
  private navItem: DispAgreement = { ...DEFAULT_DISP_AGREEMENT };

  /** 未確定契約情報 */
  private agreements: DispAgreement[] = [];

  /** v-data-table用ヘッダー情報 */
  private headers: DataTableHeader[] = [
    {
      text: "",
      value: "actions",
      width: "30px",
    },
    {
      text: "企業コード",
      value: "co_code",
      width: "50px",
    },
    {
      text: "企業名",
      value: "corp_name",
      width: "120px",
    },
    {
      text: "事業所コード",
      value: "kst_no",
      width: "100px",
    },
    {
      text: "事業所名",
      value: "office_name",
      width: "160px",
    },
    {
      text: "",
      value: "step",
      width: "260px",
    },
  ];

  /** 絞込み用条件 */
  private filterObj = {
    keyword: "",
    has_comment: false,
  };

  /** 未確定・未承認契約一覧取得 */
  private fetchContracts(searchObj: object) {
    this.postJsonCheck(
      window.base_url + "/api/admin/contracts/get",
      { ...searchObj, need_unconfirmed: 1 },
      (res) => {
        if (res.data.agreements) {
          this.agreements = res.data.agreements;
        }
      }
    );
  }

  /** 絞込み */
  private get filteredAgreements() {
    return this.agreements
      .flatMap((agreement) =>
        this.filterObj.has_comment
          ? agreement.has_comment
            ? agreement
            : []
          : agreement
      )
      .filter((agreement) => {
        return (
          this.isPartialMatch(agreement.co_name, this.filterObj.keyword) ||
          this.isPartialMatch(agreement.co_code, this.filterObj.keyword) ||
          this.isPartialMatch(agreement.office_names, this.filterObj.keyword) ||
          this.isPartialMatch(agreement.kst_nos, this.filterObj.keyword)
        );
      });
  }

  /** 詳細ボタンクリック時 */
  private goDetail(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/contractreception/detail/${id}` });
  }

  /** データテーブルの各行がクリックされた場合は契約情報をナビゲーションドロワーで表示 */
  private displayAgreementInfo(agreement: DispAgreement) {
    this.drawer = !this.drawer;
    this.navItem = agreement;
  }

  /** 文字列の部分一致判定 */
  private isPartialMatch(src: string, keyword: string) {
    return src.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
  }

  /** 型推論させるためだけの関数 */
  private typeInterface(item: unknown) {
    return item as DispAgreement;
  }
}
