






import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AppLinkButton extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "#1976d2" }) color!: string;
}
