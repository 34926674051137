import Base from "../base";
import { BillingFile } from "./billingFile";
import { BillingComment } from "./billingComment";

//請求情報
export interface Billing extends Base {
  id: number; //ID
  office_id: number; //事業所ID
  start_date: string; //開始日
  end_date: string; //終了日
  use_receipt_type: number; //利用レセプト
  use_receipt_comment: string; //利用レセプトコメント
  account_notice_date: string; //アカウント通知日
  trial_start_date: string; //試用開始日
  trial_end_date: string; //試用終了日
  stop_date: string; //停止日
  stop_reason: number; //停止理由 1:終了（更新なし） 2:中途解約 3:休止
  stop_memo: string; //停止理由コメント
  pause_end: string; //休止終了日
  agreement_plan_type: number; //契約プラン 1:2年通常(A) 2:東京CP(A) 3:2年サマーCP(A) 4:5年サマーCP(A) 5:3年CP(B) 6:3年相対(C) 7:5年相対(C)
  estimate_no: string; //見積もり番号
  ewell_sales_staff_fullname: string; //eWeLL営業担当者名
  ewell_sales_staff_mail: string; //eWeLL営業担当者メール
  customer_support_mail: string; //お客様担当者メール
  first_login_id: string; //初回ログインID
  first_login_password: string; //初回ログインパスワード
  group_office_code: string; //グループ事業所コード
  post_code: string; //郵便番号
  pref: number; //都道府県
  city: string; //市区町村
  area: string; //番地
  building: string; //建物
  billing_name: string; //請求先名
  is_send_receipt_system: number; //請求管理システム送信フラグ
  bank_selection: number; //銀行選択 1:ゆうちょ銀行 2:それ以外の金融機関
  financial_code: string; //金融機関コード
  branch_code: string; //支店コード
  bank_name: string; //銀行名
  bank_type: number; //銀行種類 1:銀行 2:信用金庫 3:信用組合 4:農業協同組合
  branch_name: string; //支店名
  branch_type: number; //支店種類 1:本店 2:支店 3:出張所 4:御中
  deposit_type: number; //預金種別 1:普通 2:当座
  account_number: string; //口座番号
  account_furigana: string; //口座名義人フリガナ
  account_name: string; //口座名義人
  japan_post_bank_code: string; //通帳記号（ゆうちょ銀行）
  japan_post_bank_number: string; //通帳番号（ゆうちょ銀行）
  aplus_code: string; //アプラスコード
  is_attendance_use: number; //iBow勤怠使用フラグ 0:未使用 1:使用
  ibow_receipt_div: number; //iBowレセプト使用フラグ 0:未使用 1:使用
  remarks: string; //備考
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
  start_date_wareki: string; //開始日（和暦）
  end_date_wareki: string; //終了日（和暦）
  account_notice_date_wareki: string; //アカウント通知日（和暦）
  trial_start_date_wareki: string; //試用開始日（和暦）
  trial_end_date_wareki: string; //試用終了日（和暦）
  stop_date_wareki: string; //停止日（和暦）
  pause_end_wareki: string; //休止終了日（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  billing_files: BillingFile[]; //請求-添付ファイル
  confflag: number; //契約状態 -1:受付 0:確認中 1:リリース前 2:リリース中
  billing_comments: BillingComment[]; // 請求-コメント
  receipt_agreement_start_date: string; //iBowレセプト契約開始日
  receipt_agreement_end_date: string; //iBowレセプト契約終了日
  receipt_start_date: string; //iBowレセプト開始日
  receipt_end_date: string; //iBowレセプト終了日
  receipt_is_send_receipt_system: number; //iBowレセプト請求管理システム送信フラグ
  receipt_notice_mailaddress: string; //iBowレセプト通知メール
  plan_storage: number; //契約ストレージ容量（GB）
  is_bi_enabled: number; //BI機能 0:無効 1:有効
}

export const setBilling = (): Billing => ({
  id: 0, //ID
  office_id: 0, //事業所ID
  start_date: "", //開始日
  end_date: "", //終了日
  use_receipt_type: 0, //利用レセプト
  use_receipt_comment: "", //利用レセプトコメント
  account_notice_date: "", //アカウント通知日
  trial_start_date: "", //試用開始日
  trial_end_date: "", //試用終了日
  stop_date: "", //停止日
  stop_reason: 0, //停止理由 1:終了（更新なし） 2:中途解約 3:休止
  stop_memo: "", //停止理由コメント
  pause_end: "", //休止終了日
  agreement_plan_type: 0, //契約プラン 1:2年通常(A) 2:東京CP(A) 3:2年サマーCP(A) 4:5年サマーCP(A) 5:3年CP(B) 6:3年相対(C) 7:5年相対(C)
  estimate_no: "", //見積もり番号
  ewell_sales_staff_fullname: "", //eWeLL営業担当者名
  ewell_sales_staff_mail: "", //eWeLL営業担当者メール
  customer_support_mail: "", //お客様担当者メール
  first_login_id: "", //初回ログインID
  first_login_password: "", //初回ログインパスワード
  group_office_code: "", //グループ事業所コード
  post_code: "", //郵便番号
  pref: 0, //都道府県
  city: "", //市区町村
  area: "", //番地
  building: "", //建物
  billing_name: "", //請求先名
  is_send_receipt_system: 0, //請求管理システム送信フラグ
  bank_selection: 0, //銀行選択 1:ゆうちょ銀行 2:それ以外の金融機関
  financial_code: "", //金融機関コード
  branch_code: "", //支店コード
  bank_name: "", //銀行名
  bank_type: 0, //銀行種類 1:銀行 2:信用金庫 3:信用組合 4:農業協同組合
  branch_name: "", //支店名
  branch_type: 0, //支店種類 1:本店 2:支店 3:出張所 4:御中
  deposit_type: 0, //預金種別 1:普通 2:当座
  account_number: "", //口座番号
  account_furigana: "", //口座名義人フリガナ
  account_name: "", //口座名義人
  japan_post_bank_code: "", //通帳記号（ゆうちょ銀行）
  japan_post_bank_number: "", //通帳番号（ゆうちょ銀行）
  aplus_code: "", //アプラスコード
  is_attendance_use: 0, //iBow勤怠使用フラグ 0:未使用 1:使用
  ibow_receipt_div: 0, //iBowレセプト使用フラグ 0:未使用 1:使用
  remarks: "", //備考
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "", //削除日時
  start_date_wareki: "", //開始日（和暦）
  end_date_wareki: "", //終了日（和暦）
  account_notice_date_wareki: "", //アカウント通知日（和暦）
  trial_start_date_wareki: "", //試用開始日（和暦）
  trial_end_date_wareki: "", //試用終了日（和暦）
  stop_date_wareki: "", //停止日（和暦）
  pause_end_wareki: "", //休止終了日（和暦）
  updated_at_wareki: "", //更新日時（和暦）
  billing_files: [], //請求-添付ファイル
  confflag: 0, //契約状態 -1:受付 0:確認中 1:リリース前 2:リリース中
  billing_comments: [], // 請求-コメント
  receipt_agreement_start_date: "", //iBowレセプト契約開始日
  receipt_agreement_end_date: "", //iBowレセプト契約終了日
  receipt_start_date: "", //iBowレセプト開始日
  receipt_end_date: "", //iBowレセプト終了日
  receipt_is_send_receipt_system: 0, //iBowレセプト請求管理システム送信フラグ
  receipt_notice_mailaddress: "", //iBowレセプト通知メール
  plan_storage: 0, //契約ストレージ容量（GB）
  is_bi_enabled: 0 //BI機能
});

export const DefaultBilling = setBilling;
