















import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Billing } from "@/model/agreement/billing";
import { USE_RECEIPT_PRE_CHECK } from "@/consts/const";

@Component
export default class ReceiptPreCheckInfoViewer extends Mixins(UtilMixin) {
  /** Prop */

  /** 事業所請求情報 */
  @Prop({ default: {} as Billing }) billing!: Billing;

  /** 変数 */

  /** レセプト事前チェック機能使用の有無 */
  private USE_RECEIPT_PRE_CHECK = USE_RECEIPT_PRE_CHECK;
}
