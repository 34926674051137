
























































import { Component, Mixins, Ref } from "vue-property-decorator";
import { Question, DEFAULT_QUESTION } from "@/model/research";
import { QUESTION_STATUS, QUESTION_STATUS_COLOR } from "@/consts/research";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import QuestionInfoViewer from "@/components/research/QuestionInfoViewer.vue";
import QuestionSearch from "@/components/research/QuestionSearch.vue";

interface DispQuestion extends Question {
  /** アンケートステータス 1~3 */
  status: number;
}

@Component({
  components: {
    QuestionInfoViewer,
    QuestionSearch,
  },
})
export default class EndResearch extends Mixins(AxiosMixin, UtilMixin) {
  /** 詳細表示用ダイアログ */
  @Ref("question_viewer")
  private readonly questionInfoViewer!: QuestionInfoViewer;

  /** 一覧表示対象のアンケート情報 */
  private questions: DispQuestion[] = [];

  /** 詳細表示対象のアンケート情報 */
  private question: DispQuestion = { ...DEFAULT_QUESTION, status: 1 };

  /** アンケート状態定数 */
  private QUESTION_STATUS = QUESTION_STATUS;

  /** アンケート状態色わけ定数 */
  private QUESTION_STATUS_COLOR = QUESTION_STATUS_COLOR;

  /** 絞込み用 */
  private searchKey = "";

  /** ヘッダー情報 */
  private headers = [
    {
      text: "詳細",
      sortable: false,
      value: "actions",
    },
    {
      text: "件名",
      sortable: false,
      value: "title",
    },
    {
      text: "掲載期間",
      sortable: true,
      value: "date",
    },
  ];

  /** 初回時の処理 */
  public created(): void {
    this.fetchQuestions();
  }

  /** アンケート情報取得 */
  private fetchQuestions(searchObj: object = {}): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/researches/get",
      { ...searchObj, time_div: 1 },
      (res) => {
        if (!res.data.questions) {
          return;
        }

        this.questions = res.data.questions;
      }
    );
  }

  /** 詳細ボタンクリック時 */
  private show(target: DispQuestion) {
    this.question = target;
    this.questionInfoViewer.open();
  }

  /** 詳細ダイアログ内で削除ボタンクリック時 */
  private async remove(target: DispQuestion) {
    if (!(await this.$openConfirm("本当に削除しますか?"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/research/delete",
      { question: target },
      () => {
        this.fetchQuestions();
        this.questionInfoViewer.close();
      }
    );
  }

  /** 詳細ダイアログ内で編集ボタンクリック時 */
  private edit(targetId: number) {
    this.$router.push({ path: `/research/edit/${targetId}` });
  }

  /** 検索時 */
  private search(searchObj: object) {
    this.fetchQuestions(searchObj);
  }

  /** 集計結果は集計情報画面で確認させる */
  private csv() {
    this.$router.push({ path: "/configuration/viewer" });
  }
}
