





































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Term, DefaultTerm, DefaultTermLong } from "#/model/master";
import RulesMixin from "#/mixins/rulesMixin";
import AiRecommendMixin from "@/components/recommend/mixin";

@Component
/**
 * AIお勧め検索 長期目標編集コンポーネント
 */
export default class FormLongTerm extends Mixins(RulesMixin, AiRecommendMixin) {
  /** Prop */

  /** 編集対象の看護計画内容 */
  @Prop({ default: () => DefaultTerm() }) value!: Term;

  /** getter */

  /** 編集対象の看護計画内容 */
  private get Term(): Term {
    return this.value;
  }

  private set Term(newValue: Term) {
    this.input(newValue);
  }

  /** 関数 */

  /** 長期目標追加 */
  private add() {
    this.Term.term_longs.push(DefaultTermLong());
  }

  /** 長期目標削除 */
  private async remove(index: number) {
    if (
      !(await this.$openConfirm(
        "長期目標を削除します。よろしいですか？\n※保存ボタンを押すまで反映はされません。"
      ))
    ) {
      return;
    }

    this.Term.term_longs.splice(index, 1);
  }

  /** 1つ上に移動 */
  private up(index: number) {
    this.Term.term_longs = [
      ...this.swapWithPrevious(this.Term.term_longs, index),
    ];
  }

  /** 1つ下に移動 */
  private down(index: number) {
    this.Term.term_longs = [...this.swapWithNext(this.Term.term_longs, index)];
  }

  /** Emit */

  /** 値変更 */
  @Emit("input")
  private input(term: Term) {
    return term;
  }
}
