
































































































































































































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import { DispInquiry, DEFAULT_DISP_INQUIRY } from "@/model/inquiry";
import {
  INQUIRY_STATUS,
  INQUIRY_STATUS_COLOR,
  INQUIRY_DIV,
  INQUIRY_PUBLISHED,
  INQUIRY_PUBLISHED_COLOR,
  HOPE_CONTACT_DIV,
  INQUIRY_VERSION,
  INQUIRY_VERSION_COLOR,
  ACCESS_CONFIRMS,
} from "@/consts/inquiry";
import { PREFECTURES, STAFF_AUTH, USE_RECEIPT_TYPE } from "@/consts/const";
import UtilMixin from "@/mixins/utilMixin";
import StatusSelectDialog from "@/components/inquiry/StatusSelectDialog.vue";
import InquiryTagSelectDialog from "@/components/inquiry/InquiryTagSelectDialog.vue";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import { INQUIRY_COMPLETED } from "@/consts/inquiry";
import { Choice } from "@/types";

@Component({
  components: {
    StatusSelectDialog,
    AttachmentViewer,
    InquiryTagSelectDialog,
  },
})
export default class InquiryInfo extends Mixins(UtilMixin) {
  /** 問い合わせ情報 */
  @Prop({ default: () => DEFAULT_DISP_INQUIRY }) inquiry!: DispInquiry;
  @Prop({ default: true }) editable!: boolean;

  /** 問い合わせタグマスタ選択肢 */
  @Prop() inquiryTagChoices!: Choice[];

  /** 問い合わせステータス設定ダイアログ */
  @Ref("status_selector") private readonly statusSelector!: StatusSelectDialog;

  /** 問い合わせタグ設定ダイアログ */
  @Ref("tag_selector") private readonly tagSelector!: InquiryTagSelectDialog;

  private INQUIRY_STATUS = INQUIRY_STATUS;
  private INQUIRY_STATUS_COLOR = INQUIRY_STATUS_COLOR;
  private STAFF_AUTH = STAFF_AUTH;
  private INQUIRY_DIV = INQUIRY_DIV;
  /** 希望連絡方法 */
  private HOPE_CONTACT_DIV = HOPE_CONTACT_DIV;

  /** 希望連絡方法 */
  private ACCESS_CONFIRMS = ACCESS_CONFIRMS;

  /** 問い合わせステータス定数(完了) */
  private INQUIRY_COMPLETED = INQUIRY_COMPLETED;

  /** 公開設定選択肢 */
  private INQUIRY_PUBLISHED = INQUIRY_PUBLISHED;

  /** 公開設定色分け */
  private INQUIRY_PUBLISHED_COLOR = INQUIRY_PUBLISHED_COLOR;

  /** バージョン定数 */
  private INQUIRY_VERSION = INQUIRY_VERSION;

  /** バージョン色分け定数 */
  private INQUIRY_VERSION_COLOR = INQUIRY_VERSION_COLOR;

  /** 使用レセプト定数 */
  private USE_RECEIPT_TYPE = USE_RECEIPT_TYPE;

  // HTML用問い合わせ内容
  private get htmlContact() {
    return this.inquiry.contact.replace(/\r?\n/g, "<br>");
  }

  private get officeAddress() {
    if (
      !this.inquiry.inquiry_office.office_info ||
      !this.inquiry.inquiry_office.office_info.pref
    ) {
      return "";
    }

    const officeInfo = this.inquiry.inquiry_office.office_info;

    return `${this.idToText(officeInfo.pref, PREFECTURES)}${officeInfo.city}${
      officeInfo.area
    }${officeInfo.building}`;
  }

  private onClickInquiryStatus() {
    /** 編集可能コンポーネントではない、もしくは完了時は何もしない */
    if (!this.editable || this.inquiry.status == INQUIRY_COMPLETED) {
      return;
    }

    this.statusSelector.open();
  }

  private onSaveStatus(status: number) {
    this.statusSelector.close();

    if (status != this.inquiry.status) {
      this.saveStatus(status);
    }
  }

  /** 問い合わせタグクリック時 */
  private onClickInquiryTag() {
    this.tagSelector.open();
  }

  @Emit()
  saveStatus(status: number) {
    return status;
  }

  /** 問い合わせタグ保存時イベント */
  @Emit("save:tag")
  saveTag(inquiryTagId: number): number {
    return inquiryTagId;
  }
}
