













































































































import {
  Component,
  Prop,
  Watch,
  Mixins,
  Ref,
  Emit
} from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { uuid } from "vue-uuid";
import AppFileInput from "./AppFileInput.vue";

// v-formインターフェース
export interface VForm {
  validate: () => boolean;
  reset: () => void;
  resetValidation: () => void;
}

// ファイル
export interface FileIF {
  id: number; //ID
  path: string; //'画像パス',
  comment: string; //'コメント',
  name?: string;
}

@Component
export default class FileUpload extends Mixins(AxiosMixin) {
  @Ref("form") private readonly form!: VForm;

  @Ref("AppFileInputForm") private readonly appFileInputForm!: AppFileInput;

  /** ファイル */
  @Prop({
    type: Object,
    default: () => {
      return {
        id: 0,
        path: "",
        comment: ""
      };
    }
  })
  model!: FileIF;
  @Prop({ default: "prefix" }) private prefix_id!: string;
  /** アップロードディレクトリパス */
  @Prop() uploadDirPath!: string;
  /** 一時保存先バケット保存フラグ デフォルトは一時保存バケットにファイルをアップロード */
  @Prop({ default: 1 }) is_temp!: number;
  /** ファイルアップロードAPIパス */
  @Prop() uploadApiUrl!: string;
  /** ファイルプレビューAPIパス */
  @Prop() previewApiUrl!: string;
  /** ファイル登録必須フラグ */
  @Prop({ default: false }) isNeedFile!: boolean;
  /** 入力コメント欄非表示フラグ */
  @Prop({ default: false }) not_coment!: boolean;
  /** 入力ファイル名欄非表示フラグ */
  @Prop({ default: true }) not_name!: boolean;
  /** コメントラベル非表示フラグ */
  @Prop({ default: false }) not_coment_label!: boolean;
  /** プレビューボタン非表示フラグ */
  @Prop({ default: false }) not_preview!: boolean;
  /** ファイルを選択ボタンの横にファイル名表示フラグ */
  @Prop({ default: false }) show_file_name!: boolean;
  /** アップロードを許可するファイルの拡張子もしくはMIMEタイプ */
  @Prop({ default: () => ["image/*"] }) accept_file_types!: string[];
  /** 起動時にファイルアップロードダイアログを初期表示するかどうか */
  @Prop({ default: false }) init_dialog!: boolean;
  /** ファイルアップロードアイコン */
  @Prop({ default: "mdi-camera-plus" }) prepend_icon!: string;
  /** ファイル登録ボタンラベル */
  @Prop({ default: "ファイル登録" }) file_edit_label!: string;
  /** 削除ボタン表示フラグ */
  @Prop({ default: true }) deletable!: boolean;
  /** ファイルサイズ制限 */
  @Prop({ default: 1e7 }) sizeLimit!: number;
  /** 画像が横長の時、縦長にするか */
  @Prop({ default: false }) imagePortrait!: boolean;
  /** プレビュー時の動き */
  @Prop({ type: Function }) previewFunc!: Function;
  /** オペログ用利用者ID */
  @Prop({ default: 0 }) opelogPatientId!: number;
  /** 画面名 */
  @Prop({ default: "" }) displayTitle!: string;

  /** ファイルアップロードダイアログ */
  private dialog = false;
  /** 一時退避パス */
  private localPath = "";
  /** 一時退避コメント */
  private localComment = "";
  /** 一時退避ファイル名 */
  private localName = "";
  /** 一時退避画像 */
  private localImage: File | [] = [];
  /** ファイル名 */
  private fileName = "";
  /** テキストフィールドの文字数制限 */
  private STRING_LIMIT = 100;
  /** 変更したかどうかフラグ */
  private isModify = false;

  /** アップロードを許可するファイル */
  get accepts() {
    return this.accept_file_types.join(",");
  }

  /** プレビューのパス取得APIURL */
  private get previewUrl(): string {
    if (this.model.path) {
      return this.previewApiUrl + "?path=" + this.model.path;
    }
    return "";
  }

  mounted() {
    this.dialog = this.init_dialog;
  }

  @Watch("model.path")
  private changePath() {
    this.init();
  }

  @Watch("dialog")
  private changeDialog() {
    this.init();
  }

  /** 初期表示処理 */
  private init() {
    this.localComment = "";
    this.localName = "";
    //this.localImage = [];
    this.isModify = false;

    this.localComment = this.model.comment;
    this.localPath = this.model.path;
    this.localName = "";
    if (this.model.name) {
      this.localName = this.model.name;
    }
    if (this.appFileInputForm) {
      this.appFileInputForm.reset();
    }
  }

  /** base64文字列をファイルに変換 */
  private base64ToFile(
    base64: string,
    fileName: string,
    fileType: string
  ): File {
    const binary = atob(base64.replace(/^.*,/, ""));
    const buffer = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      buffer[i] = binary.charCodeAt(i);
    }
    return new File([buffer.buffer], fileName, { type: fileType });
  }

  /** 横長のpngとjpegを回転して縦向きにする */
  private imageOrient(image: File): Promise<File> {
    return new Promise((resolve, reject) => {
      if (!this.imagePortrait) {
        // 画像を回転する設定でなければ、そのまま返す
        resolve(image);
      }

      const TARGET_MINE_TYPES = ["image/jpeg", "image/png"];
      if (!TARGET_MINE_TYPES.includes(image.type)) {
        // 対象でないファイル形式なら、そのまま返す
        resolve(image);
      }

      const tmpImg = new Image();
      tmpImg.src = URL.createObjectURL(image);

      tmpImg.addEventListener("load", () => {
        // 画像ファイルを一旦canvasに貼り付けることで、Orientation等Exif情報を消す
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

        if (tmpImg.width > tmpImg.height) {
          // 横長JPEGなので90度時計回しで縦向きにする
          canvas.width = tmpImg.height;
          canvas.height = tmpImg.width;
          ctx.rotate((90 * Math.PI) / 180);
          ctx.translate(0, -tmpImg.height);
          ctx.drawImage(tmpImg, 0, 0, tmpImg.width, tmpImg.height);
        } else {
          canvas.width = tmpImg.width;
          canvas.height = tmpImg.height;
          ctx.drawImage(tmpImg, 0, 0, tmpImg.width, tmpImg.height);
        }

        // canvas画像→base64→Fileオブジェクト
        const base64 = canvas.toDataURL(image.type);
        URL.revokeObjectURL(tmpImg.src);
        resolve(this.base64ToFile(base64, image.name, image.type));
      });

      tmpImg.addEventListener("error", () => {
        // 画像だと思っていたファイルからObjectURLを作れなかった場合
        reject(image);
      });
    });
  }

  /** プレビューボタンクリック */
  private preview() {
    if (!this.previewApiUrl) {
      this.previewFunc(this.model.path);
    } else {
      this.postJsonCheck(this.previewUrl, {}, res => {
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = res.data.url;
        a.click();
      });
    }
  }

  /** ファイルを削除して入力情報をリセット */
  public FileReset() {
    this.dialog = false;
    this.model.path = "";
    this.model.comment = "";
    this.fileName = "";
    this.localImage = [];
    this.deleted();
    this.appFileInputForm.reset();
  }

  /** 削除ボタンクリック */
  private async clickDelete() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) return;
    this.FileReset();
    // オペログ書き込み用 ファイル新規登録時
    if (this.opelogPatientId != 0) {
      this.postJsonBackground(
        window.base_url + "/api/fileupload/delete",
        {
          patient_id: this.opelogPatientId,
          display_title: this.displayTitle
        },
        () => {
          // Do Nothing
        }
      );
    }
  }

  /** 登録ボタンクリック */
  private async regist() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (this.isModify && this.localImage instanceof File) {
      this.imageOrient(this.localImage)
        .then(uploadFile => {
          const formData = new FormData();
          formData.append("upload", uploadFile);
          let path = this.model.path;
          let isAdd = false;
          if (!path) {
            // パスが存在しない（新規に登録）場合はパスを作成
            isAdd = true;
            path = this.uploadDirPath;
            if (path.slice(-1) !== "/") {
              path += "/";
            }
            path += uuid.v4();
            // オペログ書き込み用 ファイル新規登録時
            if (this.opelogPatientId != 0) {
              this.postJsonBackground(
                window.base_url + "/api/fileupload/regist",
                {
                  patient_id: this.opelogPatientId,
                  display_title: this.displayTitle
                },
                () => {
                  // Do Nothing
                }
              );
            }
          } else {
            // オペログ書き込み用 ファイル更新時
            if (this.opelogPatientId != 0) {
              this.postJsonBackground(
                window.base_url + "/api/fileupload/update",
                {
                  patient_id: this.opelogPatientId,
                  display_title: this.displayTitle
                },
                () => {
                  // Do Nothing
                }
              );
            }
          }

          formData.append("path", path);
          formData.append("is_temp", String(this.is_temp));
          this.postJsonCheck(this.uploadApiUrl, formData, res => {
            this.dialog = false;
            this.localPath = res.data.path;
            this.model.comment = this.localComment;
            this.model.path = this.localPath;
            this.model.name = this.localName;
            isAdd && this.addFile();
            this.updateFile();
          });
        })
        .catch(async errorFile => {
          await this.$openAlert(
            `ファイル「${errorFile.name}」を処理できませんでした。`
          );
          return;
        });
    } else {
      this.dialog = false;
      this.model.comment = this.localComment;
      this.model.name = this.localName;
      this.updateFile();
      // オペログ書き込み用 ファイル更新時
      if (this.opelogPatientId != 0) {
        this.postJsonBackground(
          window.base_url + "/api/fileupload/update",
          {
            patient_id: this.opelogPatientId,
            display_title: this.displayTitle
          },
          () => {
            // Do Nothing
          }
        );
      }
    }
  }

  /** キャンセルボタンクリック */
  private cancel() {
    this.dialog = false;
    if (this.model.path == "") {
      this.localImage = [];
    }
    this.canceled();
  }

  /** ファイル更新時 */
  private onChangeFile(file: File) {
    this.isModify = true;
    if (file != null) {
      this.fileName = file.name;
      this.localName = file.name;
    }

    /**
     * オペログ書き込み用
     * ファイル選択(変更)時
     */
    if (this.opelogPatientId != 0) {
      this.postJsonBackground(
        window.base_url + "/api/fileupload/change",
        {
          patient_id: this.opelogPatientId,
          display_title: this.displayTitle
        },
        () => {
          // Do Nothing
        }
      );
    }
  }

  /** ファイル必須チェック */
  private requiredUploadFile(): boolean | string {
    if (!this.isNeedFile) {
      return true;
    }
    if (this.localPath) {
      return true;
    } else if (this.localImage instanceof File) {
      return this.localImage.size > 0 || "必須です";
    } else if (this.localImage == null || this.localImage.length == 0) {
      return "必須です";
    }
    return true;
  }

  @Emit()
  private deleted() {
    return;
  }

  @Emit()
  private canceled() {
    return;
  }

  @Emit()
  private addFile() {
    return;
  }

  @Emit()
  private updateFile() {
    return;
  }

  /**
   * オペログ書き込み用
   * ファイル登録クリック時
   */
  private onClickFile() {
    if (this.opelogPatientId != 0) {
      this.postJsonBackground(
        window.base_url + "/api/fileupload/click",
        {
          patient_id: this.opelogPatientId,
          display_title: this.displayTitle
        },
        () => {
          // Do Nothing
        }
      );
    }
  }
}
