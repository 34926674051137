<template>
  <div>
    <div
      class="d-flex align-start"
      :class="$vuetify.breakpoint.xs ? 'flex-wrap' : ''"
      :style="{
        minWidth: minWidth
      }"
    >
      <div style="position: relative">
        <v-menu
          ref="targetDate"
          v-model="pickerFlag"
          :close-on-content-click="false"
          :disabled="disabled"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <div
                v-if="warekiPosition == 'top'"
                :class="{ disabled: disabled }"
                class="ibow2-wareki-top"
              >
                {{ warekiDate }}
              </div>
              <AppTextField
                :id="prefix_id"
                style="font-size: 1.25rem"
                dense
                outlined
                :readonly="true"
                :clearable="clearable"
                hide-details="auto"
                v-on="on"
                :prepend-inner-icon="
                  !need_next ? '' : 'mdi-calendar-arrow-left'
                "
                :append-icon="!need_next ? '' : 'mdi-calendar-arrow-right'"
                @click:prepend-inner="addMonth(-1)"
                @click:append="addMonth(1)"
                @click:clear="clearDate"
                :rules="textMonthChangeRules"
                :label="localLabel"
                v-model="dateFormatted"
                :disabled="disabled"
                minWidth="0px"
                :width="$vuetify.breakpoint.xs ? '' : width"
              />
            </div>
          </template>
          <v-date-picker
            v-model="date"
            locale="ja"
            no-title
            type="month"
            @input="pickerFlag = false"
            :yearFormat="str => jpvYearFormat(str)"
            :header-date-format="str => jpvHeaderDateFormat(str)"
            :validate-on-blur="true"
          ></v-date-picker>
        </v-menu>
      </div>
      <AppButton
        :id="`${prefix_id}-btn-beforemonth`"
        outlined
        v-if="show_before_month"
        class="ml-2"
        @click="beforemonth()"
        >前月</AppButton
      >
      <AppButton
        :id="`${prefix_id}-btn-thismonth`"
        outlined
        v-if="show_this_month"
        class="ml-2"
        @click="thismonth()"
        >今月</AppButton
      >
      <AppButton
        :id="`${prefix_id}-btn-nextmonth`"
        outlined
        v-if="show_next_month"
        class="ml-2"
        @click="nextmonth()"
        >翌月</AppButton
      >
      <slot></slot>
      <span v-if="warekiPosition == 'right'" class="ml-1">{{
        warekiDate
      }}</span>
    </div>
    <div style="height: 20px" v-if="warekiPosition == 'bottom'">
      {{ warekiDate }}
    </div>
  </div>
</template>

<script>
import AppTextField from "./AppTextField.vue";
import AppButton from "./AppButton.vue";
import RulesSaveMixin from "../mixins/rulesSaveMixin";
import DatePickerYearFormatMixin from "../mixins/datePickerYearFormatMixin";
import { toWarekiDatetime } from "#/utility/appDate";

export default {
  mixins: [RulesSaveMixin, DatePickerYearFormatMixin],
  components: {
    AppTextField,
    AppButton
  },
  props: {
    value: String,
    disabled: Boolean,
    prefix_id: {
      default: "",
      type: String
    },
    label: {
      default: "対象年月",
      type: String
    },
    width: {
      type: String,
      default: "300px"
    },
    warekiPosition: {
      type: String,
      default: "top"
    },
    isNeed: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    show_this_month: {
      type: Boolean,
      default: false
    },
    show_next_month: {
      type: Boolean,
      default: false
    },
    show_before_month: {
      type: Boolean,
      default: false
    },
    need_next: {
      type: Boolean,
      default: true
    },
    minWidth: {
      type: String,
      default: "200px"
    }
  },

  data: vm => ({
    pickerFlag: false,
    formattingDate: vm.value
  }),

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.formattingDate = newValue;
        this.$emit("input", newValue);
      }
    },
    warekiDate: {
      get() {
        return toWarekiDatetime(this.date, false, false);
      }
    },
    localLabel: {
      get() {
        if (this.isNeed) return "*" + this.label;
        return this.label;
      }
    },
    dateFormatted: {
      get() {
        if (!this.date) return null;
        const [year, month] = this.date.split("-");
        return `${year}年${month}月`;
      },
      set(newValue) {
        if (newValue === "") {
          this.date = "";
        }

        let regex = /^([0-9]{6})$/g;
        const result = regex.exec(newValue);
        if (result) {
          const date = result[1];
          this.date = date.substring(0, 4) + "-" + date.substring(4, 6);
          return;
        }

        regex = /^([0-9]{4})\/([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})$/g;
        this.regixMatch(regex, newValue);

        regex = /^([0-9]{4})-([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})$/g;
        this.regixMatch(regex, newValue);

        regex = /^([0-9]{4})年([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})月$/g;
        this.regixMatch(regex, newValue);
      }
    },
    addrRequired() {
      if (!this.isNeed) {
        return true;
      }
      return !!this.dateFormatted || "必須です";
    },
    textMonthChangeRules() {
      return [this.addrRequired, ...this.rules];
    }
  },

  methods: {
    clearDate() {
      this.$emit("click:clear");
      this.date = "";
    },
    addMonth(num) {
      // 入力されている月を(num)ヶ月後にする
      // 入力が空なら今月にする
      let date = new Date();
      if (this.date) {
        date = new Date(this.date.replace(/-/g, "/") + "/01");
      } else {
        date = new Date(date.getFullYear(), date.getMonth() - num, 1);
      }
      let month = date.getMonth();
      let year = date.getFullYear();
      const targetMonth = month + num;
      year = Math.floor(targetMonth / 12) + year;
      month = targetMonth % 12;
      if (month < 0) {
        month = 12 + month;
      }
      const newDate = new Date(year, month, 2);
      this.date = newDate.toISOString().substr(0, 7);
    },
    regixMatch(regex, value) {
      const result = regex.exec(value);
      if (result) {
        const year = result[1];
        const month = result[2];
        this.date = ("000" + year).slice(-4) + "-" + ("0" + month).slice(-2);
      }
    },
    thismonth() {
      const date = new Date();
      date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
      this.date = date.toISOString().substr(0, 7);
    },
    nextmonth() {
      const date = new Date();
      date.setDate(1); // 当月の1日にする
      date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
      date.setMonth(date.getMonth() + 1);
      this.date = date.toISOString().substr(0, 7);
    },
    beforemonth() {
      const date = new Date();
      date.setDate(1); // 当月の1日にする
      date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
      date.setMonth(date.getMonth() - 1);
      this.date = date.toISOString().substr(0, 7);
    }
  }
};
</script>
