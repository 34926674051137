var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("iBowアカウント編集")]),_c('v-form',{ref:"ibow-account-editor"},[_c('v-simple-table',{staticClass:"elevation-1 mb-3",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("システムID")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.system_id)+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("iBowID")]),_c('td',{attrs:{"align":"left"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"iBowID","hide-details":"","width":"100%","prefix":"@","maxlength":"199","rules":[
                    _vm.minLength(
                      _vm.userIbowId,
                      199,
                      '199文字以内で入力してください。'
                    ) ]},model:{value:(_vm.userIbowId),callback:function ($$v) {_vm.userIbowId=$$v},expression:"userIbowId"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("名前")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"*姓","hide-details":"","width":"100%","maxlength":"200","rules":[
                      _vm.required(_vm.ewellUser.family_name, '必須項目です。'),
                      _vm.minLength(
                        _vm.ewellUser.family_name,
                        200,
                        '200文字以内で入力してください。'
                      ) ]},model:{value:(_vm.ewellUser.family_name),callback:function ($$v) {_vm.$set(_vm.ewellUser, "family_name", $$v)},expression:"ewellUser.family_name"}}),_c('span',{staticClass:"ml-2 mr-2"}),_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"*名","hide-details":"","width":"100%","maxlength":"200","rules":[
                      _vm.required(_vm.ewellUser.first_name, '必須項目です。'),
                      _vm.minLength(
                        _vm.ewellUser.first_name,
                        200,
                        '200文字以内で入力してください。'
                      ) ]},model:{value:(_vm.ewellUser.first_name),callback:function ($$v) {_vm.$set(_vm.ewellUser, "first_name", $$v)},expression:"ewellUser.first_name"}})],1)])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("ふりがな")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"*姓（ふりがな","hide-details":"","width":"100%","maxlength":"200","rules":[
                      _vm.required(
                        _vm.ewellUser.family_name_furigana,
                        '必須項目です。'
                      ),
                      _vm.minLength(
                        _vm.ewellUser.family_name_furigana,
                        200,
                        '200文字以内で入力してください。'
                      ) ]},model:{value:(_vm.ewellUser.family_name_furigana),callback:function ($$v) {_vm.$set(_vm.ewellUser, "family_name_furigana", $$v)},expression:"ewellUser.family_name_furigana"}}),_c('span',{staticClass:"ml-2 mr-2"}),_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"*名（ふりがな）","hide-details":"","width":"100%","maxlength":"200","rules":[
                      _vm.required(
                        _vm.ewellUser.first_name_furigana,
                        '必須項目です。'
                      ),
                      _vm.minLength(
                        _vm.ewellUser.first_name_furigana,
                        200,
                        '200文字以内で入力してください。'
                      ) ]},model:{value:(_vm.ewellUser.first_name_furigana),callback:function ($$v) {_vm.$set(_vm.ewellUser, "first_name_furigana", $$v)},expression:"ewellUser.first_name_furigana"}})],1)])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("生年月日")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.birthday)+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("性別")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.sex_name)+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("メールアドレス")]),_c('td',{attrs:{"align":"left"}},[_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"メールアドレス","hide-details":"","width":"100%","maxlength":"200","rules":[
                    _vm.minLength(
                      _vm.ewellUser.mail_address,
                      200,
                      '200文字以内で入力してください。'
                    ) ]},model:{value:(_vm.ewellUser.mail_address),callback:function ($$v) {_vm.$set(_vm.ewellUser, "mail_address", $$v)},expression:"ewellUser.mail_address"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("郵便番号")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.post_code.substring(0, 3) + "-" + _vm.ewellUser.post_code.substring(3))+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("住所")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.address)+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("建物名")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.building)+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("携帯電話番号")]),_c('td',{attrs:{"align":"left"}},[_c('NumberInputString',{staticClass:"mt-2 mb-2",attrs:{"hide-details":"","width":"100%","maxlength":"200","rules":[
                    _vm.halfWidthNumeric(
                      _vm.ewellUser.tel_no,
                      '半角数字で入力してください。'
                    ) ]},model:{value:(_vm.ewellUser.tel_no),callback:function ($$v) {_vm.$set(_vm.ewellUser, "tel_no", $$v)},expression:"ewellUser.tel_no"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("資格区分")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.cert)+" ")])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("特定行為")]),_c('td',{attrs:{"align":"left"}},[_c('div',{staticClass:"text-body-1 py-4 pl-3"},[_vm._v(" "+_vm._s(_vm.ewellUser.practitioner)+" ")])])])])]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('AppAuthButton',{attrs:{"color":"","label":"キャンセル","className":"mr-2"},on:{"click":_vm.clickCancel}}),_c('AppAuthButton',{attrs:{"color":"primary","label":"保存","className":"mr-2 white--text"},on:{"click":function($event){return _vm.save()}}})],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }