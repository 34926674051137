












import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import NumberInputString from "./NumberInputString.vue";

@Component({
  components: {
    NumberInputString
  }
})
export default class NumberInput extends Vue {
  /** 値 */
  @Prop({ default: "" }) value!: string | number | null;

  /** 0を表示するか */
  @Prop({ default: false }) disp0!: boolean;

  /** 最大桁数 */
  @Prop({ default: () => [] }) rules!: (string | boolean)[];

  /** 最大桁数 */
  @Prop({ default: "" }) maxlength!: string | number;

  /** 空登録を禁止するかどうか（0入力は許可） */
  @Prop({ default: false }) notAllowedEmpty!: boolean;

  /** 幅 */
  @Prop({ default: "100%" }) width!: string;
  /** 最低幅 */
  @Prop({ default: "225px" }) minWidth!: string;

  /** 画面から入力した値 */
  private setValue = "";

  @Emit() private change(newValue: string) {
    return newValue;
  }

  @Emit() private input(value: string): string | number {
    const num = Number(value);
    if (isNaN(num)) {
      return value;
    }
    return num;
  }

  private get localValue(): string {
    if (!this.value && !this.disp0) return "";
    return this.value + "";
  }

  private set localValue(value: string) {
    this.setValue = value;
    this.input(value);
  }

  private get Rules() {
    //空登録を不許可で、0も入れてない場合、エラー
    if (this.notAllowedEmpty && this.value === 0 && this.setValue === "") {
      return ["必須です"];
    }
    return this.rules;
  }
}
