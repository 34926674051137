
















import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { Template, TemplateCategory, TemplateHashtag } from "@/model/inquiry";
import TemplateSort from "@/components/inquiry/TemplateSort.vue";

interface DispTemplate extends Template {
  categorys: string;
  keywords: string;
}

@Component({
  components: {
    AppAuthButton,
    TemplateSort,
  },
})
export default class IbowHelpSort extends Mixins(AxiosMixin, UtilMixin) {
  private templates: DispTemplate[] = [];
  private tab = 0;

  created() {
    this.fetchTemplates();
  }

  private fetchTemplates() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/templates/get",
      {
        only_recommend: 1,
        faq_valid: 1,
      },
      (res) => {
        if (res.data.templates) {
          this.templates = res.data.templates.map((src: DispTemplate) => {
            const temp: DispTemplate = {
              ...src,
              categorys: this.getCategoryName(src.template_categorys, []),
              keywords: this.getKeywordName(src.template_hashtags ?? []),
            };
            return temp;
          });
        }
      }
    );
  }

  private save(templates: DispTemplate[]) {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/sort/save",
      {
        templates: templates,
      },
      () => {
        this.fetchTemplates();
      }
    );
  }

  /** 登録済みカテゴリ名を文字列で取得 */
  private getCategoryName(categories: TemplateCategory[], result: string[]) {
    categories.forEach((category) => {
      if (!category.child_categorys || category.child_categorys.length === 0) {
        // 子カテゴリが存在しない(=最下位カテゴリ)の場合は自分自身のカテゴリ名をresultに追加
        result.push(category.category_name);
        return result;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.getCategoryName(category.child_categorys, result);
      result.push(category.category_name);
    });

    return result.reverse().join(", ");
  }

  /** 登録済みキーワード名を文字列で取得 */
  private getKeywordName(keywords: TemplateHashtag[]) {
    return keywords.map((keyword) => keyword.hashtag).join(", ");
  }
}
