




































import { Component, Mixins } from "vue-property-decorator";
import { Menu, SubMenu } from "@/types";
import { SIDE_MENUS } from "@/const";
import UserMixin from "@/mixins/userMixin";
import { AdminAuthFunction } from "@/store/modules/user/types";

@Component
export default class AppMenu extends Mixins(UserMixin) {
  private drawer = this.$vuetify.breakpoint.lgAndUp;
  // メニュー
  private menus: Menu[] = SIDE_MENUS;

  /**
   * 権限によって表示するメニュー項目を変更する
   *
   * 閲覧権限がない場合はメインメニューそのものを非表示
   * 編集権限がない場合はサブメニューの内、編集系のページを非表示
   */
  get authorizedMenus() {
    const adminAuthFunctions = this.User.admin_auth.admin_auth_functions;
    const readingAuthorizedPaths = adminAuthFunctions.flatMap(
      (authFunction: AdminAuthFunction) =>
        authFunction.is_reading == 1 ? authFunction.function.path : []
    );
    const editAuthorizedPaths = adminAuthFunctions.flatMap(
      (authFunction: AdminAuthFunction) =>
        authFunction.is_editing > 0 ? authFunction.function.path : []
    );

    // メインメニューのフィルタリング
    return this.menus.filter((menu: Menu) => {
      // pathに対して編集権限がない場合はサブメニューをフィルタリング
      if (!editAuthorizedPaths.includes(menu.path)) {
        menu.items = menu.items.filter(
          (submenu: SubMenu) => !submenu.is_edit_page
        );
      }

      return readingAuthorizedPaths.includes(menu.path);
    });
  }

  private linkto(menu: SubMenu) {
    this.$router.push({ path: menu.url });
  }
}
