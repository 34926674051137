










import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import {
  LineGraphData,
  LineData,
  CountData,
} from "@/components/configuration/statistics/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {},
})
export default class AmchartLineGraph extends Mixins(AxiosMixin, UtilMixin) {
  /** データ */
  @Prop() graphData!: LineGraphData;

  /** Excel出力用情報 */
  @Prop() excelData!: object[];

  /** エクスポートcsvファイル名 */
  @Prop({ default: "file" }) excelFileName!: string;

  /** エクスポートcsvシート名 */
  @Prop({ default: "シート1" }) sheetName!: string;

  /** チャート変数 */
  private chart!: am4charts.XYChart;

  /** 表示最大値 */
  private LIMIT = 10;

  @Watch("graphData")
  onChangeGraphData() {
    this.updateChart();
  }

  public mounted() {
    this.updateChart();
  }

  /** チャートデータ更新(初回含む) */
  private updateChart() {
    // チャート破棄
    this.disposeChart();

    // チャート初期設定
    this.chart = this.initChart();

    // チャートデータセット
    this.setChartData(this.chart);
  }

  public beforeDestroy(): void {
    this.disposeChart();
  }

  /** chart変数破棄 */
  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    let chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    // ロケール設定
    chart = this.setChartLocale(chart);

    chart.paddingRight = 20;

    // グラフカーソル
    chart.cursor = new am4charts.XYCursor();

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "top";
    chart.legend.contentAlign = "right";

    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;
    return chart;
  }

  private setChartLocale(chart: am4charts.XYChart) {
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_ja_JP;
    chart.language.locale["_date_day"] = "MMMdd日";
    chart.language.locale["_date_year"] = "yyyy年";
    return chart;
  }

  /** チャートデータセット */
  private setChartData(chart: am4charts.XYChart) {
    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    // チャートデータセット
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 5; // y軸の幅
    valueAxis.title.text = this.graphData.title; // y軸のタイトル
    valueAxis.fontSize = 12;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;

    dateAxis.baseInterval = {
      timeUnit: "month",
      count: 1,
    };

    valueAxis.min = 0;
    valueAxis.max = this.calcMaxValue(null, null);

    dateAxis.events.on("startchanged", (ev) => {
      valueAxis.max = this.calcMaxValue(
        new Date(ev.target.minZoomed),
        new Date(ev.target.maxZoomed)
      );
    });

    dateAxis.events.on("endchanged", (ev) => {
      valueAxis.max = this.calcMaxValue(
        new Date(ev.target.minZoomed),
        new Date(ev.target.maxZoomed)
      );
    });

    // 折線1本ずつのデータを作成
    this.graphData.lines.forEach((data: LineData) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.data = data.count_data;
      series.dataFields.dateX = "date"; // 日付毎
      series.dataFields.valueY = "count"; // 値
      series.name = data.title; // 凡例の名前
      series.tooltipText = "{valueY.value}" + this.graphData.unit; // ツールチップ
      series.yAxis = valueAxis; // y軸とのマッピング
      // データポイントの設定
      const bullet = series.bullets.push(new am4charts.Bullet());
      const circle = bullet.createChild(am4core.Circle);
      circle.width = 5;
      circle.height = 5;
      circle.horizontalCenter = "middle";
      circle.verticalCenter = "middle";
    });

    // Excel出力用にデータを格納
    chart.data = this.excelData;
  }

  /** Excel形式でデータエクスポート */
  public async exportData() {
    this.chart.exporting.title = this.sheetName;
    const img = await this.chart.exporting.getExcel("xlsx");
    const link = document.createElement("a");
    link.href = img;
    link.download = `${this.excelFileName}.xlsx`;
    link.click();
  }

  private calcMaxValue(
    startDt: Date | null,
    endDt: Date | null
  ): number | undefined {
    let max = 0;
    this.graphData.lines.forEach((line: LineData) => {
      line.count_data.forEach((data: CountData) => {
        // 範囲の指定がある場合はチェックする
        if (startDt != null && endDt != null) {
          if (new Date(data.date) >= startDt && new Date(data.date) <= endDt) {
            if (data.count > max) {
              max = data.count;
            }
          }
        } else {
          if (data.count > max) {
            max = data.count;
          }
        }
      });
    });

    return max < this.LIMIT ? this.LIMIT : undefined;
  }
}
