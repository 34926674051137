































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  HashtagChoice,
  HashtagCount,
  Template,
  TemplateCategory,
} from "@/model/inquiry";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import CategorySelector from "@/components/inquiry/CategorySelector.vue";
import HashtagAutoComplete from "@/components/inquiry/HashtagAutoComplete.vue";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { TEMPLATE_STATUS } from "@/consts/inquiry";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import FAQTemplateNavigationDrawer from "@/components/inquiry/FAQTemplateNavigationDrawer.vue";
import { DispTemplate, DEFAULT_DISP_TEMPLATE } from "@/views/inquiry/types";

@Component({
  components: {
    AnswerContent,
    InfiniteLoading,
    CategorySelector,
    HashtagAutoComplete,
    AttachmentViewer,
    FAQTemplateNavigationDrawer,
  },
})
export default class PastInquiryViewer extends Mixins(UtilMixin, AxiosMixin) {
  private templates: DispTemplate[] = [];

  /** キーワードの集計状況 */
  private keywordChoices: HashtagChoice[] = [];

  /** 検索キーワード */
  private keyword = "";

  /** 検索: カテゴリID */
  private category_id = 0;

  /** 検索: ハッシュタグ */
  private hashtag = "";

  /** 検索：ステータス */
  private statuses: number[] = [];

  /** ステータス一覧 */
  private TEMPLATE_STATUS = TEMPLATE_STATUS;

  /** スクロールカウント数 */
  private scrollCount = 0;

  /** 1回の取得件数 */
  private limit = 10;

  /** 最大スクロールカウント数 */
  private maxScrollCount = -1;

  /** 契約情報詳細を表示するためのナビゲーションドロワー制御用フラグ */
  private drawer = false;

  /** 詳細表示対象の問い合わせ */
  private targetTemplate: DispTemplate = { ...DEFAULT_DISP_TEMPLATE };

  /** 編集可能かどうか */
  @Prop({ default: false }) editable!: boolean;

  /** 検索エリア表示フラグ */
  private showSearchArea = true;

  created() {
    this.fetchKeywordCounts();
  }

  /** HTML用問い合わせ・回答内容 */
  private getHtmlContent(content: string): string {
    if (content) {
      return content.replace(/\r?\n/g, "<br>");
    }
    return "";
  }

  /** テンプレート情報取得 */
  private async fetchTemplates() {
    return new Promise((resolve) => {
      if (this.scrollCount != 0) {
        // 検索ボタン押下時以外での取得はローディングダイアログを表示させない
        this.loading = -100;
      }

      this.postJsonCheck(
        window.base_url + "/api/admin/inquiry/templates/get",
        {
          statuses: this.statuses,
          keyword: this.keyword,
          category_id: this.category_id,
          hashtag: this.hashtag,
          limit: this.limit,
          offset: this.limit * this.scrollCount,
        },
        (res) => {
          if (res.data.templates) {
            this.templates.push(
              ...this.convertCategorys2Disp(res.data.templates)
            );
          }

          // inquiryCountから最大スクロールカウント数を計算
          this.maxScrollCount = Math.floor(
            res.data.template_count / this.limit
          );
          resolve(0);
        }
      );
    });
  }

  /** キーワード集計取得 */
  private fetchKeywordCounts() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/template/hashtag/count",
      { use_case_type: 1 },
      (res) => {
        const counts: HashtagCount[] = res.data.hashtag_counts ?? [];
        const choices: HashtagChoice[] = counts.map((data) => ({
          text: data.hashtag,
          value: data.hashtag,
          count: data.count,
        }));
        this.keywordChoices = [
          {
            text: "すべて",
            value: "",
            count: 0,
          },
          ...choices,
        ];
      }
    );
  }

  /** FAQテンプレートリスト表示エリアの高さ */
  private get panelStyle() {
    return this.showSearchArea ? "max-height: 60vh" : "max-height: 80vh";
  }

  /** 各FAQテンプレとがクリックされた場合はFAQテンプレート情報をナビゲーションドロワーで表示 */
  private displayTemplateInfo(template: DispTemplate) {
    this.drawer = !this.drawer;
    this.targetTemplate = template;
  }

  /** 検索ボタン押下時 */
  private search(): void {
    this.templates = [];
    this.scrollCount = 0;
    this.maxScrollCount = -1;

    // 検索ボタン押下時の取得の場合はローディングダイアログを表示させる
    this.loading = 0;

    this.fetchTemplates();
  }

  /**
   * スクロールが最下部まで到達した時の無限ローディング処理
   *
   * スクロール回数が最大回数に到達していない場合は+1してさらに取得
   * 取得した結果はinquirysにpush
   */
  private async infiniteHandler($state: StateChanger) {
    // 読み込み完了していない場合は何もせず続行
    if (this.maxScrollCount == -1) {
      return;
    }

    // 最大スクロール回数に到達している場合は完了
    if (this.scrollCount == this.maxScrollCount) {
      $state.complete();
      return;
    }

    // 到達していない場合は+1して再度fetch
    this.scrollCount++;
    await this.fetchTemplates();
    $state.loaded();
  }

  /** 表示用にカテゴリ一覧を整形 */
  private convertCategorys2Disp(srcTemplates: Template[]): DispTemplate[] {
    return srcTemplates.map((src) => {
      const dist: DispTemplate = { ...src, categorys: "", admin_name: "" };
      const result: string[] = [];
      dist.categorys = this.generate(dist.template_categorys, result)
        .reverse()
        .join(", ");
      return dist;
    });
  }

  private generate(src: TemplateCategory[], result: string[]) {
    src.forEach((templateCategory: TemplateCategory) => {
      if (
        !templateCategory.child_categorys ||
        templateCategory.child_categorys.length == 0
      ) {
        // 子カテゴリが存在しない(=最下位カテゴリ)の場合は自分自身のカテゴリ名をresultに追加
        result.push(templateCategory.category_name);
        return result;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.generate(templateCategory.child_categorys, result);
      result.push(templateCategory.category_name);
    });

    return result;
  }

  @Emit("insert:template")
  private insertTemplateContent(template: DispTemplate) {
    this.drawer = false;
    return template;
  }
}
