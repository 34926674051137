


























































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Agreement } from "@/model/agreement";
import { Question } from "@/model/research";
import { SearchObj } from "@/components/configuration/statistics/types";
import { CATEGORY_QUESTION } from "@/consts/configuration";
import { Choice } from "@/types";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

@Component({
  components: {},
})
export default class ConfigurationViewer extends Mixins(UtilMixin, RulesMixin) {
  /** 検索フォームバリデーション用 */
  @Ref("searchForm") private readonly searchForm!: VForm;

  @Prop({ default: () => [] as Agreement[] }) agreements!: Agreement[];
  @Prop({ default: () => [] as Question[] }) questions!: Question[];

  private CATEGORY_QUESTION = CATEGORY_QUESTION;

  private selectedAgreementId = this.agreements.length
    ? this.agreements[0].id
    : 0;
  private startYearmonth = this.dateToStr(this.getOneYearAgoDate(), "yyyy-MM");
  private endYearmonth = this.dateToStr(new Date(), "yyyy-MM");

  private selectedCategory = 1;
  private selectedQuestionId = this.questions.length ? this.questions[0].id : 0;

  private chartItems = [
    {
      text: "看護記録書Ⅱ：提出数",
      value: 1,
    },
    {
      text: "看護記録書Ⅱ：訪問者数",
      value: 2,
    },
    {
      text: "看護記録書Ⅱ：保険種別",
      value: 4,
    },
    {
      text: "看護記録書Ⅱ：サービスコード種別",
      value: 5,
    },
    {
      text: "ストレージ使用量",
      value: 3,
    },
    {
      text: "評価アンケート",
      value: 6,
    },
  ];

  // 企業名一覧
  private get companyItems(): Choice[] {
    const items = this.agreements.flatMap((agreement) =>
      agreement.agreement_historys && agreement.agreement_historys.length > 0
        ? {
            value: agreement.id,
            text: agreement.agreement_historys.reverse()[0].co_name,
          }
        : []
    );
    return items;
  }

  // アンケート名一覧
  private get questionItems(): Choice[] {
    const items = this.questions.map((question: Question) => {
      return {
        value: question.id,
        text: question.title,
      };
    });
    return items;
  }

  /** 検索ボタンクリック時 */
  private async onClickSearch() {
    if (!this.searchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (
      this.checkYearmonthPeriod(
        this.startYearmonth,
        this.endYearmonth,
        11,
        ""
      ) !== true
    ) {
      if (
        !(await this.$openConfirm(
          "取得範囲が1年分を超えています。表示に時間が掛かる可能性がありますがよろしいですか？"
        ))
      ) {
        return;
      }
    }

    this.search();
  }

  // 検索クリック時に条件をまとめ親に返す
  @Emit()
  private search(): SearchObj {
    const conditions: SearchObj = {
      agreement_id: this.selectedAgreementId,
      start_yearmonth: Number(
        this.dateToStr(this.newDate(this.startYearmonth + "-01"), "yyyyMM")
      ),
      end_yearmonth: Number(
        this.dateToStr(this.newDate(this.endYearmonth + "-01"), "yyyyMM")
      ),
      category: this.selectedCategory,
      question_id: this.selectedQuestionId,
    };
    return conditions;
  }

  private getOneYearAgoDate() {
    const nowDt = new Date();
    nowDt.setMonth(nowDt.getMonth() - 11);
    return nowDt;
  }

  /** 検索条件のリセット */
  private reset() {
    this.startYearmonth = this.dateToStr(this.getOneYearAgoDate(), "yyyy-MM");
    this.endYearmonth = this.dateToStr(new Date(), "yyyy-MM");
  }
}
