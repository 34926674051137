var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("ほのぼの事業所編集")]),_c('v-card-text',[_c('v-form',{ref:"form"},_vm._l((_vm.office.office_honobono_offices),function(honobonoOffice,idx){return _c('div',{key:idx,staticClass:"my-2"},[_c('v-simple-table',{staticClass:"elevation-1",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("法人ID")]),_c('td',{attrs:{"align":"left"}},[_c('number-input',{staticClass:"my-2",attrs:{"width":"auto","maxlength":"9","rules":[
                        _vm.minNumber(
                          honobonoOffice.honobono_houjin_id,
                          1,
                          '1以上の数値を入力してください。'
                        ) ]},model:{value:(honobonoOffice.honobono_houjin_id),callback:function ($$v) {_vm.$set(honobonoOffice, "honobono_houjin_id", $$v)},expression:"honobonoOffice.honobono_houjin_id"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("施設ID")]),_c('td',{attrs:{"align":"left"}},[_c('number-input',{staticClass:"my-2",attrs:{"width":"auto","maxlength":"9","rules":[
                        _vm.minNumber(
                          honobonoOffice.honobono_shisetu_id,
                          1,
                          '1以上の数値を入力してください。'
                        ) ]},model:{value:(honobonoOffice.honobono_shisetu_id),callback:function ($$v) {_vm.$set(honobonoOffice, "honobono_shisetu_id", $$v)},expression:"honobonoOffice.honobono_shisetu_id"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("事業者ID")]),_c('td',{attrs:{"align":"left"}},[_c('number-input',{staticClass:"my-2",attrs:{"width":"auto","maxlength":"9","rules":[
                        _vm.minNumber(
                          honobonoOffice.honobono_sv_jigyo_id,
                          1,
                          '1以上の数値を入力してください。'
                        ) ]},model:{value:(honobonoOffice.honobono_sv_jigyo_id),callback:function ($$v) {_vm.$set(honobonoOffice, "honobono_sv_jigyo_id", $$v)},expression:"honobonoOffice.honobono_sv_jigyo_id"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("サービス種別ID")]),_c('td',{attrs:{"align":"left"}},[_c('app-select',{staticClass:"my-2",attrs:{"width":"auto","items":_vm.svKindChoices,"rules":[
                        _vm.minNumber(
                          honobonoOffice.honobono_sv_kind_cd,
                          1,
                          '必ず選択してください。'
                        ) ]},model:{value:(honobonoOffice.honobono_sv_kind_cd),callback:function ($$v) {_vm.$set(honobonoOffice, "honobono_sv_kind_cd", $$v)},expression:"honobonoOffice.honobono_sv_kind_cd"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("事業所番号")]),_c('td',{attrs:{"align":"left"}},[_c('number-input-string',{staticClass:"my-2",attrs:{"width":"auto","maxlength":"10","rules":[
                        _vm.required(
                          honobonoOffice.honobono_jigyo_number,
                          '必須項目です。'
                        ),
                        _vm.strLength(
                          honobonoOffice.honobono_jigyo_number,
                          10,
                          10,
                          '10桁で入力してください'
                        ) ]},model:{value:(honobonoOffice.honobono_jigyo_number),callback:function ($$v) {_vm.$set(honobonoOffice, "honobono_jigyo_number", $$v)},expression:"honobonoOffice.honobono_jigyo_number"}})],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("事業所名")]),_c('td',{attrs:{"align":"left"}},[_c('app-text-field',{staticClass:"my-2",attrs:{"width":"auto","maxlength":"60","rules":[
                        _vm.required(
                          honobonoOffice.honobono_jigyo_knj,
                          '必須項目です。'
                        ) ]},model:{value:(honobonoOffice.honobono_jigyo_knj),callback:function ($$v) {_vm.$set(honobonoOffice, "honobono_jigyo_knj", $$v)},expression:"honobonoOffice.honobono_jigyo_knj"}})],1)])])]},proxy:true}],null,true)}),_c('div',{staticClass:"my-2"},[_c('v-btn',{on:{"click":function($event){return _vm.clickDel(idx)}}},[_vm._v("削除")])],1)],1)}),0)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.clickAdd}},[_vm._v("事業所追加")])],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.clickSave}},[_vm._v("保存")])],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }