

















































import { Component, Prop, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import UtilMixin from "@/mixins/utilMixin";
import PortalSearchBox from "@/components/portal/PortalSearchBox.vue";
import { PortalSearchParams } from "@/components/portal/PortalSearchBox.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_IS_PUBLIC_COLOR,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_APPROVAL_STATUS_COLOR,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR,
} from "@/consts/portal";
import { PortalOrganization } from "@/model/portal";

@Component({
  components: {
    AppTextField,
    PortalSearchBox,
    AppAuthButton,
  },
})
export default class PortalOrganizationList extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private searchKey = ""; //絞り込み文字列
  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_IS_PUBLIC_COLOR = PORTAL_OFFICE_IS_PUBLIC_COLOR;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_APPROVAL_STATUS_COLOR =
    PORTAL_OFFICE_APPROVAL_STATUS_COLOR;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR;

  /** 所属情報 */
  @Prop({ default: () => [] })
  private items: PortalOrganization[] = [];

  private headers = [
    { text: "所属名", value: "name" },
    { text: "承認状態", value: "approval_status" },
    { text: "医療機関コード", value: "medical_institution_code" },
    { text: "介護保険事業所番号", value: "office_code" },
    { text: "詳細", value: "action" },
  ];

  //データ取得
  private fetchList(searchObj: PortalSearchParams): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_organizations/get",
      searchObj,
      (res) => {
        if (!res.data.organizations) {
          return;
        }
        this.items = res.data.organizations;
      }
    );
  }

  /** 詳細ボタンクリック時 */
  private goDetail(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/organization/detail/${id}` });
  }
}
