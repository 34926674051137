





































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { OfficeStaff } from "@/model/staff";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OfficeStaffSearchBox from "@/components/admin_common/OfficeStaffSearchBox.vue";
import { OfficeStaffSearchObj } from "@/types";

@Component({
  components: {
    OfficeStaffSearchBox,
    AppAuthButton,
  },
})
export default class Spoofing extends Mixins(AxiosMixin) {
  /** 検索条件 */
  private searchObj: OfficeStaffSearchObj = {
    co_code: "",
    kst_no: "",
    keyword: "",
    staff_keyword: "",
    co_name: "",
    office_name: "",
  };

  private searchKey = ""; //絞り込み文字列

  private staffs: OfficeStaff[] = [];

  private headers = [
    { text: "選択", value: "action", width: "100px" },
    { text: "iBowID", value: "ewell_user.user_id", width: "120px" },
    { text: "氏名", value: "info.ibow_nickname", width: "160px" },
    { text: "権限", value: "info.auth_name", width: "160px" },
    { text: "所属企業", value: "agreement_name", width: "200px" },
    { text: "所属事業所", value: "office.name" },
  ];

  //データ取得
  getEwellUsers() {
    this.postJsonCheck(
      window.base_url + "/api/admin/staffs/get",
      { ...this.searchObj },
      (res) => {
        this.staffs = res.data.staffs;
      }
    );
  }

  //代理ログイン
  private loginAgentIbow(staff: OfficeStaff) {
    this.postJsonCheck(
      window.base_url + "/api/admin/ibow/agent-login",
      {
        staff_id: staff.id,
      },
      (res) => {
        window.open(
          window.ibow_url + "?token=" + res.data.onetime_token,
          "_blank"
        );
      }
    );
  }
}
