






































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import AppToolTip from "#/components/AppToolTip.vue";
import AppDataTable from "#/components/AppDataTable.vue";

import { DataTableHeader } from "vuetify/types/index";
import { TargetOfficeChoice } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import { SELECT_FILTER } from "#/const";
import {
  ColumnKey,
  HeaderColumn,
  IsFilterMatch,
} from "#/components/selectFilter/filter";

@Component({
  components: {
    AppToolTip,
    SelectFilter,
    AppDataTable,
  },
})
export default class SendTargetList extends Mixins(UtilMixin) {
  /** 送信先事業所情報 */
  @Prop({ default: () => [] }) private value!: TargetOfficeChoice[];

  /** 事業所情報 */
  @Prop({ default: () => [] }) private offices!: TargetOfficeChoice[];

  @Ref("selectFilter") private readonly selectFilter!: SelectFilter;

  /** 絞込み用キーワード */
  private searchKey = "";

  private get innerValue(): TargetOfficeChoice[] {
    return this.value.slice();
  }

  private set innerValue(newValue: TargetOfficeChoice[]) {
    this.input(newValue);
  }

  /** 選択可能事業所情報 */
  private get srcOffices(): TargetOfficeChoice[] {
    if (!this.innerValue.length) {
      return this.offices;
    }

    return this.offices.filter((office: TargetOfficeChoice) => {
      let match = false;
      this.innerValue.forEach((data: TargetOfficeChoice) => {
        if (office.id == data.id) {
          match = true;
        }
      });
      return !match;
    });
  }

  /** 送信先事業所情報 */
  private get distOffices(): TargetOfficeChoice[] {
    return this.innerValue.slice();
  }

  /** 選択可能事業所の中でチェックが入った事業所情報 */
  private selectedSrcOffices: TargetOfficeChoice[] = [];

  /** 送信先事業所の中でチェックが入った事業所情報 */
  private selectedDistOffices: TargetOfficeChoice[] = [];

  /** 選択可能事業所 -> 送信先事業所への移動 */
  private toDistOffices() {
    // 1つもチェックが入っていないままボタンが押された場合は何もしない
    if (!this.selectedSrcOffices.length) {
      return;
    }

    // 送信先事業所情報に追加
    this.innerValue = [...this.innerValue, ...this.selectedSrcOffices];

    // チェック状態を初期化
    this.selectedSrcOffices = [];
  }

  /** 送信先事業所 -> 選択可能事業所への移動 */
  private toSrcOffices() {
    // 1つもチェックが入っていないままボタンが押された場合は何もしない
    if (!this.selectedDistOffices.length) {
      return;
    }

    // 送信先事業所情報から削除
    this.innerValue = this.innerValue.filter((office: TargetOfficeChoice) => {
      let match = false;
      this.selectedDistOffices.forEach((data: TargetOfficeChoice) => {
        if (office.id == data.id) {
          match = true;
        }
      });
      return !match;
    });

    // チェック状態を初期化
    this.selectedDistOffices = [];
  }

  /** v-data-table用送る事業所ヘッダー情報 */
  private nonFilterHeaders: DataTableHeader[] = [
    {
      text: "KST番号",
      value: "kst_no",
    },
    {
      text: "事業所名",
      value: "office_name",
    },
  ];

  /** ヘッダー情報 */
  private get headers(): HeaderColumn[] {
    return [
      {
        text: "KST番号",
        value: "kst_no",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("kst_no", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "kst_no",
          text: "KST番号",
          selectable: [],
        },
      },
      {
        text: "事業所名",
        value: "office_name",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("office_name", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "office_name",
          text: "事業所名",
          selectable: [],
        },
      },
      {
        text: "iBowレセプト",
        value: "ibow_receipt_div",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("ibow_receipt_div", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "ibow_receipt_div",
          text: "iBowレセプト",
          selectable: ["使用", "未使用"],
        },
      },
      {
        text: "iBowKintai",
        value: "is_attendance_use",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("is_attendance_use", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "is_attendance_use",
          text: "iBowKintai",
          selectable: ["連携", "未連携", "連携解除"],
        },
      },
      {
        text: "iBowオンライン初期操作研修",
        value: "use_ojt",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("use_ojt", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "use_ojt",
          text: "iBowオンライン初期操作研修",
          selectable: ["使用", "未使用"],
        },
      },
      {
        text: "レセプト代行",
        value: "receipt_agency_div",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("receipt_agency_div", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "receipt_agency_div",
          text: "レセプト代行",
          selectable: ["使用", "未使用"],
        },
      },
      {
        text: "iBow治験",
        value: "is_chiken_use",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("is_chiken_use", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "is_chiken_use",
          text: "iBow治験",
          selectable: ["連携", "未連携", "連携解除"],
        },
      },
    ];
  }

  /**
   * SelectFilterの選択肢
   * @return ColumnKey
   */
  private get columnKeys(): ColumnKey[] {
    const keys = this.headers
      .filter((headerColumn: HeaderColumn) => headerColumn.filter != null)
      .map((headerColumn: HeaderColumn) => headerColumn.columnKey);

    if (keys.length == 0) {
      return [] as ColumnKey[];
    }

    return keys as ColumnKey[];
  }

  /** カラムフィルター */
  private columnFilter(key: string, val: unknown) {
    const bret = IsFilterMatch(
      this.selectFilter.filterClauses,
      key,
      val as string | number
    );
    return bret;
  }

  @Emit()
  private input(newOffices: TargetOfficeChoice[]): TargetOfficeChoice[] {
    return newOffices;
  }
}
