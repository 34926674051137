

































import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DataTableHeader } from "vuetify/types/index";
import AmchartLineGraph from "@/components/configuration/statistics/AmchartLineGraph.vue";
import {
  LineGraphData,
  LineData,
  CountData,
  DEFAULT_LINE_GRAPH_DATA,
  SearchObj,
} from "@/components/configuration/statistics/types";

/** 記録書II枚数グラフ情報 */
interface ReceiptVisitRecordCountGraph extends LineGraphData {
  /** 記録書II枚数情報 */
  lines: ReceiptVisitRecordCount[];
}

/** 1事業所が持つ記録書II枚数情報 */
interface ReceiptVisitRecordCount extends LineData {
  /** 凡例名 */
  title: string;
  /** 事業所ID */
  office_id: number;
  /** 事業所名 */
  office_name: string;
  /** 集計情報 */
  count_data: ReceiptVisitRecordCountData[];
}

/** 記録書II枚数集計情報 */
interface ReceiptVisitRecordCountData extends CountData {
  /** 枚数 */
  count: number;
  /** yearmonth */
  yearmonth: number;
  /** yearmonth -> dateStr */
  date: string;
}

@Component({
  components: {
    AmchartLineGraph,
  },
})
export default class ReceiptVisitRecordCountViewer extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 検索条件 */
  @Prop() searchObj!: SearchObj;

  /** amchart操作用 */
  @Ref("amchart") private readonly amchart!: AmchartLineGraph;

  /** グラフデータ */
  private graphData: ReceiptVisitRecordCountGraph = DEFAULT_LINE_GRAPH_DATA;

  /** テーブルヘッダー情報 */
  private headers: DataTableHeader[] = [];

  /** テーブルデータ情報 */
  private items: unknown[] = [];

  /** Excel出力用情報 */
  private excelData: unknown[] = [];

  /** 初回時、検索ボタンクリック時に再描画させる */
  @Watch("searchObj")
  onChangeSearchObj() {
    this.fetchReceiptVisitRecordCountData();
  }
  /** 初回時、検索ボタンクリック時に再描画させる */
  created() {
    this.fetchReceiptVisitRecordCountData();
  }

  /** 与えられた検索条件を元に確定済みレセプト訪問記録の提出枚数を取得 */
  private async fetchReceiptVisitRecordCountData() {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url +
          "/api/admin/configuration/get_receipt_visit_record_count",
        { ...this.searchObj },
        (res) => {
          this.graphData = {
            title: "記録書II枚数",
            unit: "枚",
            lines: [],
          };

          this.graphData.lines = res.data.receipt_visit_record_counts.map(
            (data: ReceiptVisitRecordCount) => {
              // yearmonthで並び替えた後、変換
              const countData = data.count_data
                .sort(function (a, b) {
                  if (a.yearmonth < b.yearmonth) return -1;
                  if (a.yearmonth > b.yearmonth) return 1;
                  return 0;
                })
                .map((count) => {
                  return {
                    count: count.count,
                    yearmonth: count.yearmonth,
                    date: this.convYearmonth2DateStr(count.yearmonth),
                  };
                });
              return {
                office_id: data.office_id,
                office_name: data.office_name,
                title: data.office_name,
                count_data: countData,
              };
            }
          );

          this.createHeaders();
          this.createItems();
          this.createExcelData();

          resolve(0);
        }
      );
    });
  }

  // Excel形式でエクスポート
  private exportData() {
    this.amchart.exportData();
  }

  /** data-table用ヘッダー生成 */
  private createHeaders() {
    // startとendからヘッダー情報を生成
    const startDt = this.newDate(
      this.convYearmonth2DateStr(this.searchObj.start_yearmonth) + "-01"
    );
    const endDt = this.newDate(
      this.convYearmonth2DateStr(this.searchObj.end_yearmonth) + "-01"
    );

    const resHeaders: DataTableHeader[] = [];
    resHeaders.push({
      text: "事業所",
      value: "office",
      align: "center",
      width: "100px",
      divider: true,
    });

    const current = startDt;
    let index = 0;
    while (current <= endDt) {
      resHeaders.push({
        text: this.dateToStr(current, "yyyy年MM月"),
        value: `yearmonth${index}`,
        align: "center",
        width: "80px",
        divider: true,
      });
      current.setMonth(current.getMonth() + 1);
      index++;
    }
    this.headers = resHeaders;
  }

  /** data-table用データ作成 */
  private createItems() {
    this.items = this.graphData.lines.map((data: ReceiptVisitRecordCount) => {
      const item: { [index: string]: unknown } = {
        office: data.office_name,
      };
      data.count_data.forEach((count, index) => {
        item[`yearmonth${index}`] = count.count;
      });
      return item;
    });
  }

  /** Excel出力用データ作成 */
  private createExcelData() {
    this.excelData = this.graphData.lines.map(
      (data: ReceiptVisitRecordCount) => {
        const item: { [index: string]: unknown } = {
          事業所: data.office_name,
        };
        data.count_data.forEach((count) => {
          item[this.dateToStr(this.newDate(count.date + "-01"), "yyyy年MM月")] =
            count.count;
        });
        return item;
      }
    );
  }

  /** yearmonth:number -> dateStr:string */
  private convYearmonth2DateStr(yearmonth: number): string {
    const yearmonthStr = String(yearmonth);
    return `${yearmonthStr.slice(0, 4)}-${yearmonthStr.slice(4, 7)}`;
  }
}
