var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.isEditMode ? "ユーザ編集" : "ユーザ新規作成"))]),_c('v-card-text',[_c('v-form',{ref:"user_form"},[_c('v-row',[_c('v-col',[_c('AppTextField',{staticClass:"mb-4",attrs:{"type":"text","label":"ユーザーID","width":"100%","rules":[
                    _vm.required(_vm.edit_user.user_id, '必須項目です。'),
                    _vm.minLength(
                      _vm.edit_user.user_id,
                      200,
                      'IDは200文字以内で入力してください。'
                    ),
                    _vm.halfWidthAlphNumeric(
                      _vm.edit_user.user_id,
                      'IDは半角英数字で入力してください。'
                    ) ]},model:{value:(_vm.edit_user.user_id),callback:function ($$v) {_vm.$set(_vm.edit_user, "user_id", $$v)},expression:"edit_user.user_id"}})],1),_c('v-col',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"append","disabled":_vm.edit_user.user_id == ''},on:{"click":_vm.checkDuplicated}},[_vm._v("重複チェック")])],1)],1),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"メールアドレス","width":"100%","rules":[
                _vm.required(_vm.edit_user.mail_address, '必須項目です。'),
                _vm.minLength(
                  _vm.edit_user.mail_address,
                  200,
                  '200文字以内で入力してください。'
                ) ]},model:{value:(_vm.edit_user.mail_address),callback:function ($$v) {_vm.$set(_vm.edit_user, "mail_address", $$v)},expression:"edit_user.mail_address"}}),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"姓","width":"100%","rules":[
                _vm.required(_vm.edit_user.last_name, '必須項目です。'),
                _vm.minLength(
                  _vm.edit_user.last_name,
                  200,
                  '200文字以内で入力してください。'
                ) ]},model:{value:(_vm.edit_user.last_name),callback:function ($$v) {_vm.$set(_vm.edit_user, "last_name", $$v)},expression:"edit_user.last_name"}}),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"名","width":"100%","rules":[
                _vm.required(_vm.edit_user.first_name, '必須項目です。'),
                _vm.minLength(
                  _vm.edit_user.first_name,
                  200,
                  '200文字以内で入力してください。'
                ) ]},model:{value:(_vm.edit_user.first_name),callback:function ($$v) {_vm.$set(_vm.edit_user, "first_name", $$v)},expression:"edit_user.first_name"}}),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"部門","width":"100%","rules":[
                _vm.minLength(
                  _vm.edit_user.department,
                  200,
                  '200文字以内で入力してください。'
                ) ]},model:{value:(_vm.edit_user.department),callback:function ($$v) {_vm.$set(_vm.edit_user, "department", $$v)},expression:"edit_user.department"}}),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"役職","width":"100%","rules":[
                _vm.minLength(
                  _vm.edit_user.position,
                  200,
                  '200文字以内で入力してください。'
                ) ]},model:{value:(_vm.edit_user.position),callback:function ($$v) {_vm.$set(_vm.edit_user, "position", $$v)},expression:"edit_user.position"}}),_c('AppSelect',{staticClass:"mb-4",attrs:{"label":"権限","items":_vm.adminAuthChoices,"width":"100%","rules":[_vm.minNumber(_vm.edit_user.auth_id, 1, '必須項目です。')]},model:{value:(_vm.edit_user.auth_id),callback:function ($$v) {_vm.$set(_vm.edit_user, "auth_id", $$v)},expression:"edit_user.auth_id"}}),_c('v-divider',{staticClass:"mb-4"}),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"パスワード","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"width":"100%","autocomplete":"new-password","rules":[
                _vm.passwordRequired(_vm.password, '必須項目です。'),
                _vm.minLength(_vm.password, 200, '200文字以内で入力してください。'),
                _vm.checkPassword(
                  _vm.password,
                  '半角英数字・記号で入力してください。'
                ) ]},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"パスワード再入力","append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"autocomplete":"off","width":"100%","rules":[
                _vm.passwordRequired(_vm.confirm_password, '必須項目です。'),
                _vm.minLength(
                  _vm.confirm_password,
                  200,
                  '200文字以内で入力してください。'
                ),
                _vm.checkPassword(
                  _vm.confirm_password,
                  '半角英数字・記号で入力してください。'
                ),
                _vm.checkSamePassword() ]},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})],1)],1),_c('v-card-actions',[(_vm.removable)?_c('v-btn',{attrs:{"color":"alert","dark":""},on:{"click":_vm.deleteAdminUser}},[_vm._v("削除")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/user/list"}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.saveAdminUser.apply(null, arguments)}}},[_vm._v("保存")])],1)],1)],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }