

































import { Component, Mixins, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class PortalRequestUserSearchBox extends Mixins(UtilMixin) {
  /** 検索条件 */
  private request_user_name = "";
  private keyword = "";
  private request_user_system_id = "";
  private portal_organization_name = "";
  private portal_office_code = "";

  /** 検索クリック時 */
  @Emit("search")
  search() {
    return {
      request_user_name: this.request_user_name,
      keyword: this.keyword,
      request_user_system_id: this.request_user_system_id,
      portal_organization_name: this.portal_organization_name,
      portal_office_code: this.portal_office_code,
    };
  }
}
