






































































































// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@types/googlemaps" />
/*global google*/
import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { PREFECTURES } from "../const/prefectures";
import AppButton from "./AppButton.vue";
import AppSelect from "./AppSelect.vue";
import AppTextField from "./AppTextField.vue";
import NumberInputString from "./NumberInputString.vue";
import RulesMixin from "../mixins/rulesMixin";

@Component({
  components: {
    AppButton,
    AppSelect,
    AppTextField,
    NumberInputString
  }
})
export default class AppPlaces extends Mixins(RulesMixin) {
  private prefectures = [{ text: "未選択", value: 0 }].concat(PREFECTURES);
  @Prop() private readonly postal_code!: string; //郵便番号
  @Prop() private readonly prefecture!: number; //都道府県
  @Prop() private readonly cityAddress!: string; //市区町村
  @Prop() private readonly houseNumber!: string; //番地
  @Prop() private readonly building!: string; //建物
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: false }) private readonly requiredPostCode!: boolean;
  @Prop({ default: false }) private readonly requiredPrefecture!: boolean;
  @Prop({ default: false }) private readonly requiredCityAddress!: boolean;
  @Prop({ default: false }) private readonly requiredHouseNumber!: boolean;
  @Prop({ default: false }) private readonly requiredBuilding!: boolean;
  @Prop({ default: false }) private isReadOnly!: boolean;
  @Prop({ default: false }) private disabled!: boolean;

  private address_loading = false;
  private postcode_loading = false;

  @Emit("update:postal_code")
  updatePostalCode(newValue: string) {
    return newValue;
  }

  @Emit("update:prefecture")
  updatePrefecture(newValue: number) {
    return newValue;
  }

  @Emit("update:cityAddress")
  updateCityAddress(newValue: string) {
    return newValue;
  }

  @Emit("update:houseNumber")
  updateHouseNumber(newValue: string) {
    return newValue;
  }

  @Emit("update:building")
  updateBuilding(newValue: string) {
    return newValue;
  }

  get PostalCode() {
    return this.postal_code;
  }

  set PostalCode(newValue: string) {
    this.updatePostalCode(newValue);
  }

  get Prefecture() {
    return this.prefecture;
  }

  set Prefecture(newValue: number) {
    this.updatePrefecture(newValue);
  }

  get CityAddress() {
    return this.cityAddress;
  }

  set CityAddress(newValue: string) {
    this.updateCityAddress(newValue);
  }

  get HouseNumber() {
    return this.houseNumber;
  }

  set HouseNumber(newValue: string) {
    this.updateHouseNumber(newValue);
  }

  get Building() {
    return this.building;
  }

  set Building(newValue: string) {
    this.updateBuilding(newValue);
  }

  private async fromPostalCode() {
    if (this.postal_code.toString().length !== 7) {
      await this.$openAlert("郵便番号を7桁入力してください。");
      return;
    }
    this.address_loading = true;
    this.getLocation(this.postal_code.toString(), address => {
      const administrativeArea2 =
        this.getMapData(address, "administrative_area_level_2") || "";
      const locality = this.getMapData(address, "locality") || "";
      const sublocality1 =
        this.getMapData(address, "sublocality_level_1") || "";
      const sublocality2 =
        this.getMapData(address, "sublocality_level_2") || "";

      this.CityAddress =
        administrativeArea2 + locality + sublocality1 + sublocality2;

      const prefecture = this.getMapData(
        address,
        "administrative_area_level_1"
      );
      if (prefecture) {
        const prefecture_no = this.prefectures.find(p => {
          return p.text === prefecture;
        });
        if (prefecture_no) {
          this.Prefecture = prefecture_no.value;
        }
      }
    });
  }

  private fromPrefCity() {
    const selectedPref = this.prefectures.find(
      pref => pref.value === this.Prefecture
    );
    const prefText = selectedPref ? selectedPref.text : "";
    this.postcode_loading = true;
    this.getLocation(prefText + this.cityAddress, async address => {
      const postal_code = this.getMapData(address, "postal_code");
      if (postal_code) {
        this.PostalCode = postal_code.replace("-", "");
      } else {
        await this.$openAlert("郵便番号が見つかりませんでした。");
      }
    });
  }

  private getMapData(
    address: google.maps.GeocoderAddressComponent[],
    key: string
  ): string | undefined {
    const findAddress = address.find(ad => {
      for (const type of ad.types) {
        if (type === key) {
          return true;
        }
      }
      return false;
    });
    if (findAddress) {
      return findAddress.long_name;
    }
    return undefined;
  }

  private getLocation(
    param: string,
    callback: (address: google.maps.GeocoderAddressComponent[]) => void
  ) {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: param,
        region: "jp"
      },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        this.address_loading = false;
        this.postcode_loading = false;
        if (status === google.maps.GeocoderStatus.OK) {
          callback(results[0].address_components);
        }
      }
    );
  }
}
