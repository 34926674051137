





































import { Component, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import SearchBase from "@/components/admin_common/SearchBase.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import { Agreement } from "@/model/agreement";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppTextField,
    SearchBase,
    AppAuthButton,
  },
})
export default class Login extends Mixins(AxiosMixin) {
  private searchKey = ""; //絞り込み文字列

  private agreements: Agreement[] = [];

  private headers = [
    { text: "選択", value: "action" },
    { text: "企業コード", value: "co_code" },
    { text: "企業名", value: "co_name" },
    { text: "KST番号", value: "kst_nos" },
    { text: "事業所名", value: "office_names" },
  ];

  //データ取得
  private fetchAdminUsers(searchObj: unknown): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      searchObj,
      (res) => {
        if (!res.data.agreements) {
          return;
        }

        // 事業所削除や未稼働なために事業所が存在しない企業は除去
        this.agreements = res.data.agreements.filter((data: Agreement) => {
          return data.offices && data.offices.length > 0;
        });
      }
    );
  }

  //管理者としてログイン
  private loginIbow(agreement: Agreement) {
    this.postJsonCheck(
      window.base_url + "/api/admin/ibow/admin-login",
      {
        agreement_id: agreement.id,
      },
      (res) => {
        window.open(
          window.ibow_url + "?token=" + res.data.onetime_token,
          "_blank"
        );
      }
    );
  }
}
