












































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DatasetGroup } from "@/views/aivnplan/types";
import { Dataset, DefaultDataset } from "#/model/admin/dataset";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

@Component
/**
 * データセット一覧
 */
export default class DatasetForm extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** Ref */

  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** データセット */
  private dataset: Dataset = DefaultDataset();

  /** データセットグループ・項目 */
  private datasetGroups: DatasetGroup[] = [];

  /** 関数 */

  created() {
    this.fetch();
  }

  /** 取得 */
  private fetch() {
    const datasetId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/dataset/get",
      { id: datasetId },
      async (res) => {
        this.dataset = res.data.dataset;
        this.datasetGroups = res.data.groups;
      }
    );
  }

  /** 保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/dataset/save",
      { dataset: this.dataset, groups: this.datasetGroups },
      async () => {
        this.$router.push({ path: "/ai-vnplan/dataset/list" });
      }
    );
  }

  /** 削除 */
  private async deleteDataset() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/dataset/delete",
      { dataset: this.dataset },
      async () => {
        this.$router.push({ path: "/ai-vnplan/dataset/list" });
      }
    );
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** データセットバリデーションチェック */
  private checkDatasetGroup(): boolean | string {
    const checkedIndex = this.datasetGroups.findIndex(
      (datasetGroup: DatasetGroup) => {
        return (
          datasetGroup.items.findIndex(
            (datasetItem) => datasetItem.is_checked === true
          ) !== -1
        );
      }
    );

    if (checkedIndex === -1) {
      return "最低1つはチェックを入れてください。";
    }

    return true;
  }
}
