import { Choice } from "@/types";

interface SelectableChoice extends Choice {
  /** 選択肢として使用できるかフラグ */
  selectable: boolean;
}

/** 直近の問い合わせ表示件数 */
export const RECENT_INQUIRY_LIMIT = 3;

/** 未対応 */
export const INQUIRY_UNSUPPORTED = 1;
/** 連絡とれず */
export const INQUIRY_INCOMMUNICABLE = 2;
/** 進行中 */
export const INQUIRY_INPROGRESS = 3;
/** 対応完了 */
export const INQUIRY_COMPLETED = 9;

/** 回答(対応中) */
export const ANSWER_SUPPORTING = 1;
/** 回答(対応完了) */
export const ANSWER_COMPLETED = 4;
/** 回答(対応完了（未解決）) */
export const ANSWER_COMPLETED_UNSOLVED = 8;
/** 回答(要連絡) */
export const ANSWER_NEED = 10;
/** 回答(連絡待ち) */
export const ANSWER_WAIT = 11;

/** 回答(アサイン) */
export const ANSWER_ASSIGN = 9;

/** テンプレートの状態 */
export const TEMPLATE_DRAFT = 1;
export const TEMPLATE_PRIVATE = 2;
export const TEMPLATE_PUBLIC = 3;

export const INQUIRY_STATUS: SelectableChoice[] = [
  { text: "未対応", value: 1, selectable: true },
  { text: "連絡とれず", value: 2, selectable: true },
  { text: "進行中", value: 3, selectable: true },
  { text: "対応完了", value: 9, selectable: false },
];

export const INQUIRY_STATUS_COLOR: Choice[] = [
  { text: "orange darken-1", value: 1 },
  { text: "green darken-1", value: 2 },
  { text: "light-blue darken-1", value: 3 },
  { text: "blue-grey lighten-2", value: 9 },
];

/** 問い合わせ方法\n1:電話\n2:iBowフォーム\n3:営業経由\n4:その他 */
export const INQUIRY_DIV: Choice[] = [
  { text: "電話", value: 1 },
  { text: "iBowフォーム", value: 2 },
  { text: "営業経由", value: 3 },
  { text: "その他", value: 4 },
];

/** 登録状況の確認希望 0:未選択 1:希望しない 2:希望する */
export const ACCESS_CONFIRMS: Choice[] = [
  { text: "未選択", value: 0 },
  { text: "希望しない", value: 1 },
  { text: "希望する", value: 2 },
];

export const ANSWER_STATUS: SelectableChoice[] = [
  { text: "アサイン", value: 9, selectable: true },
  { text: "対応中", value: 1, selectable: true },
  { text: "連絡待ち（外）", value: 2, selectable: true },
  { text: "連絡待ち（内）", value: 3, selectable: true },
  { text: "対応完了", value: 4, selectable: true },
  { text: "対応完了（内）", value: 5, selectable: true },
  { text: "対応完了（外）", value: 6, selectable: true },
  { text: "対応完了（要望）", value: 7, selectable: true },
  { text: "対応完了（未解決）", value: 8, selectable: true },
  { text: "要連絡", value: 10, selectable: false },
  { text: "連絡待ち", value: 11, selectable: false },
];

export const LISTED_ANSWER_STATUS: Choice[] = [
  { text: "ア", value: 9 },
  { text: "対", value: 1 },
  { text: "外", value: 2 },
  { text: "内", value: 3 },
  { text: "要", value: 10 },
  { text: "待", value: 11 },
];

export const ANSWER_STATUS_COLOR: Choice[] = [
  { text: "orange darken-1", value: 1 },
  { text: "light-blue darken-1", value: 2 },
  { text: "light-blue darken-1", value: 3 },
  { text: "blue-grey lighten-2", value: 4 },
  { text: "blue-grey lighten-2", value: 5 },
  { text: "blue-grey lighten-2", value: 6 },
  { text: "blue-grey lighten-2", value: 7 },
  { text: "blue-grey lighten-2", value: 8 },
  { text: "red darken-1", value: 9 },
  { text: "light-blue darken-1", value: 10 },
  { text: "light-blue darken-1", value: 11 },
];

export const ANSWER_METHOD: Choice[] = [
  { text: "電話", value: 1 },
  { text: "メール", value: 2 },
  { text: "その他", value: 3 },
  { text: "iBowシステム", value: 4 },
];

/** 希望連絡方法\n0:—\n1:どちらでも\n2:電話\n3:メール\n4:iBowシステム */
export const HOPE_CONTACT_DIV: Choice[] = [
  { text: "どちらでも", value: 1 },
  { text: "電話", value: 2 },
  { text: "メール", value: 3 },
  { text: "iBowシステム", value: 4 },
];

/** テンプレート状態\n1:下書き\n2:完成(未公開)\n3:FAQ公開 */
export const TEMPLATE_STATUS: Choice[] = [
  { text: "下書き", value: 1 },
  { text: "完成(非公開)", value: 2 },
  { text: "FAQ公開", value: 3 },
];

/** テンプレート状態の色わけ */
export const TEMPLATE_STATUS_COLOR: Choice[] = [
  { text: "grey", value: 1 },
  { text: "teal", value: 2 },
  { text: "pink darken-3", value: 3 },
];

/** 公開フラグ\n0:非公開\n1:公開 */
export const INQUIRY_PUBLISHED: Choice[] = [
  { text: "非公開", value: 0 },
  { text: "公開", value: 1 },
];

/** CSアンケート表示フラグ 0:非表示 1:表示 */
export const INQUIRY_QUESTIONNAIRE: Choice[] = [
  { text: "非表示", value: 0 },
  { text: "表示", value: 1 },
];

/** 公開状態の色分け */
export const INQUIRY_PUBLISHED_COLOR: Choice[] = [
  { text: "grey", value: 0 },
  { text: "teal", value: 1 },
];

/** バージョン\n0:v2\n1:v1 */
export const INQUIRY_VERSION: Choice[] = [
  { text: "v2", value: 0 },
  { text: "v1", value: 1 },
];

export const INQUIRY_VERSION_COLOR: Choice[] = [
  { text: "#009199", value: 0 },
  { text: "#d84b5f", value: 1 },
];
