












import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import CategoryList from "@/components/serviceLink/CategoryList.vue";
import { ServiceLinkCategory } from "@/model/serviceLink";

@Component({
  components: {
    CategoryList,
  },
})
export default class extends Mixins(AxiosMixin, UtilMixin) {
  private categories: ServiceLinkCategory[] = [];

  mounted() {
    this.fetch();
  }

  private fetch() {
    /**
     * カテゴリ一覧取得
     */
    this.postJsonCheck(
      window.base_url + "/api/service_link/categories/get",
      {},
      (res) => {
        this.categories = res.data.service_link_categories;
      }
    );
  }

  private cancel() {
    this.$router.go(-1);
  }

  private saveCategories() {
    this.postJsonCheck(
      window.base_url + "/api/service_link/categories/save",
      {
        service_link_categories: this.categories,
      },
      () => {
        this.fetch();
      }
    );
  }
}
