








































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import AppToolTip from "#/components/AppToolTip.vue";
import AppDataTable from "#/components/AppDataTable.vue";

import { DataTableHeader } from "vuetify/types/index";
import { PortalOrganization } from "@/model/portal";
import UtilMixin from "@/mixins/utilMixin";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import { SELECT_FILTER } from "#/const";
import {
  ColumnKey,
  HeaderColumn,
  IsFilterMatch,
} from "#/components/selectFilter/filter";

@Component({
  components: {
    AppToolTip,
    SelectFilter,
    AppDataTable,
  },
})
export default class SendTargetList extends Mixins(UtilMixin) {
  /** 送信先事業所情報 */
  @Prop({ default: () => [] }) private value!: PortalOrganization[];

  /** フォーム非活性 */
  @Prop({ default: false }) private disabled!: boolean;

  /** 事業所情報 */
  @Prop({ default: () => [] })
  private organizations!: PortalOrganization[];

  @Ref("selectFilter") private readonly selectFilter!: SelectFilter;

  /** 絞込み用キーワード */
  private searchKey = "";

  private get innerValue(): PortalOrganization[] {
    return this.value.slice();
  }

  private set innerValue(newValue: PortalOrganization[]) {
    this.input(newValue);
  }

  /** 選択可能事業所情報 */
  private get srcOrganizations(): PortalOrganization[] {
    if (!this.innerValue.length) {
      return this.organizations;
    }

    return this.organizations.filter((organization: PortalOrganization) => {
      let match = false;
      this.innerValue.forEach((data: PortalOrganization) => {
        if (organization.id == data.id) {
          match = true;
        }
      });
      return !match;
    });
  }

  /** 送信先事業所情報 */
  private get distOrganizations(): PortalOrganization[] {
    return this.innerValue.slice();
  }

  /** 選択可能事業所の中でチェックが入った事業所情報 */
  private selectedSrcOrganizations: PortalOrganization[] = [];

  /** 送信先事業所の中でチェックが入った事業所情報 */
  private selectedDistOrganizations: PortalOrganization[] = [];

  /** 選択可能事業所 -> 送信先事業所への移動 */
  private toDistOrganizations() {
    // 1つもチェックが入っていないままボタンが押された場合は何もしない
    if (!this.selectedSrcOrganizations.length) {
      return;
    }

    // 送信先事業所情報に追加
    this.innerValue = [...this.innerValue, ...this.selectedSrcOrganizations];

    // チェック状態を初期化
    this.selectedSrcOrganizations = [];
  }

  /** 送信先事業所 -> 選択可能事業所への移動 */
  private toSrcOrganizations() {
    // 1つもチェックが入っていないままボタンが押された場合は何もしない
    if (!this.selectedDistOrganizations.length) {
      return;
    }

    // 送信先事業所情報から削除
    this.innerValue = this.innerValue.filter(
      (organization: PortalOrganization) => {
        let match = false;
        this.selectedDistOrganizations.forEach((data: PortalOrganization) => {
          if (organization.id == data.id) {
            match = true;
          }
        });
        return !match;
      }
    );

    // チェック状態を初期化
    this.selectedDistOrganizations = [];
  }

  /** v-data-table用送る事業所ヘッダー情報 */
  private nonFilterHeaders: DataTableHeader[] = [
    {
      text: "介護事業所番号",
      value: "office_code",
    },
    {
      text: "医療機関コード",
      value: "medical_institution_code",
    },
    {
      text: "事業所名",
      value: "name",
    },
  ];

  /** ヘッダー情報 */
  private get headers(): HeaderColumn[] {
    return [
      {
        text: "介護事業所番号",
        value: "office_code",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("office_code", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "office_code",
          text: "介護事業所番号",
          selectable: [],
        },
      },
      {
        text: "医療機関コード",
        value: "medical_institution_code",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) =>
          this.columnFilter("medical_institution_code", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "medical_institution_code",
          text: "医療機関コード",
          selectable: [],
        },
      },
      {
        text: "事業所名",
        value: "name",
        sortable: true,
        align: "center",
        width: "100px",
        filter: (val: object) => this.columnFilter("name", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "name",
          text: "事業所名",
          selectable: [],
        },
      },
    ];
  }

  /**
   * SelectFilterの選択肢
   * @return ColumnKey
   */
  private get columnKeys(): ColumnKey[] {
    const keys = this.headers
      .filter((headerColumn: HeaderColumn) => headerColumn.filter != null)
      .map((headerColumn: HeaderColumn) => headerColumn.columnKey);

    if (keys.length == 0) {
      return [] as ColumnKey[];
    }

    return keys as ColumnKey[];
  }

  /** カラムフィルター */
  private columnFilter(key: string, val: unknown) {
    const bret = IsFilterMatch(
      this.selectFilter.filterClauses,
      key,
      val as string | number
    );
    return bret;
  }

  @Emit()
  private input(newOrganizations: PortalOrganization[]): PortalOrganization[] {
    return newOrganizations;
  }
}
