


























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import { FileIF, DEFAULT_FILEIF } from "@/views/demosite/types";

@Component({
  components: { FileUpload },
})
/**
 * デモサイト一括作成 初期画面
 */
export default class InputDemositeStart extends Mixins(UtilMixin) {
  /** 選択中ファイル情報 */
  @Prop({ default: { ...DEFAULT_FILEIF } }) inputFile!: FileIF;

  /** ファイルアップロード用API */
  private uploadApiUrl = `${window.base_url}/api/upload`;

  /** アップロードファイル保存先パス */
  private get ImagePath(): string {
    return "demosite/excel";
  }

  /** 一括作成ボタンクリック時 */
  private async onClickInput() {
    if (this.inputFile.path == "") {
      await this.$openAlert("Excelファイルを指定してください。");
      return;
    }

    this.input();
  }

  /** 一括作成ボタンクリックイベント通知 */
  @Emit("click:input")
  input() {
    return;
  }
}
