import {
  Agreement,
  DEFAULT_AGREEMENT,
  AgreementHistory,
  ReceiptActingMail,
  ReceiptLink,
  ReceiptLinkSetting,
} from "@/model/agreement";

import { Office } from "@/model/office";
export interface AgreementModel extends AgreementHistory {
  pref_disp: string; //都道府県_表示用
  receipt_exclusion_div_disp: string; //レセプト排他設定
  u_start_dt_disp: string; //開始日
  u_end_dt_disp: string; //終了日
  receipt_agency_div_disp: string; //レセプト代行使用フラグ\n0:未使用\n1:使用
  creatable_offices: number; //希望事業所数
}

export interface Search {
  confflg: number; //0:指定無し,1:未確定のみ,2:確定のみ
  search_keyword: string; //企業コード・企業名・事業所名・備考欄
  pref: number; //都道府県
  tel_no: string; //電話
  u_start_dt_start_day: string; //契約日-開始日
  u_start_dt_end_day: string; //契約日-終了日
  u_end_dt_start_day: string; //契約終了日-開始日
  u_end_dt_end_day: string; //契約終了日-終了日
  office_code: string; //事業所コード
  group_office_code: string; //グループ事業所コード
  billing_name: string; //請求先名
  is_send_receipt_system: number; //請求管理システムへ送信
  use_receipt_type: number; //利用レセプト
  agreement_plan_type: number; //契約プラン
  account_notice_date_start_day: string; //アカウント通知日-開始日
  account_notice_date_end_day: string; //アカウント通知日-終了日
  start_date_start_day: string; //契約開始日-開始日
  start_date_end_day: string; //契約開始日-終了日
  end_date_start_day: string; //契約終了日-開始日
  end_date_end_day: string; //契約終了日-終了日
  estimate_no: string; //見積もり番号
  ewell_sales_staff_fullname: string; //eWeLL営業担当者名
  customer_support_mail: string; //お客様担当者メール
}

export interface ReceiptLinkModel extends ReceiptLink {
  link_system_id_disp: string; //連携システム種類名
}

export interface ReceiptLinkSettingModel extends ReceiptLinkSetting {
  setting_id_disp: string; //外部システム連携設定名
}

export { ReceiptActingMail };

export interface DispAgreement extends Agreement {
  /** 請求情報にコメントが付いた事業所を持っているかどうか */
  has_comment: boolean;
  /** 表示用事業所情報 */
  offices: DispOffice[];
}

export const DEFAULT_DISP_AGREEMENT: DispAgreement = {
  ...DEFAULT_AGREEMENT,
  has_comment: false,
};

export interface DispOffice extends Office {
  /** 現在のステップ番号 */
  current_contract_step_number: number;
  /** 全ステップ情報 */
  contract_steps: ContractStep[];
  /** 請求情報にコメントが付いているかどうか */
  has_comment: boolean;
}

export interface ContractStep {
  /** ステップ番号 */
  contract_step_number: number;
  /** ステップ名 */
  step_name: string;
  /** コメントの有無 */
  has_comment: boolean;
  /** 対応するconfflag */
  confflag: number;
}
