


























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { HashtagChoice } from "@/model/inquiry";

@Component({})
export default class HashtagAutoComplete extends Mixins(AxiosMixin, UtilMixin) {
  /** 初期値 */
  @Prop({ default: () => "" }) value!: string;

  /** キーワード選択肢 */
  @Prop({ default: () => [] }) keywords!: HashtagChoice[];

  /** v-model使う用 */
  private get inputHashtag() {
    return this.value;
  }

  /** v-model使う用 */
  private set inputHashtag(inputData: string) {
    this.onInput(inputData);
  }

  @Emit("input")
  private onInput(inputData: string) {
    return inputData;
  }
}
