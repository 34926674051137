





























































import { Component, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { DefaultLlmParameter, LlmParameter } from "#/model/admin/index";

@Component
/**
 * LLMパラメータ設定
 */
export default class LLMParameterSetting extends Mixins(UtilMixin) {
  /** Prop */

  /** 入力値 */
  @Prop({ default: () => DefaultLlmParameter() }) value!: LlmParameter;

  /** 変数 */

  /** temperature選択肢 */
  private temperatures = [
    { text: "0.0", value: "0.0" },
    { text: "0.1", value: "0.1" },
    { text: "0.2", value: "0.2" },
    { text: "0.3", value: "0.3" },
    { text: "0.4", value: "0.4" },
    { text: "0.5", value: "0.5" },
    { text: "0.6", value: "0.6" },
    { text: "0.7", value: "0.7" },
    { text: "0.8", value: "0.8" },
    { text: "0.9", value: "0.9" },
    { text: "1.0", value: "1.0" },
    { text: "1.1", value: "1.1" },
    { text: "1.2", value: "1.2" },
    { text: "1.3", value: "1.3" },
    { text: "1.4", value: "1.4" },
    { text: "1.5", value: "1.5" },
    { text: "1.6", value: "1.6" },
    { text: "1.7", value: "1.7" },
    { text: "1.8", value: "1.8" },
    { text: "1.9", value: "1.9" },
    { text: "2.0", value: "2.0" }
  ];

  /** top_p選択肢 */
  private topPs = [
    { text: "0.0", value: "0.0" },
    { text: "0.1", value: "0.1" },
    { text: "0.2", value: "0.2" },
    { text: "0.3", value: "0.3" },
    { text: "0.4", value: "0.4" },
    { text: "0.5", value: "0.5" },
    { text: "0.6", value: "0.6" },
    { text: "0.7", value: "0.7" },
    { text: "0.8", value: "0.8" },
    { text: "0.9", value: "0.9" },
    { text: "1.0", value: "1.0" }
  ];

  /** frequency_penalty選択肢 */
  private frequencyPenalties = [
    { text: "-2.0", value: "-2.0" },
    { text: "-1.9", value: "-1.9" },
    { text: "-1.8", value: "-1.8" },
    { text: "-1.7", value: "-1.7" },
    { text: "-1.6", value: "-1.6" },
    { text: "-1.5", value: "-1.5" },
    { text: "-1.4", value: "-1.4" },
    { text: "-1.3", value: "-1.3" },
    { text: "-1.2", value: "-1.2" },
    { text: "-1.1", value: "-1.1" },
    { text: "-1.0", value: "-1.0" },
    { text: "-0.9", value: "-0.9" },
    { text: "-0.8", value: "-0.8" },
    { text: "-0.7", value: "-0.7" },
    { text: "-0.6", value: "-0.6" },
    { text: "-0.5", value: "-0.5" },
    { text: "-0.4", value: "-0.4" },
    { text: "-0.3", value: "-0.3" },
    { text: "-0.2", value: "-0.2" },
    { text: "-0.1", value: "-0.1" },
    { text: "0.0", value: "0.0" },
    { text: "0.1", value: "0.1" },
    { text: "0.2", value: "0.2" },
    { text: "0.3", value: "0.3" },
    { text: "0.4", value: "0.4" },
    { text: "0.5", value: "0.5" },
    { text: "0.6", value: "0.6" },
    { text: "0.7", value: "0.7" },
    { text: "0.8", value: "0.8" },
    { text: "0.9", value: "0.9" },
    { text: "1.0", value: "1.0" },
    { text: "1.1", value: "1.1" },
    { text: "1.2", value: "1.2" },
    { text: "1.3", value: "1.3" },
    { text: "1.4", value: "1.4" },
    { text: "1.5", value: "1.5" },
    { text: "1.6", value: "1.6" },
    { text: "1.7", value: "1.7" },
    { text: "1.8", value: "1.8" },
    { text: "1.9", value: "1.9" },
    { text: "2.0", value: "2.0" },
  ];

  /** presence_penalty選択肢 */
  private presencePenalties = [
    { text: "-2.0", value: "-2.0" },
    { text: "-1.9", value: "-1.9" },
    { text: "-1.8", value: "-1.8" },
    { text: "-1.7", value: "-1.7" },
    { text: "-1.6", value: "-1.6" },
    { text: "-1.5", value: "-1.5" },
    { text: "-1.4", value: "-1.4" },
    { text: "-1.3", value: "-1.3" },
    { text: "-1.2", value: "-1.2" },
    { text: "-1.1", value: "-1.1" },
    { text: "-1.0", value: "-1.0" },
    { text: "-0.9", value: "-0.9" },
    { text: "-0.8", value: "-0.8" },
    { text: "-0.7", value: "-0.7" },
    { text: "-0.6", value: "-0.6" },
    { text: "-0.5", value: "-0.5" },
    { text: "-0.4", value: "-0.4" },
    { text: "-0.3", value: "-0.3" },
    { text: "-0.2", value: "-0.2" },
    { text: "-0.1", value: "-0.1" },
    { text: "0.0", value: "0.0" },
    { text: "0.1", value: "0.1" },
    { text: "0.2", value: "0.2" },
    { text: "0.3", value: "0.3" },
    { text: "0.4", value: "0.4" },
    { text: "0.5", value: "0.5" },
    { text: "0.6", value: "0.6" },
    { text: "0.7", value: "0.7" },
    { text: "0.8", value: "0.8" },
    { text: "0.9", value: "0.9" },
    { text: "1.0", value: "1.0" },
    { text: "1.1", value: "1.1" },
    { text: "1.2", value: "1.2" },
    { text: "1.3", value: "1.3" },
    { text: "1.4", value: "1.4" },
    { text: "1.5", value: "1.5" },
    { text: "1.6", value: "1.6" },
    { text: "1.7", value: "1.7" },
    { text: "1.8", value: "1.8" },
    { text: "1.9", value: "1.9" },
    { text: "2.0", value: "2.0" },
  ];
}
