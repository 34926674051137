import { Vue, Component } from "vue-property-decorator";
import {
  today,
  thisMonth,
  lastMonth,
  todayIsBeforeTenth,
  lastOrThisMonth,
  jpDate,
  dayjsDate
} from "../utility/appDate";
import dayjs from "dayjs";

@Component
export default class DateMixin extends Vue {
  /** 今日を文字列で返却する YYYY-MM-DD */
  protected today(): string {
    return today();
  }

  /** 今月を文字列で返却する YYYY-MM */
  protected thisMonth(): string {
    return thisMonth();
  }

  /** 先月を文字列で返却する YYYY-MM */
  protected lastmonth(): string {
    return lastMonth();
  }

  /** 今日が01〜10日かどうか */
  protected todayIsBeforeTenth(): boolean {
    return todayIsBeforeTenth();
  }

  /** 今日が01〜10日の場合 前月 それ以外の場合 今月 を取得する */
  protected lastOrThisMonth(): string {
    return lastOrThisMonth();
  }

  /** YYYY-MM-DD(dd)を取得する */
  protected jpDate(date: Date): string {
    return jpDate(date);
  }

  /** Dayjsに変換する */
  protected dayjsDate(
    date?: string | number | dayjs.Dayjs | Date | null | undefined
  ): dayjs.Dayjs {
    return dayjsDate(date);
  }
}
