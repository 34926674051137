











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ContactHistory extends Vue {
  @Prop() private readonly contact_info!: string;

  private panel = 0;

  //HTML用回答内容
  private get htmlContactMessage() {
    return this.contact_info.replace(/\r?\n/g, "<br>");
  }
}
