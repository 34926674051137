var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"960px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("問い合わせ者選択")]),_c('v-card-text',[_c('v-sheet',[_c('v-container',{attrs:{"fluid":""}},[_c('OfficeStaffSearchBox',{attrs:{"searchObj":_vm.searchObj},on:{"search":_vm.search}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-data-table',{staticClass:"mt-4 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.staffs,"no-data-text":"職員が存在しません"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.select(item)}}},[_vm._v("選択")])]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [(item.co_code)?_c('div',[_vm._v("["+_vm._s(item.co_code)+"]")]):_vm._e(),_vm._v(" "+_vm._s(item.agreement_name)+" ")]}},{key:"item.office_name",fn:function(ref){
var item = ref.item;
return [(item.office.kst_no)?_c('div',[_vm._v(" ["+_vm._s(item.office.kst_no)+"] ")]):_vm._e(),_vm._v(" "+_vm._s(item.office.name)+" ")]}},{key:"item.staff_keyword",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.info.ibow_nickname)+" ")]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.close()}}},[_vm._v("閉じる")])],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }