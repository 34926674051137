import { Office } from "./../office";
import { ReceiptActingMail } from "./receiptActingMail";
import { ReceiptLink } from "./receiptLink";
import { ReceiptLinkSetting } from "./receiptLinkSetting";
export interface Agreement {
  id: number;
  co_code: string;
  crypto_key: string;
  agreement_historys: AgreementHistory[];
  offices: Office[];

  agreement_condition: string; // 契約状態
  co_name: string; // 企業名
  office_names: string; //事業所名(複数)
  kst_nos: string; //KST番号(複数)
  office_conditions: string; // 事業所状態(複数)
  office_phones: string; // 事業所状態(複数)
  honobono_license: string; // ほのぼのライセンスキー(頭5桁)
  honobono_key: string; // ほのぼのシークレットキー

  /** v1サイトURL */
  old_ibow_url: string;

  /** 記録書II集計情報 */
  rec2_count_data: OfficeRec2Count[];
}

export interface AgreementHistory {
  /** 契約履歴ID */ id: number;
  /** 契約者名 */ co_name: string;
  /** 契約コード */ co_code: string;
  /** 郵便番号 */ post_code: string;
  /** 都道府県 */ pref: number;
  /** 市区町村 */ city: string;
  /** 番地 */ area: string;
  /** 建物名 */ building: string;
  /** 市外局番 */ tel_area: string;
  /** 市内局番 */ tel_city: string;
  /** 加入者番号 */ tel_num: string;
  /** FAX市外局番 */ fax_area: string;
  /** FAX市内局番 */ fax_city: string;
  /** FAX加入者番号 */ fax_num: string;
  /** 備考 */ remarks: string;
  /** レセプト排他設定 1:事業所単位 2:契約単位 */ receipt_exclusion_div: number;
  /** レセプト代行使用フラグ 0:未使用 1:使用 */ receipt_agency_div: number;
  /** 開始日 */ u_start_dt: string;
  /** 終了日 */ u_end_dt: string;
  /** 開始日(和暦) */ u_start_dt_wareki: string;
  /** 終了日(和暦) */ u_end_dt_wareki: string;
  /** 更新日時 */ updated_at: string;
  /** 事業所数 */ office_count: number;
  /** レセプト代行メールアドレス */ receipt_acting_mails: ReceiptActingMail[];
  /** 外部システム連携設定 */ receipt_link_settings: ReceiptLinkSetting[];
  /** 他社レセプト連携 */ receipt_links: ReceiptLink[];
  /** 契約申請状態 */ confflag: number;
  /** 変更メッセージ */ change_message: string;
  /** 看護記録II一括出力 0:OFF 1:ON */ record2_bulk_output: number;
  /** 予定作成事業所数 */ plan_offices: number;
  /** 情報連携の標準仕様CSV入力設定 0:OFF 1:ON */ info_coord_input: number;
  /** 情報連携の標準仕様CSV出力設定 0:OFF 1:ON */ info_coord_output: number;
  /** iBowレセプトボタン制御設定 0:OFF 1:ON */ ibow_rec_btn_ctrl: number;
  /** iBowレセプトボタン制御設定 開始 */ ibow_rec_btn_ctrl_start: string;
  /** iBowレセプトボタン制御設定 終了 */ ibow_rec_btn_ctrl_end: string;
  /** BI機能 0:無効 1:有効 */ is_bi_enabled: number;
  /** iBow治験機能 0:無効 1:有効 */ is_chiken_shown: number;
  /** チャット機能 0:無効 1:有効 */ is_chat_enabled: number;
}

export const DEFAULT_AGREEMENT = {
  id: 0,
  co_code: "",
  crypto_key: "",
  agreement_historys: [],
  offices: [],
  agreement_condition: "",
  co_name: "",
  office_names: "",
  kst_nos: "",
  office_conditions: "",
  office_phones: "",
  honobono_license: "",
  honobono_key: "",
  rec2_count_data: [],
  old_ibow_url: "",
};

export const DEFAULT_AGREEMENT_HISTORY = {
  id: 0,
  co_name: "",
  co_code: "",
  post_code: "",
  pref: 1,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  fax_area: "",
  fax_city: "",
  fax_num: "",
  remarks: "",
  receipt_exclusion_div: 1,
  receipt_agency_div: 0,
  u_start_dt: "",
  u_end_dt: "",
  u_start_dt_wareki: "",
  u_end_dt_wareki: "",
  updated_at: "",
  office_count: 0,
  receipt_acting_mails: [] as ReceiptActingMail[],
  receipt_link_settings: [] as ReceiptLinkSetting[],
  receipt_links: [] as ReceiptLink[],
  confflag: 0,
  change_message: "",
  record2_bulk_output: 0,
  info_coord_input: 0,
  info_coord_output: 0,
};

export interface OfficeRec2Count {
  /** 事業所ID */
  office_id: number;
  /** 集計情報 */
  counts: Rec2Count[];
}

export const DEFAULT_REC2_COUNT: OfficeRec2Count = {
  office_id: 0,
  counts: [],
};

export interface Rec2Count {
  /** 対象年月 */
  tag_ym: string;
  /** 集計情報 */
  item: Rec2Item;
}

export interface Rec2Item {
  /** 累計実績 */
  vtotal: number;
  /** 当月実績 */
  vmonth: number;
  /** 前月実績 */
  m1ago: number;
  /** 2ヶ月前実績 */
  m2ago: number;
  /** 3ヶ月前実績 */
  m3ago: number;
  /** 4ヶ月前実績 */
  m4ago: number;
  /** 5ヶ月前実績 */
  m5ago: number;
  /** 6ヶ月前実績 */
  m6ago: number;
}
