
























import { Component, Vue, Emit, Ref } from "vue-property-decorator";
import { VForm } from "@/types";

@Component
export default class SendBillingCommentDialog extends Vue {
  @Ref("comment-editor") private readonly form!: VForm;

  private isOpen = false;
  private comment = "";

  // ダイアログの開閉
  open() {
    this.comment = "";
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  private checkRequired(): boolean | string {
    if (this.comment == "") {
      return "必須項目です。";
    }
    return true;
  }

  private async onSend() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.add();
  }

  @Emit()
  add() {
    return this.comment;
  }
}
