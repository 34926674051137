

































































import { Component, Prop, Emit, Mixins, Watch } from "vue-property-decorator";
import AppDayPicker from "./TextDayChangePicker.vue";
import AppTimePickerString from "./AppTimePickerString.vue";
import * as appDate from "../utility/appDate";
import AppFlex from "./AppFlex.vue";
import AppButton from "./AppButton.vue";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component({
  components: { AppDayPicker, AppTimePickerString, AppFlex, AppButton }
})
export default class TextDatetimeChangePicker extends Mixins(RulesSaveMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** 開始日時 */
  @Prop({ default: "" }) private value!: string;

  /** 終了日時 */
  @Prop({ default: "" }) private end_date!: string;

  /** ラベル */
  @Prop({ default: "対象年月日" }) private label!: string;

  /** 開始日時必須フラグ */
  @Prop({ default: false }) private isNeed!: boolean;

  /** 終了日時必須フラグ */
  @Prop({ default: false }) private need_end_date!: string;

  /** 今日ボタン表示フラグ */
  @Prop({ default: true }) private show_today!: boolean;

  /** 年齢表示フラグ */
  @Prop({ default: false }) private showAge!: boolean;

  /** 年月日非活性フラグ */
  @Prop({ default: false }) private disabled_date!: boolean;

  /** 開始時間非活性フラグ */
  @Prop({ default: false }) private disabled_starttime!: boolean; // 開始時間非活性フラグ

  /** 終了時間非活性フラグ */
  @Prop({ default: false }) private disabled_endtime!: boolean; // 終了時間非活性フラグ

  /** クリアボタン表示フラグ */
  @Prop({ default: true }) private clearable!: boolean; // クリアボタン表示フラグ

  /** 年月日エリアwidth */
  @Prop({ default: "60%" }) private width!: string; // 年月日エリアwidth

  /** 年月日エリアmax-width */
  @Prop({ default: "360px" }) private maxwidth!: string; // 年月日エリアmax-width

  /** 時間エリアwidth */
  @Prop({ default: "64px" }) private timeWidth!: string; // 時間エリアwidth

  /** 時間エリアmin-width */
  @Prop({ default: "64px" }) private timeMinWidth!: string; // 時間エリアmin-width

  /** 和暦の表示位置 */
  @Prop({ default: "top" }) private warekiPosition!: string;

  /** 今日ボタン押下時に時間を同期するか（現在時刻） */
  @Prop({ default: false }) private sync_todaypushtime!: boolean;

  /** 必須時のアスタリスク非表示フラグ */
  @Prop() private notAster!: boolean;

  /** 即座に終了日を更新するフラグ */
  @Prop({ default: false }) private updateEndDateImmediately!: boolean;

  /** データを更新しないフラグ */
  private isNotUpd = false;

  /** 終了日データを更新しないフラグ */
  private isNotUpdEnd = false;

  @Emit() private input(startDateStr: string): string {
    this.isNotUpd = true;
    this.updateEnddate(startDateStr, this.end_date);
    return startDateStr;
  }

  @Emit("update:end_date") private updateEnddate(
    startDateStr: string,
    endDateStr: string
  ): string {
    this.isNotUpdEnd = true;
    const reg = /^([0-9]{4}[-/][0-9]{2}[-/][0-9]{2} (0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]))$/;
    const resStart = startDateStr.match(reg);
    const resEnd = endDateStr.match(reg);
    if (!resEnd || !resEnd[1] || !resStart || !resStart[1]) {
      return endDateStr;
    }

    // 終了日時算出 開始時間 > 終了時間の場合、終了日に開始日+1をセットする
    const startDate = appDate.strToDateObj(startDateStr);
    const endDate = appDate.strToDateObj(endDateStr);
    endDate.setFullYear(startDate.getFullYear());
    endDate.setMonth(startDate.getMonth());
    endDate.setDate(startDate.getDate());
    if (startDate.getTime() > endDate.getTime()) {
      endDate.setDate(startDate.getDate() + 1);
    } else {
      endDate.setDate(startDate.getDate());
    }
    endDateStr = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${endDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${endDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${endDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    this.$nextTick(() => {
      this.changeEnd(endDateStr);
    });

    return endDateStr;
  }

  @Emit() private change(dateStr: string) {
    return dateStr;
  }

  @Emit() private changeEnd(dateStr: string) {
    return dateStr;
  }

  @Emit("change:startHour") private changeStartHour(dateStr: string) {
    return dateStr;
  }

  @Emit("change:startMinute") private changeStartMinute(dateStr: string) {
    return dateStr;
  }

  @Emit("change:endHour") private changeEndHour(dateStr: string) {
    return dateStr;
  }

  @Emit("change:endMinute") private changeEndMinute(dateStr: string) {
    return dateStr;
  }

  @Watch("value") private watchValue() {
    const disp = this.convDateTime(this.value);
    this.localDate = disp[0];
    if (this.isNotUpd) {
      this.isNotUpd = false;
      return;
    }
    this.localHour = disp[1];
    this.localMinute = disp[2];
  }

  @Watch("end_date") private watchEndDate() {
    if (!this.updateEndDateImmediately) {
      if (this.isNotUpdEnd) {
        this.isNotUpdEnd = false;
        return;
      }
    }
    const disp = this.convDateTime(this.end_date);
    this.localEndHour = disp[1];
    this.localEndMinute = disp[2];
  }

  private onUpdateDate(date: string) {
    const datetime = `${date} ${this.HourValue.padStart(
      2,
      "0"
    )}:${this.MinuteValue.padStart(2, "0")}`;
    // @inputはカーソル外した時も呼ばれるので、変更がない場合は何もしないようにした
    if (datetime === this.value) {
      return;
    }
    const startDate = appDate.strToDateObj(datetime);
    if (startDate.toString() != "Invalid Date") {
      const changeDate = appDate.dateToStr(startDate, "yyyy-MM-dd HH:mm");
      this.change(changeDate);
    }
  }

  private onBlur() {
    const startDate = appDate.strToDateObj(this.value);
    if (startDate.toString() != "Invalid Date") {
      const changeDate = appDate.dateToStr(startDate, "yyyy-MM-dd HH:mm");
      this.change(changeDate);
    }
  }

  /**
   * 開始日時ルール
   * @return string | bool
   */
  private get RuleStartTime(): string | boolean {
    if (
      this.DateValue === "" &&
      this.HourValue === "" &&
      this.MinuteValue === ""
    ) {
      return true;
    }

    if (this.DateValue === "") {
      return "日付を入力してください";
    }
    if (this.HourValue === "") {
      return "開始時を入力してください";
    }
    if (this.MinuteValue === "") {
      return "開始分を入力してください";
    }

    return true;
  }

  /**
   * 終了日時ルール
   * @return string | bool
   */
  private get RuleEndTime(): string | boolean {
    if (!this.need_end_date) {
      return true;
    }

    if (
      this.DateValue === "" &&
      this.EndHourValue === "" &&
      this.EndMinuteValue === ""
    ) {
      return true;
    }

    if (this.DateValue === "") {
      return "日付を入力してください";
    }
    if (this.EndHourValue === "") {
      return "終了時を入力してください";
    }
    if (this.EndMinuteValue === "") {
      return "終了分を入力してください";
    }

    return true;
  }

  private localDate = "";
  /** 開始年月日 */
  private get DateValue(): string {
    return this.localDate;
  }
  /** 開始年月日 */
  private set DateValue(newValue: string) {
    const hour = this.dispTimeZeroPadding(this.HourValue);
    const minute = this.dispTimeZeroPadding(this.MinuteValue);
    const endHour = this.dispTimeZeroPadding(this.EndHourValue);
    const endMinute = this.dispTimeZeroPadding(this.EndMinuteValue);
    const startDatetime = `${newValue} ${hour}:${minute}`;
    const endDatetime = `${newValue} ${endHour}:${endMinute}`;
    this.input(startDatetime);
    this.updateEnddate(startDatetime, endDatetime);
  }

  private localHour = "";
  /** 開始時間（時） */
  private get HourValue(): string {
    return this.localHour;
  }
  /** 開始時間（時） */
  private set HourValue(newValue: string) {
    this.localHour = newValue;
    const minute = this.dispTimeZeroPadding(this.MinuteValue);
    const hour = this.dispTimeZeroPadding(newValue);
    const datetime = `${this.DateValue} ${hour}:${minute}`;
    this.input(datetime);
    this.changeStartHour(datetime);
  }

  private localMinute = "";
  /** 開始時間（分） */
  private get MinuteValue(): string {
    return this.localMinute;
  }
  /** 開始時間（分） */
  private set MinuteValue(newValue: string) {
    this.localMinute = newValue;
    const hour = this.dispTimeZeroPadding(this.HourValue);
    const minute = this.dispTimeZeroPadding(newValue);
    const datetime = `${this.DateValue} ${hour}:${minute}`;
    this.input(datetime);
    this.changeStartMinute(datetime);
  }

  private localEndHour = "";
  /** 終了時間（時） */
  private get EndHourValue(): string {
    return this.localEndHour;
  }
  /** 終了時間（時） */
  private set EndHourValue(newValue: string) {
    this.localEndHour = newValue;
    const minute = this.dispTimeZeroPadding(this.EndMinuteValue);
    const hour = this.dispTimeZeroPadding(newValue);
    const datetime = `${this.DateValue} ${hour}:${minute}`;
    this.updateEnddate(this.value, datetime);
    this.changeEndHour(datetime);
  }

  private localEndMinute = "";
  /** 終了時間（分） */
  private get EndMinuteValue(): string {
    return this.localEndMinute;
  }
  /** 終了時間（分） */
  private set EndMinuteValue(newValue: string) {
    this.localEndMinute = newValue;
    const hour = this.dispTimeZeroPadding(this.EndHourValue);
    const minute = this.dispTimeZeroPadding(newValue);
    const datetime = `${this.DateValue} ${hour}:${minute}`;
    this.updateEnddate(this.value, datetime);
    this.changeEndMinute(datetime);
  }

  /**
   * 日時文字列分割処理
   * @param string{str} 日時文字列
   * @return string 日付
   * @return string 時
   * @return string 分
   */
  private convDateTime = (str: string): [string, string, string] => {
    let date = "";
    let hh = "";
    let mm = "";

    const res = str.match(/([0-9]{4}[-/][0-9]{2}[-/][0-9]{2})/);
    if (res && res[1]) {
      date = res[1];
    }

    const resHH = str.match(/ ([0-9]{1,2}):/);
    if (resHH && resHH[1]) {
      hh = this.dispTimeZeroCut(resHH[1]);
    } else {
      const m = str.match(/ (.*):/);
      hh = m && m[1] ? m[1] : "";
    }

    const resMM = str.match(/:([0-9]{1,2})$/);
    if (resMM && resMM[1]) {
      mm = this.dispTimeZeroCut(resMM[1]);
    } else {
      const m = str.match(/:(.*)/);
      mm = m && m[1] ? m[1] : "";
    }

    return [date, hh, mm];
  };

  private dispTimeZeroPadding(time: string): string {
    return !Number.isNaN(parseInt(time)) && time.length == 1
      ? time.padStart(2, "0")
      : time;
  }

  private dispTimeZeroCut(time: string): string {
    return !Number.isNaN(parseInt(time)) && time.length > 1 && time[0] === "0"
      ? time[1]
      : time;
  }

  /** 今日ボタン押下時の処理 */
  private clickToday() {
    if (!this.sync_todaypushtime) {
      return;
    }
    // 今日ボタン押下時に時間をセットする
    this.$nextTick(() => {
      const date = new Date();
      const dateStr = appDate.dateToStr(date, "yyyy-MM-dd HH:mm");
      const startDate = this.input(dateStr);
      const endDate = this.updateEnddate(dateStr, dateStr);

      //時間を手動で入れる
      const startDisp = this.convDateTime(startDate);
      this.localDate = startDisp[0];
      this.localHour = startDisp[1];
      this.localMinute = startDisp[2];

      const endDisp = this.convDateTime(endDate);
      this.localEndHour = endDisp[1];
      this.localEndMinute = endDisp[2];

      this.change(dateStr);
    });
  }

  /**
   * 最大サイズ返却
   *
   * @return  {string}  [return description]
   */
  private get MaxWidth(): string {
    if (this.$vuetify.breakpoint.width < 640) {
      return "";
    }
    return this.maxwidth;
  }
}
