





























































































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { BulletinBoardAdmin } from "@/model/bulletinBoard";
import { BOARD_DIV, BOARD_DIV_COLOR, STATUS } from "./consts";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

interface DispBulletinBoardAdmin extends BulletinBoardAdmin {
  /** 編集者名 */
  editor_name: string;
}

@Component({
  components: {
    AppAuthButton,
  },
})
export default class InfoManagementCurrent extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private items: DispBulletinBoardAdmin[] = [];

  searchText = "";

  get filteredItems() {
    if (!this.searchText) return this.items;

    return this.items.filter((item) => {
      return item.title.includes(this.searchText);
    });
  }

  private BOARD_DIV = BOARD_DIV;
  private BOARD_DIV_COLOR = BOARD_DIV_COLOR;
  private STATUS = STATUS;

  private previewIsShown = false;
  private currentEditing = {} as DispBulletinBoardAdmin;

  //編集ボタンクリック
  private openEditModal(item: DispBulletinBoardAdmin) {
    this.previewIsShown = true;
    this.currentEditing = item;
  }

  //モーダル内早期終了ボタンクリック
  private async clickEarlyFinish(item: DispBulletinBoardAdmin) {
    if (await this.$openConfirm("早期終了しますか？")) {
      if (item) {
        item.status = 2;
        this.postJsonCheck(
          window.base_url + "/api/admin/bulletinboards/save",
          {
            bulletin_board_admin: item,
          },
          () => {
            this.fetchBulletinboards();
          }
        );
      }

      this.previewIsShown = false;
    }
  }

  //モーダル内編集ボタンクリック
  private redirectToEdit(id: number) {
    this.$router.push({ path: "/information/form/" + id });
  }

  created(): void {
    this.fetchBulletinboards();
  }

  private fetchBulletinboards(): void {
    this.items = [];
    this.postJsonCheck(
      window.base_url + "/api/admin/bulletinboards/get",
      {
        date_div: 4,
      },
      (res) => {
        if (res.data.bulletin_board_admins) {
          this.items = res.data.bulletin_board_admins;
        }
      }
    );
  }
}
