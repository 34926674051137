import { SupportableDateSetting } from "#/model/admin";
import { Choice } from "@/types";

export interface ListSupportableDateSetting extends SupportableDateSetting {
  /** 表示用対応日 */
  disp_date: string;
}

export const DEFAULT_LIST_SUPPORTABLE_DATE_SETTING: ListSupportableDateSetting =
{
  id: 0,
  date: "",
  disp_date: "",
  supportable: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

/** サポート対応可否色分け定数 */
export const SETTING_SUPPORTABLE_COLORS: Choice[] = [
  { text: "light-blue darken-1", value: 0 },
  { text: "orange darken-1", value: 1 },
  { text: "deep-orange lighten-1", value: 2 },
];

/** サポート対応可否文字定数 */
export const SETTING_SUPPORTABLE_TEXTS: Choice[] = [
  { text: "対応可", value: 0 },
  { text: "対応不可", value: 1 },
  { text: "対応可（時間帯選択不可）", value: 2 },
];
