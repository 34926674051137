// 引き当て事業所
export interface ApplyOffice {
  id: number; //ID
  name: string; //事業所名
  medical_is_seishin_kihon_ryouyouhi: number; //'医療_精神科訪問看護基本療養費\n0:なし\n1:あり',
  address: string; //住所
  shared_file_div: number; //共有ファイル登録権限設定区分\n1本社職員/システム管理者権限以上\n2:事業所所長権限以上\n3:事務所事務員権限以上\n4:一般職員権限以上
  report_seel_div: number; //帳票押印設定
  record_divide_div: number; //'20分超えのリハ記録書IIまとめ設定\n1:20分を超えた時、記録書Ⅱを1枚にまとめる。\n2:20分毎に記録書Ⅱを作成する。',
  service_record_disp_div: number; //'月次報告書表示設定\n0:サービス訪問（自費）記録を報告書に記載しない\n1:サービス訪問（自費）記録を報告書に記載する',
  adl_setting_div: number; //'観察項目設定区分\n0:観察項目最大21項目とし、記録書Ⅱ（カルテ部分）をA4１枚に収める\n1:観察項目の登録数制限を解除する（観察項目カテゴリ表示）',
  patient_msg_board_time: number; //'利用者メッセージボードロック時間（秒）',
  doctor_title_div: number; //'医師に対する敬称\n0:未選択\n1:先生\n2:先生 御待史\n3:先生 御侍史\n4:先生 御机下',
  hour24_order_div: number; //'24時間体制並び順区分\n1:古いものから順に表示\n2:新しいものから順に表示',
  is_shift: number; //シフト管理機能表示設定
  office_code: string; //事業所番号
  jokuso_rate_div: number; //日々の褥瘡評価設定自立度\n0:未選択\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2
  office_id_for_master: number; //マスタ引き当て用事業所ID 事業所に所属していれば、事業所ID サテライトに所属していれば、親事業所ID
  tel_no: string; //電話番号
  fax_no: string; //FAX番号
  care_regular_visit_service_exist: number; //定期巡回サービス提供
  use_receipt_type: number; // 利用レセプトタイプ
}

export const DefaultApplyOffice = (): ApplyOffice => ({
  id: 0,
  name: "",
  medical_is_seishin_kihon_ryouyouhi: 0,
  address: "",
  shared_file_div: 0,
  report_seel_div: 0,
  record_divide_div: 0,
  service_record_disp_div: 0,
  adl_setting_div: 0,
  patient_msg_board_time: 0,
  doctor_title_div: 0,
  hour24_order_div: 0,
  is_shift: 0,
  office_code: "",
  jokuso_rate_div: 0,
  office_id_for_master: 0,
  tel_no: "",
  fax_no: "",
  care_regular_visit_service_exist: 0,
  use_receipt_type: 0
});
