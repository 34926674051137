import { Vue, Component, Prop } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import * as root from "../store/types";

/**
 * フォームバリデーションMixin
 */
@Component
export default class RulesSaveMixin extends Vue {
  /** 保存フラグのセット */
  @Mutation(root.SET_IS_SAVE) setIsSave!: Function;

  /** 保存かどうか */
  @State is_save!: boolean;

  /** ルール */
  @Prop({ type: Array, default: () => [] }) rules!: (string | boolean)[];

  /** 保存状態の場合は空配列を返却するルール */
  protected get SaveCheckRules(): (string | boolean)[] {
    if (this.is_save) {
      return [];
    }
    return this.rules;
  }
}
