export const name = "user";

export const SET_USER = "SET_USER";

export interface UserState {
  user: User;
  error: boolean;
}

export interface SetUser {
  user: User;
}

export interface User {
  id: number; // ID
  user_id: string; // 'ユーザーID',
  first_name: string; // '姓',
  last_name: string; // '名',
  department: string; // '部門',
  position: string; // '役職',
  authId: number; // '権限ID',
  admin_auth: AdminAuth; // '権限情報',
  created_at: string; // 作成日時
  updated_at: string; // 更新日時
  deleted_at: string; // 削除日時
}

export interface AdminAuth {
  id: number; // ID
  name: string; // 権限名称
  admin_auth_functions: AdminAuthFunction[]; // 権限-機能群
  created_at: string; // 作成日時
  updated_at: string; // 更新日時
  deleted_at: string; // 削除日時
}

export interface AdminAuthFunction {
  id: number; // ID
  auth_id: number; // 権限ID
  function_id: number; // 機能ID
  is_editing: number; // 編集権限の有無
  is_reading: number; // 閲覧権限の有無
  is_editing_str: string[];
  editing_auths: DispAuth[];

  function: AdminFunction; // 機能
  created_at: string; // 作成日時
  updated_at: string; // 更新日時
  deleted_at: string; // 削除日時
}

export interface AdminFunction {
  id: number; // ID
  name: string; // 機能名称
  path: string; // 機能URL
  created_at: string; // 作成日時
  updated_at: string; // 更新日時
  deleted_at: string; // 削除日時
}

interface DispAuth {
  name: string;
  disabled: boolean;
  allowed: number;
  type: number;
  check_digit: number;
  key: string;
}
