







































import { Component, Prop, Emit, Vue, Ref } from "vue-property-decorator";
import { Office } from "@/model/office";
import { Agreement } from "@/model/agreement";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import BillingCommentViewer from "@/components/contractreception/common/BillingCommentViewer.vue";
import BillingSaveForm from "@/components/contractreception/approvalstepper/step2/BillingSaveForm.vue";

@Component({
  components: {
    AppAuthButton,
    BillingCommentViewer,
    BillingSaveForm,
  },
})
/**
 * 事業所契約承認作業をステップ表示するコンポーネント
 */
export default class extends Vue {
  @Ref("contractInfoForm") private readonly contractInfoForm!: BillingSaveForm;

  @Prop({ default: () => ({}) }) agreement!: Agreement;
  @Prop({ default: () => ({}) }) office!: Office;
  @Prop({ default: "" }) co_code!: string;
  /** iBowKintai連携情報 */
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;
  /** 変更履歴フラグ */
  @Prop({ default: false }) private readonly saveHistoryLog!: boolean;

  @Emit() private save() {
    return;
  }

  @Emit() private update() {
    return;
  }

  /** 変更履歴フラグ更新時 */
  @Emit("update:saveHistoryLog") private updateSaveHistoryLog(
    newValue: boolean
  ) {
    return newValue;
  }

  /** 契約状態 */
  private confflag = -1;

  /** 変更履歴フラグ */
  private get SaveHistoryLog() {
    return this.saveHistoryLog;
  }
  private set SaveHistoryLog(value: boolean) {
    this.updateSaveHistoryLog(value);
  }

  /** 契約内容入力ステップで保存ボタンクリック時 */
  private async saveContractInfo() {
    this.contractInfoForm.saveBillingInfo(async () => {
      this.save();
    });
  }
}
