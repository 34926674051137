















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppLoadingDialog extends Vue {
  @Prop() private loading!: number;

  private get Loading() {
    if (this.loading > 0) {
      return true;
    }
    return false;
  }
}
