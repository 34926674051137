



































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Choice } from "@/types";
import SearchSelect from "#/components/SearchSelect.vue";
import AppChip from "#/components/AppChip.vue";
import Base from "@/model/base";

// キーワード
interface HashtagCount {
  /** ハッシュタグ名 */
  hashtag: string;
  /** 個数 */
  count: number;
}

interface RecommendKeyword extends Base {
  /** ID */
  id: number;
  /** おすすめキーワード */
  keyword: string;
}

@Component({
  components: {
    SearchSelect,
    AppChip,
  },
})
export default class RecommendKeywordEdit extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  // 選択可能なキーワード情報
  private keywordChoices: Choice[] = [];

  // おすすめキーワード情報
  private keywords: string[] = [];

  private message = "";

  /** 初回時の処理 */
  private created() {
    this.fetchKeyword();
  }

  /** 保存 */
  private async save() {
    this.clearMessage();
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    // 保存処理
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/recommendkeywords/save",
      {
        keywords: this.keywords,
      },
      (res) => {
        if (!res.data.code) {
          this.message = "保存しました";
          this.fetchKeyword();
        }
      }
    );
  }

  private click() {
    if (this.message) this.clearMessage();
  }

  /** キーワード情報の取得 */
  private fetchKeyword() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/recommendkeywords/get",
      {},
      (res) => {
        // キーワード（選択肢）情報を格納
        if (res.data.counts) {
          this.keywordChoices = [
            ...res.data.counts.map((data: HashtagCount) => {
              const keyword = data.hashtag;
              return {
                text: keyword,
                value: keyword,
              };
            }),
          ];
        }

        // おすすめキーワード設定情報を格納
        if (res.data.recommends) {
          this.keywords = [
            ...res.data.recommends.map((data: RecommendKeyword) => {
              return data.keyword;
            }),
          ];
        }
      }
    );
  }

  private clearMessage() {
    this.message = "";
  }
}
