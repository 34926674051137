












































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { AdminUser } from "@/model/adminUser";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class UserList extends Mixins(AxiosMixin) {
  private users: AdminUser[] = [];

  searchText = "";

  get filteredUsers() {
    if (!this.searchText) return this.users;

    return this.users.filter((user) => {
      return user.user_id.includes(this.searchText);
    });
  }

  private gotoUser(id: number) {
    this.$router.push({ path: "/user/profile/" + id });
  }

  created(): void {
    this.fetchAdminUsers();
  }

  private fetchAdminUsers(): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/admin_users/get",
      {
        admin_user_id: 0,
      },
      (res) => {
        if (res.data.admin_users) {
          res.data.admin_users.forEach((user: AdminUser) =>
            this.users.push(user)
          );
        }
      }
    );
  }
}
