









































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  AiVnplanSetting,
  DefaultAiVnplanSetting,
  PromptTemplate,
} from "#/model/admin/index";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, Choice } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppAuthButton,
  },
})
/**
 * 計画書自動作成設定
 */
export default class Configuration extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** Ref */

  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** プロンプトテンプレート */
  private setting: AiVnplanSetting = DefaultAiVnplanSetting();

  /** プロンプトテンプレート選択肢 */
  private promptTemplateChoice: Choice[] = [];

  /** 登録済みプロンプトテンプレート */
  private promptTemplates: PromptTemplate[] = [];

  /** 条件枠組み選択肢 */
  private conditionChoice: Choice[] = [{ text: "年月指定", value: 1 }];

  /** 年月指定選択肢 */
  private conditionYearmonthChoice: Choice[] = [
    { text: "未使用", value: 0 },
    { text: "全期間", value: 1 },
    { text: "直近3ヶ月", value: 2 },
    { text: "直近6ヶ月", value: 3 },
  ];

  /** 関数 */

  created() {
    this.fetch();
  }

  /** 取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/setting/get",
      {},
      async (res) => {
        this.setting = res.data.ai_vnplan_setting;
        this.promptTemplateChoice = res.data.prompt_template_choice;
        this.promptTemplates = res.data.prompt_templates;
      }
    );
  }

  /** 保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/setting/save",
      { ai_vnplan_setting: this.setting },
      async () => {
        this.fetch();
        this.$openAlert("保存に成功しました。");
      }
    );
  }
}
