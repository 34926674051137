import { Choice } from "@/types";

export const QUESTION_STATUS: Choice[] = [
  { text: "公開終了", value: 1 },
  { text: "公開中", value: 2 },
  { text: "公開予定", value: 3 },
];

export const QUESTION_STATUS_COLOR: Choice[] = [
  { text: "grey", value: 1 },
  { text: "teal", value: 2 },
  { text: "pink darken-3", value: 3 },
];
