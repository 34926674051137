









































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { Term } from "#/model/master";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import BatchGenerateDialog from "@/components/recommend/BatchGenerateDialog.vue";

interface ListTerm extends Term {
  /** 公開設定(表示用) */
  visibility_str: string;
  /** 公開者(表示用) */
  change_visibility_admin_name: string;
}

@Component({
  components: { BatchGenerateDialog, AppAuthButton },
})
/**
 * AIお勧め検索 一覧
 */
export default class TermList extends Mixins(AxiosMixin) {
  /** Ref */

  @Ref("batchGenerateDialog")
  private readonly batchGenerateDialog!: BatchGenerateDialog;

  /** 変数 */

  /** フィルター用キーワード */
  private searchKeyword = "";

  /** 病名に対する看護計画内容 */
  private terms: ListTerm[] = [];

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  /** データテーブル用ヘッダー */
  private headers = [
    {
      text: "病名",
      align: "start",
      value: "disease",
    },
    {
      text: "公開設定",
      align: "center",
      value: "visibility",
      filterable: false,
    },
    {
      text: "最終更新者",
      align: "start",
      value: "editor_admin_name",
      filterable: false,
      sortable: false,
    },
    {
      text: "公開者",
      align: "start",
      value: "change_visibility_admin_name",
      filterable: false,
      sortable: false,
    },
    {
      text: "最終更新日時",
      align: "start",
      value: "updated_at",
      filterable: false,
    },
    { text: "", value: "actions", sortable: false, align: "end" },
  ];

  /** 関数 */

  created() {
    this.fetch();
  }

  /** 一覧取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/admin/recommend/terms/get",
      {},
      async (res) => {
        this.terms = res.data.terms;
      }
    );
  }

  /** 編集画面へ遷移 */
  private moveEditPage(id: number) {
    this.$router.push({ path: `/recommend/edit/${id}` });
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** 一括作成用ダイアログを開く */
  private openBatchGenerateDialog() {
    this.batchGenerateDialog.openDialog();
  }

  /** 一括作成完了時 */
  private generated() {
    // データ再読み込み
    this.fetch();
  }
}
