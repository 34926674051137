export interface Setting {
  /** ID */
  id: number;
  /** アカウント通知発行メールアドレス */
  notice_mail: string;
  /** 2段階認証有効期間（日） */
  double_check_range_date: number;
  /** アンケート再表示期間（日） */
  question_range_date: number;
  /** 事業所情報変更通知先メールアドレス */
  office_history_change_notice_mail: string;
  /** 掲示板掲載通知先メールアドレス */
  information_notice_mail: string;
  /** 作成日時 */
  created_at: string;
  /** 更新日時 */
  updated_at: string;
}

export const DEFAULT_SETTING: Setting = {
  id: 0,
  notice_mail: "",
  double_check_range_date: 0,
  question_range_date: 0,
  office_history_change_notice_mail: "",
  information_notice_mail: "",
  created_at: "",
  updated_at: "",
};
