












































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import {
  Template,
  TemplateFile,
  DEFAULT_TEMPLATE,
  DEFAULT_TEMPLATE_FILE,
} from "@/model/inquiry";

@Component({
  components: {
    FileUpload,
  },
})
export default class TemplateFileUploader extends Mixins(UtilMixin) {
  @Prop({ default: () => DEFAULT_TEMPLATE }) template!: Template;

  /** アップロード可能なファイルタイプ */
  private accepts = [];
  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";
  /** ファイルダウンロードAPIパス */
  private previewApiUrl = window.base_url + "/api/preview";

  /** 添付ファイル上限数 */
  private TEMPLATE_FILE_LIMIT = 5;

  /** 保存先 */
  private get imagePath(): string {
    return `faq/${this.template.id}/`;
  }

  @Watch("template", { immediate: true })
  onChangeTemplate() {
    this.template.template_files = this.template.template_files.map(
      (templateFile: TemplateFile) => {
        const file = { ...templateFile };
        file.path = file.file_path;
        return file;
      }
    );
  }

  /** 添付ファイル枠を追加 */
  private add(): void {
    const templateFile = { ...DEFAULT_TEMPLATE_FILE };
    templateFile.template_id = this.template.id;
    this.template.template_files.push(templateFile);
  }

  /** 添付ファイル枠を削除 */
  private async remove(index: number): Promise<void> {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }

    this.template.template_files.splice(index, 1);
  }

  /** 追加ボタンの有効無効 */
  private get isDisabledAdd() {
    return this.template.template_files.length >= this.TEMPLATE_FILE_LIMIT;
  }
}
