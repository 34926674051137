
















import { Component, Mixins, Ref, Vue } from "vue-property-decorator";
import Main from "@/components/app/Main.vue";
import Login from "@/components/app/Login.vue";
import DoubleLoginArea from "@/components/app/DoubleLoginArea.vue";
import Cookies from "js-cookie";
import AxiosMixin from "@/mixins/axiosMixin";
import { namespace, Mutation, State } from "vuex-class";
import * as user from "@/store/modules/user/types";
import * as root from "@/store/types";
import UserMixin from "./mixins/userMixin";
import { Route } from "vue-router";
import AppConfirm from "#/components/AppAlertConfirm.vue";

const User = namespace(user.name);

window.axios = require("axios");

window.base_url = process.env.VUE_APP_BACKEND_URL;
if (!window.base_url) {
  window.base_url = "http://localhost:8083";
}

window.ibow_url = process.env.VUE_APP_IBOW_FRONTEND_URL;
if (!window.ibow_url) {
  window.ibow_url = "http://localhost:9000";
}

window.ibow_auth_url = process.env.VUE_APP_FRONTEND_AUTH_URL;
if (!window.ibow_auth_url) {
  window.ibow_auth_url = "http://localhost:9200";
}

window.portal_url = process.env.VUE_APP_PORTAL_FRONTEND_URL;
if (!window.portal_url) {
  window.portal_url = "https://carelogood.launchibow2.jp";
}

@Component({
  components: {
    Main,
    Login,
    DoubleLoginArea,
    AppConfirm,
  },
})
export default class App extends Mixins(AxiosMixin, UserMixin) {
  protected isToken = false; //トークン認証済みかどうか
  protected isDisp = false; //画面描画するかどうか

  @User.Mutation(user.SET_USER) setUser!: Function;

  @Mutation(root.SET_IS_REGISTERD_AUTH_NAVIGATION)
  setIsRegisterdAuthNavigation!: Function;

  @State is_registerd_auth_navigation!: boolean;

  @Ref("app_confirm") private readonly appConfirm!: AppConfirm;
  /** 2段階認証 */
  private isDoubleLogin = false;

  created() {
    //トークン
    const token = Cookies.get("admin-token");
    if (token) {
      window.token = token;
    }

    //リフレッシュトークン
    let canGetUser = false;
    const reftoken = Cookies.get("admin-reftoken");
    if (reftoken) {
      window.reftoken = reftoken;
      canGetUser = true;
    }

    //名前
    const name = Cookies.get("admin-name");
    if (name) {
      window.name = name;
    }

    //取得後の権限取得
    if (canGetUser) {
      this.postJsonCheck(window.base_url + "/api/admin-user/get", {}, (res) => {
        this.setUser(res.data);
        this.isToken = true;
        this.isDisp = true;

        // ナビゲーションガード登録前の権限チェック
        if (!this.hasRoutingAuth(this.$router.currentRoute)) {
          this.$router.push({ path: "/" });
        }

        // ナビゲーションガード登録
        if (!this.is_registerd_auth_navigation) {
          this.setIsRegisterdAuthNavigation(true);
          this.$router.beforeEach((to, from, next) => {
            this.hasRoutingAuth(to) ? next() : next(false);
          });
        }
      });
    } else {
      this.isDisp = true;
    }
  }

  mounted() {
    // this使えないので、参照を保持
    const dialog = this.appConfirm;

    // アラートダイアログ呼び出し用インスタンスプロパティ作成
    Vue.prototype.$openAlert = async function (text: string): Promise<boolean> {
      dialog.isConfirm = false;
      const res = await dialog.open(text);
      return res;
    };
    // 確認ダイアログ呼び出し用インスタンスプロパティ作成
    Vue.prototype.$openConfirm = async function (
      text: string
    ): Promise<boolean> {
      dialog.isConfirm = true;
      const res = await dialog.open(text);
      return res;
    };
  }
  // 認証が成功した後の処理
  private logined() {
    this.isToken = true;
    this.isDoubleLogin = false;

    // ナビゲーションガード登録前の権限チェック
    if (!this.hasRoutingAuth(this.$router.currentRoute)) {
      this.$router.push({ path: "/" });
    }

    // ナビゲーションガード登録
    if (!this.is_registerd_auth_navigation) {
      this.setIsRegisterdAuthNavigation(true);
      this.$router.beforeEach((to, from, next) => {
        this.hasRoutingAuth(to) ? next() : next(false);
      });
    }
  }

  /** 指定ルートに移動できるか判定 */
  private hasRoutingAuth(to: Route): boolean {
    // "/"への移動は権限なしで可能
    if (to?.meta?.authName == "/") {
      return true;
    }

    // ログインユーザが存在しない場合はNG
    if (!this.User) {
      return false;
    }

    // 権限取得
    const auth = this.User.admin_auth.admin_auth_functions.find(
      (adminAuthFunction) => {
        return adminAuthFunction.function.path == to?.meta?.authName;
      }
    );

    // 権限情報が存在しない場合はNG
    if (!auth) {
      return false;
    }

    // 閲覧権限がない場合はNG
    if (!auth.is_reading) {
      return false;
    }

    // 編集権限がない場合は編集系画面に行けない
    if (auth.is_editing == 0 && to?.meta?.is_edit_page == true) {
      return false;
    }

    return true;
  }
}
