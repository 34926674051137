var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("未確定契約一覧")]),_c('v-card',{attrs:{"flat":""}},[_c('SearchBase',{on:{"search":_vm.fetchContracts}}),_c('AppTextField',{staticClass:"mt-4",attrs:{"label":"絞り込み(企業名、企業コード、事業所名、事業所コード)","width":"100%","outlined":false,"hide-details":"auto"},model:{value:(_vm.filterObj.keyword),callback:function ($$v) {_vm.$set(_vm.filterObj, "keyword", $$v)},expression:"filterObj.keyword"}}),_c('AppCheckbox',{staticClass:"mt-2 mb-5",attrs:{"label":"コメントがある契約のみを表示","hide-details":true},model:{value:(_vm.filterObj.has_comment),callback:function ($$v) {_vm.$set(_vm.filterObj, "has_comment", $$v)},expression:"filterObj.has_comment"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredAgreements},on:{"click:row":_vm.displayAgreementInfo},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(item.id)}}},[_vm._v("詳細")])]}},{key:"item.corp_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-1 mr-2",attrs:{"dark":"","x-small":"","label":"","color":_vm.idToText(
                item.agreement_historys[0].confflag,
                _vm.AGREEMENT_STATUS_COLOR
              )}},[_c('span',{staticStyle:{"display":"inline-block","width":"40px","text-align":"center"}},[_vm._v(_vm._s(_vm.idToText( item.agreement_historys[0].confflag, _vm.AGREEMENT_STATUS )))])]),_c('div',[_vm._v(_vm._s(item.co_name))])]}},{key:"item.office_name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"p-table-cell--around"},_vm._l((_vm.typeInterface(item).offices),function(office,index){return _c('div',{key:index},[_c('v-chip',{staticClass:"my-1 mr-2",attrs:{"dark":"","x-small":"","label":"","color":_vm.idToText(office.billing_info.confflag, _vm.OFFICE_STATUS_COLOR)}},[_c('span',{staticStyle:{"display":"inline-block","width":"100px","text-align":"center"}},[_vm._v(_vm._s(_vm.idToText(office.billing_info.confflag, _vm.OFFICE_STATUS)))])]),_c('div',[_vm._v(_vm._s(office.office_name))])],1)}),0)]}},{key:"item.kst_no",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"p-table-cell--around"},_vm._l((_vm.typeInterface(item).offices),function(office,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(office.kst_no))])])}),0)]}},{key:"item.step",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"p-table-cell--around"},_vm._l((_vm.typeInterface(item).offices),function(office,index){return _c('div',{key:index},[_c('v-stepper',{staticClass:"elevation-0",staticStyle:{"background-color":"rgba(255, 255, 255, 0)"},model:{value:(office.current_contract_step_number),callback:function ($$v) {_vm.$set(office, "current_contract_step_number", $$v)},expression:"office.current_contract_step_number"}},[_c('v-stepper-header',[_vm._l((office.contract_steps),function(step,index){return [_c('v-stepper-step',{key:index,attrs:{"step":step.contract_step_number,"complete":office.current_contract_step_number >
                        step.contract_step_number,"rules":[function () { return !step.has_comment; }],"error-icon":"mdi-comment-alert-outline"}},[_vm._v(" "+_vm._s(step.step_name)+" ")])]})],2)],1)],1)}),0)]}}],null,true)})],1)],1),_c('ContractDetailNavigation',{attrs:{"agreement":_vm.navItem},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}}),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }