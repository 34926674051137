





















































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { MaintenanceInfo, DEFAULT_MEINTENANCE_INFO } from "@/model/agreement";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import MaintenanceInfoDialog from "@/components/maintenance/MaintenanceInfoDialog.vue";

@Component({
  components: { MaintenanceInfoDialog },
})
export default class FinishMaintenance extends Mixins(AxiosMixin, UtilMixin) {
  /** 詳細表示用ダイアログ */
  @Ref("maintenanceInfoDialog")
  private readonly maintenanceInfoDialog!: MaintenanceInfoDialog;

  private notifications: MaintenanceInfo[] = [];

  searchText = "";

  get filteredItems() {
    if (!this.searchText) return this.notifications;

    return this.notifications.filter((item) => {
      return item.title.includes(this.searchText);
    });
  }

  created(): void {
    this.fetchMaintenanceInfo();
  }

  /** メンテナンス情報取得 */
  fetchMaintenanceInfo(): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/maintenances/get",
      {
        date_div: 1,
      },
      (res) => {
        if (res.data.maintenance_infos) {
          this.notifications = res.data.maintenance_infos;
        }
      }
    );
  }

  /** 詳細表示対象のメンテナンス情報 */
  private maintenance: MaintenanceInfo = { ...DEFAULT_MEINTENANCE_INFO };

  /** 詳細ボタンクリック時 */
  private show(target: MaintenanceInfo) {
    this.maintenance = target;
    this.maintenanceInfoDialog.open();
  }

  /** 詳細ダイアログ経由で編集ボタンクリック時 */
  private goEditPage(id: number) {
    // 編集画面へ遷移
    this.$router.push({ path: "/maintenance/edit/" + id });
  }
}
