


































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Choice, VForm } from "@/types";

@Component
/**
 * 職員一括連携解除
 */
export default class ReleaseStaffLink extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** 入力フォームRef */
  @Ref("form") private readonly form!: VForm;

  /** 検索種類選択肢 */
  private searchTypeChoices: Choice[] = [
    { text: "企業コード", value: 1 },
    { text: "KST番号", value: 2 },
  ];

  /** 検索種類(1:企業コード 2:KST番号) */
  private searchType = 1;

  /** 検索キーワード */
  private keyword = "";

  /** 検索キーワードラベル */
  private get SearchLabel(): string {
    return this.searchType == 1 ? "*企業コード" : "*KST番号";
  }

  /** 一括連携解除ボタン押下時 */
  private async search() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/stafflink/search",
      {
        search_type: this.searchType,
        keyword: this.keyword,
      },
      async (res) => {
        if (!(await this.$openConfirm(res.data.confirm_message))) {
          return;
        }

        this.postJsonCheck(
          window.base_url + "/api/admin/stafflink/release",
          {
            agreement_id: res.data.agreement_id,
            office_ids: res.data.office_ids,
          },
          async (res) => {
            if (!(await this.$openAlert("連携解除しました。"))) {
              return;
            }
          }
        );
      }
    );
  }

  /** 検索キーワードリセット */
  private reset() {
    this.keyword = "";
    this.form.resetValidation();
  }
}
