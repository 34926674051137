


























































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { deepCopy } from "#/utility/appCopy";

import {
  ListSupportableDateSetting,
  SETTING_SUPPORTABLE_COLORS,
  SETTING_SUPPORTABLE_TEXTS,
  DEFAULT_LIST_SUPPORTABLE_DATE_SETTING,
} from "@/views/configuration/types";
import { SupportableDateSetting } from "#/model/admin";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class ConfigurationShow extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** サポート対応可否設定情報 */
  @Prop({ default: () => [] })
  supportableDateSettings!: ListSupportableDateSetting[];

  /** サポート対応可否設定情報用ヘッダー */
  private headers = [
    {
      text: "日付",
      value: "disp_date",
      align: "start",
      width: "",
      sortable: false,
    },
    {
      text: "対応可否",
      value: "supportable",
      align: "start",
      width: "",
      sortable: false,
    },
    {
      text: "",
      value: "actions",
      align: "start",
      width: "",
      sortable: false,
    },
  ];

  /** サポート対応可否色分け定数 */
  private SETTING_SUPPORTABLE_COLORS = SETTING_SUPPORTABLE_COLORS;

  /** サポート対応可否文字定数 */
  private SETTING_SUPPORTABLE_TEXTS = SETTING_SUPPORTABLE_TEXTS;

  /** ダイアログ操作用 */
  private isOpenEditDialog = false;

  /** 編集用サポート対応可否設定情報 */
  private editTarget: SupportableDateSetting = {
    ...DEFAULT_LIST_SUPPORTABLE_DATE_SETTING,
  };

  /** サポート対応可否設定変更ボタンクリック時 */
  private onClickChangeSupportableDateSetting(target: SupportableDateSetting) {
    // コピーを作りダイアログへ渡す
    this.editTarget = deepCopy(target);
    this.isOpenEditDialog = true;
  }

  /** サポート対応可否設定変更ボタンクリック時 */
  private onClickAddSupportableDateSetting() {
    // 新規オブジェクトを作りダイアログへ渡す
    this.editTarget = { ...DEFAULT_LIST_SUPPORTABLE_DATE_SETTING };
    this.editTarget.date = this.dateToStr(new Date(), "yyyy-MM-dd");
    this.isOpenEditDialog = true;
  }

  /** ダイアログ内 */
  @Emit("save:setting")
  private saveSupportableDateSetting() {
    return this.editTarget;
  }

  /** サポート対応可否設定削除ボタンクリック時 */
  @Emit("delete:setting")
  private onClickDeleteSupportableDateSetting(target: SupportableDateSetting) {
    return target;
  }

  /** リセット（外部コンポーネントからの呼び出し） */
  public reset() {
    this.editTarget = { ...DEFAULT_LIST_SUPPORTABLE_DATE_SETTING };
    this.isOpenEditDialog = false;
  }
}
