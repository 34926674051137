import { Billing } from "@/model/agreement";

export interface Office {
  id: number;
  agreement_id: number;
  kst_no: string;
  office_div: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  billings: Billing[];
  office_historys: OfficeHistory[];
  satelite_historys: SateliteHistory[];
  office_info: OfficeHistory;
  satelite_info: SateliteHistory;
  billing_info: Billing;
  office_name: string;
  recent_total_size: number;
}

export const DEFAULT_OFFICE = {
  id: 0,
  agreement_id: 0,
  kst_no: "",
  office_div: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  billings: [] as Billing[],
  office_historys: [] as OfficeHistory[],
  satelite_historys: [] as SateliteHistory[],
  office_info: {} as OfficeHistory,
  satelite_info: {} as SateliteHistory,
  billing_info: {} as Billing,
  office_name: "",
  recent_total_size: 0,
};

export interface OfficeHistory {
  id: number;
  office_id: number;
  start_date: string;
  end_date: string;
  /** 事業所名 */ name: string;
  /** 事業所名(審査支払機関に届け出た名称) */ name_notification: string;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  fax_area: string;
  fax_city: string;
  fax_num: string;
  medical_institution_code: string;
  office_code: string;
  is_care_service: number;
  business_div: number;
  area_div: number;
  jokuso_rate_div: number;
  doctor_title_div: number;
  is_shift: number;
  record_divide_div: number;
  staff_list_div: number;
  service_record_disp_div: number;
  adl_setting_div: number;
  patient_msg_board_time: number;
  bank_name: string;
  branch_name: string;
  account_kind: string;
  account_num: string;
  account_name: string;
  care_service_time_required: number;
  care_regular_visit_service_exist: number;
  care_special_area_div: number;
  care_kinkyu_houmon_add: number;
  care_is_senmon_kangoshi: number;
  care_tokubetsu_kanri_add: number;
  care_terminal_care_add: number;
  care_service_supply_add: number;
  care_small_office_area_add: number;
  care_small_office_scale_add: number;
  care_kyoka_add: number;
  care_riyousya_gensan: number;
  care_end_time_include_div: number;
  care_elderly_abuse_prevention_gensan: number;
  care_mouth_renkei_kyoka_add : number;
  care_over_therapist_visit_count_flg: number;
  care_over_therapist_no_additions_flg: number;
  care_over_therapist_no_additions_july_flg: number;
  medical_kinkyu_houmon_kango_add: number;
  medical_tokubetsu_kanri_add: number;
  medical_terminal_care_add: number;
  medical_hour24_taiou_add: number;
  medical_hour24_renraku_add: number;
  medical_is_senmon_kangoshi: number;
  medical_is_seishin_kihon_ryouyouhi: number;
  medical_seishin_fukusukai_add: number;
  medical_seishin_renkei_add: number;
  medical_kanri_ryouyouhi_div: number;
  medical_kinou_kyoka_ryouyouhi1: number;
  medical_kinou_kyoka_ryouyouhi2: number;
  medical_kinou_kyoka_ryouyouhi3: number;
  medical_is_hour24_taiou_reduce_burden: number;
  medical_dx_info_util_add: number;
  medical_base_up1: number;
  medical_base_up2: number;
  hour24_order_div: number;
  wiseman_office_id: number;
  before0_patientid_div: number;
  sukoyaka_csv_self_bear_default_check: number;
  sukoyaka_csv_mind_body_state_default_check: number;
  use_edi_flg: number;
  edi_code: string;
  edi_name: string;
  transfer_div: number;
  transfer_day: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  start_date_wareki: string;
  end_date_wareki: string;
  updated_at_wareki: string;
  office_history_mails: OfficeHistoryMail[];
  office_history_same_buildings: OfficeHistorySameBuilding[];
}

export interface OfficeHistoryMail {
  id: number;
  office_history_id: number;
  mail_address: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface OfficeHistorySameBuilding {
  id: number;
  office_history_id: number;
  same_building_id: number;
  subtraction_rate: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  same_building: ApplySameBuilding;
}

export interface SateliteHistory {
  id: number;
  office_id: number;
  parent_office_id: number;
  start_date: string;
  end_date: string;
  satelite_no: number;
  name: string;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  fax_area: string;
  fax_city: string;
  fax_num: string;
  area_div: number;
  care_special_area_div: number;
  care_riyousya_gensan: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  start_date_wareki: string;
  end_date_wareki: string;
  satelite_history_same_buildings: SateliteHistorySameBuilding[];
}

export interface SateliteHistorySameBuilding {
  id: number;
  satelite_history_id: number;
  same_building_id: number;
  subtraction_rate: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  same_building: ApplySameBuilding;
}

export interface ApplySameBuilding {
  id: number;
  name: string;
  display_name: string;
}
