
















import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { FileIF } from "@/types";

@Component({
  components: {},
})
export default class AttachmentViewer extends Mixins(UtilMixin, AxiosMixin) {
  /** 表示対象の添付ファイル情報 */
  @Prop({ default: () => [] as FileIF[] }) attachments!: FileIF[];

  /** 添付ファイルパスとして扱うプロパティ名 */
  @Prop({ default: "path" }) pathValue!: keyof FileIF;

  /** ファイルダウンロードAPIパス */
  private previewApiUrl = window.base_url + "/api/preview";

  /** プレビュー処理 */
  private async preview(attachment: FileIF) {
    // プロパティの存在チェック
    if (!(this.pathValue in attachment)) {
      await this.$openAlert("ファイル指定方式が間違っています。");
      return;
    }

    // パスの空文字チェック
    if (attachment[this.pathValue] == "") {
      await this.$openAlert("ファイルパスが存在しません。");
      return;
    }

    const filePath = attachment[this.pathValue];

    this.postJsonCheck(this.previewApiUrl + "?path=" + filePath, {}, (res) => {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = res.data.url;
      a.click();
    });
  }
}
