












import Vue from "vue";
import Component from "vue-class-component";
@Component
export default class AppConfirm extends Vue {
  /** 確認ダイアログかどうか */
  public isConfirm = false;

  /** ダイアログに表示するテキスト */
  public text = "";

  /** 保存ラベルテキスト */
  private saveLabel = "OK";

  /** キャンセルラベルテキスト */
  private cancelLabel = "キャンセル";

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** resolveの保持 */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _resolve: (ans: boolean | PromiseLike<boolean>) => void = () => {};

  /** ダイアログopen時、Promiseのresolveを保持する */
  public async open(
    text: string,
    saveLabel = "OK",
    cancelLabel = "キャンセル"
  ) {
    this.text = text;
    this.saveLabel = saveLabel;
    this.cancelLabel = cancelLabel;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._resolve = () => {};

    // Promise生成
    return await new Promise<boolean>(resolve => {
      this.isOpen = true;
      this._resolve = resolve;
    });
  }

  private close() {
    this.text = "";
    this.isOpen = false;
  }
  private cancel() {
    this._resolve(false);
    this.close();
  }
  private ok() {
    this._resolve(true);
    this.close();
  }
}
