





















import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { PortalMailLog } from "@/model/portal";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class PortalMailLogDetailDialog extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  @Prop({
    type: Object,
    default: () => ({
      mail_subject: "",
      mail_body: "",
    }),
  })
  private item!: PortalMailLog;

  /** ダイアログ表示・非表示用フラグ */
  private dialog = false;

  public open() {
    this.dialog = true;
  }

  public close() {
    this.dialog = false;
  }
}
