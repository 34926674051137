


























































































import { Component, Mixins, Ref } from "vue-property-decorator";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import { Question, DEFAULT_QUESTION } from "@/model/research";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import TargetOfficeSelector from "@/components/admin_common/TargetOfficeSelector.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, TargetOfficeChoice } from "@/types";

interface DispQuestion extends Question {
  target_offices: TargetOfficeChoice[];
}

@Component({
  components: {
    TextDayChangePicker,
    TrumbowygEditor,
    AppAuthButton,
    TargetOfficeSelector,
  },
})
export default class EditResearch extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** フォームバリデーション用 */
  @Ref("form") private readonly form!: VForm;

  /** 表示対象選択エリアを表示するかどうか */
  private researchTarget = 0;

  /** 作成・編集対象のアンケート情報 */
  private question: DispQuestion = { ...DEFAULT_QUESTION, target_offices: [] };

  /** 送信先事業所情報 */
  private targetOffices: TargetOfficeChoice[] = [];

  /** 選択可能事業所情報 */
  private offices: TargetOfficeChoice[] = [];

  public created(): void {
    this.fetchQuestion();
  }

  /** アンケート情報取得 */
  private fetchQuestion(): void {
    // id=0(新規作成)の時も事業所取得したいのでリクエスト投げる
    const questionId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/research/get",
      { ids: [questionId] },
      (res) => {
        this.offices = res.data.offices;

        if (res.data.questions.length > 0) {
          this.question = res.data.questions[0];
          this.targetOffices = this.question.target_offices.slice();
        }

        // 送信先が既に存在している場合
        this.researchTarget = this.targetOffices.length > 0 ? 1 : 0;
      }
    );
  }

  /** 閉じるボタンクリック */
  private async cancel() {
    if (
      await this.$openConfirm(
        "編集を終了します\n編集中の場合は内容が破棄されます"
      )
    ) {
      this.$router.push({ path: "/research/current" });
    }
  }

  /** 保存ボタンクリック */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    
    if (!(await this.$openConfirm("保存しますか"))) {
      return;
    }

    if (this.researchTarget == 1 && this.targetOffices.length == 0) {
      if (
        !(await this.$openConfirm(
        "事業所が選択されていないため、全体へのお知らせになります。よろしいですか？"
        ))
      ) {
        return;
      }
    }

    // リクエストサイズ削減のため、不要な事業所情報を空で渡す
    // 結局、this.targetOfficesが表示対象事業所情報を持っているので問題なし
    this.question.target_offices = [];
    this.question.question_offices = [];

    this.postJsonCheck(
      window.base_url + "/api/admin/research/save",
      { question: this.question, target_offices: this.targetOffices },
      () => {
        this.$router.push({ path: "/research/current" });
      }
    );
  }

  /** 削除ボタンクリック */
  private async remove() {
    if (!(await this.$openConfirm("削除しますか"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/research/delete",
      { question: this.question },
      () => {
        this.$router.push({ path: "/research/current" });
      }
    );
  }

  /** 全体 <-> 選択の切り替え時 */
  private onChangeTarget() {
    // 送信先事業所情報を空にする
    this.targetOffices = [];
  }
}
