



























import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppSwitch extends Mixins(RulesSaveMixin) {
  /** ラベル */
  @Prop() label!: string;

  /** 右に設置するかどうか */
  @Prop({ default: false }) right!: boolean;

  /** 横幅 */
  @Prop({ default: "inherit" }) width!: string;

  /** 値 */
  @Prop() value!: number;

  private style = {
    width: this.width
  };

  @Emit() private input(value: number) {
    return value;
  }

  private get localValue(): number {
    return this.value;
  }

  private set localValue(value: number) {
    this.input(+value);
  }
}
