


































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { EwellUser } from "@/model/ewellUser";
import UserMixin from "@/mixins/userMixin";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class IbowAccountList extends Mixins(AxiosMixin, UserMixin) {
  /** ewellユーザー情報 */
  private ewellUsers: EwellUser[] = [];

  /** 絞込み用条件 */
  private filterObj = {
    name: "",
    keyword: "",
  };

  private headers = [
    {
      text: "停止解除",
      align: "center",
      sortable: false,
      value: "action",
    },
    {
      text: "iBowID",
      sorttable: true,
      value: "user_id",
    },
    {
      text: "氏名",
      sorttable: true,
      value: "name",
    },
    {
      text: "ふりがな",
      sorttable: true,
      value: "furigana",
    },
    {
      text: "メールアドレス",
      sorttable: false,
      value: "mail_address",
    },
    {
      text: "携帯電話番号",
      sorttable: false,
      value: "tel_no",
    },
    {
      text: "システムID",
      sorttable: false,
      value: "system_id",
    },
  ];

  /** 停止中のeWeLLユーザー取得 */
  private fetchIbowAccounts() {
    this.postJsonCheck(
      window.base_url + "/api/ibowaccounts/stopped_list/get",
      {
        name: this.filterObj.name,
        keyword: this.filterObj.keyword,
      },
      (res) => {
        this.ewellUsers = res.data.ewell_users;
      }
    );
  }

  /** 停止解除ボタンクリック時 */
  private goLift(id: number) {
    if (!confirm("アカウントの停止状態を解除します\nよろしいですか？")) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/ibowaccount/lift",
      {
        id: id,
      },
      () => {
        this.fetchIbowAccounts();
      }
    );
  }
}
