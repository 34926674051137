export interface MaintenanceInfo {
  id: number; // 'メンテナンス通知ID',
  start_datetime: string; // '掲載開始日時',
  end_datetime: string; // '掲載終了日時',
  title: string; // 'タイトル',
  content: string; // '本文',
  mainte_start_datetime: string; //'メンテ開始日時',
  mainte_end_datetime: string; //'メンテ終了日時',
  updated_at: string; // '更新日時',
  maintenance_info_offices: MaintenanceInfoOffice[];
}

export interface MaintenanceInfoOffice {
  id?: number;
  maintenance_info_id: number;
  agreement_id?: number;
  office_id: number;
}

export const DEFAULT_MEINTENANCE_INFO: MaintenanceInfo = {
  id: 0,
  start_datetime: "",
  end_datetime: "",
  title: "",
  content: "",
  mainte_start_datetime: "",
  mainte_end_datetime: "",
  updated_at: "",
  maintenance_info_offices: [],
};
