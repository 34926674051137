import { InquiryDiv, InquiryGroup, InquiryServiceDiv } from ".";
import Base from "./../base";
import { FileIF } from "./file";

export interface InquiryAnswer {
  id: number; // ID
  inquiry_id: number; // 問い合わせID
  sort_no: number; // '並び順',
  answer_status: number; // '回答ステータス'
  is_published: number; // 公開フラグ\n0:非公開\n1:公開
  answer_admin_id: number; // '回答者ID',
  answer_admin_name: string; // '回答者名',
  answer_datetime: string; // 回答日時
  answer_div: number; // '回答区分',
  answer_content: string; // '回答内容',
  answer_time: number; // '回答時間',
  answer_datetime_wareki: string; // 回答日時(和暦),
  /** CSアンケート表示フラグ 0:非表示 1:表示 */
  show_questionnaire: number;
  /** CSアンケートマスタID */
  inquiry_questionnaire_id: number;
  /** 1:CSアンケート回答済み */
  questionnaire_answered: number;

  inquiry_answer_inquiry_divs: InquiryAnswerInquiryDiv[];
  inquiry_answer_inquiry_groups: InquiryAnswerInquiryGroup[];
  inquiry_answer_service_divs: InquiryAnswerServiceDiv[];
  inquiry_answer_files: InquiryAnswerFile[];
  /** CSアンケート回答結果(設問) */
  questionnaire_answers: InquiryQuestionnaireQuestionAnswer[];
}
export interface InquiryAnswerInquiryDiv extends Base {
  id: number; //ID
  inquiry_answer_id: number; //'問い合わせ回答ID',
  inquiry_div_id: number; //'問い合わせ種類ID',
  sort_no?: number; //'並び順',
  name?: string; //'問い合わせ種類名',
}

/* 問い合わせ回答 - 問い合わせグループ */
export interface InquiryAnswerInquiryGroup extends Base {
  id: number; //ID
  inquiry_answer_id: number; //'問い合わせ回答ID',
  inquiry_group_id: number; //'問い合わせグループID',
  sort_no?: number; //'並び順',
  name?: string; //'問い合わせグループ名',
}

/* 問い合わせ回答 - 問い合わせサービス種類 */
export interface InquiryAnswerServiceDiv extends Base {
  id: number; //ID
  inquiry_answer_id: number; //'問い合わせ回答ID',
  service_div_id: number; //'サービス種類ID',
  sort_no?: number; //'並び順',
  name?: string; //'問い合わせサービス種類名',
}

/** 問い合わせ回答添付ファイル */
export interface InquiryAnswerFile extends Base, FileIF {
  /** ID */
  id: number;
  /** 問い合わせ回答ID */
  inquiry_answer_id: number;
  /** 並び順 */
  sort_no: number;
  /** ファイルパス */
  file_path: string;
}

/** CSアンケート回答結果(設問) */
export interface InquiryQuestionnaireQuestionAnswer extends Base {
  /** ID */
  id: number;
  /** 問合せID */
  inquiry_id: number;
  /** 回答ID */
  inquiry_answer_id: number;
  /** CSアンケート設問マスタID */
  inquiry_questionnaire_question_id: number;
  /** (マスタ)設問種類 1:n段階評価設問 2:コメント単体設問 */
  question_type: number;
  /** (マスタ)設問文章 */
  question_text: string;
  /** (マスタ)並び順 */
  sort_no: number;
  /** CSアンケート回答結果(選択肢) */
  selections: InquiryQuestionnaireSelectionAnswer[];
}

/** CSアンケート回答結果(選択肢) */
export interface InquiryQuestionnaireSelectionAnswer extends Base {
  /** ID */
  id: number;
  /** CSアンケート回答結果(設問)ID */
  inquiry_questionnaire_question_answer_id: number;
  /** CSアンケート選択肢マスタID */
  inquiry_questionnaire_selection_id: number;
  /** コメント */
  comment: string;
  /** 1:チェック状態(画面側で使用) */
  is_checked: number;
  /** (マスタ)選択肢文章 */
  selection_text: string;
  /** (マスタ)選択肢値 */
  selection_value: number;
  /** (マスタ)並び順 */
  sort_no: number;
}

export interface DispInquiryAnswer extends InquiryAnswer {
  can_release: boolean;

  selected_service_divs: InquiryServiceDiv[];
  selected_inquiry_groups: InquiryGroup[];
  selected_inquiry_divs: InquiryDiv[];
}

export const DEFAULT_INQUIRY_ANSWER: InquiryAnswer = {
  id: 0,
  inquiry_id: 0,
  sort_no: 1,
  answer_status: 1,
  is_published: 0,
  answer_admin_id: 0,
  answer_admin_name: "",
  answer_datetime: "",
  answer_div: 1,
  answer_content: "",
  answer_time: 0,
  answer_datetime_wareki: "",
  show_questionnaire: 0,
  inquiry_questionnaire_id: 0,
  questionnaire_answered: 0,
  inquiry_answer_inquiry_divs: [],
  inquiry_answer_inquiry_groups: [],
  inquiry_answer_service_divs: [],
  inquiry_answer_files: [],
  questionnaire_answers: [],
};

export const DEFAULT_INQUIRY_ANSWER_FILE: InquiryAnswerFile = {
  id: 0,
  inquiry_answer_id: 0,
  sort_no: 1,
  file_path: "",
  path: "",
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

export const DEFAULT_DISP_INQUIRY_ANSWER: DispInquiryAnswer = {
  ...DEFAULT_INQUIRY_ANSWER,
  can_release: false,
  selected_service_divs: [],
  selected_inquiry_groups: [],
  selected_inquiry_divs: [],
};
