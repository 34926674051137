

























































import { Component, Mixins } from "vue-property-decorator";
import { Agreement, DEFAULT_AGREEMENT } from "@/model/agreement";
import ContractDetailNavigation from "@/components/contractreception/ContractDetailNavigation.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import SearchBase from "@/components/admin_common/SearchBase.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import UtilMixin from "@/mixins/utilMixin";
import ContractListTable from "@/components/contractmanagement/ContractListTable.vue";

@Component({
  components: {
    ContractDetailNavigation,
    SearchBase,
    AppAuthButton,
    ContractListTable,
  },
})
export default class ContractmanagementList extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 契約情報詳細を表示するためのナビゲーションドロワー制御用フラグ */
  private drawer = false;

  /** ナビゲーションドロワーで表示する契約情報 */
  private navItem: Agreement = { ...DEFAULT_AGREEMENT };

  /** 契約情報 */
  private agreements: Agreement[] = [];

  /** 変更届のある契約情報 */
  private contractChangingAgreements: Agreement[] = [];

  /** タブ情報 */
  private currentTab = 0;

  /** v-data-table 絞込み用 */
  private searchKey = "";

  /** 契約一覧取得 */
  private fetchAgreements(searchObj: object): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/contracts/get",
      { ...searchObj, need_unconfirmed: 0 },
      (res) => {
        if (res.data.agreements) {
          this.agreements = res.data.agreements;
        }

        if (res.data.contract_changing_agreements) {
          this.contractChangingAgreements =
            res.data.contract_changing_agreements;
        }
      }
    );
  }

  /** 詳細ボタンクリック時 */
  private goDetail(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/contractmanagement/detail/${id}` });
  }

  /** データテーブルの各行がクリックされた場合は契約情報をナビゲーションドロワーで表示 */
  private displayAgreementInfo(agreement: Agreement): void {
    this.drawer = !this.drawer;
    this.navItem = agreement;
  }
}
