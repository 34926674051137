


















import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class extends Mixins(AxiosMixin) {
  /** ダイアログ開閉状態 */
  @Prop({ default: false }) private readonly value!: boolean;

  @Emit() private input(value: boolean) {
    return value;
  }

  private get Value() {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  /** iBowKINTAI事業所ID */
  private kintaiOfficeIds = "";

  /** KINTAI連携用のExcel出力 */
  private outputKintaiReplicationExcel() {
    const kintaiOfficeIds: number[] = [];
    this.kintaiOfficeIds.split(",").forEach((val) => {
      kintaiOfficeIds.push(Number(val));
    });

    this.postJsonBlobResCheck(
      window.base_url +
        "/api/kintai-replication/output-kintai-replication-excel",
      {
        agreement_id: Number(this.$route.params.id),
        kintai_officecds: kintaiOfficeIds,
      },
      (res) => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = "kintai-replication-excel.xlsx";
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }
}
