




































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

/**
 * 事業所契約承認作業をステップ表示するコンポーネント
 */
@Component
export default class extends Mixins(UtilMixin, RulesMixin) {
  /** 変更メッセージ */
  @Prop({ default: "" }) private readonly value!: string;

  /** 履歴情報を残すかどうか */
  @Prop({ default: false }) private readonly saveHistoryLog!: boolean;

  /** 変更メッセージの変更時 */
  @Emit() private input(value: string) {
    return value;
  }

  @Emit("update:saveHistoryLog") private updateSaveHistoryLog(value: boolean) {
    return value;
  }

  /** 変更メッセージ */
  private get Value() {
    return this.value;
  }
  private set Value(newValue) {
    this.input(newValue);
  }

  /** 履歴情報を残すかどうか */
  private get SaveHistoryLog() {
    return this.saveHistoryLog;
  }
  private set SaveHistoryLog(newValue) {
    this.updateSaveHistoryLog(newValue);
  }

  /** 履歴保存時のメッセージに関するバリデーションチェック */
  private requireChangeMessage(): boolean | string {
    if (this.saveHistoryLog && this.Value == "") {
      return "必須項目です。";
    }
    return true;
  }
}
