












import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import ServiceLinkEdit from "@/components/serviceLink/ServiceLinkEdit.vue";
import { ServiceLink } from "@/model/serviceLink";

@Component({
  components: {
    ServiceLinkEdit,
  },
})
export default class extends Mixins(AxiosMixin, UtilMixin) {
  private serviceLink: ServiceLink = {
    id: 0,
    title: "",
    start_date: "",
    end_date: "",
    img: "",
    category_id: 0,
    type: 1,
    str_type: "",
    url: "",
    intermediate_page: "",
    description: "",
    sort_no: 0,
    home_sort_no: 0,
    service_link_category: {
      id: 0,
      name: "",
      sort_no: 0,
      created_at: "",
      deleted_at: "",
      updated_at: "",
    },
    service_link_mails: [],
    show_home: 1,
    show_service_links: 1,
    show_home_str: "",
    show_service_links_str: "",
    created_at: "",
    deleted_at: "",
    updated_at: "",
    footer_comment: "",
    remarks: "",
  };

  mounted() {
    if (Number(this.$route.params.id) != 0) {
      this.fetch();
    }
  }

  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/service_links/get",
      {
        ids: [Number(this.$route.params.id)],
      },
      (res) => {
        console.log(res.data);
        this.serviceLink = res.data.service_links[0];
        if (!this.serviceLink.service_link_mails) {
          this.serviceLink.service_link_mails = [];
        }
      }
    );
  }

  private cancelServiceLink() {
    this.$router.go(-1);
  }

  private saveServiceLink() {
    this.postJsonCheck(
      window.base_url + "/api/service_link/save",
      {
        service_link: this.serviceLink,
      },
      () => {
        this.$router.go(-1);
      }
    );
  }

  private deleteServiceLink() {
    console.log("delete", this.serviceLink);
    this.postJsonCheck(
      window.base_url + "/api/service_link/delete",
      {
        service_link: this.serviceLink,
      },
      () => {
        this.$router.go(-1);
      }
    );
  }
}
