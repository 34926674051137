

































































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Term, DefaultTerm } from "#/model/master";
import CopyButton from "@/components/recommend/CopyButton.vue";
import AiRecommendPrompt from "@/components/recommend/AiRecommendPrompt.vue";
import { PromptLine } from "@/views/recommend/types";
import AxiosMixin from "@/mixins/axiosMixin";

@Component({ components: { CopyButton, AiRecommendPrompt } })
/**
 * AIお勧め検索 AIお勧め
 */
export default class AiRecommend extends Mixins(AxiosMixin) {
  /** Prop */

  /** 編集対象の看護計画内容 */
  @Prop({ default: () => DefaultTerm() }) term!: Term;

  @Prop({ default: () => [] }) promptLines!: PromptLine[];

  /** 変数 */

  /** タブ位置 */
  private tabPosition = 0;

  /** スナックバー表示フラグ */
  private showSnackBar = false;

  /** 回答原文表示ダイアログ開閉フラグ */
  private isOpenAnswerDialog = false;

  /** getter */

  /** 問い合わせボタン無効化 */
  private get IsButtonDisabled(): boolean {
    return this.term.disease == "";
  }

  /** 結果を反映ボタン無効化 */
  private get IsApplyButtonDisabled(): boolean {
    if (!this.term.term_ai_answers || this.term.term_ai_answers.length == 0) {
      return true;
    }

    return this.term.term_ai_answers[0].answer_type != 1;
  }

  /** ChatGPTの回答(原文) */
  private get Answer(): string {
    if (!this.term || !this.term.term_ai_answers) {
      return "";
    }

    if (this.term.term_ai_answers.length == 0) {
      return "";
    }

    return this.term.term_ai_answers[0].answer;
  }

  /** 関数 */

  /** 反映ボタン押下時 */
  private async beforeApply() {
    if (
      !(await this.$openConfirm(
        "問い合わせ結果を現在編集中の内容に反映します。よろしいですか？"
      ))
    ) {
      return;
    }

    this.apply();
  }

  /** クリップボードにコピー */
  private copy(content: string) {
    navigator.clipboard.writeText(content);
    this.showSnackBar = true;
  }

  /** Emit */

  /** 値変更 */
  @Emit("input")
  private input(term: Term) {
    return term;
  }

  /** AIに問い合わせ */
  @Emit("send:ai")
  private send() {
    return;
  }

  /** AIが回答した看護計画を反映 */
  @Emit("apply")
  private apply() {
    return;
  }
}
