








































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { Choice } from "../types";
import AppIcon from "./AppIcon.vue";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component({
  components: {
    AppIcon
  }
})
export default class AppAutocomplete extends Mixins(RulesSaveMixin) {
  /** ラベル */
  @Prop() label!: string;
  /** 値 */
  @Prop() value!: string;
  /** 選択肢 */
  @Prop() items!: Choice[];
  /** 備考 */
  @Prop() remarks!: string;
  /** 高さ */
  @Prop({ default: "48px" }) height!: string;
  /** 横幅 */
  @Prop({ default: "100%" }) width!: string;
  /** 最小横幅 */
  @Prop({ default: "225px" }) minWidth!: string;
  /** 選択肢がない場合の文言 */
  @Prop({ default: "データがありません" }) noDataText!: string;
  /** 必須フラグ */
  @Prop({ default: false }) isNeed!: boolean;
  /** 複数選択フラグ */
  @Prop({ default: false }) multiple!: boolean;
  /** 表示用文言のキー */
  @Prop({ default: "text" }) itemText!: string;
  /** オブジェクトを返却するかどうか */
  @Prop({ default: false }) returnObject!: boolean;

  /** 値変更時 */
  @Emit() private input(newValue: string): string {
    return newValue;
  }

  /** 値 */
  private get Value(): string {
    return this.value;
  }

  /** 値 */
  private set Value(newValue: string) {
    this.input(newValue);
  }

  /** ラベル */
  private get Label(): string {
    if (this.isNeed) return "*" + this.label;
    return this.label;
  }
}
