























































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import {
  Agreement,
  AgreementHistory,
  DEFAULT_AGREEMENT,
  DEFAULT_AGREEMENT_HISTORY,
} from "@/model/agreement/agreement";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class ContractDetailNavigation extends Mixins(UtilMixin) {
  /** ナビゲーションドロワー制御用 */
  @Prop({ default: false }) value!: boolean;

  /** 詳細表示対象の契約情報 */
  @Prop({ default: DEFAULT_AGREEMENT }) agreement!: Agreement;

  /** 詳細表示対象の契約履歴ID(変更履歴閲覧用) */
  @Prop({ default: 0 }) historyId!: number;

  /** 契約履歴情報 */
  private get agreementHistory() {
    if (!this.agreement.agreement_historys) {
      return DEFAULT_AGREEMENT_HISTORY;
    }

    if (this.agreement.agreement_historys.length == 0) {
      return DEFAULT_AGREEMENT_HISTORY;
    }

    if (this.historyId == 0) {
      return this.agreement.agreement_historys[0];
    }

    return this.agreement.agreement_historys.filter(
      (data: AgreementHistory) => data.id == this.historyId
    )[0];
  }

  private get Drawer(): boolean {
    return this.value;
  }

  private set Drawer(newValue: boolean) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }
}
