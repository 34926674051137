













import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import NumberInputString from "./NumberInputString.vue";

@Component({
  components: { NumberInputString }
})
export default class AppPhoneNo extends Vue {
  /** 入力ラベル */
  @Prop() private readonly label!: string;

  /** 入力値（郵便番号） */
  @Prop({ default: "" }) private readonly value!: string;

  /** 読み込みのみかどうか */
  @Prop({ default: false }) private readonly isReadOnly!: boolean;

  /** 無効かどうか */
  @Prop({ default: false }) private readonly disabled!: boolean;

  /** 必須かどうか */
  @Prop({ default: false }) private readonly required!: boolean;

  @Emit("input") input(newValue: string) {
    return newValue;
  }

  get Value() {
    return this.value;
  }

  set Value(newValue: string) {
    this.input(newValue);
  }

  private rulePhoneNo() {
    if (this.required && !this.value) {
      return "必須です";
    }
    const ok = this.Value.match(/^0\d{10}$/);
    if (!ok) {
      return "入力形式が不正です";
    }
    return true;
  }
}
