







































































































































































































import Component from "vue-class-component";
import { Emit, Mixins, Ref, Prop } from "vue-property-decorator";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import { VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import { Choice } from "@/types";
import { AdminUser } from "@/model/adminUser";
import { InquiryDiv, InquiryServiceDiv } from "@/model/inquiry";
import RulesMixin from "#/mixins/rulesMixin";
import {
  InquirySearchObj,
  DEFAULT_INQUIRY_SEARCH_OBJ,
} from "@/views/inquiry/types";

@Component({
  components: {
    TextDayChangePicker,
  },
})
export default class InquirySearch extends Mixins(AxiosMixin, RulesMixin) {
  /** 検索フォーム操作用 */
  @Ref("inquiry_search") private readonly searchForm!: VForm;

  /** 検索条件 */
  @Prop({ default: DEFAULT_INQUIRY_SEARCH_OBJ }) searchObj!: InquirySearchObj;

  /** 担当者名選択肢 */
  private adminUserChoices: Choice[] = [];

  /** サービス種類選択肢 */
  private serviceDivChoices: Choice[] = [];

  /** 区分選択肢 */
  private divChoices: Choice[] = [];

  /** アンケート回答結果選択肢 */
  private questionnaireChoices: Choice[] = [];

  /** AND検索OR検索選択肢 */
  private andorChoices: Choice[] = [
    { text: "AND検索", value: 0 },
    { text: "OR検索", value: 1 },
  ];

  /** 並び替え項目選択肢 */
  private orderItems = [
    {
      text: "問い合わせ日時",
      value: "inquiry_datetime",
    },
    {
      text: "問い合わせ完了日時",
      value: "complete_datetime",
    },
  ];

  private orderDivs = [
    {
      text: "昇順",
      value: "ASC",
    },
    {
      text: "降順",
      value: "DESC",
    },
  ];

  public created(): void {
    this.fetchAdminUsers();
    this.fetchInquiryMaster();
    this.fetchInquiryQuestionnaireMaster();
  }

  private fetchAdminUsers() {
    this.postJsonCheck(
      window.base_url + "/api/admin/admin_users/get",
      {
        admin_user_id: 0,
      },
      (res) => {
        if (res.data.admin_users) {
          this.adminUserChoices = [
            { text: "未選択", value: 0 },
            ...res.data.admin_users.map((data: AdminUser) => {
              return {
                text: `${data.last_name} ${data.first_name}`,
                value: data.id,
              };
            }),
          ];
        }
      }
    );
  }

  private fetchInquiryMaster() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/master/get",
      {},
      (res) => {
        this.serviceDivChoices = [
          { text: "未選択", value: 0 },
          ...res.data.inquiry_service_divs.map((data: InquiryServiceDiv) => {
            return {
              text: data.name,
              value: data.id,
            };
          }),
        ];
        this.divChoices = [
          { text: "未選択", value: 0 },
          ...res.data.inquiry_divs.map((data: InquiryDiv) => {
            return {
              text: data.name,
              value: data.id,
            };
          }),
        ];
      }
    );
  }

  private fetchInquiryQuestionnaireMaster() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/questionnaire/master/get",
      {},
      (res) => {
        this.questionnaireChoices = res.data.questionnaire_choices;
      }
    );
  }

  /** 検索条件リセットボタンクリック時 */
  @Emit("reset")
  private reset() {
    return;
  }

  private onChangeServiceDivs(serviceDivId: number) {
    this.searchObj.service_divs = [serviceDivId];
  }

  private onChangeDivs(divId: number) {
    this.searchObj.divs = [divId];
  }

  private onSubmit() {
    if (this.searchForm.validate()) {
      this.searchObj.order = `${this.searchObj.order_item} ${this.searchObj.order_div}`;
      this.search();
    }
  }

  @Emit()
  private search() {
    return this.searchObj;
  }
}
