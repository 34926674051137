import { UserState } from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: UserState = {
  user: {
    id: 0, // ID
    user_id: "", // 'ユーザーID',
    first_name: "", // '姓',
    last_name: "", // '名',
    department: "", // '部門',
    position: "", // '役職',
    authId: 0, // '権限ID',
    admin_auth: {
      id: 0, // ID
      name: "", // 権限名称
      admin_auth_functions: [], // 権限-機能群
      created_at: "", // 作成日時
      updated_at: "", // 更新日時
      deleted_at: "", // 削除日時
    },
    created_at: "", // 作成日時
    updated_at: "", // 更新日時
    deleted_at: "", // 削除日時
  },
  error: false,
};

const namespaced = true;

export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
