












// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/langs/ja.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize";
import "trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste";
import "trumbowyg/dist/plugins/base64/trumbowyg.base64.min.js";
import "trumbowyg/dist/ui/trumbowyg.css";

import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component({
  components: {
    Trumbowyg,
  },
})
export default class TrumbowygEditor extends Vue {
  /** プレースホルダー */
  @Prop({ default: "" }) placeholder!: string;

  /** 入力内容 */
  @Prop() value!: string;

  /** 必須かどうか */
  @Prop({ default: false }) required!: boolean;

  /** エディタの設定 */
  private trumbowygConfig = {
    lang: "ja",
    semantic: false,
    btnsDef: {
      image: {
        dropdown: ["base64"],
        ico: "insertImage",
      },
    },
    btns: [
      ["viewHTML"],
      ["undo", "redo"],
      ["strong", "em", "del", "removeformat"],
      ["formatting", "fontsize", "foreColor", "backColor"],
      ["justifyLeft", "justifyCenter", "justifyRight"],
      ["link"],
      ["image"],
      ["fullscreen"],
    ],
  };
  private isError = false;

  private rule(): boolean | string {
    if (this.innerValue.length == 0 && this.required) {
      return "必須項目です。";
    }

    this.isError = false;
    return true;
  }

  private onBlur() {
    this.isError = this.innerValue.length == 0;
  }

  private get innerValue(): string {
    return this.value;
  }

  private set innerValue(newValue: string) {
    this.$emit("input", newValue);
  }

  @Emit("input")
  input(value: string) {
    return value;
  }
}
