





























































































































































































































































































import { Component, Mixins, Prop, Ref, Emit } from "vue-property-decorator";
import {
  DispInquiry,
  DEFAULT_DISP_INQUIRY,
  InquiryOfficeStaff,
  DEFAULT_OFFICESTAFF_CONTACT,
  OfficestaffContact,
} from "@/model/inquiry";
import {
  INQUIRY_STATUS,
  INQUIRY_DIV,
  INQUIRY_COMPLETED,
  INQUIRY_PUBLISHED,
  HOPE_CONTACT_DIV,
  INQUIRY_VERSION,
  ACCESS_CONFIRMS,
} from "@/consts/inquiry";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import { USE_RECEIPT_TYPE, PREFECTURES } from "@/consts/const";
import UtilMixin from "@/mixins/utilMixin";
import OfficeStaffSelectDialog from "@/components/inquiry/OfficeStaffSelectDialog.vue";
import OfficeSelectDialog from "@/components/inquiry/OfficeSelectDialog.vue";
import InquiryFileUploader from "@/components/inquiry/InquiryFileUploader.vue";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import { Choice } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import { Agreement, DEFAULT_AGREEMENT } from "@/model/agreement";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import { Staff, DefaultStaff } from "#/model/staff";
import OfficestaffContactInfo from "@/components/inquiry/OfficestaffContactInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    OfficeStaffSelectDialog,
    OfficeSelectDialog,
    TextDayChangePicker,
    TrumbowygEditor,
    InquiryFileUploader,
    OfficestaffContactInfo,
    AppAuthButton,
  },
})
export default class InquiryForm extends Mixins(AxiosMixin, UtilMixin) {
  /** 編集対象の問い合わせ情報 */
  @Prop({ default: () => DEFAULT_DISP_INQUIRY }) inquiry!: DispInquiry;

  /** 問い合わせに紐付く契約情報 */
  @Prop({ default: () => DEFAULT_AGREEMENT }) inquiryAgreement!: Agreement;

  /** 問い合わせに紐付く事業所情報 */
  @Prop({ default: () => DEFAULT_OFFICE }) inquiryOffice!: Office;

  /** 問い合わせに紐付く職員情報 */
  @Prop({ default: () => DefaultStaff() }) inquiryStaff!: Staff;

  /** 事業所職員コンタクト履歴 */
  @Prop({ default: () => DEFAULT_OFFICESTAFF_CONTACT })
  officestaffContact!: OfficestaffContact;

  /** ログイン中ユーザー名 */
  @Prop({ default: "" }) userName!: string;

  /** 追加問い合わせされている状態か */
  @Prop({ default: false }) hasSubInquiry!: boolean;

  /** 職員情報取得用ダイアログ */
  @Ref("staff_selector") staffSelector!: OfficeStaffSelectDialog;

  /** 事業所情報取得用ダイアログ */
  @Ref("office_selector") officeSelector!: OfficeSelectDialog;

  private INQUIRY_STATUS = INQUIRY_STATUS;
  private INQUIRY_DIV = INQUIRY_DIV;
  private PREFECTURES = PREFECTURES;

  /** 希望連絡方法選択肢 */
  private HOPE_CONTACT_DIV = HOPE_CONTACT_DIV;

  private ACCESS_CONFIRMS = ACCESS_CONFIRMS;

  /** 公開設定選択肢 */
  private INQUIRY_PUBLISHED = INQUIRY_PUBLISHED;

  /** 利用レセプトタイプ */
  private USE_RECEIPT_TYPE = USE_RECEIPT_TYPE;

  /** バージョン情報 */
  private INQUIRY_VERSION = INQUIRY_VERSION;

  /** 問い合わせステータスの選択肢を生成 */
  private get FILTERED_INQUIRY_STATUS() {
    // 問い合わせ編集で完了ステータスは選択できない
    return this.INQUIRY_STATUS.filter(
      (data: Choice) => data.value != INQUIRY_COMPLETED
    );
  }

  /** 職員選択ダイアログで選択時 */
  @Emit("staff")
  private onSelectStaff(staff: InquiryOfficeStaff) {
    return staff;
  }

  /** 事業所選択ダイアログで選択時 */
  @Emit("select:office")
  private onSelectOfficeDialog(obj: { agreement: Agreement; office: Office }) {
    return obj;
  }

  /** バージョン情報変更時 */
  @Emit("change:version")
  private onChangeVersion() {
    return;
  }

  private get innerOffice() {
    return { ...this.inquiryOffice };
  }

  private set innerOffice(value: Office) {
    this.onSelectOffice(value);
  }

  /** 事業所選択時 */
  @Emit("office")
  private onSelectOffice(office: Office) {
    return office;
  }

  /** 管理者ログイン */
  @Emit("systemlogin")
  private systemLogin() {
    return this.inquiryStaff.agreement_id;
  }

  /** 代理ログイン */
  @Emit("agentlogin")
  private agentLogin() {
    return this.inquiryStaff.id;
  }

  /** 事業所職員コンタクト履歴保存 */
  @Emit("officestaffcontact")
  private saveOfficestaffContact(data: OfficestaffContact) {
    return data;
  }
}
