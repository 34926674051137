




























import { Component, Mixins, Prop } from "vue-property-decorator";
import { Term, DefaultTerm } from "#/model/master";
import { PromptLine } from "@/views/recommend/types";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
/**
 * AIお勧め検索 AIお勧め用プロンプト編集
 */
export default class AiRecommendPrompt extends Mixins(AxiosMixin) {
  /** Prop */

  /** 編集対象の看護計画内容 */
  @Prop({ default: () => DefaultTerm() }) term!: Term;

  /** 変数 */
  @Prop({ default: () => [] }) promptLines!: PromptLine[];

  /** 編集モードフラグ */
  private isEditMode = false;

  /** getter */

  /** 表示するプロンプト */
  private get Prompt(): PromptLine[] {
    const prompt = this.promptLines.map((promptLine: PromptLine) => {
      const tmp = { ...promptLine };
      if (tmp.text === "${病名}") {
        tmp.text = this.term.disease;
      }
      if (tmp.text === "") {
        tmp.text = " ";
      }

      return tmp;
    });

    return prompt;
  }

  /** 関数 */

  /** 編集モード切り替え */
  private toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
}
