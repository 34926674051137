
















































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppButton from "./AppButton.vue";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppButton,
    AppIcon
  }
})
export default class AppDialog extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  @Prop() title!: string; //タイトル

  @Prop() value!: boolean; //ダイアログ表示フラグ

  @Prop() isDelete!: boolean; //削除ボタン表示フラグ

  @Prop({ default: "" }) contentStyle!: string; //ダイアログのスタイル

  @Prop({ default: "" }) contentClass!: string; //ダイアログの追加content-class

  @Prop({ default: "" }) bottomLabel!: string; //ボタンと並んで表示されるラベル

  @Prop({ default: true }) needSave!: boolean; //保存ボタン表示フラグ

  @Prop({ default: true }) needCancel!: boolean; //キャンセルボタン表示フラグ

  @Prop({ default: "保存する" }) saveLabel!: string; //保存ボタンラベル

  @Prop({ default: "キャンセル" }) cancelLabel!: string; //キャンセルボタンラベル

  @Prop({ default: "400px" }) width!: string; //横幅最大

  @Prop({ default: true }) persistent!: boolean; //画面外クリックで閉じるかどうか

  @Prop({ default: true }) isActionArea!: boolean; //操作エリアを表示するかどうか

  @Prop({ default: "削除する" }) deleteLabel!: string; //削除ボタンラベル

  @Prop({ default: "" }) supplementText!: string; //タイトル横に出す補足文

  private get Value(): boolean {
    return this.value;
  }

  private set Value(newValue: boolean) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  @Emit()
  private cancel() {
    return;
  }

  @Emit()
  private save() {
    return;
  }

  @Emit()
  private remove() {
    return;
  }
}
