export const linkHeaders = [
  {
    text: "ID",
    value: "id",
    sortable: false,
    align: "start",
  },
  {
    text: "タイトル",
    value: "title",
    sortable: false,
    align: "start",
  },
  {
    text: "掲載状況",
    value: "status",
    sortable: false,
    align: "center",
    width: "98px",
  },
  {
    text: "掲載開始日",
    value: "start_date",
    sortable: false,
    align: "center",
    width: "128px",
  },
  {
    text: "掲載終了日",
    value: "end_date",
    sortable: false,
    align: "center",
    width: "128px",
  },
  {
    text: "カテゴリ",
    value: "service_link_category.name",
    sortable: false,
    align: "start",
    width: "128px",
  },
  {
    text: "ホーム",
    value: "show_home_str",
    sortable: false,
    align: "center",
  },
  {
    text: "サービスリンク一覧",
    value: "show_service_links_str",
    sortable: false,
    align: "center",
  },
  {
    text: "リンク遷移",
    value: "str_type",
    sortable: false,
    align: "start",
    width: "128px",
  },
];

export const linkSortableHeaders = [
  {
    text: "ID",
    value: "id",
    sortable: true,
    align: "start",
  },
  {
    text: "タイトル",
    value: "title",
    sortable: true,
    align: "start",
  },
  {
    text: "掲載状況",
    value: "status",
    sortable: true,
    align: "center",
    width: "98px",
  },
  {
    text: "掲載開始日",
    value: "start_date",
    sortable: true,
    align: "center",
    width: "128px",
  },
  {
    text: "掲載終了日",
    value: "end_date",
    sortable: true,
    align: "center",
    width: "128px",
  },
  {
    text: "カテゴリ",
    value: "service_link_category.name",
    sortable: true,
    align: "start",
    width: "128px",
  },
  {
    text: "ホーム",
    value: "show_home_str",
    sortable: true,
    align: "center",
  },
  {
    text: "サービスリンク一覧",
    value: "show_service_links_str",
    sortable: true,
    align: "center",
  },
  {
    text: "リンク遷移",
    value: "str_type",
    sortable: true,
    align: "start",
    width: "128px",
  },
  {
    text: "更新日時",
    value: "updated_at",
    sortable: true,
    align: "start",
    width: "128px",
  },
];

export const linkMailLogHeaders = [
  {
    text: "送信日時",
    value: "created_at",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "メール送信",
    value: "str_status",
    sortable: true,
    align: "center",
    width: "150px",
  },
  {
    text: "お問合せ番号",
    value: "inquiry_no",
    sortable: true,
    align: "center",
    width: "150px",
  },
  {
    text: "システムID",
    value: "system_id",
    sortable: true,
    align: "center",
    width: "150px",
  },
  {
    text: "企業コード",
    value: "co_code",
    sortable: true,
    align: "center",
    width: "150px",
  },
  {
    text: "事業所コード",
    value: "kst_no",
    sortable: true,
    align: "center",
    width: "150px",
  },
  {
    text: "サービスリンク名",
    value: "service_link_name",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "法人名",
    value: "corp_name",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "名前",
    value: "name",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "メールアドレス",
    value: "mail_address",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "電話番号",
    value: "tel",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "事業所名",
    value: "station_name",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "郵便番号",
    value: "post_code",
    sortable: true,
    align: "start",
    width: "150px",
  },
  {
    text: "住所",
    value: "address",
    sortable: true,
    align: "start",
    width: "150px",
  },
];
