




















































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { Choice } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { AdminUser, AdminAuth } from "@/model/adminUser";
import { VForm } from "@/types";
import UserMixin from "@/mixins/userMixin";

@Component({})
export default class Profile extends Mixins(
  UserMixin,
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("user_form") private readonly form!: VForm;

  private isEditMode = false;
  private show1 = false;
  private show2 = false;

  private edit_user = {
    id: 0,
    user_id: "",
    mail_address: "",
    last_name: "",
    first_name: "",
    department: "",
    position: "",
    auth_id: 1,
    admin_auth: {} as AdminAuth,
    created_at: "",
    updated_at: "",
    deleted_at: "",
  } as AdminUser;

  password = "";
  confirm_password = "";

  private adminAuthChoices: Choice[] = [];

  /** ユーザーの削除ができるかどうか */
  private get removable(): boolean {
    // ログイン中のユーザーID
    const userId = this.User.id;
    // 編集対象のユーザーID
    const targetUserId = this.edit_user.id;

    // 新規作成時(targetUserId=0)の場合は削除できない
    if (targetUserId == 0) {
      return false;
    }

    // 自身は削除できない
    return userId != targetUserId;
  }

  mounted(): void {
    const userId = Number(this.$route.params.id);
    if (userId) {
      this.isEditMode = true;
      this.getAdminUser(userId);
    }

    this.postJsonCheck(window.base_url + "/api/admin/auths/get", {}, (res) => {
      for (const adminAuth of res.data.auths) {
        this.adminAuthChoices.push({
          text: adminAuth.name,
          value: adminAuth.id,
        });
      }
    });
  }

  private passwordRequired(password: string, message = ""): boolean | string {
    // 編集時で空の場合は必須ではない
    if (
      this.edit_user.id > 0 &&
      this.password == "" &&
      this.confirm_password == ""
    ) {
      return true;
    }

    if (password == "") {
      return message;
    }

    return true;
  }

  private checkSamePassword(): boolean | string {
    if (this.password == "" || this.confirm_password == "") {
      return true;
    }

    if (this.password !== this.confirm_password) {
      return "パスワードが一致していません。";
    }
    return true;
  }

  private getAdminUser(adminId: number): void {
    if (adminId == 0) return;
    this.postJsonCheck(
      window.base_url + "/api/admin/admin_users/get",
      {
        admin_user_id: adminId,
      },
      (res) => {
        if (res.data.admin_users) {
          this.edit_user = res.data.admin_users[0];
        }
      }
    );
  }

  /** 保存処理 */
  private async saveAdminUser(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/admin_users/save",
      {
        admin_user: this.edit_user,
        password: this.password,
      },
      () => {
        this.$router.push({ path: "/user/list" });
      }
    );
  }

  /** 削除処理 */
  private async deleteAdminUser() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    if (this.edit_user.id != 0) {
      this.postJsonCheck(
        window.base_url + "/api/admin/admin_users/delete",
        {
          admin_user: this.edit_user,
        },
        () => {
          this.$router.push({ path: "/user/list" });
        }
      );
    }
  }

  /** ユーザーIDの重複チェック */
  private checkDuplicated() {
    this.postJsonCheck(
      window.base_url + "/api/admin/admin_users/check",
      {
        admin_user_id: this.edit_user.user_id,
        admin_id: this.edit_user.id,
      },
      async (res) => {
        const message =
          res.data.is_duplicated == 1
            ? "既に使われているユーザーIDです。"
            : "利用可能です。";
        await this.$openAlert(message);
      }
    );
  }

  /** パスワードチェック */
  private checkPassword(password: string, message = ""): boolean | string {
    if (password == "") {
      return true;
    }

    return !password || /^[!-~]+$/.test(password) || message;
  }
}
