var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agreements,"search":_vm.searchKey,"custom-filter":_vm.customFilter},on:{"click:row":_vm.row},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.detail(item.id)}}},[_vm._v("詳細")])]}},{key:"item.corp_name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-chip',{staticClass:"my-1 mr-2",attrs:{"dark":"","x-small":"","label":"","color":_vm.idToText(
            item.agreement_historys[0].confflag,
            _vm.AGREEMENT_STATUS_COLOR
          )}},[_c('span',{staticStyle:{"display":"inline-block","width":"40px","text-align":"center"}},[_vm._v(_vm._s(_vm.idToText(item.agreement_historys[0].confflag, _vm.AGREEMENT_STATUS)))])]),_c('span',[_vm._v(_vm._s(item.co_name))])],1)]}},{key:"item.office_name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"p-table-cell--around"},_vm._l((item.offices),function(office,index){return _c('div',{key:index},[_c('v-row',[_c('v-chip',{staticClass:"my-1 mr-2",attrs:{"dark":"","x-small":"","label":"","color":_vm.idToText(office.billing_info.confflag, _vm.OFFICE_STATUS_COLOR)}},[_c('span',{staticStyle:{"display":"inline-block","width":"100px","text-align":"center"}},[_vm._v(_vm._s(_vm.idToText(office.billing_info.confflag, _vm.OFFICE_STATUS)))])]),_c('span',[_vm._v(_vm._s(office.office_name))])],1)],1)}),0)]}},{key:"item.kst_no",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"p-table-cell--around"},_vm._l((item.offices),function(office,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(office.kst_no))])])}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }