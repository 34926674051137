










































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { OfficeStaffSearchObj } from "@/types";

@Component({})
export default class OfficeStaffSelectDialog extends Mixins(UtilMixin) {
  /** 検索条件 */
  @Prop() searchObj!: OfficeStaffSearchObj;

  /** 検索クリック時 */
  @Emit("search")
  search() {
    return;
  }
}
