






















import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { INQUIRY_STATUS } from "@/consts/inquiry";

interface NumberComboBox {
  internalSearch: string;
}

@Component({})
export default class StatusSelectDialog extends Mixins(AxiosMixin, UtilMixin) {
  @Prop() value!: number;

  private INQUIRY_STATUS = INQUIRY_STATUS;

  private dialog = false;

  private innerValue = 0;
  private dummyInnerValue = 0;

  /** 問い合わせステータスの選択肢を生成 */
  private get FILTERED_INQUIRY_STATUS() {
    // 選択可能なステータスに限定
    return this.INQUIRY_STATUS.filter((data) => data.selectable);
  }

  @Emit()
  private save(): number {
    return this.innerValue;
  }

  public open() {
    this.dialog = true;
    this.$nextTick(() => {
      this.innerValue = this.value;
      this.dummyInnerValue = this.value;
    });
  }
  public close() {
    this.dialog = false;
  }

  onChangeStatus() {
    this.innerValue = this.dummyInnerValue;
  }
}
