import { Vue, Component } from "vue-property-decorator";

/**
 * date-pickerに和暦表示を追加
 */
@Component
export default class DatePickerYearFormatMixin extends Vue {
  /** 元号判定 */
  private year2Wareki(year: number) {
    let wYear = 0;
    let wYearStr = "";
    let gen = "";
    if (year > 2018) {
      wYear = year - 2018;
      gen = "令和";
    } else if (year > 1988) {
      wYear = year - 1988;
      gen = "平成";
    } else if (year > 1925) {
      wYear = year - 1925;
      gen = "昭和";
    } else if (year > 1911) {
      wYear = year - 1911;
      gen = "大正";
    } else if (year > 1867) {
      wYear = year - 1867;
      gen = "明治";
    }
    if (wYear === 1) {
      wYearStr = "元";
    } else {
      wYearStr = String(wYear);
    }

    return gen !== "" ? gen + wYearStr + "年" : false;
  }

  /** 年用 */
  private jpvYearFormat(str: string) {
    if (!str) return "";
    const yearStr = str.split("-")[0];
    const beforeNengo = this.getWarekiSwitch(yearStr);
    const year = Number(yearStr);
    if (year > 1867) {
      return this.year2Wareki(year) + beforeNengo + "(" + yearStr + ")";
    }
    /** 明治元年(1868)より前は西暦で返す */
    return yearStr + "年";
  }

  /** 年月、年月日用 */
  private jpvHeaderDateFormat(str: string) {
    if (!str) return "";
    const [yearStr, monthStr] = str.split("-");
    const beforeNengo = this.getWarekiSwitch(yearStr);
    const year = Number(yearStr);

    let formatDate: any;
    if (year > 1867) {
      formatDate = this.year2Wareki(year) + beforeNengo + "(" + yearStr + ")";
    } else {
      /** 明治元年(1868)より前は西暦で返す */
      formatDate = yearStr + "年";
    }

    if (monthStr) {
      formatDate += monthStr + "月";
    }

    return formatDate;
  }

  /** 年号の切り替わり年は前年号も表示させる */
  private getWarekiSwitch(nengo: string) {
    switch (nengo) {
      case "1868":
        return "/慶応4年";
      case "1926":
        return "/大正15年";
      case "1989":
        return "/昭和64年";
      case "2019":
        return "/平成31年";
    }
    return "";
  }
}
