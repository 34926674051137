
























































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { VForm } from "@/types";
import {
  AiMonthReportSetting,
  DefaultAiMonthReportSetting,
} from "#/model/admin/index";
import { ListPromptTemplate } from "@/views/aimonthreport/types";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {
    AppAuthButton,
  },
})
/**
 * 月次報告書自動作成管理 プロンプトテンプレート一覧
 */
export default class AiMonthReportPromptTemplateList extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** Ref */

  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** 月次報告書AI自動作成設定 */
  private setting: AiMonthReportSetting = DefaultAiMonthReportSetting();

  /** フィルター用キーワード */
  private searchKeyword = "";

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  /** プロンプトテンプレート一覧 */
  private promptTemplates: ListPromptTemplate[] = [];

  /** データテーブル用ヘッダー */
  private headers = [
    {
      text: "テンプレート名",
      align: "start",
      value: "name",
      width: "180px",
      filterable: true,
      sortable: false,
    },
    {
      text: "コメント",
      align: "start",
      value: "comment",
      width: "180px",
      filterable: true,
      sortable: false,
    },
    {
      text: "AI種類",
      align: "start",
      value: "llm_type_name",
      filterable: false,
      width: "180px",
    },
    {
      text: "最終更新者",
      align: "start",
      value: "editor_admin_name",
      filterable: false,
      sortable: false,
    },
    {
      text: "最終更新日時",
      align: "start",
      value: "updated_at",
      filterable: false,
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
    },
  ];

  /** getter */

  /** 自動作成テンプレート選択肢 */
  private get PromptTemplateChoice(): ListPromptTemplate[] {
    return this.promptTemplates;
  }

  /** 別添自動作成テンプレート選択肢 */
  private get BettenPromptTemplateChoice(): ListPromptTemplate[] {
    return [
      {
        id: 0,
        name: "未選択",
        comment: "",
        llm_type_name: "",
        editor_admin_name: "",
        created_at: "",
        updated_at: "",
      },
      ...this.promptTemplates,
    ];
  }

  /** 関数 */

  created() {
    this.fetch();
    this.fetchSetting();
  }

  /** 取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/prompts/get",
      {},
      async (res) => {
        this.promptTemplates = res.data.prompt_templates;
      }
    );
  }

  /** 自動作成設定取得 */
  private fetchSetting() {
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/setting/get",
      {},
      async (res) => {
        this.setting = res.data.ai_month_report_setting;
      }
    );
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** 編集画面へ遷移 */
  private moveEditPage(id: number) {
    this.$router.push({ path: `/ai-monthreport/prompt/edit/${id}` });
  }

  /** 月次報告書AI自動作成設定保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/setting/save",
      { ai_month_report_setting: this.setting },
      async () => {
        this.fetch();
        this.fetchSetting();
        this.$openAlert("保存が完了しました。");
      }
    );
  }
}
