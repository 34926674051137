

































































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { MaintenanceInfo } from "@/model/agreement";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class MaintenanceInfoDialog extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 詳細表示対象のメンテナンス情報 */
  @Prop({}) maintenance!: MaintenanceInfo;

  /** 編集ボタン表示フラグ */
  @Prop({ default: false }) editable!: boolean;

  /** ダイアログ表示・非表示用フラグ */
  private dialog = false;

  public open() {
    this.dialog = true;
  }

  public close() {
    this.dialog = false;
  }

  @Emit()
  private edit() {
    return this.maintenance.id;
  }
}
