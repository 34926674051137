












































































































import { Component, Prop, Emit, Mixins, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Office } from "@/model/office";
import { Agreement } from "@/model/agreement";
import { VForm } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import ChangeHistory from "@/components/common/ChangeHistory.vue";
import BillingCommentViewer from "@/components/contractreception/common/BillingCommentViewer.vue";

/**
 * 事業所契約承認作業をステップ表示するコンポーネント
 */
@Component({
  components: { AppAuthButton, ChangeHistory, BillingCommentViewer },
})
export default class extends Mixins(UtilMixin, RulesMixin, AxiosMixin) {
  @Ref("estimateEditor") private readonly estimateNoForm!: VForm;

  @Prop({ default: () => ({}) }) private readonly agreement!: Agreement;

  @Prop({ default: () => ({}) }) private readonly office!: Office;

  /** 変更履歴フラグ */
  @Prop({ default: false }) private readonly saveHistoryLog!: boolean;

  @Emit() private save() {
    return;
  }

  /** 変更履歴フラグ更新時 */
  @Emit("update:saveHistoryLog") private updateSaveHistoryLog(
    newValue: boolean
  ) {
    return newValue;
  }

  /** 契約状態 */
  private confflag = -2;

  /** 変更履歴フラグ */
  private get SaveHistoryLog() {
    return this.saveHistoryLog;
  }
  private set SaveHistoryLog(value: boolean) {
    this.updateSaveHistoryLog(value);
  }

  /** 見積もり番号入力ステップで保存ボタンクリック時 */
  private async saveEstimateNo() {
    if (!this.estimateNoForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/billing_info/save",
      {
        billing: this.office.billing_info,
        agreement_id: this.agreement.id,
        saving_confflag: this.confflag,
      },
      () => {
        this.save();
      }
    );
  }

  /** 見積もり番号のバリデーションチェック */
  private checkEstimateNo(data: string, message: string): boolean | string {
    if (data == "") {
      return true;
    }

    if (!data.match(/.*\S+.*/)) {
      return message;
    }

    return true;
  }
}
