


























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { ServiceLink } from "@/model/serviceLink";
import Sortable from "sortablejs";
import { linkHeaders } from "@/components/serviceLink/const";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class SortTable extends Mixins(AxiosMixin) {
  @Prop({}) serviceLinks!: ServiceLink[];
  @Prop({}) headers!: unknown[];
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "並び順保存" }) saveLabel!: string;
  @Prop({ default: 0 }) sortType!: number;
  @Prop({ default: "" }) suffix!: string;
  private sortable?: Sortable = undefined; //SortableObject
  private sortNos: number[] = [];

  searchText = "";

  mounted() {
    const elmSortList = document.querySelector(
      `.p-link-sort__list--${this.suffix} tbody`
    ) as HTMLElement;

    this.sortable = new Sortable(elmSortList, {
      animation: 100,
      ghostClass: "ghost",
      onEnd: (e) => {
        // 初回の並び順を保持
        if (this.sortNos.length == 0) {
          this.sortNos = this.serviceLinks.map((serviceLink) => {
            return this.sortType === 1
              ? serviceLink.sort_no
              : serviceLink.home_sort_no;
          });
        }

        if (e.oldIndex !== undefined && e.newIndex !== undefined) {
          const rowSelected = this.serviceLinks.splice(e.oldIndex, 1)[0];
          this.serviceLinks.splice(e.newIndex, 0, rowSelected);
        }
      },
    });
  }

  @Emit("clickSave")
  private clickSave() {
    // 並び替えした時のみ処理を行う
    if (this.sortNos.length != 0) {
      // ソート番号を振り直す
      if (this.sortType === 1) {
        // サービスリンク画面
        this.serviceLinks.forEach((serviceLink, idx) => {
          serviceLink.sort_no = this.sortNos[idx];
        });
      } else {
        // ホーム画面
        this.serviceLinks.forEach((serviceLink, idx) => {
          serviceLink.home_sort_no = this.sortNos[idx];
        });
      }
    }

    return this.serviceLinks;
  }
}
