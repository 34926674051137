



















































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import { BOARD_DIV } from "./consts";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import TargetOfficeSelector from "@/components/admin_common/TargetOfficeSelector.vue";
import {
  BulletinBoardAdmin,
  DEFAULT_BULLETIN_BOARD_ADMIN,
} from "@/model/bulletinBoard";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, TargetOfficeChoice } from "@/types";

interface DispBulletinBoardAdmin extends BulletinBoardAdmin {
  target_offices: TargetOfficeChoice[];
}

@Component({
  components: {
    TextDayChangePicker,
    TargetOfficeSelector,
    TrumbowygEditor,
    AppAuthButton,
  },
})
export default class InfoManagementForm extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Watch("$route")
  private changeUrl() {
    this.fetchBulletinboard();
  }
  /** フォームバリデーション用 */
  @Ref("form") private readonly form!: VForm;

  private BOARD_DIV = BOARD_DIV;

  /** 表示対象選択エリアを表示するかどうか */
  private noticeTarget = 0;

  /** 作成・編集対象のお知らせ情報 */
  private board: DispBulletinBoardAdmin = {
    ...DEFAULT_BULLETIN_BOARD_ADMIN,
    target_offices: [],
  };

  /** 送信先事業所情報 */
  private targetOffices: TargetOfficeChoice[] = [];

  /** 選択可能事業所情報 */
  private offices: TargetOfficeChoice[] = [];

  //閉じるボタンクリック
  private async cancel() {
    if (
      await this.$openConfirm(
        "編集を終了します。\n編集中の場合は内容が破棄されます。"
      )
    ) {
      this.$router.push({ path: "/information/current" });
    }
  }

  public created(): void {
    this.fetchBulletinboard();
  }

  /** お知らせ取得 */
  private fetchBulletinboard() {
    // id=0(新規作成)の時も事業所取得したいのでリクエスト投げる
    const boardId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/bulletinboard/get",
      {
        ids: [boardId],
      },
      (res) => {
        this.offices = res.data.offices;

        if (res.data.bulletin_board_admins.length > 0) {
          this.board = res.data.bulletin_board_admins[0];
          this.targetOffices = this.board.target_offices.slice();
        }

        // 送信先が既に存在している場合
        this.noticeTarget = this.targetOffices.length > 0 ? 1 : 0;

        // 編集する場合は必ずステータスを通常にする
        this.board.status = 1;
      }
    );
  }

  /** 保存処理 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか?"))) {
      return;
    }

    if (this.noticeTarget == 1 && this.targetOffices.length == 0) {
      if (
        !(await this.$openConfirm(
          "事業所が選択されていないため、全体へのお知らせになります。よろしいですか？"
        ))
      ) {
        return;
      }
    }

    // リクエストサイズ削減のため、不要な事業所情報を空で渡す
    // 結局、this.targetOfficesが表示対象事業所情報を持っているので問題なし
    this.board.target_offices = [];
    this.board.bulletin_board_admin_offices = [];

    this.postJsonCheck(
      window.base_url + "/api/admin/bulletinboards/save",
      {
        bulletin_board_admin: this.board,
        target_offices: this.targetOffices,
      },
      async (res) => {
        // 通知メールを送信できなかったエラーメッセージを個別で表示
        if (res.data.send_error_message) {
          await this.$openAlert(res.data.send_error_message);
        }
        this.$router.push({ path: "/information/current" });
      }
    );
  }

  /** 削除 */
  private async deleteBoard() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/bulletinboards/delete",
      {
        bulletin_board_admin: this.board,
      },
      () => {
        this.$router.push({ path: "/information/current" });
      }
    );
  }

  /** 全体 <-> 選択の切り替え時 */
  private onChangeTarget() {
    // 送信先事業所情報を空にする
    this.targetOffices = [];
  }
}
