









































import { Component, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import PortalSearchBox from "@/components/portal/PortalSearchBox.vue";
import { PortalSearchParams } from "@/components/portal/PortalSearchBox.vue";

import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import PortalOfficeTable from "@/views/portal/PortalOfficeTable.vue";

@Component({
  components: {
    AppTextField,
    PortalSearchBox,
    AppAuthButton,
    PortalOfficeTable,
  },
})
export default class PortalOrganizationUnapprovedList extends Mixins(
  AxiosMixin
) {
  private searchKey = ""; //絞り込み文字列

  /** タブ情報 */
  private currentTab = 0;

  private offices = [];
  private reviewings = [];
  private listingReviewings = [];
  private rejects = [];

  private headers = [
    { text: "id", value: "id", width: "20px" },
    { text: "事業所名", value: "name", width: "160px" },
    { text: "承認状態", value: "approval_status" },
    { text: "初回掲載情報承認状態", value: "listing_review_status" },
    { text: "医療機関コード", value: "medical_institution_code" },
    { text: "事業所番号", value: "office_code" },
    { text: "編集", value: "action" },
    { text: "掲載情報公開状態", value: "is_public" },
  ];

  //データ取得
  private fetchList(searchObj: PortalSearchParams): void {
    searchObj.approval_statuses = [0, 1];
    searchObj.listing_review_statuses = [1];

    this.postJsonCheck(
      window.base_url + "/api/admin/portal_offices/get",
      searchObj,
      (res) => {
        this.offices = res.data.portal_offices ?? [];
        this.reviewings = res.data.reviewing_portal_offices ?? [];
        this.listingReviewings =
          res.data.listing_reviewing_portal_offices ?? [];
        this.rejects = res.data.reject_portal_offices ?? [];
      }
    );
  }

  /** 詳細ボタンクリック時 */
  private goDetail(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/portal-office/detail/${id}` });
  }
}
