




























































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { PortalOfficialAnnouncement } from "@/model/portal";
import {
  PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION,
  PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS,
} from "@/consts/portal";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class PortalOfficialAnnouncementList extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private items: PortalOfficialAnnouncement[] = [];
  private PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION =
    PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION;
  private PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS =
    PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS;

  searchText = "";

  get filteredItems() {
    if (!this.searchText) return this.items;

    return this.items.filter((item) => {
      return item.title != null && item.title.includes(this.searchText);
    });
  }

  private previewIsShown = false;

  /** 詳細ボタンクリック時 */
  private goEdit(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal-announcement/edit/${id}` });
  }

  created(): void {
    this.fetch();
  }

  private fetch(): void {
    this.items = [];
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_official_announcement/list",
      {},
      (res) => {
        if (res.data.official_announcements) {
          this.items = res.data.official_announcements;
        }
      }
    );
  }
}
