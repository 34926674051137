


























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { ServiceLink } from "@/model/serviceLink";
import ServiceLinkList from "@/components/serviceLink/ServiceLinkList.vue";

@Component({
  components: {
    AppAuthButton,
    ServiceLinkList,
  },
})
export default class extends Mixins(AxiosMixin) {
  private serviceLinks: ServiceLink[] = [];
  private searchText = "";

  private toEdit(id: number) {
    this.$router.push({ path: "/service_link/edit/" + id });
  }

  get ServiceLinks() {
    return this.serviceLinks;
  }

  created(): void {
    this.fetch();
  }

  private fetch(): void {
    this.postJsonCheck(
      window.base_url + "/api/service_links/get",
      {},
      (res) => {
        this.serviceLinks = res.data.service_links;
      }
    );
  }
}
