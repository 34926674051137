



























import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import FileUpload from "#/components/FileUpload.vue";
import { BillingFile } from "@/model/agreement/billing";

@Component({
  components: {
    FileUpload,
  },
})
export default class BillingFileViewer extends Mixins(UtilMixin, AxiosMixin) {
  /** 対象の関連ファイル情報 */
  @Prop({ default: () => [] as BillingFile[] }) billingFiles!: BillingFile[];

  /** ファイルダウンロードAPIパス */
  private previewApiUrl = `${window.base_url}/api/preview`;

  /** プレビュー処理 */
  private preview(filePath: string) {
    this.postJsonCheck(this.previewApiUrl + "?path=" + filePath, {}, (res) => {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = res.data.url;
      a.click();
    });
  }
}
