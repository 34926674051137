import { Choice } from "@/types";

/** 企業契約情報の未確定・確定ステータス */
export const AGREEMENT_STATUS: Choice[] = [
  { text: "未確定", value: -2 },
  { text: "確定", value: 1 },
];

/** 未確定・確定ステータスの色 */
export const AGREEMENT_STATUS_COLOR: Choice[] = [
  { text: "orange darken-1", value: -2 },
  { text: "green darken-1", value: 1 },
];

/** 事業所契約承認作業ステータス */
export const OFFICE_STATUS: Choice[] = [
  { text: "見積もり番号入力待ち", value: -2 },
  { text: "契約内容入力待ち", value: -1 },
  { text: "承認待ち", value: 0 },
  { text: "承認済み", value: 1 },
];

/** 事業所契約承認作業ステータスの色 */
export const OFFICE_STATUS_COLOR: Choice[] = [
  { text: "orange darken-1", value: -2 },
  { text: "light-blue darken-1", value: -1 },
  { text: "blue-grey lighten-2", value: 0 },
  { text: "red darken-1", value: 1 },
];

/** 事業所契約承認作業コメントありステータスの色 */
export const OFFICE_COMMENT_STATUS_COLOR = "red darken-1";
