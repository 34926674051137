




















































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import {
  InquiryAnswer,
  InquiryAnswerFile,
  DEFAULT_INQUIRY_ANSWER,
  DEFAULT_INQUIRY_ANSWER_FILE,
} from "@/model/inquiry";

@Component({
  components: {
    FileUpload,
  },
})
export default class InquiryAnswerFileUploader extends Mixins(UtilMixin) {
  @Prop({ default: () => DEFAULT_INQUIRY_ANSWER })
  inquiryAnswer!: InquiryAnswer;
  /** アップロード可能なファイルタイプ */
  private accepts = [];
  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";
  /** ファイルダウンロードAPIパス */
  private previewApiUrl = window.base_url + "/api/preview";
  /** 添付可能数 */
  private FILE_LIMIT = 5;
  /** 保存先 */
  private get imagePath(): string {
    return `admin/inquiry/${this.inquiryAnswer.inquiry_id}/`;
  }

  @Watch("inquiryAnswer", { immediate: true })
  onChangeInquiryAnswer() {
    this.inquiryAnswer.inquiry_answer_files =
      this.inquiryAnswer.inquiry_answer_files.map(
        (inquiryAnswerFile: InquiryAnswerFile) => {
          const file = { ...inquiryAnswerFile };
          file.path = file.file_path;
          return file;
        }
      );
  }

  /** 添付枠を追加 */
  private add(): void {
    const inquiryAnswerFile = { ...DEFAULT_INQUIRY_ANSWER_FILE };
    inquiryAnswerFile.inquiry_answer_id = this.inquiryAnswer.id;
    this.inquiryAnswer.inquiry_answer_files.push(inquiryAnswerFile);
  }

  /** 添付ファイル枠 */
  private async remove(index: number): Promise<void> {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }

    this.inquiryAnswer.inquiry_answer_files.splice(index, 1);
  }
}
