
















import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import AppExpansionPanels from "./expantion/AppExpansionPanels.vue";
import AppExpansionPanel from "./expantion/AppExpansionPanel.vue";
import AppExpansionPanelHeader from "./expantion/AppExpansionPanelHeader.vue";
import AppExpansionPanelContent from "./expantion/AppExpansionPanelContent.vue";

@Component({
  components: {
    AppExpansionPanels,
    AppExpansionPanel,
    AppExpansionPanelHeader,
    AppExpansionPanelContent
  }
})
export default class AppExpansionPanelGroups extends Vue {
  @Prop() header!: string;
  @Prop() value!: string;
  @Prop({ default: false }) noPadding!: boolean;

  @Emit()
  private input(newValue: string): string {
    return newValue;
  }

  private get Value(): string {
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }
}
