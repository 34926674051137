













































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import { ListInquiry, DEFAULT_LIST_INQUIRY } from "@/views/inquiry/types";
import { InquiryAnswer } from "@/model/inquiry";
@Component({
  components: {
    AttachmentViewer,
    AnswerContent,
  },
})
export default class PastInquiryNavigationDrawer extends Mixins(UtilMixin) {
  /** ナビゲーションドロワー制御用 */
  @Prop({ default: false }) value!: boolean;

  /** 表示対象の問い合わせ情報 */
  @Prop({ default: () => DEFAULT_LIST_INQUIRY }) inquiry!: ListInquiry;

  /** 編集可能かどうか */
  @Prop({ default: false }) editable!: boolean;

  private get Drawer(): boolean {
    return this.value;
  }

  private set Drawer(newValue: boolean) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  @Emit("insert:answer")
  private insertAnswerContent(answer: InquiryAnswer) {
    return answer;
  }
}
