

















































import { Component, Mixins, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeSearchBox from "@/components/admin_common/OfficeSearchBox.vue";
import { OfficeSearchObj } from "@/types";
import { Office } from "@/model/office";
import { Agreement } from "@/model/agreement";

@Component({
  components: {
    OfficeSearchBox,
  },
})
export default class OfficeSelectDialog extends Mixins(AxiosMixin, UtilMixin) {
  /** 検索条件 */
  private searchObj: OfficeSearchObj = {
    co_code: "",
    kst_no: "",
    office_name: "",
  };

  private headers = [
    {
      text: "法人名",
      align: "start",
      sortable: false,
      value: "co_name",
      width: "240px",
    },
    {
      text: "事業所名",
      align: "start",
      sortable: false,
      value: "office_name",
      width: "600px",
    },
  ];

  /** 事業所情報 */
  private agreements: Agreement[] = [];

  /** ダイアログ操作用 */
  private dialog = false;

  private fetchAgreements() {
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      {
        ...this.searchObj,
        is_display_confirm: 1,
        is_get_before_migration_data: 1,
      },
      (res) => {
        this.agreements = res.data.agreements;
      }
    );
  }

  public open() {
    this.dialog = true;
  }
  public close() {
    this.reset();
    this.dialog = false;
  }

  /** データリセット */
  private reset() {
    this.searchObj = {
      co_code: "",
      kst_no: "",
      office_name: "",
    };

    this.agreements = [];
  }

  /** 検索クリック時 */
  private async search() {
    if (
      this.searchObj.kst_no == "" &&
      this.searchObj.co_code == "" &&
      this.searchObj.office_name == ""
    ) {
      await this.$openAlert("検索条件は必ず入力してください。");
      return;
    }

    this.fetchAgreements();
  }

  @Emit("select:office")
  select(agreement: Agreement, office: Office) {
    this.close();
    return { agreement: agreement, office: office };
  }
}
