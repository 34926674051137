import Base from "../base";
import { Billing, setBilling } from "./billing";
import { OfficeHistory, setOfficeHistory } from "./officeHistory";
import { SateliteHistory, setSateliteHistory } from "./sateliteHistory";

// ほのぼの事業所
export interface OfficeHonobonoOffice extends Base {
  id: number;
  agreement_id: number;
  office_id: number;
  honobono_sv_jigyo_id: number; // ほのぼのサービス事業者ID
  honobono_houjin_id: number; // ほのぼの法人ID
  honobono_shisetu_id: number; // ほのぼの施設ID
  honobono_sv_kind_cd: number; // ほのぼのサービス種類
  honobono_jigyo_number: string; // ほのぼの事業所番号
  honobono_jigyo_knj: string; // ほのぼの事業所名
}
export const DefaultHonobonoOffice = (): OfficeHonobonoOffice => ({
  id: 0,
  agreement_id: 0,
  office_id: 0,
  honobono_sv_jigyo_id: 0,
  honobono_houjin_id: 0,
  honobono_shisetu_id: 0,
  honobono_sv_kind_cd: 0,
  honobono_jigyo_number: "",
  honobono_jigyo_knj: "",
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "" //削除日時
});

// 事業所
export interface Office extends Base {
  id: number; // 事業所ID
  agreement_id: number; // 事業所名
  kst_no: string; // 医療機関コード
  office_div: number; // 事業所番号
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
  billings: Billing[]; //請求情報
  office_historys: OfficeHistory[]; //事業所履歴
  satelite_historys: SateliteHistory[]; //サテライト履歴
  office_info: OfficeHistory; //事業所履歴（現在有効）
  satelite_info: SateliteHistory; //サテライト履歴（現在有効）
  billing_info: Billing; //請求情報（現在有効）
  office_name: string; //事業所名
  satelites: SateliteHistory[]; //サテライト
  office_honobono_offices: OfficeHonobonoOffice[];
  add_total_size: number; //加算ストレージサイズ（MB）移行データ等、初期で加算しておくストレージ容量
  recent_total_size: number; //最新の合計サイズ（MB）
}

export const setOffice = (): Office => ({
  id: 0, // 事業所ID
  agreement_id: 0, // 事業所名
  kst_no: "", // 医療機関コード
  office_div: 0, // 事業所番号
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "", //削除日時
  billings: [], //請求情報
  office_historys: [], //事業所履歴
  satelite_historys: [], //サテライト履歴
  office_info: setOfficeHistory(), //事業所履歴（現在有効）
  satelite_info: setSateliteHistory(), //サテライト履歴（現在有効）
  billing_info: setBilling(), //請求情報（現在有効）
  office_name: "", //事業所名
  satelites: [], //サテライト
  office_honobono_offices: [],
  add_total_size: 0,
  recent_total_size: 0
});

export const DefaultOffice = setOffice;
