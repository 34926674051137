import Base from "../base";
import { OfficeHistoryMail } from "./officeHistoryMail";
import { OfficeHistorySameBuilding } from "./officeHistorySameBuilding";
import { OfficeHistoryOverTherapistVisitCount } from "./officeHistoryOverTherapistVisitCount";
import { OfficeHistoryOverTherapistTargetAddition } from "./officeHistoryOverTherapistTargetAddition";

//事業所履歴
export interface OfficeHistory extends Base {
  id: number; //ID
  office_id: number; //'事業所ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  name: string; //'事業所名',
  name_notification: string; //'事業所名(審査支払機関に届け出た名称)',
  post_code: string; //'郵便番号',
  pref: number; //'都道府県',
  city: string; //'市区',
  area: string; //'町村番地',
  building: string; //'建物',
  tel_area: string; //'市外局番',
  tel_city: string; //'市内局番',
  tel_num: string; //'加入者番号',
  fax_area: string; //'市外局番',
  fax_city: string; //'市内局番',
  fax_num: string; //'加入者番号',
  medical_institution_code: string; //'医療機関コード',
  office_code: string; //'事業所番号',
  invoice_no: string; //適格請求書用登録番号(iBowレセプト利用者請求で出力)
  is_care_service: number; //'介護サービス提供\n0:なし\n1:あり',
  business_div: number; //'事業区分\n0:未選択\n1:指定\n2:基準該当',
  area_div: number; //'地域区分\n0:未選択\n1:1級\n2:2級\n3:3級\n4:4級\n5:5級\n6:6級\n7:7級\n8:その他',
  jokuso_rate_div: number; //'日々の褥瘡評価設定自立度\n0:未選択\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
  doctor_title_div: number; //'医師に対する敬称\n0:未選択\n1:先生\n2:先生 御待史\n3:先生 御侍史\n4:先生 御机下',
  is_shift: number; //'シフト管理機能表示設定\n0:表示しない\n1:表示する',
  record_divide_div: number; //'20分超えのリハ記録書IIまとめ設定\n1:20分を超えた時、記録書Ⅱを1枚にまとめる。\n2:20分毎に記録書Ⅱを作成する。',
  staff_list_div: number; //'職員リスト表示設定\n1:在職／休職／退職を表示\n2:在職／休職を表示\n3:在職のみ表示',
  service_record_disp_div: number; //'月次報告書表示設定\n0:サービス訪問（自費）記録を報告書に記載しない\n1:サービス訪問（自費）記録を報告書に記載する',
  adl_setting_div: number; //'観察項目設定区分\n0:観察項目最大21項目とし、記録書Ⅱ（カルテ部分）をA4１枚に収める\n1:観察項目の登録数制限を解除する（観察項目カテゴリ表示）',
  patient_msg_board_time: number; //'利用者メッセージボードロック時間（秒）',
  bank_name: string; //'銀行名',
  branch_name: string; //'支店名',
  account_kind: string; //'口座種別',
  account_num: string; //'口座番号',
  account_name: string; //'口座名義',
  care_service_time_required: number; //'介護_介護訪問提供時間必須フラグ\n0:必須にしない\n1:必須にする',
  care_regular_visit_service_exist: number; //'介護_定期巡回サービス提供\n0:なし\n1:あり',
  care_special_area_div: number; //'介護_特別地域加算\n0:なし\n1:あり',
  care_kinkyu_houmon_add: number; //'介護_緊急時訪問看護加算\n0:加算なし\n1:緊急時訪問看護加算Ⅱ\n2:緊急時訪問看護加算Ⅰ
  care_tokubetsu_kanri_add: number; //'介護_特別管理体制加算\n0:加算なし\n1:特別管理Ⅰ\n2:特別管理Ⅱ',
  care_terminal_care_add: number; //'介護_ターミナルケア加算\n0:加算なし\n1:加算あり',
  care_service_supply_add: number; //'介護_サービス提供体制強化加算\n0:加算なし\n1:加算あり',
  care_is_senmon_kangoshi: number; //'介護_専門看護師\n0:なし\n1:あり',
  care_small_office_area_add: number; //'介護_中山間地域等居住者提供加算\n0:加算なし\n1:加算あり',
  care_small_office_scale_add: number; //'介護_中山間地域等小規模事業所加算\n0:加算なし\n1:加算あり',
  care_kyoka_add: number; //'介護_看護体制強化加算\n0:加算なし\n1:加算Ⅱ(300単位)あり(介護・予防)\n2:加算Ⅱ(300単位)あり(予防のみ)\n3:加算Ⅱ(300単位)あり(介護のみ)\n4:加算Ⅰ(600単位)あり(介護のみ)\n5:加算Ⅰ(600単位)介護あり、加算Ⅱ(300単位)予防あり',
  care_riyousya_gensan: number; //'介護_同一建物に居住する利用者の減算\n0:なし\n1:あり',
  care_end_time_include_div: number; //'介護_ほのぼの連携:サービス提供終了時刻を連携時に含めるか含めないかの区分\n2:含めない\n1:含める\n0:Ⅰ5は＋1分しない、Ⅰ5以外は+1分する',
  care_elderly_abuse_prevention_gensan: number; //介護_高齢虐待防止未実施減算\n0:なし\n1:あり
  care_mouth_renkei_kyoka_add: number; //介護_口腔連携強化加算 0:加算なし 1:加算あり
  care_over_therapist_visit_count_flg: number; //介護_リハ職超過フラグ 0:なし 1:あり
  care_over_therapist_no_additions_flg:number; //介護_対象加算なし(6月) 0:対象加算あり 1:対象加算なし
  care_over_therapist_no_additions_july_flg:number; //介護_対象加算なし(7月) 0:対象加算あり 1:対象加算なし
  medical_kinkyu_houmon_kango_add: number; //'医療_緊急時訪問看護加算\n0:加算なし\n1:加算あり',
  medical_tokubetsu_kanri_add: number; //'医療_特別管理加算\n0:加算なし\n1:加算あり',
  medical_terminal_care_add: number; //'医療_ターミナルケア加算\n0:加算なし\n1:加算あり',
  medical_hour24_taiou_add: number; //'医療_24時間対応加算\n0:加算なし\n1:加算あり',
  medical_hour24_renraku_add: number; //'医療_24時間連絡体制加算\n0:加算なし\n1:加算あり',
  medical_is_senmon_kangoshi: number; //'医療_専門看護師\n0:なし\n1:あり',
  medical_is_seishin_kihon_ryouyouhi: number; //'医療_精神科訪問看護基本療養費\n0:なし\n1:あり',
  medical_seishin_fukusukai_add: number; //'医療_精神科複数回訪問加算\n0:加算なし\n1:加算あり',
  medical_seishin_renkei_add: number; //'医療_精神科重症患者早期集中支援管理連携加算\n0:加算なし\n1:加算あり',
  medical_kanri_ryouyouhi_div: number; //'医療_訪問看護管理療養費区分（2日目以降）1\n1:管理療養費１\n2:管理療養費２',
  medical_kinou_kyoka_ryouyouhi1: number; //'医療_機能強化型訪問看護管理療養費1\n0:加算なし\n1:加算あり',
  medical_kinou_kyoka_ryouyouhi2: number; //'医療_機能強化型訪問看護管理療養費2\n0:加算なし\n1:加算あり',
  medical_kinou_kyoka_ryouyouhi3: number; //'医療_機能強化型訪問看護管理療養費3\n0:加算なし\n1:加算あり',
  medical_is_confirm_same_building_record2: number; //'医療_同一日同一建物減算（記録提出時のチェック） 0:なし 1:あり',
  medical_is_hour24_taiou_reduce_burden: number; //'医療_24時間対応体制負担軽減の取組 0:なし 1:あり',
  medical_dx_info_util_add: number; //'医療_訪問看護医療DX情報活用加算 0:なし 1:あり',
  medical_base_up1: number; //'医療_ベースアップ評価料（Ⅰ） 0:なし 1:あり',
  medical_base_up2: number; //'医療_ベースアップ評価料（Ⅱ） 0:なし 1~18:ベースアップ評価料（Ⅱ）1~18',
  comment: string; //'履歴コメント'
  hour24_order_div: number; //'24時間体制並び順区分\n1:古いものから順に表示\n2:新しいものから順に表示',
  wiseman_office_id: number; //ワイズマン自機関番号
  before0_patientid_div: number; //ワイズマン連携利用者ID前0有効区分\n0:,無効\n1:有効'
  sukoyaka_csv_self_bear_default_check: number; //すこやかサン連携CSV出力 公費情報の負担額デフォルトチェック状態\n0:チェックなし\n1:チェックあり\nすこやかサンをなくすかどうか協議中
  sukoyaka_csv_mind_body_state_default_check: number; //すこやかサン連携CSV出力 訪問看護療養費情報の心身の状態デフォルトチェック状態\n0:チェックなし\n1:チェックあり\nすこやかサンをなくすかどうか協議中
  use_edi_flg: number; //集金代行サービス利用フラグ\n0:利用しない 1:利用する
  edi_code: string; //委託者コード
  edi_name: string; //委託者名
  transfer_div: number; //振替区分\n1:当月\n2:翌月
  transfer_day: number; //振替日
  start_date_wareki: number; //開始日（和暦）
  end_date_wareki: number; //終了日（和暦）
  updated_at_wareki: number; //更新日時（和暦）
  office_history_mails: OfficeHistoryMail[]; //メール
  office_history_same_buildings: OfficeHistorySameBuilding[]; //同一建物
  office_history_over_therapist_visit_count: OfficeHistoryOverTherapistVisitCount[]; //介護_理学療法士超過減算 リハ職訪問回数
  office_history_over_therapist_target_addition: OfficeHistoryOverTherapistTargetAddition[]; //介護_理学療法士超過減算 対象加算
  covid19_div: number; //コロナ対策区分（介護・医療共通）\n0:なし\n1:あり
  report_seel_div: number; //押印区分
  shared_file_div: number; //共有ファイル区分
  is_all_edit_visit_schedule: number;
  is_careplan_used: number; //ケアプラン連携利用するかどうか
  hour24_comment_order_div: number; //'24時間体制コメント並び順区分\n1:古いものから順に表示\n2:新しいものから順に表示',
  notices_chat_occur_report_submitted: boolean; //ヒヤリハット提出時、チャットにも通知を飛ばすかどうか
  notices_chat_patient_file_registered: boolean; //利用者ファイル登録時、チャットにも通知を飛ばすかどうか
}
export const setOfficeHistory = (): OfficeHistory => ({
  id: 0, //ID
  office_id: 0, //'事業所ID',
  start_date: "", //'開始日',
  end_date: "", //'終了日',
  name: "", //'事業所名',
  name_notification: "", //'事業所名(審査支払機関に届け出た名称)',
  post_code: "", //'郵便番号',
  pref: 0, //'都道府県',
  city: "", //'市区',
  area: "", //'町村番地',
  building: "", //'建物',
  tel_area: "", //'市外局番',
  tel_city: "", //'市内局番',
  tel_num: "", //'加入者番号',
  fax_area: "", //'市外局番',
  fax_city: "", //'市内局番',
  fax_num: "", //'加入者番号',
  medical_institution_code: "", //'医療機関コード',
  office_code: "", //'事業所番号',
  invoice_no: "", //適格請求書用登録番号(iBowレセプト利用者請求で出力)
  is_care_service: 0, //'介護サービス提供\n0:なし\n1:あり',
  business_div: 0, //'事業区分\n0:未選択\n1:指定\n2:基準該当',
  area_div: 0, //'地域区分\n0:未選択\n1:1級\n2:2級\n3:3級\n4:4級\n5:5級\n6:6級\n7:7級\n8:その他',
  jokuso_rate_div: 0, //'日々の褥瘡評価設定自立度\n0:未選択\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
  doctor_title_div: 0, //'医師に対する敬称\n0:未選択\n1:先生\n2:先生 御待史\n3:先生 御侍史\n4:先生 御机下',
  is_shift: 0, //'シフト管理機能表示設定\n0:表示しない\n1:表示する',
  record_divide_div: 0, //'20分超えのリハ記録書IIまとめ設定\n1:20分を超えた時、記録書Ⅱを1枚にまとめる。\n2:20分毎に記録書Ⅱを作成する。',
  staff_list_div: 1, //'職員リスト表示設定\n1:在職／休職／退職を表示\n2:在職／休職を表示\n3:在職のみ表示',
  service_record_disp_div: 0, //'月次報告書表示設定\n0:サービス訪問（自費）記録を報告書に記載しない\n1:サービス訪問（自費）記録を報告書に記載する',
  adl_setting_div: 0, //'観察項目設定区分\n0:観察項目最大21項目とし、記録書Ⅱ（カルテ部分）をA4１枚に収める\n1:観察項目の登録数制限を解除する（観察項目カテゴリ表示）',
  patient_msg_board_time: 0, //'利用者メッセージボードロック時間（秒）',
  bank_name: "", //'銀行名',
  branch_name: "", //'支店名',
  account_kind: "", //'口座種別',
  account_num: "", //'口座番号',
  account_name: "", //'口座名義',
  care_service_time_required: 0, //'介護_介護訪問提供時間必須フラグ\n0:必須にしない\n1:必須にする',
  care_regular_visit_service_exist: 0, //'介護_定期巡回サービス提供\n0:なし\n1:あり',
  care_special_area_div: 0, //'介護_特別地域加算\n0:なし\n1:あり',
  care_kinkyu_houmon_add: 0, //'介護_緊急時訪問看護加算\n0:加算なし\n1:加算あり',
  care_tokubetsu_kanri_add: 0, //'介護_特別管理体制加算\n0:加算なし\n1:特別管理Ⅰ\n2:特別管理Ⅱ',
  care_terminal_care_add: 0, //'介護_ターミナルケア加算\n0:加算なし\n1:加算あり',
  care_service_supply_add: 0, //'介護_サービス提供体制強化加算\n0:加算なし\n1:加算あり',
  care_is_senmon_kangoshi: 0, //'介護_専門看護師\n0:なし\n1:あり',
  care_small_office_area_add: 0, //'介護_中山間地域等居住者提供加算\n0:加算なし\n1:加算あり',
  care_small_office_scale_add: 0, //'介護_中山間地域等小規模事業所加算\n0:加算なし\n1:加算あり',
  care_kyoka_add: 0, //'介護_看護体制強化加算\n0:加算なし\n1:加算Ⅱ(300単位)あり(介護・予防)\n2:加算Ⅱ(300単位)あり(予防のみ)\n3:加算Ⅱ(300単位)あり(介護のみ)\n4:加算Ⅰ(600単位)あり(介護のみ)\n5:加算Ⅰ(600単位)介護あり、加算Ⅱ(300単位)予防あり',
  care_riyousya_gensan: 0, //'介護_同一建物に居住する利用者の減算\n0:なし\n1:あり',
  care_end_time_include_div: 2, //'介護_ほのぼの連携:サービス提供終了時刻を連携時に含めるか含めないかの区分\n2:含めない\n1:含める\n0:Ⅰ5は＋1分しない、Ⅰ5以外は+1分する',
  care_elderly_abuse_prevention_gensan: 0, //介護_高齢虐待防止未実施減算\n0:なし\n1:あり
  care_mouth_renkei_kyoka_add: 0, //介護_口腔連携強化加算 0:加算なし 1:加算あり
  care_over_therapist_visit_count_flg: 0, //介護_リハ職超過フラグ 0:なし 1:あり
  care_over_therapist_no_additions_flg: 0, //介護_対象加算なし(6月) 0:対象加算あり 1:対象加算なし
  care_over_therapist_no_additions_july_flg: 0, //介護_対象加算なし(7月) 0:対象加算あり 1:対象加算なし
  medical_kinkyu_houmon_kango_add: 0, //'医療_緊急時訪問看護加算\n0:加算なし\n1:加算あり',
  medical_tokubetsu_kanri_add: 0, //'医療_特別管理加算\n0:加算なし\n1:加算あり',
  medical_terminal_care_add: 0, //'医療_ターミナルケア加算\n0:加算なし\n1:加算あり',
  medical_hour24_taiou_add: 0, //'医療_24時間対応加算\n0:加算なし\n1:加算あり',
  medical_hour24_renraku_add: 0, //'医療_24時間連絡体制加算\n0:加算なし\n1:加算あり',
  medical_is_senmon_kangoshi: 0, //'医療_専門看護師\n0:なし\n1:あり',
  medical_is_seishin_kihon_ryouyouhi: 0, //'医療_精神科訪問看護基本療養費\n0:なし\n1:あり',
  medical_seishin_fukusukai_add: 0, //'医療_精神科複数回訪問加算\n0:加算なし\n1:加算あり',
  medical_seishin_renkei_add: 0, //'医療_精神科重症患者早期集中支援管理連携加算\n0:加算なし\n1:加算あり',
  medical_kanri_ryouyouhi_div: 0, //'医療_訪問看護管理療養費区分（2日目以降）\n1:管理療養費１\n2:管理療養費２',
  medical_kinou_kyoka_ryouyouhi1: 0, //'医療_機能強化型訪問看護管理療養費1\n0:加算なし\n1:加算あり',
  medical_kinou_kyoka_ryouyouhi2: 0, //'医療_機能強化型訪問看護管理療養費2\n0:加算なし\n1:加算あり',
  medical_kinou_kyoka_ryouyouhi3: 0, //'医療_機能強化型訪問看護管理療養費3\n0:加算なし\n1:加算あり',
  medical_is_confirm_same_building_record2: 0, //'医療_同一日同一建物減算（記録提出時のチェック） 0:なし 1:あり',
  medical_is_hour24_taiou_reduce_burden: 0, //'医療_24時間対応体制負担軽減の取組 0:なし 1:あり',
  medical_dx_info_util_add: 0, //'医療_訪問看護医療DX情報活用加算 0:なし 1:あり',
  medical_base_up1: 0, //'医療_ベースアップ評価料（Ⅰ） 0:なし 1:あり',
  medical_base_up2: 0, //'医療_ベースアップ評価料（Ⅱ） 0:なし 1~18:ベースアップ評価料（Ⅱ）1~18',
  comment: "", //'履歴コメント'
  hour24_order_div: 0, //'24時間体制並び順区分\n1:古いものから順に表示\n2:新しいものから順に表示',
  wiseman_office_id: 0, //ワイズマン自機関番号
  before0_patientid_div: 0, //ワイズマン連携利用者ID前0有効区分\n0:,無効\n1:有効'
  sukoyaka_csv_self_bear_default_check: 0, //すこやかサン連携CSV出力 公費情報の負担額デフォルトチェック状態\n0:チェックなし\n1:チェックあり\nすこやかサンをなくすかどうか協議中
  sukoyaka_csv_mind_body_state_default_check: 0, //すこやかサン連携CSV出力 訪問看護療養費情報の心身の状態デフォルトチェック状態\n0:チェックなし\n1:チェックあり\nすこやかサンをなくすかどうか協議中
  use_edi_flg: 0, //集金代行サービス利用フラグ\n0:利用しない 1:利用する
  edi_code: "", //委託者コード
  edi_name: "", //委託者名
  transfer_div: 0, //振替区分\n1:当月\n2:翌月
  transfer_day: 0, //振替日
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "", //削除日時
  start_date_wareki: 0, //開始日（和暦）
  end_date_wareki: 0, //終了日（和暦）
  updated_at_wareki: 0, //更新日時（和暦）
  office_history_mails: [], //メール
  office_history_same_buildings: [], //同一建物
  office_history_over_therapist_visit_count: [], //理学療法士超過減算
  covid19_div: 0, //コロナ対策区分（介護・医療共通）\n0:なし\n1:あり
  report_seel_div: 0,
  shared_file_div: 0,
  is_all_edit_visit_schedule: 0,
  is_careplan_used: 0,
  hour24_comment_order_div: 0, //'24時間体制並び順区分\n1:古いものから順に表示\n2:新しいものから順に表示',
  notices_chat_occur_report_submitted: false,
  notices_chat_patient_file_registered: false,
  office_history_over_therapist_target_addition: [], //理学療法士超過減算 対象加算
});
export const DefaultOfficeHistory = setOfficeHistory;