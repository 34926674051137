var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('app-text-field',{staticClass:"mt-2",attrs:{"label":"絞り込み","width":"100%","outlined":false,"hide-details":"auto"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}),_c('v-data-table',{staticClass:"mt-2 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.searchKey,"no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('AppAuthButton',{attrs:{"small":"","label":"詳細"},on:{"click":function($event){return _vm.goDetail(item.id)}}})]}},{key:"item.approval_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"dark":"","small":"","label":"","color":_vm.idToText(
                item.approval_status,
                _vm.PORTAL_OFFICE_APPROVAL_STATUS_COLOR
              )}},[_c('span',{staticStyle:{"display":"inline-block","width":"60px","text-align":"center"}},[_vm._v(_vm._s(_vm.idToText(item.approval_status, _vm.PORTAL_OFFICE_APPROVAL_STATUS)))])])]}}],null,true)})],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }