export interface EwellUser {
  id: number;
  system_id: string;
  default_office_staff_id: number;
  user_id: string;
  pass_exp_date: string;
  mail_address: string;
  icon_path: string;
  tel_no: string;
  family_name: string;
  first_name: string;
  family_name_furigana: string;
  first_name_furigana: string;
  sex: number;
  birthday: string;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pass_exp_date_wareki: string;
  birthday_wareki: string;
  address: string;
  cert: string;
  practitioner: string;
  sex_name: string;
  icon_full_path: string;
}

export const DEFAULT_EWELLUSER = {
  id: 0,
  system_id: "",
  default_office_staff_id: 0,
  user_id: "",
  pass_exp_date: "",
  mail_address: "",
  icon_path: "",
  tel_no: "",
  family_name: "",
  first_name: "",
  family_name_furigana: "",
  first_name_furigana: "",
  sex: 0,
  birthday: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  pass_exp_date_wareki: "",
  birthday_wareki: "",
  address: "",
  cert: "",
  practitioner: "",
  sex_name: "",
  icon_full_path: "",
};
