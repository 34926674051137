import { Choice } from "@/types";
import { OfficeHonobonoOffice } from "#/model/agreement/office";

/* 定数 */

// サービス種別
export const HonobonoSvKindCd = {
  NURSING: 13, // 訪問看護
  PREVENTION_NURSING: 63, // 予防訪問看護
} as const;
export type HonobonoSvKindCd =
  typeof HonobonoSvKindCd[keyof typeof HonobonoSvKindCd];
export const HonobonoSvKindCdChoices: Choice[] = [
  { value: HonobonoSvKindCd.NURSING, text: "訪問看護" },
  { value: HonobonoSvKindCd.PREVENTION_NURSING, text: "予防訪問看護" },
];

// ほのぼの事業所設定
interface HonobonoOfficSettingIF {
  0: string;
  1: string;
  [key: number]: string;
}
export const HonobonoOfficSetting = {
  0: "なし", // ほのぼの事業所設定あり
  1: "あり", // ほのぼの事業所設定なし
} as HonobonoOfficSettingIF;

// 事業所区分
interface OfficeDivIf {
  1: string;
  2: string;
  [key: number]: string;
}
export const OfficeDiv = {
  1: "主たる事業所", // 主たる事業所
  2: "サテライト", // サテライト
} as OfficeDivIf;

/* インターフェース */

// 行データ
export interface Record {
  id: number; // 事業所ID
  office_name: string; // 事業所名
  office_div: number; // 事業所区分
  office_code: string; // 事業所番号
  set_honobono: number; // ほのぼの事業所設定区分 0:なし 1:あり
}
export const DefaultRecord = (): Record => ({
  id: 0,
  office_name: "",
  office_code: "",
  office_div: 0,
  set_honobono: 0,
});

// 詳細データ
export interface Detail {
  id: number; // 事業所ID
  office_name: string; // 事業所名
  office_div: number; // 事業所区分
  office_code: string; // 事業所番号
  honobono_offices: OfficeHonobonoOffice[]; // ほのぼの事業所
}
export const DefaultDetail = (): Detail => ({
  id: 0,
  office_name: "",
  office_div: 0,
  office_code: "",
  honobono_offices: [],
});
