







































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import Sortable from "sortablejs";
import { Template } from "@/model/inquiry";

interface DispTemplate extends Template {
  categorys: string;
  keywords: string;
}

@Component({
  components: {
    AppAuthButton,
  },
})
export default class TemplateSort extends Mixins(AxiosMixin, UtilMixin) {
  @Prop({}) templates!: DispTemplate[];
  private sortable?: Sortable = undefined;
  private sortNos: number[] = [];
  private searchText = "";

  private headers = [
    {
      text: "ID",
      value: "id",
      sortable: false,
      align: "start",
      width: "70px",
    },
    {
      text: "詳細",
      value: "actions",
      sortable: false,
      align: "center",
      width: "80px",
    },
    {
      text: "問い合わせ内容",
      value: "inquiry_content",
      sortable: false,
      align: "start",
      width: "400px",
    },
    {
      text: "カテゴリ",
      value: "categorys",
      sortable: false,
      align: "start",
      width: "200px",
    },
    {
      text: "おすすめキーワード",
      value: "keywords",
      sortable: false,
      align: "start",
      width: "250px",
    },
  ];

  mounted() {
    const elmSortList = document.querySelector(
      `.p-template-sort__list tbody`
    ) as HTMLElement;

    this.sortable = new Sortable(elmSortList, {
      animation: 100,
      ghostClass: "ghost",
      onEnd: (e) => {
        if (this.sortNos.length == 0) {
          this.sortNos = this.templates.map((template) => {
            return template.recommend_sort_no;
          });
        }

        if (e.oldIndex !== undefined && e.newIndex !== undefined) {
          const rowSelected = this.templates.splice(e.oldIndex, 1)[0];
          this.templates.splice(e.newIndex, 0, rowSelected);
        }
      },
    });
  }

  @Emit("clickSave")
  clickSave() {
    return this.templates;
  }
}
