






















































































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { EwellUser, DEFAULT_EWELLUSER } from "@/model/ewellUser";

import RulesMixin from "#/mixins/rulesMixin";
import AppPhoneNo from "#/components/AppPhoneNo.vue";
import { VForm } from "@/types";

@Component({
  components: {
    AppAuthButton,
    AppPhoneNo,
  },
})
export default class IbowAccountEdit extends Mixins(AxiosMixin, RulesMixin) {
  /** ewellユーザー情報 */
  private ewellUser: EwellUser = { ...DEFAULT_EWELLUSER };

  /** 絞込み用条件 */
  private filterObj = {
    name: "",
    keyword: "",
  };

  @Ref("ibow-account-editor") private readonly form!: VForm;

  //「＠」を除いたiBowID
  private userIbowId = "";

  created() {
    this.fetchIbowAccount(Number(this.$route.params.id));
  }

  /** eWeLLユーザー取得 */
  private fetchIbowAccount(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/ibowaccount/get",
      {
        id: id,
      },
      (res) => {
        this.ewellUser = res.data.ewell_user;
        this.userIbowId = this.ewellUser.user_id.replace("@", "");
      }
    );
  }

  /** eWeLLユーザー編集 */
  private save() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }

    if (!confirm("保存しますか？")) {
      return;
    }

    this.ewellUser.user_id = "@" + this.userIbowId;

    this.postJsonCheck(
      window.base_url + "/api/ibowaccount/save",
      {
        ewell_user: this.ewellUser,
      },
      () => {
        this.$router.go(-1);
      }
    );
  }

  /** eWeLLユーザー削除 */
  private deleteMode(id: number) {
    this.$router.push({ path: "/ibowaccount/edit/" + id });
  }

  //キャンセルボタンクリック
  private clickCancel() {
    this.$router.go(-1);
  }
}
