import Base from "../base";

/** データセット */
export interface Dataset extends Base {
  /** ID */
  id: number;
  /** データセット名 */
  name: string;
  /** コメント */
  comment: string;
  /** 最終更新者ID */
  editor_admin_id: number;
  /** 選択項目 */
  selections: DatasetSelection[];
}

export const DefaultDataset = (): Dataset => ({
  id: 0,
  name: "",
  comment: "",
  editor_admin_id: 0,
  selections: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

/** データセット選択項目 */
export interface DatasetSelection extends Base {
  /** ID */
  id: number;
  /** データセットID */
  dataset_id: number;
  /** データセットグループID */
  dataset_group_id: number;
  /** データセット項目ID */
  dataset_item_id: number;
  /** チェック状態 */
  is_checked: number;
  /** データセットグループ名 */
  group_name: string;
  /** データセット項目名 */
  item_name: string;
}

export const DefaultDatasetSelection = (): DatasetSelection => ({
  id: 0,
  dataset_id: 0,
  dataset_group_id: 0,
  dataset_item_id: 0,
  is_checked: 0,
  group_name: "",
  item_name: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
});
