









































import { Component, Mixins, Emit } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import Cookies from "js-cookie";
import UserMixin from "@/mixins/userMixin";
import * as user from "@/store/modules/user/types";
import { namespace } from "vuex-class";
import { AxiosError, AxiosResponse } from "axios";

const User = namespace(user.name);

@Component({
  components: {
    AppTextField,
  },
})
export default class Login extends Mixins(UserMixin) {
  private userid = "";
  private password = "";
  private autofocus = false;
  private errorMessages = "";
  private loading = false;

  @User.Mutation(user.SET_USER) setUser!: Function;

  mounted() {
    const userid = Cookies.get("admin-userid");
    if (userid) {
      this.userid = userid;
      this.autofocus = true;
    }
  }

  //ログインボタンクリック
  private clickLoginBtn() {
    this.loading = true;

    window.axios
      .post(window.base_url + "/auth", {
        userid: this.userid,
        password: this.password,
      })
      .then((res: AxiosResponse) => {
        this.loading = false;

        if (res.data.token && res.data.ref_token) {
          window.token = res.data.token;
          Cookies.set("admin-token", res.data.token);
          window.reftoken = res.data.ref_token;
          Cookies.set("admin-reftoken", res.data.ref_token);
          Cookies.set("admin-userid", res.data.user.user_id);
          this.setUser(res.data);
          this.logined();
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              security_code: res.data.security_code,
            },
          });
          this.login();
        }
      })
      .catch(async (error: AxiosError) => {
        this.loading = false;
        if (error.response && error.response.status == 401) {
          this.password = "";
          this.errorMessages = error.response.data.message;
        } else {
          await this.$openAlert(
            "サーバでエラーが発生しました。\n(" + error + ")"
          );
          console.log(error);
          console.log(error.response);
        }
      });
  }

  @Emit("login")
  private login() {
    return;
  }

  @Emit("logined")
  private logined() {
    return;
  }
}
