import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const portalFirebaseConfig = {
  apiKey: "AIzaSyCMiJByfeI0JRFh0KbxQNRL8EIZfGD2daU",
  authDomain: "ibow-protal-devlop.firebaseapp.com",
  projectId: "ibow-protal-devlop",
  storageBucket: "ibow-protal-devlop.appspot.com",
  messagingSenderId: "347769297376",
  appId: "1:347769297376:web:5280d2bb2ebc264f3bf385"
};

const addFirebaseConfig = {
  apiKey: "AIzaSyDBMIld6VICu-YY1XC2J_MZ7Xr7Ss8FUH4",
  authDomain: "ibowv2-add-dev.firebaseapp.com",
  projectId: "ibowv2-add-dev",
  storageBucket: "ibowv2-add-dev.appspot.com",
  messagingSenderId: "552309459287",
  appId: "1:552309459287:web:a72f0555db6ff2ff249612"
};

const stgFirebaseConfig = {
  apiKey: "AIzaSyDXPeoyktFtqu5QieMKeoR-lS1-7_uKW18",
  authDomain: "ibowv2-trial.firebaseapp.com",
  projectId: "ibowv2-trial",
  storageBucket: "ibowv2-trial.appspot.com",
  messagingSenderId: "482646732140",
  appId: "1:482646732140:web:cd75296d711d4f49bb1749"
};

const prdFirebaseConfig = {
  apiKey: "AIzaSyBWkh69I2mNXC1ZLIpr0QeewDTuJZoBjc0",
  authDomain: "ibowv2.firebaseapp.com",
  projectId: "ibowv2",
  storageBucket: "ibowv2.appspot.com",
  messagingSenderId: "852337431318",
  appId: "1:852337431318:web:c53e0876b2768b2847b637"
};

const prefirebaseConfig = {
  apiKey: "AIzaSyDo46htLtJDosD2xkoKPHvuyNazF1yUFLQ",
  authDomain: "ibowv2pre.firebaseapp.com",
  projectId: "ibowv2pre",
  storageBucket: "ibowv2pre.appspot.com",
  messagingSenderId: "366656082352",
  appId: "1:366656082352:web:643297118ceb3a2ba04675"
};

const demoFirebaseConfig = {
  apiKey: "AIzaSyB3ToQlHOfg28Nnaz4NzLCjpy6c1klYHhY",
  authDomain: "scientific-crow-434707-r6.firebaseapp.com",
  projectId: "scientific-crow-434707-r6",
  storageBucket: "scientific-crow-434707-r6.appspot.com",
  messagingSenderId: "881460400076",
  appId: "1:881460400076:web:999855e36bf986149fd769"
};

let firebaseConfig = addFirebaseConfig;
switch (process.env.VUE_APP_MODE) {
  case "stg":
    firebaseConfig = stgFirebaseConfig;
    break;
  case "prd":
    firebaseConfig = prdFirebaseConfig;
    break;
  case "pre":
    firebaseConfig = prefirebaseConfig;
    break;
  case "portal-dev":
    firebaseConfig = portalFirebaseConfig;
    break;
  case "demo":
    firebaseConfig = demoFirebaseConfig;
    break;
}

// 開発でFirestoreを使う場合
switch (process.env.VUE_APP_FIRESTORE_MODE) {
  case "portal-dev":
    firebaseConfig = portalFirebaseConfig;
    break;
}

let app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const storage = firebase.storage();

/** delete後の再取得 */
export const install = () => {
  app = firebase.initializeApp(firebaseConfig);
};

export { firebase, db, storage, app };
