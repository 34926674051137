import Base from "./base";

export interface Choice {
  text: string;
  value: number;
}

// 危険因子マスタ
export interface RiskFactorMaster {
  cans: Choice[]; // 出来るできない
  existences: Choice[]; // 有無
}

// 看護内容マスタ
export interface NursingDetailMaster {
  care_large_contents: CareLargeContent[];
}

// 看護内容大項目
export interface CareLargeContent {
  id: number;
  name: string;
  is_ai: number; //'AI用 2:AI 1:AI以外',
  sort_no: number; // 表示順
  updated_at: string; // 更新日時
  care_medium_contents: CareMediumContent[];
}
export const DefaultCareLargeContent = (): CareLargeContent => ({
  id: 0,
  name: "",
  is_ai: 0,
  sort_no: 0,
  updated_at: "",
  care_medium_contents: [],
});
// 看護内容中項目
export interface CareMediumContent {
  id: number;
  name: string;
  sort_no: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  /** 選択状態への追加フラグ 1:追加 2:除外 */
  select_flag: number;
  /** マスタ変更フラグ 1:枠追加 2:枠削除 */
  change_flag: number;
}
export const DefaultCareMediumContent = (): CareMediumContent => ({
  id: 0,
  name: "",
  sort_no: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  select_flag: 0,
  change_flag: 0,
});

// 観察項目カテゴリマスタ
export interface ObservationCategoryMaster {
  is_display_category: boolean;
  observation_categories: ObservationCategory[]; // 観察項目カテゴリリスト
}

// 観察項目カテゴリ
export interface ObservationCategory {
  id: number; // カテゴリID
  name: string; // カテゴリ名
  use_care: number; // 介護看護使用フラグ
  use_medical: number; // 医療看護使用フラグ
  use_medical_mental: number; // 精神看護使用フラグ
  use_care_rehabilitation: number; // 介護リハ使用フラグ
  use_medical_rehabilitation: number; // 医療リハ使用フラグ
  use_medical_mental_rehabilitation: number; // 精神リハ使用フラグ
  is_deleted: boolean, // 削除済みカテゴリか
  ovservation_items: ObservationItemMaster[]; // 観察項目リスト
}

// 観察項目マスタ
export interface ObservationItemMaster {
  id: number;
  label: string;
  is_deleted: boolean; // 削除済み項目か
  selet_items: ObservationItemChoice[]; // 観察項目選択肢リスト
}

// 観察項目選択肢マスタ
export interface ObservationItemChoice {
  value: number;
  text: string;
  is_deleted: boolean; // 削除済み項目か
}

// 褥瘡マスタ
export interface BedsoreMaster {
  parts: BedsorePart[]; // 部位
  depths: Choice[]; // 深さ
  exudates: Choice[]; // 滲出液
  sizes: Choice[]; // 大きさ
  infections: Choice[]; // 炎症・感染
  granulations: Choice[]; // 肉芽形成
  necrotics: Choice[]; // 壊死組織
  pockets: Choice[]; // ポケット
}

//褥瘡 - 部位
export interface BedsorePart {
  name: string;
  bedsore_part_id: number;
}

// 服薬-事業所
export interface MedicineOffice extends Base {
  id: number; //ID
  medicine_id: number; //'服薬ID',
  office_id: number; //'事業所ID',
}
export const DefaultMedicineOffice = (): MedicineOffice => ({
  id: 0,
  medicine_id: 0,
  office_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

// 服薬マスタ
export interface Medicine extends Base {
  id: number; //ID
  agreement_id: number; //'契約ID',
  name: string; //'続柄名',
  medicine_offices: MedicineOffice[];
}
export const DefaultMedicine = (): Medicine => ({
  id: 0,
  agreement_id: 0,
  name: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  medicine_offices: [],
});

// AIお勧め検索-長期目標
export interface TermLong extends Base {
  id: number; //長期目標ID
  term_id: number; //目標ID
  term_name: string; //病名
  mark: string; //目標
  check_flg: boolean; //チェックフラグ
}
export const DefaultTermLong = (): TermLong => ({
  id: 0,
  term_id: 0,
  term_name: "",
  mark: "",
  check_flg: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

// AIお勧め検索-短期目標
export interface TermShort extends Base {
  id: number; //短期目標ID
  term_id: number; //目標ID
  term_name: string; //病名
  mark: string; //目標
  check_flg: boolean; //チェックフラグ
}
export const DefaultTermShort = (): TermShort => ({
  id: 0,
  term_id: 0,
  term_name: "",
  mark: "",
  check_flg: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

// AIお勧め検索-改善策
export interface TermSolution extends Base {
  id: number; //問題点ID
  solution: string; //改善策
  check_flg: boolean; //チェックフラグ
}
export const DefaultTermSolution = (): TermSolution => ({
  id: 0,
  solution: "",
  check_flg: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

// AIお勧め検索-問題点
export interface TermProblem extends Base {
  id: number; //問題点ID
  term_id: number; //目標ID
  term_name: string; //病名
  problem: string; //問題点
  check_flg: boolean; //チェックフラグ
  term_solutions: TermSolution[]; //改善策
}
export const DefaultTermProblem = (): TermProblem => ({
  id: 0,
  term_id: 0,
  term_name: "",
  problem: "",
  check_flg: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  term_solutions: [],
});

/** AIが回答した看護計画 */
export interface TermAiAnswer extends Base {
  /** ID */
  id: number;
  /** 病名ID */
  term_id: number;
  /** 回答(原文ママ) */
  answer: string;
  /** 回答種類 0:回答なし 1:看護計画に変換可能 2:看護計画に変換不可能 */
  answer_type: number;
  /** 看護計画 */
  term: Term;
}

export const DefaultTermAiAnswer = (): TermAiAnswer => ({
  id: 0,
  term_id: 0,
  answer: "",
  answer_type: 0,
  term: {} as Term,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

/** AIお勧め検索-目標 */
export interface Term extends Base {
  /** 目標ID */
  id: number;
  /** 病名 */
  disease: string;
  /** 公開設定 0:非公開 1:公開 */
  visibility: number;
  /** 長期目標 */
  term_longs: TermLong[];
  /** 短期目標 */
  term_shorts: TermShort[];
  /** 問題点 */
  term_problems: TermProblem[];
  /** AIが回答した看護計画 */
  term_ai_answers: TermAiAnswer[];
}
export const DefaultTerm = (): Term => ({
  id: 0,
  disease: "",
  visibility: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  term_ai_answers: [],
  term_longs: [],
  term_shorts: [],
  term_problems: [],
});

// 看護計画マスタ関連病名
export interface CareProblemSolutionDisease extends Base {
  id: number; //ID
  care_problem_solution_id: number; //'看護問題解決策ID',
  disease_name: string; //'病名',
}
export const DefaultCareProblemSolutionDisease =
  (): CareProblemSolutionDisease => ({
    id: 0,
    care_problem_solution_id: 0,
    disease_name: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
  });

// 看護計画マスタ-事業所
export interface CareProblemSolutionOffice extends Base {
  id: number; //ID
  care_problem_solution_id: number; //'看護問題解決策ID',
  office_id: number; //'事業所ID',
}
export const DefaultCareProblemSolutionOffice =
  (): CareProblemSolutionOffice => ({
    id: 0,
    care_problem_solution_id: 0,
    office_id: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
  });

// 看護計画マスタ
export interface CareProblemSolution extends Base {
  id: number; //ID
  agreement_id: number; //'契約ID',
  keyword: string; //'キーワード',
  problem: string; //'問題点',
  solution: string; //'解決策',
  disease1: string; //'関連病名1',
  disease2: string; //'関連病名2',
  disease3: string; //'関連病名3',
  disease4: string; //'関連病名4',
  disease5: string; //'関連病名5',
  care_problem_solution_diseases: CareProblemSolutionDisease[];
  care_problem_solution_offices: CareProblemSolutionOffice[];
}
export const DefaultCareProblemSolution = (): CareProblemSolution => ({
  id: 0,
  agreement_id: 0,
  keyword: "",
  problem: "",
  solution: "",
  disease1: "",
  disease2: "",
  disease3: "",
  disease4: "",
  disease5: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  care_problem_solution_diseases: [],
  care_problem_solution_offices: [],
});

// 自費カテゴリー事業所
export interface OwnExpenseCategoryOffice extends Base {
  id: number; //ID
  own_expense_category_id: number; //'自費カテゴリID',
  office_id: number; //'事業所ID',
}
export const DefaultOwnExpenseCategoryOffice =
  (): OwnExpenseCategoryOffice => ({
    id: 0,
    own_expense_category_id: 0,
    office_id: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
  });

// 自費アイテム
export interface OwnExpenseItem extends Base {
  id: number; //ID
  own_expense_category_id: number; //'自費カテゴリID',
  name: string; //'自費項目名',
  amount: number; //'金額',
  tax_div: number; //'課税区分\n1:内税(10%)\n2:外税(10%)\n3:非課税\n4:内税(8%)\n5:外税(8%)',
  is_medical_deducation: number; //'医療費控除フラグ',
  code: string; //'自費項目コード',
  remarks: string; //'備考',
  sort_no: number; //'並び順',
}
export const DefaultOwnExpenseItem = (): OwnExpenseItem => ({
  id: 0,
  own_expense_category_id: 0,
  name: "",
  amount: 0,
  tax_div: 0,
  is_medical_deducation: 0,
  code: "",
  remarks: "",
  sort_no: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});

// 自費カテゴリ
export interface OwnExpenseCategory extends Base {
  id: number; //ID
  agreement_id: number; //'利用者ID',
  name: string; //'カテゴリ名',
  code: string; //'カテゴリコード',
  sort_no: number; //'並び順',
  items: OwnExpenseItem[]; //自費アイテム
  offices: OwnExpenseCategoryOffice[]; //事業所
}
export const DefaultOwnExpenseCategory = (): OwnExpenseCategory => ({
  id: 0,
  agreement_id: 0,
  name: "",
  code: "",
  sort_no: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  items: [],
  offices: [],
});
