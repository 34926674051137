



























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PastInquiryViewer from "@/components/inquiry/PastInquiryViewer.vue";
import FAQTemplateViewer from "@/components/inquiry/FAQTemplateViewer.vue";
import {
  InquiryDiv,
  InquiryGroup,
  InquiryServiceDiv,
  InquiryAnswer,
  Template,
} from "@/model/inquiry";

@Component({
  components: {
    PastInquiryViewer,
    FAQTemplateViewer,
  },
})
export default class RelatedInquiryViewer extends Mixins(
  UtilMixin,
  AxiosMixin
) {
  /** 問い合わせ区分マスタ情報 */
  @Prop({ default: () => [] }) divs!: InquiryDiv[];

  /** 問い合わせグループマスタ情報 */
  @Prop({ default: () => [] }) groups!: InquiryGroup[];

  /** 問い合わせサービス種類マスタ情報 */
  @Prop({ default: () => [] }) serviceDivs!: InquiryServiceDiv[];

  /** 編集可能かどうか */
  @Prop({ default: false }) editable!: boolean;

  /** 現在開いているタブ番号 */
  private currentTab = 0;

  @Emit("insert:answer")
  private insertAnswerContent(answer: InquiryAnswer) {
    return answer;
  }

  @Emit("insert:template")
  private insertTemplateContent(template: Template) {
    return template;
  }
}
