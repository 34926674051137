import { Vue, Component } from "vue-property-decorator";
import { PREFECTURES } from "./../consts/const";
interface ListItem {
  value: number;
  text: string;
}

@Component
export default class UtilMixin extends Vue {
  public idToText(id: number, obj: ListItem[]): string {
    const item = obj.find((x) => x.value == id);
    if (item) return item.text;

    return "";
  }

  public getPrefName(prefId: number): string {
    if (prefId) return PREFECTURES.find((x) => x.value == prefId)?.text || "";
    return "";
  }

  // Dateオブジェクトを指定のフォーマットに変換
  public dateToStr(date: Date, format: string): string {
    // 無効なdateオブジェクトが渡された場合は空文字列を返却
    if (Number.isNaN(date.getDate())) {
      return "";
    }

    format = format.replace(/yyyy/g, date.getFullYear().toString());
    format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ("00" + date.getMilliseconds()).slice(-3));
    return format;
  }

  /** new Date()のラッパー safariへの対応 */
  public newDate(dateStr: string) {
    if (typeof dateStr === "string") {
      dateStr = dateStr.replace(/-/g, "/");
    }
    return new Date(dateStr);
  }
}
