/** 折線グラフ全体が持つ情報 */
export interface LineGraphData {
  /** グラフのy軸タイトル */
  title: string;
  /** ツールチップで表示する単位情報(枚、回、etc) */
  unit: string;
  /** 折線データ */
  lines: LineData[];
}

/** 1本の折線が持つ情報 */
export interface LineData {
  /** 凡例名 */
  title: string;
  /** 集計情報 */
  count_data: CountData[];
}

/** 集計情報 */
export interface CountData {
  /** 集計値 */
  count: number;
  /** 形式自由? */
  date: string;
}

export const DEFAULT_LINE_GRAPH_DATA = {
  title: "",
  unit: "",
  lines: [],
};

/** 検索条件 */
export interface SearchObj {
  /** 契約ID */
  agreement_id: number;
  /** 開始yearmonth */
  start_yearmonth: number;
  /** 終了yearmonth */
  end_yearmonth: number;
  /** 集計対象カテゴリ */
  category: number;
  /** アンケートID */
  question_id: number;
}

/** 棒グラフ全体が持つ情報 */
export interface CategoryGraphData {
  /** グラフのx軸タイトル */
  title_xAxis: string;
  /** グラフのy軸タイトル */
  title_yAxis: string;
  /** グラフのx軸に使う変数名 */
  xAxis_var_name: string;
  /** グラフのy軸に使う変数名 */
  yAxis_var_name: string;
  /** ツールチップで表示する単位情報(枚、回、etc) */
  unit: string;
  /** データ */
  data: unknown[];
}

export const DEFAULT_CATEGORY_GRAPH_DATA: CategoryGraphData = {
  title_xAxis: "",
  title_yAxis: "",
  xAxis_var_name: "",
  yAxis_var_name: "",
  unit: "",
  data: [],
};

/** amchartで出力するExcelにワークシートを追加するための情報 */
export interface ExcelAdditionalData {
  /** ワークシート名 */
  sheet_name: string;
  /** データ */
  data: string[][];
}
