





































import { Component, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import PortalRequestUserSearchBox from "@/components/portal/PortalRequestUserSearchBox.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { PORTAL_STAFF_ROLE } from "@/consts/portal";

@Component({
  components: {
    AppTextField,
    PortalRequestUserSearchBox,
    AppAuthButton,
  },
})
export default class PortalRequestUserAgentLogin extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private searchKey = ""; //絞り込み文字列

  private items = [];

  private PORTAL_STAFF_ROLE = PORTAL_STAFF_ROLE;

  private headers = [
    { text: "選択", value: "action", width: "100px" },
    { text: "システムID", value: "system_id" },
    { text: "スタッフ名", value: "staff_name" },
    { text: "所属名", value: "organization.name" },
    { text: "権限", value: "role" },
  ];

  //データ取得
  private fetchList(searchObj: unknown): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_request_users/get_with_organization",
      searchObj,
      (res) => {
        this.items = res.data.request_users ?? [];
      }
    );
  }

  //代理ログイン
  private login(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/requestuser-agent-login",
      {
        request_user_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=2&token=" +
            res.data.onetime_token,
          "_blank"
        );
      }
    );
  }
}
