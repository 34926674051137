



























import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppTextArea extends Mixins(RulesSaveMixin) {
  @Prop({ default: 1000000000 })
  limitPerLine!: number;

  @Prop({ default: 5000000000 })
  maxLines!: number;

  @Prop({ default: 0 })
  lastCharMinus!: number;

  /** 入力文字数テキストの最大文字数を消すか */
  @Prop({ default: false })
  unneedMaxCount!: boolean;

  @Prop({ default: false })
  showCounter!: boolean;

  @Prop({ default: false })
  simpleCounter!: boolean;

  @Prop() remarks!: string;

  @Prop() value!: string;

  @Prop({ default: false }) private isNeed!: boolean;

  @Prop({ default: "" }) private label!: string;

  @Prop({ default: true }) private autoGrow!: boolean;

  @Emit()
  private input(newValue: string): string {
    return newValue;
  }

  private inputHeightDefault: number | undefined;

  private get Value(): string {
    this.$nextTick(() => {
      this.calcInputHeight();
    });
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }

  private get localLabel(): string {
    if (this.isNeed) return "*" + this.label;
    return this.label;
  }

  /** 入力欄自体の高さ、つまり何も入力されていない時の高さを取得 */
  private getInputHeightDefault(input: HTMLTextAreaElement) {
    const inputStyle = window.getComputedStyle(input);
    const paddingTop = parseFloat(inputStyle.paddingTop) || 0;
    const paddingBottom = parseFloat(inputStyle.paddingBottom) || 0;
    const lineHeight = parseFloat(inputStyle.lineHeight) || 0;
    return lineHeight * input.rows + paddingTop + paddingBottom;
  }

  /** 入力欄が自動伸縮するように、内容に応じて高さを調整 */
  private calcInputHeight() {
    if (!this.autoGrow) {
      // 自動伸縮しない場合、高さ決めはブラウザに任せる(rows属性により変わる)
      return;
    }
    const inputWrap = this.$el?.querySelector(
      ".v-text-field__slot"
    ) as HTMLElement;
    const input = this.$el?.querySelector("textarea");
    if (!inputWrap || !input) {
      return;
    }

    // 入力欄に入力したテキストの表示高さを取得する
    // 入力欄自体の高さを最小にすることで、テキストの高さをinput.scrollHeightで取得できる
    // ただ入力欄自体の高さを変えるだけでは、content jumpingを引き起こすので、その前にラッパー要素の高さを固定化する
    // また欄内スクロール位置もリセットされてしまうので、処理前にスクロール位置を保持し、戻す
    if (this.inputHeightDefault === undefined) {
      this.inputHeightDefault = this.getInputHeightDefault(input);
    }
    const inputScrollCurrent = input.scrollTop;
    inputWrap.style.height = inputWrap.clientHeight + "px";
    input.style.height = "0";
    input.style.height =
      Math.max(this.inputHeightDefault, input.scrollHeight) + "px";
    inputWrap.style.height = "";

    input.scrollTo(0, inputScrollCurrent);
  }

  /** エリアルール */
  private get TextAreaRules(): (string | boolean)[] {
    return [this.CounterRule, ...this.SaveCheckRules];
  }

  /** 最大文字数ルール */
  private get CounterRule(): string | boolean {
    if (!this.showCounter) {
      return true;
    }

    if (this.Counter > this.MaxCount) {
      return this.MaxCount + "文字以内で入力して下さい";
    }
    return true;
  }

  /** 最大文字数 */
  private get MaxCount(): number {
    return this.limitPerLine * this.maxLines - this.lastCharMinus;
  }

  /** 入力文字数テキスト */
  private get CounterText(): string {
    let counterText = String(this.Counter);
    if (!this.unneedMaxCount) {
      counterText += ` / ${this.MaxCount}`;
    }

    return counterText;
  }

  /** 現在入力中の入力文字数 */
  private get Counter(): number {
    if (this.simpleCounter) {
      return this.simpleCountChar(this.Value)[0];
    } else {
      return this.rowCountChar(this.Value)[0];
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.calcInputHeight();
    });
  }

  //単純文字計測
  private simpleCountChar(newValue: string): [number, string] {
    if (!newValue) {
      return [0, newValue];
    }
    const length = [...newValue].length;
    return [length, newValue];
  }

  //行文字計測
  private rowCountChar(newValue: string): [number, string] {
    let counter = 0;
    if (!newValue) {
      return [counter, newValue];
    }

    //行数割り出し
    const chunks = newValue.split(/\r*\n/g);
    const lines: string[] = [];
    const regexp = new RegExp(".{1," + this.limitPerLine + "}", "g");

    //改行ごとに繰り返し
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const matches = chunk.match(regexp);
      if (matches === null) {
        lines.push("");
      } else {
        //1行あたりの最大文字数単位で繰り返し
        for (const match of matches) {
          lines.push(match);
        }
      }
    }

    //最後一個前の行数 + 最後の行の文字数
    counter = this.limitPerLine * (lines.length - 1);
    const lastRow = lines[lines.length - 1];
    counter += [...lastRow].length;
    return [counter, newValue];
  }
}
