

































































import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";

@Component({
  components: {},
})
export default class KintaiLinkInfoViewer extends Mixins(UtilMixin) {
  /** iBowKintai連携情報 */
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;

  @Prop({ default: 0 })
  isAttendanceUse!: number;

  /** 統一日付フォーマット */
  private DATE_FORMAT = "yyyy年MM月dd日";

  /** 有料版契約期間(表示用) */
  private get paidContractPeriod() {
    // 開始・終了ともに空白の場合は空文字列を返却
    if (
      this.kintaiLinkInfo.paid_contract_start == "" &&
      this.kintaiLinkInfo.paid_contract_end == ""
    ) {
      return "";
    }

    const startDt = this.newDate(this.kintaiLinkInfo.paid_contract_start);
    const endDt = this.newDate(this.kintaiLinkInfo.paid_contract_end);

    return `${this.dateToStr(startDt, this.DATE_FORMAT)} 〜 ${this.dateToStr(
      endDt,
      this.DATE_FORMAT
    )}`;
  }
}
