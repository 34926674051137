


















import { Component, Prop, Vue, Emit } from "vue-property-decorator";
@Component
export default class AppFilterButton extends Vue {
  @Prop({ default: false }) private isFiltered!: boolean;
  @Prop({ default: "絞り込み" }) private label!: string;
  @Prop({ default: "grey" }) private defaultColor!: string;
  @Prop({ default: "" }) private defaultTextColor!: string;
  @Emit("click") click(event: Event) {
    return event;
  }
}
