























































import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Mixins
} from "vue-property-decorator";
import { Choice } from "../types";
import AppIcon from "./AppIcon.vue";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

interface AutoComplete extends Vue {
  cachedItems: [];
}
@Component({
  components: { AppIcon }
})
export default class SearchSelect extends Mixins(RulesSaveMixin) {
  @Ref("autocomplete") private readonly autocomplete!: AutoComplete;

  @Prop({ default: "" }) label!: string;

  @Prop({
    default: () => {
      return [];
    }
  })
  items!: Choice[];

  @Prop({
    default: () => {
      return [];
    }
  })
  value!: number[];

  @Prop({ default: "48px" }) height!: string;

  @Prop({ default: "100%" }) width!: string;

  @Prop({ default: "225px" }) minWidth!: string;

  @Emit() private input(val: (string | number)[]): (string | number)[] {
    return val;
  }

  @Emit("click:all") private clickAll() {
    return;
  }

  @Emit("click:clear") public clear() {
    this.localValue = [];
  }

  private search = "";

  private catch = [];

  public get localValue(): (string | number)[] {
    return this.value;
  }

  public set localValue(val) {
    // this.$emit("input", val);
    this.input(val);
  }

  // cachedItemをクリア
  public resetCachedItems() {
    this.autocomplete.cachedItems = [];
  }
  // searchInputをクリア
  public resetSearchInput() {
    this.search = "";
  }

  private get linkesAll(): boolean {
    return this.localValue.length === this.items.length;
  }

  private get linkesSome() {
    return this.localValue.length > 0 && !this.linkesAll;
  }

  public toggle() {
    this.$nextTick(() => {
      if (this.linkesAll) {
        // 全て選択済みの場合
        this.localValue = [];
      } else if (this.search) {
        // 検索文字がある場合
        // 選択肢を取得
        const searchItemsAll = this.items
          .filter(item => {
            return item.text.includes(this.search);
          })
          .map(item => item.value);

        // 選択済と検索結果が等しい場合
        if (searchItemsAll.toString() === this.localValue.toString()) {
          // 全て解除
          this.localValue = [];
        } else {
          // 一旦全て解除
          this.localValue = [];
          // 検索文字列が含まれる選択肢をチェック
          this.localValue = searchItemsAll;
        }
      } else {
        // １つ以上選択済み && 未選択
        this.localValue = this.items.map(item => item.value);
      }
      this.clickAll();
    });
  }

  public get icon() {
    if (this.linkesAll) return "mdi-close-box";
    if (this.linkesSome) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }
}
