














































import Component from "vue-class-component";
import { Prop, Emit, Mixins } from "vue-property-decorator";
import { TEMPLATE_STATUS } from "@/consts/inquiry";
import { Choice, TemplateSearchObj } from "@/types";
import { Category, HashtagChoice } from "@/model/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import HashtagAutoComplete from "@/components/inquiry/HashtagAutoComplete.vue";

interface CategoryChoice extends Choice {
  /** カテゴリの深さ */
  depth: number;
}

@Component({
  components: {
    HashtagAutoComplete,
  },
})
export default class TemplateSearch extends Mixins(AxiosMixin) {
  /** キーワード選択肢 */
  @Prop({ default: () => [] }) keywords!: HashtagChoice[];

  /** 検索条件 */
  @Prop() searchObj!: TemplateSearchObj;

  /** テンプレートの状態変数 */
  private TEMPLATE_STATUS = [
    { text: "すべてのステータス", value: 0 },
    ...TEMPLATE_STATUS,
  ];

  /** 選択肢用カテゴリ一覧 */
  private categoryChoices: CategoryChoice[] = [
    { text: "すべて", value: 0, depth: 0 },
  ];

  /** 初回時の処理 */
  public async created() {
    this.fetchCategory();
  }

  /** カテゴリマスタ情報取得 */
  private fetchCategory() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/categorys/get",
      {},
      (res) => {
        this.generateCategoryListRecursively(res.data.categorys);
      }
    );
  }

  /**
   * カテゴリマスタデータを再帰的に探索してカテゴリ一覧Choiceデータを生成
   */
  private generateCategoryListRecursively(src: Category[], depth = 0) {
    src.forEach((category: Category) => {
      this.categoryChoices.push({
        text: category.category_name,
        value: category.id,
        depth: depth,
      });

      // 子カテゴリが存在しない(=最下位カテゴリ)の場合は探索打ち切り
      if (!category.child_categorys || category.child_categorys.length == 0) {
        return;
      }

      // 子カテゴリが存在する場合は再帰的に処理を行う
      this.generateCategoryListRecursively(category.child_categorys, depth + 1);
    });
  }

  /** 検索クリック時 */
  @Emit("search")
  private search() {
    return;
  }
}
