



















































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { ListPromptTemplate } from "@/views/aivnplan/types";

@Component({
  components: {
    AppAuthButton,
  },
})
/**
 * プロンプトテンプレート一覧
 */
export default class PromptTemplateList extends Mixins(AxiosMixin, UtilMixin) {
  /** 変数 */

  /** フィルター用キーワード */
  private searchKeyword = "";

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  /** データセット */
  private promptTemplates: ListPromptTemplate[] = [];

  /** データテーブル用ヘッダー */
  private headers = [
    {
      text: "テンプレート名",
      align: "start",
      value: "name",
      width: "180px",
      filterable: true,
      sortable: false,
    },
    {
      text: "コメント",
      align: "start",
      value: "comment",
      width: "180px",
      filterable: true,
      sortable: false,
    },
    {
      text: "AI種類",
      align: "start",
      value: "llm_type_name",
      filterable: false,
      width: "180px",
    },
    {
      text: "最終更新者",
      align: "start",
      value: "editor_admin_name",
      filterable: false,
      sortable: false,
    },
    {
      text: "最終更新日時",
      align: "start",
      value: "updated_at",
      filterable: false,
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
    },
  ];

  /** 関数 */

  created() {
    this.fetch();
  }

  /** 取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/prompts/get",
      {},
      async (res) => {
        this.promptTemplates = res.data.prompt_templates;
      }
    );
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** 編集画面へ遷移 */
  private moveEditPage(id: number) {
    this.$router.push({ path: `/ai-vnplan/prompt/edit/${id}` });
  }
}
