

































































































































































































































import { Component, Mixins, Prop, Watch, Emit } from "vue-property-decorator";
import { Agreement, AgreementHistory } from "@/model/agreement";
import {
  DEFAULT_AGREEMENT,
  DEFAULT_AGREEMENT_HISTORY,
} from "@/model/agreement/agreement";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { IBOW_RECEIPT_DIV } from "@/consts/const";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import ChangeHistory from "@/components/common/ChangeHistory.vue";

@Component({ components: { AppAuthButton, ChangeHistory } })
export default class ChangeContractDialog extends Mixins(
  UtilMixin,
  AxiosMixin
) {
  @Prop({ default: DEFAULT_AGREEMENT }) agreement!: Agreement;

  private agreementHistoryBefore = DEFAULT_AGREEMENT_HISTORY;
  private agreementHistoryAfter = DEFAULT_AGREEMENT_HISTORY;

  private isOpen = false;

  private saveHistoryLog = false;

  private IBOW_RECEIPT_DIV = IBOW_RECEIPT_DIV;

  @Watch("agreement", { deep: true })
  private setBeforeAndAfterHistorys() {
    const befores = this.agreement.agreement_historys.filter(
      (history) => history.confflag == 1
    );
    const afters = this.agreement.agreement_historys.filter(
      (history) => history.confflag == 2
    );

    this.agreementHistoryBefore =
      befores.length > 0 ? befores[0] : DEFAULT_AGREEMENT_HISTORY;

    this.agreementHistoryAfter =
      afters.length > 0 ? afters[0] : DEFAULT_AGREEMENT_HISTORY;
  }

  private getTelNo(history: AgreementHistory): string {
    const tels: string[] = [
      history.tel_area,
      history.tel_city,
      history.tel_num,
    ];
    return tels.join("-");
  }

  private getFaxNo(history: AgreementHistory): string {
    const faxs: string[] = [
      history.fax_area,
      history.fax_city,
      history.fax_num,
    ];
    return faxs.join("-");
  }

  /** 変更届の承認 */
  private async accept() {
    if (
      this.saveHistoryLog &&
      this.agreementHistoryAfter.change_message == ""
    ) {
      await this.$openAlert("変更メッセージを入力してください。");
      return;
    }

    if (!(await this.$openConfirm("変更を承認しますか？"))) {
      return;
    }

    if (!this.saveHistoryLog) {
      this.agreementHistoryAfter.change_message = "";
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/agreement/change",
      {
        agreement_id: this.agreement.id,
        history_after: this.agreementHistoryAfter,
        history_before: this.agreementHistoryBefore,
      },
      (res) => {
        this.agreementHistoryAfter = res.data.history_after;
        this.complete();
        this.close();
      }
    );
  }

  /** ダイアログオープン */
  open() {
    this.isOpen = true;
  }

  /** ダイアログクローズ */
  close() {
    this.isOpen = false;
  }

  @Emit()
  private complete() {
    return this.agreementHistoryAfter;
  }
}
