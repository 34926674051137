/**
 * 日付操作共通モジュール
 */

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/ja";
import utc from "dayjs/plugin/utc";
import wareki from "wareki";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.locale("ja");

/** Dateオブジェクトを指定のフォーマットに変換 */
export const dateToStr = (date: Date, format: string): string => {
  format = format.replace(/yyyy/g, date.getFullYear().toString());
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/M/g, "" + (date.getMonth() + 1));
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
  format = format.replace(/d/g, "" + date.getDate());
  format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
  format = format.replace(/H/g, "" + date.getHours());
  format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ("00" + date.getMilliseconds()).slice(-3));
  return format;
};

/** stringオブジェクトをDateオブジェクトに変換する */
export const strToDate = (str: string): Date => {
  return new Date(str.replace(/-/g, "/"));
};

/** 2021-01-01 00:00:00 -> [2021-01-01, 0, 0] */
export const convDispDateTime = (str: string): [string, number, number] => {
  let date = "";
  let hh = 0;
  let mm = 0;
  if (!str) {
    return [date, hh, mm];
  }
  const t = new Date(str.replace(/-/g, "/"));
  date = `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`;
  hh = t.getHours();
  mm = t.getMinutes();
  return [date, hh, mm];
};

/** [2021-01-01, 0, 0] -> 2021-01-01 00:00:00 */
export const convApiDateTime = (
  date: string,
  hh: number,
  mm: number
): string => {
  if (date === "") {
    return "";
  }
  const t = new Date(date.replace(/-/g, "/"));
  t.setHours(hh);
  t.setMinutes(mm);
  return dateToStr(t, "yyyy-MM-dd HH:mm");
};

/** 期間が他の期間と重複しているか */
export const checkInnerTime = (
  startDate: string,
  endDate: string,
  comStartDate: string,
  comEndDate: string
) => {
  // 登録期間・比較期間のどちらかが全期間であれば、重複する。
  if (startDate === "" && endDate === "") {
    return true;
  }
  if (comStartDate === "" && comEndDate === "") {
    return true;
  }
  // 重複しないケース：開始日が比較期間よりも後であれば、重複しない。
  if (startDate !== "" && comEndDate !== "" && comEndDate < startDate) {
    return false;
  }
  // 重複しないケース：終了日が比較期間よりも前であれば、重複しない。
  if (endDate !== "" && comStartDate !== "" && endDate < comStartDate) {
    return false;
  }
  return true;
};

/** 期間が指定月に収まっているか */
export const checkInnerYearmonth = (
  startDate: string,
  endDate: string,
  yearmonth: string
) => {
  const monthLastDate = dayjs(yearmonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return checkInnerTime(startDate, endDate, yearmonth + "-01", monthLastDate);
};

/** 日付間の日数を取得 */
export const diffDate = (d1: Date, d2: Date): number => {
  let diff = d1.getTime() - d2.getTime();
  if (diff < 0) {
    diff = diff * -1;
  }
  return diff / (24 * 60 * 60 * 1000);
};

/** 分の差分を取得 */
export const diffMinute = (d1: Date, d2: Date): number => {
  let diff = d1.getTime() - d2.getTime();
  if (diff < 0) {
    diff = diff * -1;
  }
  return diff / (60 * 1000);
};

/** 日付間の月数を取得（日付は考慮しない） */
export const diffMonth = (d1: Date, d2: Date): number => {
  const d1Month = d1.getFullYear() * 12 + d1.getMonth();
  const d2Month = d2.getFullYear() * 12 + d2.getMonth();
  let diff = d1Month - d2Month;
  if (diff < 0) {
    diff = diff * -1;
  }
  return diff;
};

/** 日付がその月の何週目かを算出（日曜を週の初めとする） */
export const calcWeekNumber = (d: Date): number => {
  const date = new Date(d.getFullYear(), d.getMonth(), 1);
  // １日から対象日までの土曜日の数をカウント
  let saturdayCount = 0;
  while (d.getTime() > date.getTime()) {
    if (date.getDay() === 6) {
      saturdayCount++;
    }
    date.setDate(date.getDate() + 1);
  }
  return saturdayCount + 1;
};

/** YYYY-MM-DD(dd)を取得する */
export const jpDate = (date: Date): string => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("YYYY年MM月DD日(dd)");
};

/** dayjsを返却する */
export const dayjsDate = (
  date?: string | number | dayjs.Dayjs | Date | null | undefined
): dayjs.Dayjs => {
  return dayjs(date);
};

/**
 * 日付文字列をDateに変換
 * 文字列が渡されなかった場合は現在日時
 * @param str 日付文字列
 * @returns Date
 */
export const strToDateObj = (str?: string): Date => {
  return str ? dayjs(str).toDate() : new Date();
};

/// 曜日を取得する
export const getDayOfWeek = (d: Date): string => {
  return dayjs(d).format("dd");
};

/** 今日を文字列で返却する YYYY-MM-DD */
export const today = (): string => {
  return dayjs().format("YYYY-MM-DD");
};

/** 今月を文字列で返却する YYYY-MM */
export const thisMonth = (): string => {
  return dayjs().format("YYYY-MM");
};

/** 先月を文字列で返却する YYYY-MM */
export const lastMonth = (): string => {
  return dayjs()
    .subtract(1, "month")
    .format("YYYY-MM");
};

/** 今日が01〜10日かどうか */
export const todayIsBeforeTenth = (): boolean => {
  const day = Number(dayjs().format("D"));
  return day <= 10;
};

/** 今日が01〜10日の場合 前月 それ以外の場合 今月 を取得する */
export const lastOrThisMonth = (): string => {
  if (todayIsBeforeTenth()) {
    return lastMonth();
  } else {
    return thisMonth();
  }
};

/** 日付差分を取得（時間での判定でなく、実際の日付）*/
export const diffOfDay = (target: Date, from: Date): number => {
  // 差が24時間未満だと、同日と判断されてしまうため、時間を合わせる
  // dayjs(target) だと、例えば target が 2022-08-08 02:00:00+09:00 の場合
  // t は 2022-08-07 00:00:00Z となってしまうため tz を使用
  const t = dayjs
    .tz(target)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  const f = dayjs
    .tz(from)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  return t.diff(f, "day");
};

export const formatDate = (target: string, format: string): string => {
  return dayjs(target).format(format);
};

/** yyyy-MM-dd HH:mm形式の年月日または日時を、和暦に変換する */
export const toWarekiDatetime = (
  date: string,
  showDay = true,
  showHourmin = true
): string => {
  if (!date) return "";
  const dateSplit = date.match(/[^-\s:]+/g);
  if (!dateSplit) return "";

  // yyyy-MM
  // yyyy-MM-dd
  // yyyy-MM-dd HH:mm
  const [year, month, day, hour, minute] = dateSplit;

  // 月と日を０埋め（正確な和暦取得のため）
  let dateFormat = year + "-" + month.padStart(2, "0");;
  if (day != null) {
    dateFormat += "-" + day.padStart(2, "0");;
  }

  let warekiResult = wareki(`${dateFormat}`, { unit: true }) + month + "月";
  if (showDay) {
    warekiResult += day + "日";
  }
  if (showHourmin) {
    warekiResult += ` ${hour}時${minute}分`;
  }

  return warekiResult;
};
