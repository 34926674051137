import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { mutations } from "@/store/mutations";
import { RootState } from "@/store/types";
import { user } from "@/store/modules/user";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "0.0.1",
    is_registerd_auth_navigation: false,
  },
  mutations,
  modules: {
    user,
  },
};

export default new Vuex.Store<RootState>(store);
