











import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import NumberInputString from "./NumberInputString.vue";

@Component({
  components: {
    NumberInputString
  }
})
export default class ZeroDispNumberInput extends Vue {
  /** 値 */
  @Prop({ default: "" }) value!: string | number | null;

  /** バリデーションルール */
  @Prop({ default: () => [] }) rules!: (string | boolean)[];

  /** 最大桁数 */
  @Prop({ default: "" }) maxlength!: string | number;

  /** 幅 */
  @Prop({ default: "100%" }) width!: string;

  /** 最低幅 */
  @Prop({ default: "225px" }) minWidth!: string;

  /** 必須化 */
  @Prop({ default: false }) required!: boolean;

  /** 初期値0を空で表示するか */
  @Prop({ default: false }) isNeedDispEmptyFirst!: boolean;

  /** 画面上で入力した値 */
  private setValue = "";

  /** 値を変更したかどうか */
  private isChanged = false;

  @Emit("input")
  private input(value: string): string | number {
    const num = Number(value);
    if (isNaN(num)) {
      return value;
    }
    return num;
  }

  @Watch("setValue")
  private watchSetValue() {
    // 入力があればフラグを立てる
    this.isChanged = true;
  }

  private get InnerValue(): string {
    // 入力していないが初期値0を「0」として表示したい場合
    if (this.value === 0 && !this.isChanged && !this.isNeedDispEmptyFirst) {
      this.setValue = "0";
      return "0";
    }

    // 「0」と入力している
    if (this.value === 0 && this.setValue !== "") {
      return "0";
    }

    return !this.value ? "" : this.value + "";
  }

  private set InnerValue(value: string) {
    this.setValue = value;
    this.input(value);
  }

  private get Rules() {
    if (this.setValue === "" && this.InnerValue === "" && this.required) {
      return ["必須です"];
    }
    return this.rules;
  }
}
