






































import { Component, Mixins } from "vue-property-decorator";
import ContractDetailNavigation from "@/components/contractreception/ContractDetailNavigation.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Agreement, AgreementHistory } from "@/model/agreement";

@Component({
  components: {
    ContractDetailNavigation,
  },
})
export default class AgreementContractHistory extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  private drawer = false;

  /** ナビゲーションドロワーで表示する契約情報 */
  private currentItem = {} as Agreement;

  /** ナビゲーションドロワーで表示する契約履歴ID */
  private targetHistoryId = 0;

  private agreement: Agreement = {} as Agreement;

  private headers = [
    {
      text: "履歴保存日時",
      value: "updated_at",
      align: "start",
      width: "",
    },
    {
      text: "変更メッセージ",
      value: "change_message",
      align: "start",
      width: "",
      sortable: false,
    },
  ];

  public created(): void {
    if (this.$route.params.id)
      this.getAgreementHistorys(Number(this.$route.params.id));
  }

  private getAgreementHistorys(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      {
        agreement_id: id,
        ids: [id],
        need_change_history: 1,
      },
      (res) => {
        this.agreement = res.data.agreements[0];
        this.agreement.agreement_historys = this.agreement.agreement_historys
          .filter((history: AgreementHistory) => {
            return history.change_message && history.change_message != "";
          })
          .sort((a, b) => {
            if (this.newDate(a.updated_at) > this.newDate(b.updated_at))
              return -1;
            if (this.newDate(a.updated_at) < this.newDate(b.updated_at))
              return 1;
            return 0;
          });
      }
    );
  }

  private rowClicked(history: AgreementHistory): void {
    this.drawer = !this.drawer;
    this.currentItem = this.agreement;
    this.targetHistoryId = history.id;
  }
}
