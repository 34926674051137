














































































































import { Component, Vue, Prop, Ref, Emit } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { ElemDataTable, TableItem } from "../types/table";
import AppTextField from "./AppTextField.vue";
import AppIcon from "./AppIcon.vue";
import AppButton from "./AppButton.vue";

@Component({
  components: {
    AppTextField,
    AppIcon,
    AppButton,
  },
})
export default class AppDataTable extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: () => [] }) headers!: DataTableHeader[]; //ヘッダー
  @Prop({ default: [] }) items!: TableItem[]; //アイテム
  @Prop({ default: () => [] }) value!: TableItem[]; //選択済みアイテム
  @Prop({ default: "id" }) itemKey!: string; //表アイテムのユニークキーとして使われるプロパティ名
  @Prop({ default: 1 }) pageNo!: number; //ページ番号
  @Prop({ default: 0 }) pageCount!: number; //ページ数
  @Prop({ default: 7 }) totalVisible!: number; //表示アイテム数
  @Prop({ default: false }) isNoHeader!: boolean; //ヘッダー非表示フラグ
  @Prop({ default: false }) isNoFooter!: boolean; //フッター非表示フラグ
  @Prop({ default: false }) showCheckFooter!: boolean; //チェックフッター表示フラグ
  @Prop({ default: false }) isNoTop!: boolean; //トップ非表示フラグ
  @Prop({ default: "" }) label!: string; //検索エリアのラベル
  @Prop({ default: "絞り込み" }) filterLabel!: string; //絞り込みボタンのラベル
  @Prop({ default: "" }) search!: string; //検索文字列
  @Prop({ default: "370px" }) searchWidth!: string; //検索欄横幅
  @Prop({ default: "データがありません" }) noDataText!: string; //1件もない時のメッセージ
  @Prop({ default: -1 }) frontOnlyPageCount!: number; //フロントだけで制御する場合の1ページあたりの表示件数（これを有効にした場合、フロントのみのページングになる）
  @Prop({ default: true }) isMultiSort!: boolean; //複数列ソートフラグ
  @Prop({ default: false }) isNoFilterButton!: boolean; //絞り込みボタン非表示フラグ
  @Prop({ default: false }) isNoFilterFront!: boolean; //フロントでの絞り込み無効フラグ
  @Prop({ default: false }) isFiltered!: boolean; //絞り込んでいる状態
  @Prop({ default: false }) isHeadPagination!: boolean; //画面上部ページネイション

  /** 合計件数（フロントだけで制御する場合以外の時） */
  @Prop({ default: 0 }) backTotal!: number;

  /** １ページあたりの件数（フロントだけで制御する場合以外の時） */
  @Prop({ default: 0 }) backPageItems!: number;

  //データテーブル
  @Ref("dataTable") private readonly dataTable!: ElemDataTable;

  private innerPageCount = 0;
  /** コンポーネントがマウントされたか */
  private isMounted = false;

  mounted() {
    this.isMounted = true;
  }

  private setPageCount(count: number) {
    this.innerPageCount = count;
  }

  @Emit("update:pageNo")
  private updatePageNo(newValue: number) {
    this.changePage(newValue);
    return newValue;
  }

  @Emit("update:search")
  private updateSearch(newValue: string) {
    return newValue;
  }

  @Emit()
  private getFilteredItems(items: TableItem[]) {
    return items;
  }

  @Emit()
  private changePage(page: number) {
    return page;
  }

  @Emit()
  private input(selects: unknown[]) {
    return selects;
  }

  @Emit()
  private openFilter() {
    return;
  }

  private get Value() {
    return this.value;
  }

  private set Value(selects: TableItem[]) {
    // 全選択/解除の場合、toggleSelectAllよりも後に実行する
    this.$nextTick(() => {
      this.updateSort(selects);
    });
  }

  private get PageNo() {
    return this.pageNo;
  }

  private set PageNo(newValue: number) {
    this.updatePageNo(newValue);
  }

  private get Search(): string {
    return this.search;
  }

  private set Search(newValue: string) {
    this.updateSearch(newValue);
  }

  private get DatatableSearch(): string {
    if (this.isNoFilterFront) {
      return "";
    } else {
      return this.Search;
    }
  }

  private get PageCount() {
    if (this.frontOnlyPageCount !== -1) {
      return this.innerPageCount;
    }
    return this.pageCount;
  }

  private get ScopeCols() {
    const slots: string[] = [];
    for (const scope in this.$scopedSlots) {
      if (scope == "footer" || scope == "top") {
        continue;
      }
      slots.push(scope);
    }
    return slots;
  }

  private get TableElem() {
    if (this.isMounted && this.dataTable && this.dataTable.$children) {
      return this.dataTable.$children[0];
    }
    return undefined;
  }

  /** フッターの総件数と現在件 */
  private get TotalInfo() {
    let total = 0;
    let first = 0;
    let last = 0;
    if (this.frontOnlyPageCount !== -1) {
      total = this.FilteredItems.length;
      first = (this.PageNo - 1) * this.frontOnlyPageCount + 1;
      last = this.PageNo * this.frontOnlyPageCount;
    } else {
      total = this.backTotal;
      first = (this.PageNo - 1) * this.backPageItems + 1;
      last = this.PageNo * this.backPageItems;
    }
    if (total == 0) {
      return "全 0 件";
    }
    if (last > total) {
      last = total;
    }
    return "全 " + total + " 件中 " + first + " 件 〜 " + last + " 件";
  }

  /** 一覧をフィルター・ソートされた状態で取得 */
  private get FilteredItems(): TableItem[] {
    // 表の要素を取得できなければ。一覧が空であると見なす
    if (!this.TableElem) {
      return [];
    }
    this.getFilteredItems(this.TableElem.filteredItems);
    return this.TableElem.filteredItems;
  }

  /** 表データ一覧 サーバーページングを考慮し、選択済みだが一覧に無い項目も含める */
  private get AllItems(): TableItem[] {
    const selectMap = new Map<string, TableItem>();
    // キーの重複は単に排除、同じキーなら必ず同じ内容であるため
    [...this.items, ...this.Value].forEach((item) => {
      selectMap.set(item[this.itemKey] as string, item);
    });
    const allItems = [...selectMap.values()];
    if (!this.TableElem) {
      return allItems;
    }
    return this.TableElem.sortItems(allItems);
  }

  private onChangeSort() {
    this.updateSort(this.Value);
  }

  /** 選択済み一覧を、表の並び替え設定にもとづいてソートする */
  private resortSelects(selects: TableItem[]): TableItem[] {
    const selectKeys = selects.map((select) => select[this.itemKey] as string);
    return this.AllItems.filter((item) => {
      return selectKeys.includes(item[this.itemKey] as string);
    });
  }

  /** ソート設定が変わる度に、選択済みリストもソートする */
  private updateSort(selects: TableItem[]) {
    const updatedSelects = this.resortSelects(selects);
    this.input(updatedSelects);
  }

  /** ヘッダーのチェックボックスを操作時 valueは全選択時true 解除時false */
  private toggleSelectAll(event: { value: boolean }) {
    const tableItems = this.FilteredItems;
    // 表の項目がない場合は何もしない
    if (tableItems.length === 0) {
      return;
    }
    let updatedSelects: TableItem[] = [];
    if (event.value) {
      // 全選択時、以前に選択して今はフィルタ非該当の項目も、選択したままにする
      updatedSelects = [...tableItems, ...this.Value];
    } else {
      // フィルタが設定されていれば、それに該当するアイテムだけが選択解除対象
      const deleteTargetKeys = tableItems.map((item) => item[this.itemKey]);
      updatedSelects = this.Value.filter((selectItem) => {
        return !deleteTargetKeys.includes(selectItem[this.itemKey]);
      });
    }
    updatedSelects = this.resortSelects(updatedSelects);
    this.input(updatedSelects);
  }
}
