/**
 * フィルタタイプ
 *
 * @var {[type]}
 */
export const FILTER_TYPE = {
  /** 通常 */
  EDIT: 0,
  /** 一覧選択方式 */
  LIST: 1,
  /** 一覧選択方式（編集可） */
  NUMBER: 2,
  /** 数値大小方式 */
  EDIT_LIST: 3,
  /** 日付選択方式 */
  DATE: 4,
  /** 日付選択方式 */
  TIME: 5,
  /** 部分一致のみ文字列検索 */
  EDIT_PARTIAL: 6,
  /** 日付選択方式 */
  MONTH: 7,
  /** 一覧選択方式(等しい・等しくない選択可能) */
  EQUAL_LIST: 8
};

export const NO_DATA = "データなし";

export const EXIST_DATA = "データあり";

export const MATCH_TYPE_PREFIX = 1; // 前方一致
export const MATCH_TYPE_PARTIAL = 2; // 部分一致
export const MATCH_TYPE_PERFECT = 3; // 完全一致
