

















import { Component, Vue } from "vue-property-decorator";
import AppIcon from "../AppIcon.vue";

@Component({
  components: { AppIcon }
})
export default class AppExpansionPanelHeader extends Vue {}
