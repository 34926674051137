import { Vue, Component } from "vue-property-decorator";
import { Term } from "#/model/master";

@Component
export default class AiRecommendMixin extends Vue {
  /** 配列中の要素を1つ前の要素と入れ替える */
  public swapWithPrevious<T>(arr: T[], index: number): T[] {
    if (index > 0 && index < arr.length) {
      return this.swap(arr, index, index - 1);
    }

    return arr;
  }

  /** 配列中の要素を1つ後ろの要素と入れ替える */
  public swapWithNext<T>(arr: T[], index: number): T[] {
    if (index >= 0 && index < arr.length - 1) {
      return this.swap(arr, index, index + 1);
    }

    return arr;
  }

  /** 配列中のある要素と別の要素を入れ替える */
  public swap<T>(arr: T[], srcIndex: number, distIndex: number): T[] {
    const copiedArr = arr.slice(0);

    if (srcIndex < 0 || srcIndex > copiedArr.length - 1) {
      return copiedArr;
    }
    if (distIndex < 0 || distIndex > copiedArr.length - 1) {
      return copiedArr;
    }

    const temp: T = copiedArr[srcIndex];
    copiedArr[srcIndex] = copiedArr[distIndex];
    copiedArr[distIndex] = temp;

    return copiedArr;
  }

  /** 公開設定でバリデーションチェックの有無を切り替える */
  private saveCheckRules(
    term: Term,
    rules: (string | boolean)[]
  ): (string | boolean)[] {
    return term.visibility == 0 ? [] : rules;
  }
}
