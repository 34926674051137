




































import { Component, Mixins } from "vue-property-decorator";
import HonobonoNavigation from "@/components/honobonosetting/HonobonoNavigation.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  Record,
  Detail,
  DefaultDetail,
  HonobonoOfficSetting,
  OfficeDiv,
} from "@/components/honobonosetting/honobonoOfficeCommon";

@Component({
  components: {
    HonobonoNavigation,
  },
})
export default class HonobonoOfficeList extends Mixins(AxiosMixin) {
  /* 定数 */

  // ほのぼの事業所設定
  private HonobonoOfficSetting = HonobonoOfficSetting;
  // 事業所区分
  private OfficeDiv = OfficeDiv;

  /* 変数 */

  // 詳細情報ナビゲーション展開状態
  private drawer = false;
  // テーブルヘッダ
  private headers = [
    {
      text: "",
      align: "center",
      sortable: false,
      value: "actions",
      width: "80px",
    },
    {
      text: "事業所名",
      value: "office_name",
    },
    {
      text: "区分",
      value: "office_div",
    },
    {
      text: "事業所番号／サテライト番号",
      value: "office_code",
    },
    {
      text: "ほのぼの事業所設定",
      value: "set_honobono",
    },
  ];
  // 行データ
  private items: Record[] = [];
  // 詳細データ
  private details: Detail[] = [];
  // 表示詳細データ
  private selectDetail = DefaultDetail();

  /* 関数 */

  public created(): void {
    if (this.$route.params.id) {
      this.fetch(Number(this.$route.params.id));
    }
  }

  // APIアクセス 事業所グループ一覧取得
  private fetch(officeId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/honobonosetting/offices/get",
      { office_id: officeId },
      (res) => {
        this.items = res.data.records;
        this.details = res.data.details;
      }
    );
  }

  // イベント 編集クリック
  private clickEdit(id: number) {
    this.$router.push({ path: "/honobono/office/edit/" + id });
  }

  // イベント 行クリック
  private clickRow({ id }: Record) {
    const detail = this.details.find((detail) => detail.id == id);
    if (!detail) return;
    this.selectDetail = detail;
    this.drawer = !this.drawer;
  }
}
