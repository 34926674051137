




















































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { VForm } from "@/types";
import { HonobonoSvKindCdChoices } from "@/components/honobonosetting/honobonoOfficeCommon";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { DefaultOffice, DefaultHonobonoOffice } from "#/model/agreement/office";

@Component
export default class HonobonoOfficeEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("form") private readonly form!: VForm;

  /* 定数 */

  // サービス種別
  private svKindChoices = HonobonoSvKindCdChoices;

  /* 変数 */

  private office = DefaultOffice();

  /* 関数 */

  public created() {
    this.fetch(Number(this.$route.params.id));
  }

  // APIアクセス ほのぼの事業所設定 詳細取得
  private fetch(officeId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/honobonosetting/office/get",
      { office_id: officeId },
      (res) => {
        console.log(res.data);
        this.office = res.data.office;
      }
    );
  }

  // APIアクセス ほのぼの事業所設定 保存
  private save() {
    this.postJsonCheck(
      window.base_url + "/api/admin/honobonosetting/office/save",
      { office: this.office },
      () => {
        this.$router.go(-1);
      }
    );
  }

  // イベント 保存
  private async clickSave() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.save();
  }

  // イベント 事業所追加
  private clickAdd() {
    if (!this.office.office_honobono_offices) {
      this.office.office_honobono_offices = [];
    }
    this.office.office_honobono_offices.push(DefaultHonobonoOffice());
  }

  // イベント 事業所削除
  private clickDel(idx: number) {
    this.office.office_honobono_offices.splice(idx, 1);
  }
}
