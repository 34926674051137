import Base from "../base";
import { SateliteHistorySameBuilding } from "./sateliteHistorySameBuilding";

/* サテライト履歴 */
export interface SateliteHistory extends Base {
  id: number; //ID
  office_id: number; //'事業所ID',
  parent_office_id: number; //'主たる事業所ID（サテライト）',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  satelite_no: number; //'サテライト番号',
  name: string; //'事業所名',
  post_code: string; //'郵便番号',
  pref: number; //'都道府県',
  city: string; //'市区',
  area: string; //'町村番地',
  building: string; //'建物',
  tel_area: string; //'市外局番',
  tel_city: string; //'市内局番',
  tel_num: string; //'加入者番号',
  fax_area: string; //'市外局番',
  fax_city: string; //'市内局番',
  fax_num: string; //'加入者番号',
  area_div: number; //'地域区分\n0:未選択\n1:1級\n2:2級\n3:3級\n4:4級\n5:5級\n6:6級\n7:7級\n8:その他',
  care_special_area_div: number; //'介護_特別地域加算\n0:なし\n1:あり',
  care_riyousya_gensan: number; //'介護_同一建物に居住する利用者の減算\n0:なし\n1:あり',
  care_small_office_scale_add: number; //‘介護_中山間地域等小規模事業所加算\n0:加算なし\n1:加算あり（予防・介護）\n2:加算あり（予防）\n3:加算あり（介護）‘
  start_date_wareki: string; //開始日（和暦）
  end_date_wareki: string; //終了日（和暦）
  satelite_history_same_buildings: SateliteHistorySameBuilding[]; //同一建物
  comment: string; //'コメント'
}

export const setSateliteHistory = (): SateliteHistory => ({
  id: 0, //ID
  office_id: 0, //'事業所ID',
  parent_office_id: 0, //'主たる事業所ID（サテライト）',
  start_date: "", //'開始日',
  end_date: "", //'終了日',
  satelite_no: 0, //'サテライト番号',
  name: "", //'事業所名',
  post_code: "", //'郵便番号',
  pref: 0, //'都道府県',
  city: "", //'市区',
  area: "", //'町村番地',
  building: "", //'建物',
  tel_area: "", //'市外局番',
  tel_city: "", //'市内局番',
  tel_num: "", //'加入者番号',
  fax_area: "", //'市外局番',
  fax_city: "", //'市内局番',
  fax_num: "", //'加入者番号',
  area_div: 0, //'地域区分\n0:未選択\n1:1級\n2:2級\n3:3級\n4:4級\n5:5級\n6:6級\n7:7級\n8:その他',
  care_special_area_div: 0, //'介護_特別地域加算\n0:なし\n1:あり',
  care_riyousya_gensan: 0, //'介護_同一建物に居住する利用者の減算\n0:なし\n1:あり',
  care_small_office_scale_add: 0, //‘介護_中山間地域等小規模事業所加算\n0:加算なし\n1:加算あり（予防・介護）\n2:加算あり（予防）\n3:加算あり（介護）‘
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "", //削除日時
  start_date_wareki: "", //開始日（和暦）
  end_date_wareki: "", //終了日（和暦）
  satelite_history_same_buildings: [], //同一建物
  comment: "" //'コメント'
});

export const DefaultSateliteHistory = setSateliteHistory;
