



































import { Component, Emit, Prop, Ref, Mixins } from "vue-property-decorator";
import RulesMixin from "../mixins/rulesMixin";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class NumberInputString extends Mixins(
  RulesMixin,
  RulesSaveMixin
) {
  /** フォーム */
  @Ref("form") private readonly form!: { lazyValue: string };
  /** ラベル */
  @Prop() label!: string;
  /** 値 */
  @Prop({ default: "" }) value!: number | null;
  /** 備考 */
  @Prop() remarks!: string;
  /** 高さ */
  @Prop({ default: "48px" }) height!: string;
  /** 幅 */
  @Prop({ default: "100%" }) width!: string;
  /** 最低幅 */
  @Prop({ default: "225px" }) minWidth!: string;
  /** 必須フラグ（ラベルに*がつくだけで必須チェックはしない） */
  @Prop({ default: false }) isNeed!: boolean;
  /** 小数点ありフラグ */
  @Prop({ default: false }) isDecimal!: boolean;
  /** 桁数 */
  @Prop({ default: "" }) maxlength!: string | number;
  /** 入力欄は無効か */
  @Prop({ default: false }) disabled!: boolean;
  /** バリデーションの制御 */
  @Prop({ default: true }) validateOnBlur!: boolean;

  /** inputエリア */
  private inputArea: HTMLInputElement | null = null;

  @Emit() private change(newValue: string) {
    return newValue;
  }

  @Emit() private blur(event: unknown) {
    return event;
  }

  @Emit() private focus(event: unknown) {
    return event;
  }

  @Emit() private input(value: string): string | null {
    // NumberInputから呼ばれた時に表示値が更新されるようにする
    this.form.lazyValue = value;
    return value;
  }

  private get LocalLabel(): string {
    if (this.isNeed) {
      return "*" + this.label;
    }
    return this.label;
  }

  private get LocalValue(): string {
    if (!this.value) {
      return "";
    }
    return this.value.toString();
  }

  private set LocalValue(value: string) {
    this.input(value.replace(/[^-.\d]/g, ""));
  }

  /** 数値入力ルール */
  private get NumberInputRules(): (string | boolean)[] {
    if (this.disabled) {
      // 無効なフォームではバリデーションも無効
      return [];
    }
    return [this.NumRule, this.MaxlengthRule, ...this.SaveCheckRules];
  }

  /** 数値ルール */
  private get NumRule(): string | boolean {
    let matchStr = /^(-?[1-9])?[0-9]*$/g;
    if (this.isDecimal) {
      matchStr = /^((-?[1-9])?([0-9]*)(\.[0-9]+)?|-0\.[0-9]+)$/g;
    }
    if (this.LocalValue.match(matchStr)) {
      return true;
    }
    return "半角数値で入力してください。";
  }

  /** 最大桁数ルール */
  private get MaxlengthRule() {
    if (!this.maxlength) {
      return true;
    }
    return this.minLength(
      String(this.value),
      Number(this.maxlength),
      this.maxlength + "桁以内で入力してください。"
    );
  }

  /** 入力タイプ */
  private get InputMode() {
    if (this.isDecimal) {
      return "decimal";
    }
    return "numeric";
  }

  mounted() {
    this.inputArea = this.$el.querySelector("input");
  }

  /**
   * 入力の変更後フォーカスが外れた時、数値形式でない入力なら補正する
   * 「3-」→「3」 「8.」→「8」 「5-6」→「」 「」→「」
   */
  private onChange(newValue: string) {
    const fixValue = newValue.replace(/^\.+|\D+$/, "");
    if (fixValue === "") {
      // 空文字または記号だけの場合、入力欄は空にする
      this.change(fixValue);
      return;
    }
    if (Number.isNaN(Number(fixValue))) {
      // 補正できない場合は空にする
      this.input("");
    } else if (fixValue !== newValue) {
      this.input(fixValue);
    }
    this.change(fixValue);
  }
}
