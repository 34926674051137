














import { Component, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { DefaultLlmParameter, LlmParameter } from "#/model/admin/index";
import RulesMixin from "#/mixins/rulesMixin";
import LLMParameterSetting from "@/components/ai/LLMParameterSetting.vue";

@Component({ components: { LLMParameterSetting } })
/**
 * OpenAI設定
 */
export default class OpenAISetting extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 入力値 */
  @Prop({ default: () => DefaultLlmParameter() }) value!: LlmParameter;

  /** 詳細パラメータ表示フラグ */
  @Prop({ default: () => false }) showParameterSetting!: boolean;

  /** 変数 */

  /** openAIモデル選択肢 */
  private openAiChoice = [
    { text: "gpt-4o", value: "gpt-4o" },
    { text: "gpt-4o-mini", value: "gpt-4o-mini" },
    { text: "gpt-4-turbo", value: "gpt-4-turbo" },
    { text: "gpt-4-turbo-preview", value: "gpt-4-turbo-preview" },
    { text: "gpt-4", value: "gpt-4" },
    { text: "gpt-3.5-turbo-16k", value: "gpt-3.5-turbo-16k" },
    { text: "gpt-3.5-turbo", value: "gpt-3.5-turbo" },
  ];
}
