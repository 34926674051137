


















import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import UserMixin from "@/mixins/userMixin";
import { AdminAuth, AdminAuthFunction } from "@/model/adminUser";

interface DispAdminAuth extends AdminAuth {
  admin_auth_functions: DispAdminAuthFunction[];
}

interface DispAdminAuthFunction extends AdminAuthFunction {
  editing_auths: DispAuth[];
  is_editing_str: string[];
}

interface DispAuth {
  name: string;
  disabled: boolean;
  allowed: number;
  type: number;
  check_digit: number;
  key: string;
}

@Component
export default class AppAuthButton extends Mixins(UserMixin) {
  /** ボタンにつけるラベル名 */
  @Prop({ default: "ボタン" }) private label!: string;
  /** ボタンにつける色 */
  @Prop({ default: "primary" }) private color!: string;
  /** ボタンにつけるクラス名 */
  @Prop({ default: "" }) private className!: string;
  /** サブ権限名(契約管理用権限で使用) */
  @Prop({ default: "is_edit" }) private subAuthName!: string;
  /** 権限以外によるボタンの無効化フラグ */
  @Prop({ default: false }) private disabled!: boolean;
  /** 権限によるボタンの無効化フラグ */
  private authorizedDisabled = true;

  /** ボタンを無効化するかどうかを決定する */
  @Watch("subAuthName", { immediate: true })
  onChanged() {
    // 現在のユーザー権限情報とルーティング情報に設定されている権限情報を比較

    // ルーティング情報に設定されている権限情報
    const routeAuth = this.$router.currentRoute.meta?.authName as string;
    if (!routeAuth) {
      // 設定されていない場合は無効化する
      this.authorizedDisabled = true;
      return;
    }

    // ログインユーザーの権限情報
    const userAuth = this.User.admin_auth;

    // adminFunctionに設定されているpathがルーティング情報のauthNameと一致しない場合はNG
    const filteredUserAuthFunctions = userAuth.admin_auth_functions.filter(
      (data) => {
        return data.function.path == routeAuth;
      }
    );

    // pathがルーティング情報のauthNameと一致しない場合はNG
    if (filteredUserAuthFunctions.length == 0) {
      this.authorizedDisabled = true;
      return;
    }

    // pathは存在しているが、編集権限が設定されていない場合はNG
    if (!filteredUserAuthFunctions[0].editing_auths[0].allowed) {
      this.authorizedDisabled = true;
      return;
    }

    // 編集権限は設定されているが、サブ権限がない場合はNG
    if (
      !filteredUserAuthFunctions[0].editing_auths.filter((data) => {
        return data.key == this.subAuthName && data.allowed;
      }).length
    ) {
      this.authorizedDisabled = true;
      return;
    }

    this.authorizedDisabled = false;
  }

  @Emit()
  click() {
    return;
  }
}
