











































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Agreement } from "@/model/agreement";
import { Question } from "@/model/research";
import SearchArea from "@/components/configuration/statistics/SearchArea.vue";
import EditorStaffCountViewer from "@/components/configuration/statistics/EditorStaffCountViewer.vue";
import ReceiptVisitRecordCountViewer from "@/components/configuration/statistics/ReceiptVisitRecordCountViewer.vue";
import AgreementStorageViewer from "@/components/configuration/statistics/AgreementStorageViewer.vue";
import InsuranceDivCountViewer from "@/components/configuration/statistics/InsuranceDivCountViewer.vue";
import AddDivCountViewer from "@/components/configuration/statistics/AddDivCountViewer.vue";
import QuestionCountViewer from "@/components/configuration/statistics/QuestionCountViewer.vue";
import {
  CATEGORY_RECEIPT_VISIT_RECORD,
  CATEGORY_RECEIPT_VISIT_RECORD_STAFF,
  CATEGORY_AGREEMENT_STORAGE,
  CATEGORY_INSURANCE_DIV,
  CATEGORY_ADD_DIV,
  CATEGORY_QUESTION,
} from "@/consts/configuration";
import { SearchObj } from "@/components/configuration/statistics/types";

@Component({
  components: {
    SearchArea,
    EditorStaffCountViewer,
    ReceiptVisitRecordCountViewer,
    AgreementStorageViewer,
    InsuranceDivCountViewer,
    AddDivCountViewer,
    QuestionCountViewer,
  },
})
export default class ConfigurationViewer extends Mixins(AxiosMixin, UtilMixin) {
  /** 全契約情報 */
  private agreements: Agreement[] = [];
  /** 全アンケート情報 */
  private questions: Question[] = [];

  /** 現在の集計対象 */
  private category = 0;

  /** 集計対象定数 */
  private CATEGORY_RECEIPT_VISIT_RECORD = CATEGORY_RECEIPT_VISIT_RECORD;
  private CATEGORY_RECEIPT_VISIT_RECORD_STAFF =
    CATEGORY_RECEIPT_VISIT_RECORD_STAFF;
  private CATEGORY_AGREEMENT_STORAGE = CATEGORY_AGREEMENT_STORAGE;
  private CATEGORY_INSURANCE_DIV = CATEGORY_INSURANCE_DIV;
  private CATEGORY_ADD_DIV = CATEGORY_ADD_DIV;
  private CATEGORY_QUESTION = CATEGORY_QUESTION;

  /** 検索条件 */
  private searchObj: SearchObj = {} as SearchObj;

  created() {
    // 検索エリアに渡す契約情報を取得
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      {},
      (res) => {
        this.agreements = res.data.agreements;
      }
    );

    // 検索エリアに渡すアンケート情報を取得
    this.postJsonCheck(
      window.base_url + "/api/admin/research/get",
      {},
      (res) => {
        this.questions = res.data.questions;
      }
    );
  }

  /** 検索ボタンクリック時 */
  private search(searchObj: SearchObj) {
    this.searchObj = searchObj;
    this.category = searchObj.category;
  }
}
