var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("変更届内容確認")]),_c('v-card-text',[(_vm.agreementHistoryAfter.id == 0)?[_c('v-alert',{attrs:{"type":"info","border":"left","outlined":""}},[_c('div',[_vm._v("変更届が提出されていません。")])])]:_vm._e(),(_vm.agreementHistoryAfter.id > 0)?[_c('v-simple-table',{staticClass:"elevation-1",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("企業名")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.agreementHistoryBefore.co_name))]),(
                        _vm.agreementHistoryBefore.co_name !=
                        _vm.agreementHistoryAfter.co_name
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.agreementHistoryAfter.co_name)+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("企業コード")]),_c('td',{attrs:{"align":"left"}},[_c('div',[_vm._v(_vm._s(_vm.agreement.co_code))])])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("郵便番号")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.agreementHistoryBefore.post_code))]),(
                        _vm.agreementHistoryBefore.post_code !=
                        _vm.agreementHistoryAfter.post_code
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.agreementHistoryAfter.post_code)+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("都道府県")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.getPrefName(_vm.agreementHistoryBefore.pref)))]),(
                        _vm.agreementHistoryBefore.pref !=
                        _vm.agreementHistoryAfter.pref
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.getPrefName(_vm.agreementHistoryAfter.pref))+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("市区町村")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.agreementHistoryBefore.city))]),(
                        _vm.agreementHistoryBefore.city !=
                        _vm.agreementHistoryAfter.city
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.agreementHistoryAfter.city)+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("番地")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.agreementHistoryBefore.area))]),(
                        _vm.agreementHistoryBefore.area !=
                        _vm.agreementHistoryAfter.area
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.agreementHistoryAfter.area)+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("建物名")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.agreementHistoryBefore.building))]),(
                        _vm.agreementHistoryBefore.building !=
                        _vm.agreementHistoryAfter.building
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.agreementHistoryAfter.building)+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("電話")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.getTelNo(_vm.agreementHistoryBefore))+"-")]),(
                        _vm.getTelNo(_vm.agreementHistoryBefore) !=
                        _vm.getTelNo(_vm.agreementHistoryAfter)
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.getTelNo(_vm.agreementHistoryAfter))+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("FAX")]),_c('td',{attrs:{"align":"left"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.getFaxNo(_vm.agreementHistoryBefore))+"-")]),(
                        _vm.getFaxNo(_vm.agreementHistoryBefore) !=
                        _vm.getFaxNo(_vm.agreementHistoryAfter)
                      )?[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-arrow-right")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.getFaxNo(_vm.agreementHistoryAfter))+" ")])]:_vm._e()],2)],1)]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("レセプト代行設定")]),_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.idToText( _vm.agreementHistoryBefore.receipt_agency_div, _vm.IBOW_RECEIPT_DIV ))+" ")])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("契約日")]),_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.agreementHistoryBefore.u_start_dt_wareki)+" ")])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("契約終了日")]),_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.agreementHistoryBefore.u_end_dt_wareki)+" ")])]),_c('tr',[_c('td',{staticClass:"head",attrs:{"align":"left"}},[_vm._v("備考")]),_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.agreementHistoryBefore.remarks)+" ")])])])]},proxy:true}],null,false,3517046429)}),_c('ChangeHistory',{staticClass:"mt-4",attrs:{"saveHistoryLog":_vm.saveHistoryLog},on:{"update:saveHistoryLog":function($event){_vm.saveHistoryLog=$event},"update:save-history-log":function($event){_vm.saveHistoryLog=$event}},model:{value:(_vm.agreementHistoryAfter.change_message),callback:function ($$v) {_vm.$set(_vm.agreementHistoryAfter, "change_message", $$v)},expression:"agreementHistoryAfter.change_message"}})]:_vm._e()],2),_c('v-card-actions',[(_vm.agreementHistoryAfter.id > 0)?_c('AppAuthButton',{attrs:{"color":"primary","label":"承認"},on:{"click":_vm.accept}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }