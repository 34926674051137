











































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Agreement } from "@/model/agreement";
import ContractDetailNavigation from "@/components/contractreception/ContractDetailNavigation.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import SearchBase from "@/components/admin_common/SearchBase.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { DataTableHeader } from "vuetify/types/index";
import {
  AGREEMENT_STATUS,
  AGREEMENT_STATUS_COLOR,
  OFFICE_STATUS,
  OFFICE_STATUS_COLOR,
} from "@/consts/agreement";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    ContractDetailNavigation,
    SearchBase,
    AppAuthButton,
  },
})
export default class ContractmanagementList extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  /** 未確定・確定ステータス */
  private AGREEMENT_STATUS = AGREEMENT_STATUS;
  private AGREEMENT_STATUS_COLOR = AGREEMENT_STATUS_COLOR;

  /** 事業所承認作業ステータス */
  private OFFICE_STATUS = OFFICE_STATUS;
  private OFFICE_STATUS_COLOR = OFFICE_STATUS_COLOR;

  /** 契約情報 */
  @Prop({ default: () => [] }) private agreements!: Agreement[];

  /** 絞込み用キーワード */
  @Prop({ default: "" }) private searchKey!: string;

  /** v-data-table用ヘッダー情報 */
  private headers: DataTableHeader[] = [
    {
      text: "",
      value: "actions",
    },
    {
      text: "企業名",
      value: "corp_name",
    },
    {
      text: "企業コード",
      value: "co_code",
    },
    {
      text: "事業所名",
      value: "office_name",
    },
    {
      text: "事業所コード",
      value: "kst_no",
    },
  ];

  /** v-data-table カスタムフィルタリング */
  customFilter(value: unknown, search: string, item: Agreement) {
    // 企業名、企業コード、事業所名、事業所コードで絞り込む
    const lowerCaseSearch = search.toLowerCase();

    return (
      item.co_name.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
      item.co_code.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
      item.office_names.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
      item.kst_nos.toLowerCase().indexOf(lowerCaseSearch) !== -1
    );
  }

  /** 詳細ボタンクリック時 */
  @Emit()
  private detail(id: number) {
    return id;
  }

  /** データテーブルの各行がクリックされた時 */
  @Emit()
  private row(agreement: Agreement) {
    return agreement;
  }
}
