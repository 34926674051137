import { GetterTree } from "vuex";
import { UserState } from "./types";
import { RootState } from "@/store/types";

export const getters: GetterTree<UserState, RootState> = {
  UserName: (state: UserState) => {
    if (state && state.user) {
      return state.user.last_name + " " + state.user.first_name;
    }
  },
};

export default getters;
