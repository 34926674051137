var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-input',{staticClass:"mb-2",attrs:{"hide-details":"auto","rules":_vm.saveCheckRules(_vm.Term, [
        _vm.requiredArray(
          _vm.Term.term_problems,
          '問題点は最低1つ入力してください。'
        ) ])}}),_vm._l((_vm.Term.term_problems),function(problem,index){return _c('div',{key:("problem-" + index)},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"mr-4",staticStyle:{"font-size":"1.1rem"}},[_c('span',[_vm._v("【問題点"+_vm._s(index + 1)+"】")])]),(_vm.Term.term_problems.length > 1)?_c('AppButton',{attrs:{"height":"30px","width":"30px","text":""},on:{"click":function($event){return _vm.removeProblem(index)}}},[_c('AppIcon',{attrs:{"icon":"garbage-can"}})],1):_vm._e(),_c('AppButton',{attrs:{"height":"30px","width":"30px","text":""},on:{"click":function($event){return _vm.upProblem(index)}}},[_c('AppIcon',{attrs:{"icon":"up"}})],1),_c('AppButton',{attrs:{"height":"30px","width":"30px","text":""},on:{"click":function($event){return _vm.downProblem(index)}}},[_c('AppIcon',{attrs:{"icon":"down"}})],1)],1),_c('AppTextArea',{attrs:{"showCounter":true,"simpleCounter":true,"limitPerLine":255,"maxLines":1},model:{value:(problem.problem),callback:function ($$v) {_vm.$set(problem, "problem", $$v)},expression:"problem.problem"}}),_c('v-input',{staticClass:"mb-2",attrs:{"hide-details":"auto","rules":_vm.saveCheckRules(_vm.Term, [
              _vm.required(problem.problem, '必須項目です。') ])}}),(index == _vm.Term.term_problems.length - 1)?_c('v-row',{attrs:{"no-gutters":""}},[_c('AppButton',{staticClass:"mb-4",attrs:{"height":"50px","width":"50px","text":""},on:{"click":function($event){return _vm.addProblem()}}},[_c('AppIcon',{attrs:{"icon":"add-1"}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('v-input',{attrs:{"hide-details":"auto","rules":_vm.saveCheckRules(_vm.Term, [
                _vm.requiredArray(
                  problem.term_solutions,
                  '解決策は最低1つ入力してください。'
                ) ])}}),_vm._l((problem.term_solutions),function(solution,solIndex){return _c('div',{key:("problem-" + index + "solution-" + solIndex)},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"mr-4",staticStyle:{"font-size":"1.1rem"}},[_c('span',[_vm._v("【解決策"+_vm._s(solIndex + 1)+"】")])]),(_vm.Term.term_problems[index].term_solutions.length > 1)?_c('AppButton',{attrs:{"height":"30px","width":"30px","text":""},on:{"click":function($event){return _vm.removeSolution(index, solIndex)}}},[_c('AppIcon',{attrs:{"icon":"garbage-can"}})],1):_vm._e(),_c('AppButton',{attrs:{"height":"30px","width":"30px","text":""},on:{"click":function($event){return _vm.upSolution(index, solIndex)}}},[_c('AppIcon',{attrs:{"icon":"up"}})],1),_c('AppButton',{attrs:{"height":"30px","width":"30px","text":""},on:{"click":function($event){return _vm.downSolution(index, solIndex)}}},[_c('AppIcon',{attrs:{"icon":"down"}})],1)],1),_c('AppTextArea',{attrs:{"showCounter":true,"simpleCounter":true,"limitPerLine":255,"maxLines":1},model:{value:(solution.solution),callback:function ($$v) {_vm.$set(solution, "solution", $$v)},expression:"solution.solution"}}),_c('v-input',{staticClass:"mb-2",attrs:{"hide-details":"auto","rules":_vm.saveCheckRules(_vm.Term, [
                  _vm.required(solution.solution, '必須項目です。') ])}})],1)}),_c('v-row',{attrs:{"no-gutters":""}},[_c('AppButton',{staticClass:"mb-4",attrs:{"height":"50px","width":"50px","text":""},on:{"click":function($event){return _vm.addSolution(index)}}},[_c('AppIcon',{attrs:{"icon":"add-1"}})],1)],1)],2)])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }