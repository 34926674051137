
















import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import RulesMixin from "../mixins/rulesMixin";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppFileInput extends Mixins(RulesMixin, RulesSaveMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** ラベル */
  @Prop() label!: string;

  /** 値 */
  @Prop() value!: File;

  /** ファイルサイズ制限 */
  @Prop({ default: 1e7 }) sizeLimit!: number;

  private inputKey = 0;

  @Emit() private input(newValue: File): File {
    return newValue;
  }

  private get Value(): File {
    return this.value;
  }

  private set Value(newValue: File) {
    this.input(newValue);
  }

  public reset() {
    this.inputKey++;
  }

  /** ファイルインプットルール */
  private get FileInputRules() {
    return [
      this.maxFileSize(
        this.Value,
        this.sizeLimit,
        `ファイルサイズが${this.sizeLimit / 1e6}MBを超えています`
      ),
      ...this.SaveCheckRules
    ];
  }
}
