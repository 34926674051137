import { Vue, Component } from "vue-property-decorator";
import { PersonnelContact, DoctorContact } from "#/model/uniquemaster";

/**
 * フォームバリデーションMixin
 */
@Component
export default class RulesMixin extends Vue {
  /**
   * 必須チェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | エラーメッセージ
   */
  required(str: string, message = ""): boolean | string {
    return !!str || message;
  }

  /**
   * 必須チェック（改行は文字として扱わない）
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | エラーメッセージ
   */
  requiredExLine(str: string, message = ""): boolean | string {
    return !!str.replace(/\n/g, "") || message;
  }

  /**
   * 必須チェック（数値）
   * @param str チェック対象の数値（0は許容 null,undefinedをチェック）
   * @param message チェックエラーメッセージ
   * @returns true | エラーメッセージ
   */
  requiredNumber(
    num: number | null | undefined,
    message = ""
  ): boolean | string {
    return num != null || message;
  }

  /**
   * 文字数チェック
   * @param str チェック対象の文字列
   * @param min 最小値
   * @param max 最大値
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  strLength(
    str: string,
    min: number,
    max: number,
    message = ""
  ): boolean | string {
    return !str || (min <= str.length && str.length <= max) || message;
  }

  /**
   * 文字数チェック（最大）
   * @param str チェック対象の文字列
   * @param limit 許容する長さ
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  minLength(str: string, limit: number, message = ""): boolean | string {
    return !str || str.length <= limit || message;
  }

  /**
   * 文字数チェック（最小）
   * @param str チェック対象の文字列
   * @param limit 許容する長さ
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  maxLength(str: string, limit: number, message = ""): boolean | string {
    return !str || str.length >= limit || message;
  }

  /**
   * 郵便番号チェック(数字 - ハイフン(任意) - 数字の構成)
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  postCode(str: string, message = ""): boolean | string {
    return !str || /^\d+(-)?\d+$/.test(str) || message;
  }

  /**
   * 半角数値チェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  halfWidthNumeric(str: string, message = ""): boolean | string {
    return !str || /^[0-9]+$/.test(str) || message;
  }

  /**
   * 半角少数チェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  halfWidthDecimal(str: string, message = ""): boolean | string {
    return !str || /^([1-9]\d*|0)(\.\d+)?$/.test(str) || message;
  }

  /**
   * 半角英数値チェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  halfWidthAlphNumeric(str: string, message = ""): boolean | string {
    return !str || /^[a-zA-Z0-9]+$/.test(str) || message;
  }

  /**
   * ひらがなチェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  hiragana(str: string, message = ""): boolean | string {
    return !str || /^[あ-ん゛゜ぁ-ぉゃ-ょー「」、ゔ\s]+$/.test(str) || message;
  }

  /**
   * 銀行委託者名チェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  hankanaBank(str: string, message: string | boolean = ""): boolean | string {
    return (
      !str ||
      /^[A-Z0-9ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝﾞﾟ()\-. ]+$/.test(
        str
      ) ||
      message
    );
  }

  /**
   * 銀行支店名チェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  hankanaBankBranchName(
    str: string,
    message: string | boolean = ""
  ): boolean | string {
    return (
      !str ||
      /^[A-Z0-9ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝﾞﾟ()\-.]+$/.test(
        str
      ) ||
      message
    );
  }

  /**
   * emailフォーマットチェック
   * @param str チェック対象の文字列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  emailFormat(str: string, message = ""): boolean | string {
    return (
      !str ||
      /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
        str
      ) ||
      message
    );
  }

  /**
   * 数値チェック（最小）
   * @param num チェック対象の数値
   * @param limit 許容する値
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  minNumber(num: number, limit: number, message = ""): boolean | string {
    return num >= limit || message;
  }

  /**
   * 数値チェック（最大）
   * @param num チェック対象の数値
   * @param limit 許容する値
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  maxNumber(num: number, limit: number, message = ""): boolean | string {
    return num <= limit || message;
  }

  /**
   * 数値チェック（最大と最小の間）
   * @param num チェック対象の数値
   * @param min 許容する最小値
   * @param max 許容する最大値
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  rangeNumber(
    num: number,
    min: number,
    max: number,
    message = ""
  ): boolean | string {
    return min <= num && num <= max || message;
  }

  /**
   * ファイル空チェック
   * @param file チェック対象のファイル
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  requiredFile(file: File | [], message = ""): boolean | string {
    if (file instanceof File) {
      return file.size > 0 || message;
    }
    return message;
  }

  /**
   * ファイルサイズチェック（最大）
   * @param file チェック対象のファイル
   * @param limit 許容する値
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  maxFileSize(file: File | [], limit: number, message = ""): boolean | string {
    if (file instanceof File) {
      return file.size < limit || message;
    }
    return true;
  }

  /**
   * 配列必須チェック
   * @param array チェック対象の配列
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  requiredArray(array: Array<unknown>, message: string) {
    return (array != undefined && array.length > 0) || message;
  }

  /**
   * 2つ目の日付が1つ目の日付以降かチェック
   * @param date1 1つ目の日付 yyyy-MM-dd形式
   * @param date2 2つ目の日付 yyyy-MM-dd形式
   * @param same 同日を許可するか true | false
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  dateAfter(date1: string, date2: string, same: boolean, message: string) {
    date1 = date1.replace(/-/g, "/");
    date2 = date2.replace(/-/g, "/");

    const date1Dt = new Date(date1);
    const date2Dt = new Date(date2);

    // 1つ目の日付が後に来る場合はNG
    if (date1Dt > date2Dt) {
      return message;
    }

    // 同日だが同日許可がない場合はNG
    if (date1Dt == date2Dt && !same) {
      return message;
    }

    // 同日で同日許可がある、もしくは1つ目の日付が先に来る場合はOK
    return true;
  }

  /**
   * 年月と年月の間の期間が指定した期間内であるかチェック
   * @param yearmonth1 1つ目の年月 yyyy-MM形式
   * @param yearmonth2 2つ目の年月 yyyy-MM形式
   * @param period 期間(月数)
   * @param message チェックエラーメッセージ
   * @returns true | チェックエラーメッセージ
   */
  checkYearmonthPeriod(
    yearmonth1: string,
    yearmonth2: string,
    period: number,
    message: string
  ) {
    yearmonth1 = yearmonth1.replace(/-/g, "/");
    yearmonth2 = yearmonth2.replace(/-/g, "/");

    const yearmonth1Dt = new Date(yearmonth1);
    const yearmonth2Dt = new Date(yearmonth2);

    yearmonth2Dt.setMonth(yearmonth2Dt.getMonth() - period);

    return yearmonth1Dt < yearmonth2Dt ? message : true;
  }

  /**
   * 担当者の連絡先形式であるかチェック
   * @param contact 関連機関担当者または医師の連絡先情報
   * @returns true | チェックエラーメッセージ
   */
  checkContactInfo(contact: PersonnelContact | DoctorContact) {
    if (contact.contact_type === 4 || contact.contact_type === 5) {
      // 連絡種類がemail(4)または携帯メール(5)なら、形式チェック
      return this.emailFormat(
        contact.contact_info,
        "メールアドレスの形式が正しくありません。"
      );
    }
    return true;
  }
}
